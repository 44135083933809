import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { Button, Input, Modal, Row, Col, Alert } from 'antd';
import { _t } from 'rosis-translation';
import { useValueEncryptor } from 'rosis-api';

function EncryptedValueEditor({ name, value, onChange, ...rest }) {
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalEditorValue, setModalEditorValue] = useState('');
  const [modalErrorMessage, setModalErrorMessage] = useState('');
  const queryClient = useQueryClient();
  const { mutateAsync: encrypt } = useValueEncryptor(queryClient);

  const clear = () => {
    onChange('');
  };
  const showModal = () => {
    setModalEditorValue('');
    setModalErrorMessage('');
    setModalVisible(true);
  };
  const tryEncryptValue = async (modalEditorValue) => {
    if (!modalEditorValue) {
      return modalEditorValue;
    }
    const response = await encrypt({ value: modalEditorValue });
    return response?.data;
  };
  const acceptModal = async () => {
    try {
      const valueToSet = await tryEncryptValue(modalEditorValue);
      onChange(valueToSet);
      setModalVisible(false);
    } catch (err) {
      setModalErrorMessage(_t('error::Cannot encrypt value'));
    }
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const actionLabel = _t('::Change value');
  return (
    <>
      {value !== undefined ? (
        <div className="input-encrypted-wrapper">
          <Input.Password name={name} value={value} visibilityToggle={false} readOnly {...rest} />
          <Button onClick={clear}>{_t('button::Erase')}</Button>
          <Button onClick={showModal}>{actionLabel}</Button>
        </div>
      ) : (
        <Button onClick={showModal}>{actionLabel}</Button>
      )}
      <Modal
        title={actionLabel}
        open={isModalVisible}
        onOk={acceptModal}
        onCancel={hideModal}
        okText={_t('::OK')}
        cancelText={_t('::Cancel')}
        okButtonProps={{ disabled: !modalEditorValue }}>
        <>
          {!!modalErrorMessage && (
            <Alert
              message={modalErrorMessage}
              type="error"
              showIcon
              style={{ marginBottom: '10px' }}
            />
          )}
          <Row gutter={16}>
            <Col span={24}>
              <Input.Password
                value={modalEditorValue}
                onChange={(e) => setModalEditorValue(e.target.value)}
              />
            </Col>
          </Row>
        </>
      </Modal>
    </>
  );
}

EncryptedValueEditor.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

EncryptedValueEditor.defaultProps = {
  name: '',
  value: undefined,
  onChange: () => {}
};

export default EncryptedValueEditor;
