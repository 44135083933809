import React, { forwardRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useNavigate as useRrNavigate,
  useParams,
  useLocation,
  NavLink as RrNavLink,
  Link as RrLink
} from 'react-router-dom';
import { useNavigationBlocker } from './navigation-blocker';

const useNavigate = (force = false) => {
  const { isBlockadeNeeded, setPendingBlockade } = useNavigationBlocker();
  const rrNavigate = useRrNavigate();
  const navigate = useCallback(
    (to, options) => {
      if (!force && isBlockadeNeeded) {
        setPendingBlockade({
          forceNavigate: () => rrNavigate(to, options)
        });
        return;
      }
      rrNavigate(to, options);
    },
    [rrNavigate, isBlockadeNeeded, setPendingBlockade]
  );
  return navigate;
};

const useHandleNavigationClick = ({
  to,
  replace,
  state,
  preventScrollReset,
  relative,
  onClick
}) => {
  const { isBlockadeNeeded, setPendingBlockade } = useNavigationBlocker();
  const rrNavigate = useRrNavigate();
  const options = useMemo(
    () => ({
      replace,
      state,
      preventScrollReset,
      relative
    }),
    [replace, state, preventScrollReset, relative]
  );
  const handleClick = useCallback(
    (e) => {
      if (onClick) {
        onClick(e);
      }
      if (e.defaultPrevented) {
        return;
      }
      if (isBlockadeNeeded) {
        setPendingBlockade({
          forceNavigate: () => rrNavigate(to, options)
        });
        e.preventDefault();
      }
    },
    [to, options, isBlockadeNeeded, setPendingBlockade, onClick]
  );
  return { handleClick };
};

const Link = forwardRef(function LinkRef(
  { to, replace, state, preventScrollReset, relative, onClick, ...props },
  ref
) {
  const { handleClick } = useHandleNavigationClick({
    to,
    replace,
    state,
    preventScrollReset,
    relative,
    onClick
  });
  return (
    <RrLink
      {...props}
      ref={ref}
      to={to}
      replace={replace}
      state={state}
      preventScrollReset={preventScrollReset}
      relative={relative}
      onClick={handleClick}
    />
  );
});

Link.propTypes = {
  preventScrollReset: PropTypes.bool,
  relative: PropTypes.oneOf(['route', 'path']),
  replace: PropTypes.bool,
  state: PropTypes.object,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

const NavLink = forwardRef(function NavLinkRef(
  { to, replace, state, preventScrollReset, relative, onClick, ...props },
  ref
) {
  const { handleClick } = useHandleNavigationClick({
    to,
    replace,
    state,
    preventScrollReset,
    relative,
    onClick
  });
  return (
    <RrNavLink
      {...props}
      ref={ref}
      to={to}
      replace={replace}
      state={state}
      preventScrollReset={preventScrollReset}
      relative={relative}
      onClick={handleClick}
    />
  );
});

NavLink.propTypes = {
  preventScrollReset: PropTypes.bool,
  relative: PropTypes.oneOf(['route', 'path']),
  replace: PropTypes.bool,
  state: PropTypes.object,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export { useNavigate, useParams, useLocation, Link, NavLink };
