import React from 'react';
import PropTypes from 'prop-types';
import { datetime } from 'rosis-date-picker';
import OffsetTimezone from 'rosis-components/OffsetTimezone';

const DateCell = ({ _createdAt }) => {
  return (
    <div className="created-at-cell">
      <div>{datetime(_createdAt).format('YYYY-MM-DD')}</div>
      <div>
        {datetime(_createdAt).format('HH:mm:ss')} <OffsetTimezone time={_createdAt} />
      </div>
    </div>
  );
};

DateCell.propTypes = {
  _createdAt: PropTypes.string
};

export default DateCell;
