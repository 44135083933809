import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppLoading from 'rosis-components/AppLoading';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { useCurrent } from 'rosis-api';
import { useAuth } from 'rosis-contexts/auth';

import useTranslationsLoader from './../hooks/translation-loader';

export const TranslationContext = createContext();

const { NODE_ENV } = process.env;
const isDevelop = NODE_ENV === 'development';

export function useTranslation() {
  const { direction, setDirection, changeLang, defaultLang } = useContext(TranslationContext);
  return {
    direction,
    setDirection,
    changeLang,
    defaultLang
  };
}

export const TranslationProvider = ({ children }) => {
  const [direction, setDirection] = useState('ltr');
  const { user } = useAuth();
  const { data: defaultLang, refetch } = useCurrent('lang');

  const { loading: isTranslationsLoading, isRtl } = useTranslationsLoader(defaultLang);

  useEffect(() => {
    refetch();
  }, [user]);

  const changeLang = () => {
    refetch();
  };

  useEffect(() => {
    setDirection(isRtl ? 'rtl' : 'ltr');
  }, [isRtl]);

  return (
    <TranslationContext.Provider value={{ direction, setDirection, changeLang, defaultLang }}>
      {isTranslationsLoading ? <AppLoading /> : children}

      {isDevelop ? (
        <KeyboardEventHandler
          handleKeys={['ctrl+i']}
          onKeyEvent={() => setDirection(direction === 'ltr' ? 'rtl' : 'ltr')}
        />
      ) : null}
    </TranslationContext.Provider>
  );
};

TranslationProvider.propTypes = {
  children: PropTypes.node.isRequired
};
