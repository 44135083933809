import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Table, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faInfoCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import { quadDot, queryMonitor } from 'system/symbol';
import { _t } from 'rosis-translation';
import ResetToDefault from 'components/buttons/ResetToDefault';
import { useConfig } from 'rosis-api';
import { useMemo } from 'react';

const parseMonitoringStatusKey = (val) => {
  return `${queryMonitor.clinicalMonitoringStatusPrefix}${quadDot}${val.trim()}`;
};

const formatMonitoringStatusKey = (val) => {
  return val?.split(quadDot).pop();
};

const FieldMonitoringStatuses = ({ value, label, onChange }) => {
  const { data: defaultFlagConfig } = useConfig('setup', 'field-flags');
  const defaultFlagValues =
    defaultFlagConfig
      ?.split(/\s+/)
      .map((v) => parseMonitoringStatusKey(v))
      .sort() || [];
  const [newItemValue, setNewItemValue] = useState('');
  const newItemInputRef = useRef();

  const editorValues = value || [];

  const isNewItemValid = () => {
    if (!newItemValue?.trim()) {
      return false;
    }
    if (editorValues.includes(parseMonitoringStatusKey(newItemValue))) {
      return false;
    }
    return true;
  };

  const removeStatus = (val) => {
    const newValues = editorValues.filter((v) => v !== val);
    onChange(newValues);
  };

  const addStatus = (val) => {
    const newVal = parseMonitoringStatusKey(val);
    const newValues = [...editorValues, newVal].sort();
    onChange(newValues);
    setNewItemValue('');
    newItemInputRef.current.focus();
  };

  const columns = [
    {
      key: 'name',
      width: '50%',
      title: _t('clinical-monitoring-status-column::Key'),
      render: (val) => formatMonitoringStatusKey(val)
    },
    {
      key: 'label',
      width: '50%',
      title: (
        <>
          {_t('clinical-monitoring-status-column::Label')}
          <Tooltip title={_t('::Status labels can be changed in Languages tab')}>
            <FontAwesomeIcon
              icon={faInfoCircle}
              size="xs"
              color="var(--roche-blue)"
              style={{ marginInlineStart: '5px' }}
            />
          </Tooltip>
        </>
      ),
      render: (val) => _t(val)
    },
    {
      kay: 'actions',
      render: (val) => (
        <Tooltip title={_t('button::Remove')} placement="bottom">
          <Button
            type={'link'}
            disabled={editorValues.length <= 1}
            icon={<FontAwesomeIcon icon={faTrash} />}
            onClick={() => removeStatus(val)}
          />
        </Tooltip>
      )
    }
  ];
  const isDefaultValue = useMemo(() => {
    if (!defaultFlagValues || !editorValues) {
      return defaultFlagValues === editorValues;
    }
    if (defaultFlagValues.length !== editorValues.length) {
      return false;
    }
    return defaultFlagValues.every((val, index) => val === editorValues[index]);
  }, [defaultFlagValues, editorValues]);
  const resetToDefaultClick = () => {
    onChange(defaultFlagValues, true);
  };
  const resetButton = <ResetToDefault onClick={resetToDefaultClick} />;
  return (
    <div className="field-monitoring-statuses-wrapper">
      <h3>
        {label}
        {!isDefaultValue ? <> {resetButton}</> : null}
      </h3>
      <Table
        columns={columns}
        dataSource={editorValues}
        bordered={false}
        size="small"
        pagination={false}
        rowKey={(v) => v}
      />
      <Input.Group style={{ display: 'inline-flex' }} compact>
        <Input
          ref={newItemInputRef}
          value={newItemValue}
          onChange={(e) => setNewItemValue(e.target.value)}
          onPressEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (isNewItemValid()) {
              addStatus(newItemValue);
            }
          }}
        />
        <Button
          type="primary"
          disabled={!isNewItemValid()}
          onClick={() => addStatus(newItemValue)}
          icon={<FontAwesomeIcon icon={faPlusCircle} style={{ marginInlineEnd: '10px' }} />}>
          {_t('button::Monitoring status')}
        </Button>
      </Input.Group>
    </div>
  );
};

FieldMonitoringStatuses.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func
};

export default FieldMonitoringStatuses;
