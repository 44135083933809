import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Input, Button } from 'antd';
import { useQueryClient } from 'react-query';
import { _t } from 'rosis-translation';
import { quadDot } from 'system/symbol';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';
import Conditions from 'components/Conditions';
import { useStatsByModel, useUpdateSchema } from 'rosis-api';
import { WarningOutlined } from '@ant-design/icons';

function Settings({ frameName, extra }) {
  const queryClient = useQueryClient();
  const [hint, setHint] = useState('');
  const { data: stats } = useStatsByModel(frameName);
  const { mutate: update, ...updateSchemaStatus } = useUpdateSchema(queryClient, frameName);
  const [conditions, setConditions] = useState(
    extra.conditions ? JSON.parse(extra.conditions) : []
  );

  useEffect(() => {
    if (extra?.hint?.includes(quadDot)) {
      let hintFromExtra = extra?.hint?.split(quadDot)[1];

      setHint(hintFromExtra);
    }
  }, [extra?.hint]);

  const changeExtra = (e) => {
    const { checked, name } = e.target;
    let model = {
      [name]: checked
    };
    update(model);
  };

  const handleChangeHint = (e) => {
    setHint(e.target.value);
  };

  const saveConditions = () => {
    const conditionsList = conditions.filter(([, field]) => field);
    update({ conditions: conditionsList?.length ? JSON.stringify(conditionsList) : null });
    setConditions(conditionsList);
  };

  useApiFeedback({
    status: updateSchemaStatus,
    silentProgress: true
  });

  return (
    <>
      <div className="schema-hint">
        <label htmlFor="hint">{_t('section-settings::Hint')}</label>
        <Input
          rows={4}
          name="hint"
          onChange={handleChangeHint}
          value={hint}
          onBlur={() => update({ hint })}
        />
        <div className="hint-section-input">
          <WarningOutlined />
          <small>
            {_t(
              '::If you change the hint, all its translations will be removed'
            )}
          </small>
        </div>
      </div>

      <Checkbox
        name="onlyOne"
        style={{ margin: '15px 0' }}
        disabled={stats?.count}
        onChange={changeExtra}
        defaultChecked={extra?.onlyOne}>
        {_t('section-settings::At most one per parent')}{' '}
      </Checkbox>
      <div>
        <Checkbox
          name="hide"
          style={{ margin: '0' }}
          onChange={changeExtra}
          defaultChecked={extra?.hide}>
          {_t('section-settings::Hide section')}
        </Checkbox>
      </div>
      {extra.loginPoint ? null : (
        <div className="conditions">
          <Conditions
            modelName={extra.parentName}
            conditions={conditions}
            setConditions={setConditions}
          />
          <Button type="primary" onClick={saveConditions}>
            {_t('button,store::Save conditions')}
          </Button>
        </div>
      )}
    </>
  );
}

Settings.propTypes = {
  extra: PropTypes.object,
  frameName: PropTypes.string,
  fields: PropTypes.array
};

export default Settings;
