import React from 'react';
import { Skeleton, Table, Empty } from 'antd';
import { _t } from 'rosis-translation';
import Pagination from 'components/Pagination';
import PropTypes from 'prop-types';

function UserList({ isLoading, data, page, setPage, elementsPerPage, setElementsPerPage }) {
  const { elements, next } = data || {};

  const columns = [
    {
      title: _t('site::Username'),
      dataIndex: 'idStr',
      key: 'idStr'
    }
  ];
  return isLoading ? (
    <Skeleton />
  ) : (
    <section>
      <Table
        columns={columns}
        size="middle"
        pagination={false}
        loading={isLoading}
        locale={{
          emptyText: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={_t('site::No users')} />
          )
        }}
        dataSource={elements}
        rowKey="_id"
      />
      <Pagination
        currentPage={page}
        next={next}
        elementsPerPage={elementsPerPage}
        countOfElements={elements?.length}
        setElementsPerPage={setElementsPerPage}
        changePage={setPage}
      />
    </section>
  );
}

UserList.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.shape({ element: PropTypes.array, next: PropTypes.bool }),
  page: PropTypes.number,
  setPage: PropTypes.func,
  elementsPerPage: PropTypes.number,
  setElementsPerPage: PropTypes.func
};

export default UserList;
