import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { _t } from 'rosis-translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEquals, faNotEqual } from '@fortawesome/free-solid-svg-icons';

function EqualOrNotEqual({ isEqual, onChange }) {
  return (
    <Tooltip title={isEqual ? _t('::Change to not equal') : _t('::Change to equal')}>
      <Button
        className="conditions__element__equal"
        icon={<FontAwesomeIcon icon={isEqual ? faEquals : faNotEqual} />}
        onClick={() => onChange(!isEqual)}
      />
    </Tooltip>
  );
}

EqualOrNotEqual.propTypes = {
  isEqual: PropTypes.bool,
  onChange: PropTypes.func
};

export default EqualOrNotEqual;
