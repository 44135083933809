import React from 'react';
import PropTypes from 'prop-types';
import { List, Empty, Typography, Row, Col } from 'antd';
import { sortableContainer } from 'react-sortable-hoc';
import Field from './Field';
import { useConfig } from 'rosis-api';
import { _t } from 'rosis-translation';
import DisclaimerMessage from 'components/DisclaimerMessage';

const { Title } = Typography;

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <List locale={{ emptyText: _t('::No fields') }} bordered>
      {children}
    </List>
  );
});

function FieldList({ frameName, fields, onChangeFieldOrder, onChangeFieldConfig }) {
  const { data: max } = useConfig('setup', 'maxDisplayFields');

  const handleDragEnd = ({ oldIndex, newIndex }) => {
    onChangeFieldOrder(fields[oldIndex].name, newIndex, oldIndex);
  };

  const selectedFields = fields.filter((item) => item.checked);
  const notSelectedFields = fields.filter((item) => !item.checked);

  const disableMin = selectedFields.length < 2;
  const disableMax = selectedFields.length >= parseInt(max);

  return (
    <>
      <Title level={3}>
        <Row justify="space-between">
          <Col>{_t('::Table columns')}</Col>
        </Row>
      </Title>
      {max && (
        <DisclaimerMessage
          lines={[
            _t(
              '::You can select 1 to {max} columns, which will be displayed in a table in the order presented below',
              { max }
            ),
            _t(
              '::Drag and drop columns to move it between the sections or to change the order of the selected columns'
            )
          ]}
        />
      )}
      <Row gutter={24}>
        <Col lg={16} xs={24}>
          <Title level={4} className="field-title">
            {_t('::Selected columns')}
          </Title>
          <SortableContainer onSortEnd={handleDragEnd} useDragHandle>
            {selectedFields.length ? (
              selectedFields.map((current, index) => (
                <Field
                  key={current.name}
                  index={index}
                  frameName={frameName}
                  data={current}
                  disableMin={disableMin}
                  onChangeField={onChangeFieldConfig}
                />
              ))
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={_t('::No fields')} />
            )}
          </SortableContainer>
        </Col>
        <Col lg={8} xs={24}>
          <Title level={4} className="field-title">
            {_t('::Remaining columns')}
          </Title>
          <SortableContainer>
            {notSelectedFields.length ? (
              notSelectedFields
                .filter((item) => !item.checked)
                .map((current, index) => (
                  <Field
                    key={current.name}
                    index={index}
                    frameName={frameName}
                    data={current}
                    disabled
                    disableMax={disableMax}
                    onChangeField={onChangeFieldConfig}
                  />
                ))
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={_t('::No fields')} />
            )}
          </SortableContainer>
        </Col>
      </Row>
    </>
  );
}

FieldList.propTypes = {
  frameName: PropTypes.string,
  fields: PropTypes.array,
  onChangeFieldOrder: PropTypes.func,
  onChangeFieldConfig: PropTypes.func,
  onSaveView: PropTypes.func
};

export default FieldList;
