import React from 'react';
import { _t } from 'rosis-translation';
import PropTypes from 'prop-types';
import ConfigSettings from 'components/ConfigSettings';
import PswdReq from './PswdReq';
import symbol from 'system/symbol';
import { calcSettingPageTitle } from '../utils';
import PageTitle from 'components/PageTitle';
import GridSectionEditor from 'components/ConfigSettings/GridSectionEditor';
import ExpirationTimeEditor from './ExpirationTimeEditor';
import { ConfigFormControlProvider } from 'components/ConfigSettings/ConfigFormControl';
import ConfigPageHeader from 'components/ConfigSettings/ConfigPageHeader';
import { useChange } from 'contexts/change';

const { panels } = symbol;

const getPasswordExpireSettings = () => {
  let result = [];
  for (let panel in panels) {
    result = [
      ...result,
      {
        name: `${panel}-passwdTTL`,
        group: 'pub',
        label: _t('::Maximum password age'),
        kind: 'custom',
        CustomComponent: ExpirationTimeEditor,
        editorProps: { min: 1, max: 365 },
        validationRules: [{ required: true, message: _t("::It's required") }]
      },
      {
        name: `${panel}-passwdChangeReminder`,
        group: 'pub',
        label: _t('::Begin prompting this many days before password expires'),
        kind: 'custom',
        CustomComponent: ExpirationTimeEditor,
        editorProps: { min: 1, max: 365 },
        validationRules: [{ required: true, message: _t("::It's required") }]
      }
    ];
  }
  return result;
};

function PasswordSettings({ title }) {
  const { isChanged, setIsChanged } = useChange();

  const settings = [
    {
      name: '0Aa!*',
      group: 'setup',
      label: _t('::Password requirements'),
      kind: 'custom',
      CustomComponent: PswdReq
    },
    {
      name: 'allowAccentedCharsInPasswords',
      group: 'setup',
      label: _t('::Allow regional/accented characters in password'),
      kind: 'bool'
    },
    {
      name: 'ui',
      label: _t('::User Interface'),
      subsettings: [
        {
          name: 'generatePsw',
          group: 'pub',
          label: _t('::Show password generator button on password change screens'),
          kind: 'bool'
        },
        {
          name: 'pswdWidget',
          group: 'pub',
          label: _t('::Show password requirements on password change screens'),
          kind: 'bool'
        }
      ]
    },
    {
      kind: 'custom',
      name: 'expired-pwd',
      subsettings: getPasswordExpireSettings(),
      label: _t('::Password expiration'),
      CustomComponent: GridSectionEditor,
      editorProps: {
        onGetCellIds: (item) => item.name.split('-'),
        titleColumns: [
          {
            title: _t('::Panel name'),
            dataIndex: 'key',
            render: (val) => _t(panels[val])
          }
        ]
      }
    }
  ];

  const pageTitle = calcSettingPageTitle(title);

  return (
    <ConfigFormControlProvider>
      <PageTitle title={pageTitle} />
      <ConfigPageHeader pageTitle={pageTitle} isDirty={isChanged} />
      <ConfigSettings settings={settings} onIsDirtyChange={setIsChanged} />
    </ConfigFormControlProvider>
  );
}

PasswordSettings.propTypes = {
  title: PropTypes.string
};

export default PasswordSettings;
