import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { _t } from 'rosis-translation';

function SFCustom({ name, label, required, errorMessage, children }) {
  const rules = [{ required: required, message: errorMessage }];
  return (
    <Form.Item name={name} label={label} rules={rules}>
      {children}
    </Form.Item>
  );
}

SFCustom.defaultProps = {
  errorMessage: _t("::It's required")
};

SFCustom.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  children: PropTypes.any
};

export default SFCustom;
