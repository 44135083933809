import { useQuery, useQueries, useMutation } from 'react-query';

import { url, getData, get, post, put, del } from './internal/rest-api';
import { useProjection, useMultipleProjections, noRetryFor403 } from './internal/utils';

export const useGetMyData = () => useQuery(['db', 'me'], () => getData(url`db/me`));

const _useDbItemsFull = (model, query, config = {}) => {
  return useQuery(
    ['db', 'data', model, JSON.stringify(query)],
    () => get(url`db/${model}`, { params: query }),
    { enabled: !!model, retry: noRetryFor403, ...config }
  );
};

const _useDbTableJoin = (query, config = {}) =>
  useQuery(['db', 'join', JSON.stringify(query)], () => post(url`db/join`, query), config);

const _useLadder = (model, id, config) =>
  useQuery(['db', 'ladder', model, id], () => getData(url`db/ladder/${model}/${id}`), config);

export const useDbCanRead = (model) =>
  useQuery(['db', 'can-read', model], () => getData(url`db/can-read/${model}`));

export const useCanReadByRole = (role) => useDbCanRead(role);

export const useCanRead = (frame) =>
  useQuery(['db', 'can-read', frame], () => getData(url`db/can-read/${frame}`));

export const useCanEdit = (frame) =>
  useQuery(['db', 'can-edit', frame], () => getData(url`db/can-edit/${frame}`));

export const useDisplayName = (frameName, id) =>
  useQuery(['db', 'display', frameName, id], () => getData(url`db/display/${frameName}/${id}`));

const _useStats = (models) =>
  useQueries(
    models.map((model) => ({
      queryKey: ['db', 'stats', model],
      queryFn: () => getData(url`db/get-stats/${model}`)
    }))
  );

export const useStatsByModel = (model) =>
  useQuery(['db', 'stats'], () => getData(url`db/get-stats/${model}`));

export const useUserEdit = (queryClient) =>
  useMutation(({ formData, role, editedId }) => put(url`db/store/${role}/${editedId}`, formData), {
    onSuccess: () => queryClient.invalidateQueries()
  });

export const useMeEdit = (queryClient) =>
  useMutation(({ formData }) => put(url`db/me`, formData), {
    onSuccess: () => queryClient.invalidateQueries()
  });

export const useEditMyProfile = (queryClient) =>
  useMutation(({ formData }) => put(url`db/me`, formData), {
    onSuccess: () => queryClient.invalidateQueries()
  });

export const useHasChildren = (model, id, config = {}) =>
  useQuery(
    ['db', 'data', model, 'has-children'],
    () => getData(url`db/has-leafs/${model}/${id}`),
    config
  );

export const useAddElement = (queryClient, model, query, frameId) =>
  useMutation(
    async ({ formData, editedId, reason }) => {
      const { data } = await put(url`db/store/${model}/${editedId}`, formData, {
        params: { ...query, reason }
      });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['db', 'data', model]);
        queryClient.invalidateQueries(['monitoring-status', model, frameId]);
      }
    }
  );

export const useRemoveElement = (queryClient, model) =>
  useMutation(
    async ({ id, reason }) => {
      const { data } = await del(url`db/${model}/${id}`, {
        params: { reason }
      });
      return data;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(['db', 'data', model])
    }
  );

//COMPLEX

export const useUserSimpleData = ({ id }, config = {}) => {
  const query = { find: { _id: id } };
  const queryResult = useDbItemsData('user', query, config);
  return useProjection(queryResult, (data) => data[0]);
};

const useDbItemsData = (frame, query, config) => {
  const queryResult = _useDbItemsFull(frame, query, config);
  return useProjection(queryResult, (data) => data.data);
};

const buildFindCriteria = (find) => {
  if (!find) {
    return null;
  }
  const result = { ...find };
  return result;
};

export const useElement = (name, id) => {
  const find = buildFindCriteria({ _id: id });
  const query = { find };
  const queryResults = useDbItemsData(name, query);
  return useProjection(queryResults, (data) => data?.[0]);
};

export const useElements = (name, options, queryOptions = {}) => {
  const { find, sortBy, page, elementsPerPage, creator, sites, showDeleted } = options || {};
  const query = {};
  const findCriteria = buildFindCriteria(find, showDeleted);
  if (findCriteria) {
    query.find = findCriteria;
  }
  if (sortBy) {
    query.sort = sortBy;
  }
  if (sites) {
    query.sites = sites;
  }
  if (page && elementsPerPage) {
    query.skip = (page - 1) * elementsPerPage;
    query.limit = elementsPerPage;
  }
  if (creator) {
    query.creator = creator;
  }
  const queryResult = _useDbItemsFull(name, query, { ...queryOptions, keepPreviousData: true });
  return useProjection(queryResult, (data) => {
    const result = {
      elements: data.data,
      next: data.headers['x-pager'] === 'More'
    };
    return result;
  });
};

export const useStats = (models) => {
  const queryResult = _useStats(models.map((m) => m.name));
  return useMultipleProjections(queryResult, (allData) => {
    return allData.map(({ data, ...rest }, index) => {
      const model = models[index];
      const result = { ...data, label: model?.label };
      return {
        data: result,
        ...rest
      };
    });
  });
};

export const useUserDetails = ({ user, model, showDeleted }) => {
  const find = buildFindCriteria({ user: user }, showDeleted);
  const query = { find };
  const queryResults = useDbItemsData(model, query);
  return useProjection(queryResults, (data) => data?.[0]);
};

export const useGetLadder = (model, id, config) => {
  const queryResults = _useLadder(model, id, config);
  return useProjection(queryResults, (data) => {
    const result = Array.isArray(data) ? data.slice() : [];
    return result.reverse();
  });
};

export const useDbJoinTable = (options) => {
  const query = options.map((option) => {
    const { find, sortBy, page, elementsPerPage, name } = option || {};
    const query = {};
    if (name) {
      query.name = name;
    }
    if (find) {
      query.find = find;
    }
    if (sortBy) {
      query.sort = sortBy;
    }
    if (page && elementsPerPage) {
      query.skip = (page - 1) * elementsPerPage;
      query.limit = elementsPerPage;
    }

    return query;
  });

  const queryResult = _useDbTableJoin(query, { keepPreviousData: true });
  return useProjection(queryResult, (data) => {
    const result = {
      elements: data.data,
      next: data.headers['x-pager'] === 'More'
    };
    return result;
  });
};
