import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Droppable } from 'react-beautiful-dnd';
import Actions from './Actions';
import TitleRow from './TitleRow';
import DndRow from './DndRow';

function Row({ provided, model, extendedModels, handleExtend, className, lastOne }) {
  const { innerRef, draggableProps, dragHandleProps } = provided || {};
  const { name, children } = model;
  const expended = extendedModels?.includes(name);
  return (
    <div
      key={name}
      ref={innerRef}
      className={classNames('table-dnd_row', lastOne ? 'last-row' : '', className)}
      {...draggableProps}>
      <div className="table-dnd_row_main">
        <TitleRow model={model} expended={expended} handleExtend={handleExtend} />
        <Actions {...model} dragHandleProps={dragHandleProps} />
      </div>
      {expended ? (
        <div className="table-dnd_sub-elements">
          <Droppable droppableId={name} type={name}>
            {({ droppableProps, placeholder, innerRef }) => (
              <div {...droppableProps} ref={innerRef}>
                {children?.map((subModel, index) => (
                  <DndRow
                    key={subModel.name}
                    index={index}
                    lastOne={children.length - 1 === index}
                    model={subModel}
                    extendedModels={extendedModels}
                    handleExtend={handleExtend}
                  />
                ))}
                {React.cloneElement(placeholder, { className: 'placeholder' })}
              </div>
            )}
          </Droppable>
        </div>
      ) : null}
    </div>
  );
}

Row.propTypes = {
  provided: PropTypes.object,
  model: PropTypes.shape({ name: PropTypes.string.isRequired, children: PropTypes.array }),
  extendedModels: PropTypes.array,
  handleExtend: PropTypes.func,
  className: PropTypes.string,
  lastOne: PropTypes.bool
};

export default Row;
