import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslationListByName } from 'rosis-api';
import { Popover, Tooltip } from 'antd';
import { _t } from 'rosis-translation';

function DisplayTranslationList({ name, children }) {
  const { data: translationList, refetch } = useTranslationListByName(name);

  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open]);

  const content = () => {
    return translationList
      ? translationList.map((item) => (
          <div key={item.value}>
            {item.lang} - {item.value}
          </div>
        ))
      : '';
  };

  return (
    <Popover
      content={content}
      title={name}
      trigger="click"
      placement="bottom"
      onOpenChange={handleOpenChange}>
      <Tooltip
        style={{ cursor: 'pointer' }}
        title={_t('::Click to see all translations for this key')}>
        {children}
      </Tooltip>
    </Popover>
  );
}

DisplayTranslationList.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.any
};

export default DisplayTranslationList;
