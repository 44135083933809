import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Card, Skeleton, Table, Empty, Space, Switch, Button, Tooltip } from 'antd';
import { withLayout } from 'layout';
import { Link } from 'rosis-components/navigation-wrapper';
import { useQueryClient } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faDownload, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'contexts/translation';
import { Upload, Download, AddNewLang } from 'components/buttons';
import TitleActions from 'components/TitleActions';
import {
  useLangs,
  apiUrl,
  useDefaultLang,
  useSetDefaultLang,
  useMissingTranslations
} from 'rosis-api';
import { _t } from 'rosis-translation';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import WarningInfoLang from '../../components/WarningInfoLang';
import './Languages.less';
import { useBreadcrumbs } from 'rosis-contexts/breadcrumbs';
import { usePageHeader } from 'contexts/pageHeader';
import PageTitle from 'components/PageTitle';
import { links } from 'router/links';

const DefaultLang = ({ id }) => {
  const queryClient = useQueryClient();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { changeLang } = useTranslation();
  const { mutate: change, isSuccess } = useSetDefaultLang(queryClient);
  const { data: defaultLang, refetch: refetchDefaultLang } = useDefaultLang();
  const changeDefaultLang = (id) => {
    change(id);
    changeLang(id);
  };

  useEffect(() => {
    setBreadcrumbs([
      {
        label: '',
        link: '/'
      },
      {
        label: _t('::Languages'),
        link: '/lang'
      }
    ]);
  }, []);

  useEffect(() => {
    refetchDefaultLang();
    changeLang();
  }, [isSuccess]);

  return (
    <Space>
      <Switch onChange={() => changeDefaultLang(id)} checked={defaultLang === id ? true : false} />
    </Space>
  );
};

DefaultLang.propTypes = {
  id: PropTypes.string
};

const Missing = ({ id, missingTranslations }) => (
  <WarningInfoLang lang={id} missingTranslations={missingTranslations} />
);

Missing.propTypes = {
  id: PropTypes.string,
  missingTranslations: PropTypes.object
};

const ActionButtons = ({ id }) => (
  <Tooltip title={_t('button::Edit')}>
    <Link key="edit" to={links.langDetails(id)}>
      <Button type="link">
        <FontAwesomeIcon icon={faPen} />
      </Button>
    </Link>
  </Tooltip>
);

ActionButtons.propTypes = {
  id: PropTypes.string
};

function Languages() {
  const { showInfoBar } = useInfoBar();
  const { data: languages, isLoading } = useLangs();
  const { data: missingTranslations, refetch: refetchMissingTranslations } =
    useMissingTranslations();

  const columns = [
    {
      title: _t('::Language name'),
      dataIndex: 'title',
      key: 'title',
      render: (title, record) => (
        <Link key="edit" to={links.langDetails(record.id)}>
          {title}
        </Link>
      )
    },
    {
      title: _t('::Language code'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: _t('::Default language'),
      key: 'defaultLang',
      render: ({ id }) => <DefaultLang id={id} />
    },
    {
      title: '',
      key: 'missing',
      render: ({ id }) => <Missing id={id} missingTranslations={missingTranslations} />
    },
    {
      title: _t('::Actions'),
      key: 'actions',
      render: ({ id }) => <ActionButtons id={id} />
    }
  ];

  const onSuccess = () => {
    showInfoBar({
      message: _t('info::Upload succeeded'),
      type: 'success'
    });
    refetchMissingTranslations();
  };
  const onError = (err, message) => {
    showInfoBar({
      message: _t(message || 'info::Upload failed'),
      type: 'error'
    });
  };

  const title = _t('::Languages');

  usePageHeader(
    <TitleActions
      actions={
        !isLoading
          ? [
              <AddNewLang key="addNewLang" existingLangs={languages} />,
              <Upload
                key="upload"
                action={`${apiUrl}/lang/set`}
                accept=".csv"
                onSuccess={onSuccess}
                onError={onError}>
                <>
                  <FontAwesomeIcon icon={faFileUpload} />
                  &nbsp;
                  {_t('::Upload')}
                </>
              </Upload>,
              <Download key="download" link={`${apiUrl}/lang/get`}>
                <>
                  <FontAwesomeIcon icon={faDownload} />
                  &nbsp;
                  {_t('button::Download')}
                </>
              </Download>
            ]
          : []
      }>
      {title}
    </TitleActions>,
    [languages, isLoading]
  );

  return (
    <>
      <PageTitle title={title} />
      <Card>
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <Table
              className="lang"
              columns={columns}
              size="middle"
              loading={isLoading}
              pagination={false}
              locale={{
                emptyText: (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={_t('::No languages')} />
                )
              }}
              dataSource={languages}
              rowKey="id"
            />
          </>
        )}
      </Card>
    </>
  );
}

export default withLayout(Languages);
