import * as displayAs from './displayAs';
import * as editAs from './editAs';
import * as additionalOptions from './additionalOptions';
import makeType from '../../makeType';

const datetime = makeType({
  name: 'DateTime',
  label: '::Date & Time',
  displayAs,
  editAs,
  additionalOptions
});

export default datetime;
