import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Input, Tooltip, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'rosis-components/navigation-wrapper';
import { _t } from 'rosis-translation';

function SecretQuestions({ questionsByUser, secretQuestions, onChangeAnswer }) {
  const onChange = (e, index) => {
    const { value } = e.target;

    onChangeAnswer(
      secretQuestions.map((question, i) => (i === index ? { ...question, value } : question))
    );
  };

  return (
    <Row className="question-list">
      <p style={{ margin: 0 }}>{_t('::Secret question')}:</p>
      {(questionsByUser || []).map((question, index) => (
        <span key={index} style={{ width: '100%' }}>
          <Col span={24} className="question">
            {_t(question.value)}
          </Col>
          <Col span={24}>
            <label>{_t('::Your answer')}:</label>
            <Input.Password autoComplete="off" visibilityToggle={true} onChange={(e) => onChange(e, index)} />
            <div className='pin_code__position'>
              <Link to="/pinCode">
                <Button style={{ paddingRight: 0 }} type="link">{_t('::PIN code')}</Button>
              </Link>
              <Tooltip title={_t('::Please use the PIN code you received from the system administrator in order to unlock your account.')}>
                <FontAwesomeIcon
                  className="pin-code__info"
                  icon={faInfoCircle}
                />
              </Tooltip>
            </div>
          </Col>
        </span>
      ))}
    </Row>
  );
}


SecretQuestions.propTypes = {
  questionsByUser: PropTypes.array.isRequired,
  secretQuestions: PropTypes.array,
  onChangeAnswer: PropTypes.func
};

SecretQuestions.defaultProps = {
  questionsByUser: []
};

export default SecretQuestions;
