import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { Layout, Space, Button, Badge, Alert, Row, Col } from 'antd';
import { Link } from 'rosis-components/navigation-wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useConfigs, apiUrl, useIsSystemReady, useMarkSystemAsReady } from 'rosis-api';
import createMenuListList from 'src/menuList';
import { useAuth } from 'rosis-contexts/auth';
import { bool } from 'system/helper/value';
import { links } from 'router/links';

const { Header } = Layout;

function AppHeader(props) {
  const { setVisible, isTabletOrDesktop } = props;
  const [systemReady, setSystemReady] = useState(true);
  const { data: isSystemReady } = useIsSystemReady();
  const { mutate: markSystemAsReady } = useMarkSystemAsReady();
  const { data: configOG } = useConfigs('opengraph');
  const { data: configPub } = useConfigs('pub');
  const logo = configOG?.['admin-logo'] || configOG?.logo;
  const title = configPub?.['admin-title'] || configPub?.title;
  const { devMode } = useAuth();

  const menuList = createMenuListList({ devMode });

  function handleOpen() {
    setVisible(true);
  }

  useEffect(() => {
    if (isSystemReady !== undefined) {
      setSystemReady(bool(isSystemReady));
    }
  }, [isSystemReady]);

  return (
    <Header className="top-main-menu site-layout-background">
      <Row justify="space-between" style={{ width: '100%' }}>
        <Col style={{ display: 'flex' }}>
          {' '}
          <Space className="logo bar-bottom-shadow">
            {logo && (
              <Link
                key="home"
                to={links.home()}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={`${apiUrl}${logo}`} alt="logo" />
              </Link>
            )}
          </Space>
          <Space>
            <Link key="home" to={links.home()}>
              <h1 className="main-menu-title">
                {title}
                {devMode < 0 ? (
                  <Badge dot={true} offset={[0, -10]} style={{ height: '10px', width: '10px' }} />
                ) : null}
              </h1>
            </Link>
          </Space>
          {!systemReady ? (
            <Space>
              <Alert
                message={_t('::System is not active')}
                type="error"
                action={<Button onClick={markSystemAsReady}>{_t('::Activate')}</Button>}
              />
            </Space>
          ) : null}
        </Col>
        <Col>
          {isTabletOrDesktop ? (
            menuList.top.map((el) => {
              if (el.content) {
                return (
                  <a key={el.key} href={el.href}>
                    {el.content}
                  </a>
                );
              } else {
                return (
                  <Button key={el.key} onClick={el.onClick.bind(this, props)}>
                    {typeof el.label === 'function' ? el.label(props) : el.label}
                  </Button>
                );
              }
            })
          ) : (
            <Button
              className="burger-btn"
              icon={<FontAwesomeIcon icon={faBars} size="2x" />}
              onClick={handleOpen}
            />
          )}
        </Col>
      </Row>

      <Space className="top-main-menu-element" align="center"></Space>
    </Header>
  );
}

AppHeader.propTypes = {
  setVisible: PropTypes.func,
  isTabletOrDesktop: PropTypes.bool
};

export default AppHeader;
