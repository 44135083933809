import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { _t } from 'rosis-translation';
import { SFSelect } from 'rosis-setting-form';
import { fieldTypes } from 'rosis-field-types';
import Example from './Example';

const fieldTypesList = Object.values(fieldTypes);

function DisplayOrEditAs({ name, initialValue, className }) {
  const { watch, setValue, getValues } = useFormContext();
  const [options, setOptions] = useState();
  const type = watch('type');
  const resultType = watch('resultType');
  const displayAs = watch('displayAs');
  const editAs = watch('editAs');

  const labels = {
    displayAs: _t('::Display as'),
    editAs: _t('::Edit as')
  };

  const preperOptions = () => {
    const field = fieldTypesList.find(
      (field) => field.name === (type === 'Calculated' ? resultType : type)
    );
    const options = field?.[name].filter(
      ({ hide }) => !(typeof hide === 'function' ? hide({ displayAs, editAs }) : hide)
    );
    return options;
  };

  useEffect(() => {
    if (type) {
      const displayOrEditOptions = preperOptions();
      setOptions(displayOrEditOptions);
      const defaultValue = displayOrEditOptions?.find((el) => el.default)?.value;
      setValue(
        name,
        displayOrEditOptions?.find((el) => el.value === initialValue) ? initialValue : defaultValue
      );
    }
  }, [type, resultType, initialValue]);

  useEffect(() => {
    setOptions(preperOptions());
  }, [displayAs, editAs]);

  useEffect(() => {
    if (options) {
      const value = getValues(name);
      options?.find((option) => option.value === value) || setValue(name, null);
    }
  }, [options]);

  const isShow = type === 'Calculated' ? name !== 'editAs' : true;

  return options && isShow ? (
    <Row className={className} gutter={16}>
      <Col span={12}>
        <SFSelect label={labels[name]} name={name} options={options} required />
      </Col>
      <Col className="field-form__example" span={12}>
        <Example name={name} type={type} options={options} />
      </Col>
    </Row>
  ) : null;
}

DisplayOrEditAs.propTypes = {
  name: PropTypes.string,
  initialValue: PropTypes.string,
  className: PropTypes.string
};

export default DisplayOrEditAs;
