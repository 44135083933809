import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { useNavigationBlocker } from 'rosis-components/navigation-blocker';

export function NavigationBlockModal({ title, okText, cancelText, children }) {
  const { pendingBlockade, setPendingBlockade, setIsBlockadeNeeded } = useNavigationBlocker();
  const handleOk = () => {
    const { forceNavigate } = pendingBlockade;
    setPendingBlockade(null);
    setIsBlockadeNeeded(false);
    forceNavigate();
  };
  const handleCancel = () => {
    setPendingBlockade(null);
  };
  return (
    <Modal
      title={title}
      open={!!pendingBlockade}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={okText}
      cancelText={cancelText}
      closable={true}>
      {children}
    </Modal>
  );
}

NavigationBlockModal.propTypes = {
  title: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  children: PropTypes.node
};
