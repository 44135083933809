import React from 'react';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Actions from '../Actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { _t } from 'rosis-translation';
import PropTypes from 'prop-types';
function FieldTable({ frameName, fields, cantRemove, fieldTypes, onMarkToRemove }) {
  return (
    <div>
      {fields?.length ? (
        <>
          <div className="fields-row fields-header">
            <div>{_t('::Field label')}</div>
            <div>{_t('::Field type')}</div>
            <div>{_t('::Actions')}</div>
          </div>
          <Droppable droppableId="fields">
            {({ droppableProps, placeholder, innerRef }) => (
              <div {...droppableProps} ref={innerRef}>
                {fields.map((field, index) => (
                  <Draggable key={field.name} draggableId={field.name} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className="fields-row"
                        style={{
                          ...provided.draggableProps.style
                        }}>
                        <div>
                          <span className="field-display-icon">
                            <span
                              className={classNames(
                                field?.required && 'require-star',
                                field?.hide && 'hide-field'
                              )}>
                              {_t(field.label)}
                            </span>
                            {field?.conditions && (
                              <Tooltip
                                title={_t('icon::Conditional field')}
                                className="conditions-icon">
                                [<FontAwesomeIcon icon={faEyeSlash} />]
                              </Tooltip>
                            )}
                          </span>
                        </div>

                        <div>{fieldTypes[field.type]?.label || field.type}</div>
                        <div>
                          <Actions
                            frameName={frameName}
                            record={field}
                            onMarkToRemove={onMarkToRemove}
                            cantRemove={cantRemove?.[field.name]}
                            dragHandleProps={provided.dragHandleProps}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {placeholder}
              </div>
            )}
          </Droppable>
        </>
      ) : (
        <div className="fields-row no-fields">{_t('::No fields')}</div>
      )}
    </div>
  );
}

FieldTable.propTypes = {
  frameName: PropTypes.string,
  fields: PropTypes.array,
  cantRemove: PropTypes.object,
  fieldTypes: PropTypes.object,
  onMarkToRemove: PropTypes.func
};

export default FieldTable;
