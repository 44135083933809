import { useMutation, useQuery } from 'react-query';

import { url, get } from './internal/rest-api';

export const useLogs = (options) => {
  const { find, sortBy, page, elementsPerPage, creator } = options || {};
  const query = {};
  if (find) {
    query.find = find;
  }
  if (sortBy) {
    query.sort = sortBy;
  }
  if (page && elementsPerPage) {
    query.skip = (page - 1) * elementsPerPage;
    query.limit = elementsPerPage;
  }
  if (creator) {
    query.creator = creator;
  }

  return useQuery(['logs', JSON.stringify(query)], () =>
    get(url`log`, { params: query }).then((data) => ({
      elements: data.data,
      next: data.headers['x-pager'] === 'More'
    }))
  );
};

export const useExportLog = (queryClient) =>
  useMutation((query) => get(url`log/csv`, { params: query }), {
    onSuccess: () => {
      queryClient.invalidateQueries();
    }
  });
