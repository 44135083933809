import React from 'react';
import Upload from 'rc-upload';
import PropTypes from 'prop-types';
import { getCsrfToken } from 'rosis-api/src/internal/interceptors';

function UploadButton({
  action,
  accept,
  multiple,
  onSuccess,
  onError,
  onProgress,
  itemRender,
  children
}) {
  return (
    <Upload
      component="button"
      className="ant-btn"
      accept={accept}
      action={action}
      headers={{
        'x-ctoq': getCsrfToken()
      }}
      multiple={multiple}
      onSuccess={onSuccess}
      onError={onError}
      onProgress={onProgress}
      itemRender={itemRender}
      withCredentials>
      {children}
    </Upload>
  );
}

UploadButton.propTypes = {
  action: PropTypes.string.isRequired,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onProgress: PropTypes.func,
  itemRender: PropTypes.func,
  children: PropTypes.node
};

UploadButton.defaultProps = {
  multiple: false
};

export default UploadButton;
