import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, Skeleton } from 'antd';
import { _t } from 'rosis-translation';
import RoleCheckbox from './RoleCheckbox';
import RoleCheckboxAll from './RoleCheckboxAll';
import { useModel } from 'rosis-api';
import { fieldPrivilegesSettings, isUpdatable } from './utils';

function getTitle(type, modelPrivileges, mtx, setPrivileges, fields) {
  const conditions = {
    read: modelPrivileges.includes('read'),
    update:
      fields.find(({ key }) => mtx[key]?.includes('read')) &&
      modelPrivileges.includes('update') &&
      modelPrivileges.includes('read')
  };

  return (
    <RoleCheckboxAll
      type={type}
      disabled={!conditions[type]}
      mtx={mtx}
      setPrivileges={setPrivileges}
      fields={fields}
    />
  );
}

function getRender(type, label, key, fileType, mtx, setPrivileges, modelPrivileges) {
  const filePrivileges = mtx?.[key] || [];
  const setFieldPrivileges = (type, value) => {
    setPrivileges(key, type, value);
  };

  const conditions = {
    read: modelPrivileges.includes('read'),
    update:
      modelPrivileges.includes('update') &&
      modelPrivileges.includes('read') &&
      filePrivileges.includes('read')
  };

  const hidden = {
    update: !isUpdatable(fileType)
  };

  return hidden[type] ? null : (
    <RoleCheckbox
      type={type}
      label={_t(label)}
      disabled={!conditions[type]}
      privileges={filePrivileges}
      setPrivileges={setFieldPrivileges}
    />
  );
}

function FieldPermissions({ frameName, mtx, setPrivileges, modelPrivileges }) {
  const { isLoading, data: model } = useModel(frameName);

  const columns = useMemo(() => {
    const result = [
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'label',
        render: (label) => _t(label)
      }
    ];
    if (!isLoading) {
      result.push(
        ...Object.entries(fieldPrivilegesSettings).map(([key, label]) => ({
          key: key,
          className: 'role-column-privileges',
          title: getTitle(key, modelPrivileges, mtx, setPrivileges, model.fields),
          render: (t, { key: name, type }) =>
            getRender(key, label, name, type, mtx, setPrivileges, modelPrivileges)
        }))
      );
    }
    return result;
  }, [isLoading, model, mtx]);

  return isLoading ? (
    <Skeleton />
  ) : (
    <Table
      showHeader={!!model?.fields?.length}
      columns={columns}
      dataSource={model.fields}
      pagination={false}
      locale={{ emptyText: _t('::No attributes') }}
    />
  );
}

FieldPermissions.propTypes = {
  frameName: PropTypes.string,
  mtx: PropTypes.object,
  setPrivileges: PropTypes.func,
  modelPrivileges: PropTypes.array
};

export default FieldPermissions;
