import React from 'react';
import { Button, Result } from 'antd';
import './ServerError.less';

export default function ServerError() {
  const handleClick = () => (window.location = `${process.env['PUBLIC_URL']}`);
  return (
    <Result
      className="server-error"
      title="Server issue"
      subTitle="Our server is having some problems and you may not be able to use our website and online services. 
      We're working to fix it as soon as we can, so try again in a few minutes"
      status="warning"
      extra={
        <Button type="primary" key="console" onClick={handleClick}>
          Try again
        </Button>
      }
    />
  );
}
