import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Space, Button } from 'antd';
import { _t } from 'rosis-translation';
import PinCodeInput from './PinCodeInput';
import PinCodeTimer from './PinCodeTimer';
import './PinCodeAuthentication.less';

const { Title, Text } = Typography;

function PinCodeAuthentication({ startTime, onFinish, restartTime, setTimer }) {
  const [pinResent, setPinResent] = useState(false);
  const [currentTime, setCurrentTime] = useState(startTime);
  const [pin, setPin] = useState('');
  const [isValid, setIsValid] = useState(false);

  const handleClick = () => onFinish(pin);

  const handleResend = () => {
    onFinish({ pin: null });
    setPinResent(true);
  };

  useEffect(() => {
    if (currentTime <= 0) {
      location.reload();
    }
  }, [currentTime]);

  useEffect(() => {
    restartTime && setCurrentTime(startTime);
  }, [restartTime]);

  useEffect(() => {
    const interval = setInterval(
      () =>
        setCurrentTime((current) => {
          if (current > 0) {
            return current - 1;
          } else {
            clearInterval(interval);
            return 0;
          }
        }),
      1000
    );
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    const blockBackButton = () => {
      setTimer(null);
      history.back();
    };
    window.addEventListener('popstate', blockBackButton);

    return () => {
      window.removeEventListener('popstate', blockBackButton);
    };
  }, []);

  return (
    <Space className="pin-code__wrapper" direction="vertical">
      <Title level={4}>{_t('::Two-Factor authentication code')}</Title>
      <Text>{_t('::Please provide the authentication PIN code to sign in')}</Text>
      <Text>
        {_t('::An email with your authentication code has been sent to you. The code expires in')}{' '}
        <PinCodeTimer currentTime={currentTime} />
      </Text>
      {currentTime ? <PinCodeInput onChange={setPin} onValidityChange={setIsValid} /> : null}
      <Text>
        {pinResent ? (
          _t('::A new email with PIN code has been sent again')
        ) : (
          <>
            {_t("::Didn't get your code?")}
            <Button type="link" onClick={handleResend}>
              {_t('::Click to resend')}
            </Button>
          </>
        )}
      </Text>
      {currentTime ? (
        <Button
          className="pin-code__button"
          type="primary"
          disabled={!isValid}
          onClick={handleClick}>
          {_t('::Sign in')}
        </Button>
      ) : null}
    </Space>
  );
}

PinCodeAuthentication.propTypes = {
  startTime: PropTypes.number.isRequired,
  onFinish: PropTypes.func.isRequired,
  restartTime: PropTypes.bool.isRequired,
  setTimer:PropTypes.func.isRequired
};

export default PinCodeAuthentication;
