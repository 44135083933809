import { useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

const filterAsArray = ['confirmation'];

const calcFind = (role, filters) => {
  const baseFilter = {
    role: role || { $ne: 'patient' }
  };
  const userFilters = Object.fromEntries(
    Object.entries(filters).map(([name, value]) => {
      if (name === 'penalty') {
        if (value === 'blocked') return [name, { $exists: true }];
        if (value === 'notBlocked') return [name, undefined];
        return [name, value];
      }
      if (name === 'sites') {
        //"sites" have own separate field so in "find" are always undefined
        return [name, undefined];
      }
      if (name === 'idStr') {
        return [name, value ? { $regex: value } : undefined];
      }
      return [name, value];
    })
  );
  const result = {
    ...baseFilter,
    ...userFilters
  };
  return result;
};

const calcSites = (filters) => {
  return filters.sites;
};

const isFilterChanged = (f1, f2) => {
  if (!f1 || !f2) {
    return f1 !== f2;
  }
  if (Object.keys(f1).length !== Object.keys(f2).length) {
    return true;
  }
  for (const key of Object.keys(f1)) {
    if (f1[key] !== f2[key]) {
      return true;
    }
  }
  return false;
};

const useUsersCriteria = ({ role, recentFilters, onChangeFiltering }) => {
  const [requestCriteria, setRequestCriteria] = useState({});
  const [elementsPerPage, setElementsPerPage] = useState(null);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(null);
  const [filters, setFilters] = useState(recentFilters || {});

  useEffect(() => {
    setPage(1);
    setRequestCriteria({
      find: calcFind(role, filters),
      sites: calcSites(filters),
      elementsPerPage,
      page: 1,
      sortBy
    });
  }, [role]);

  useEffect(() => {
    if (!recentFilters || !isFilterChanged(recentFilters, filters)) {
      return;
    }
    setFilters(recentFilters);
    updateRequestFilters(recentFilters);
  }, [recentFilters]);

  const changePagination = ({ current, perPage }) => {
    setPage(current);
    setElementsPerPage(perPage);
    setRequestCriteria({
      find: calcFind(role, filters),
      sites: calcSites(filters),
      elementsPerPage: perPage,
      page: current,
      sortBy
    });
  };

  const changeSortBy = (value) => {
    setSortBy(value);
    setRequestCriteria({
      find: calcFind(role, filters),
      sites: calcSites(filters),
      elementsPerPage,
      page,
      sortBy: value
    });
  };

  const updateRequestFilters = (filters) => {
    setRequestCriteria({
      find: calcFind(role, filters),
      sites: calcSites(filters),
      elementsPerPage,
      page,
      sortBy
    });
  };

  const debounceFilter = useCallback(
    debounce((filters) => {
      updateRequestFilters(filters);
    }, 500),
    []
  );

  const changeFilters = (field, val) => {
    const newVal = {
      ...filters,
      [field]: filterAsArray.includes(field) ? (val ? { $in: val.split(',') } : undefined) : val
    };
    setPage(1);
    setFilters(newVal);
    onChangeFiltering(newVal);
    const delayNeeded = ['idStr'].includes(field);
    if (delayNeeded) {
      debounceFilter(newVal);
    } else {
      updateRequestFilters(newVal);
    }
  };

  return {
    elementsPerPage,
    page,
    changePagination,
    sortBy,
    changeSortBy,
    filters,
    changeFilters,
    requestCriteria
  };
};

export default useUsersCriteria;
