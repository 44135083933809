import React from 'react';
import PropTypes from 'prop-types';
import { List, Button, Tooltip } from 'antd';
import { sortableElement, sortableHandle } from 'react-sortable-hoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsAlt,
  faArrowRight,
  faArrowLeft,
  faSort,
  faSortAmountUpAlt,
  faSortAmountUp
} from '@fortawesome/free-solid-svg-icons';
import { _t } from 'rosis-translation';
import './Views.less';

const DragHandle = sortableHandle(() => (
  <Tooltip title={_t('::Change order')} key="list-action-drag">
    <Button type="link" className="list-action-drag">
      <FontAwesomeIcon icon={faArrowsAlt} />
    </Button>
  </Tooltip>
));

const typeFieldsExludedSort = ['Virt', 'Multi', 'Select', 'Calculated', 'File'];

function Field({ data, onChangeField, disableMax, disableMin }) {
  const { label, checked, name, sortable, defSort, type } = data;

  const addNewFieldIntoList = () => {
    onChangeField(name, 'checked', true);
  };

  const removeFieldFromList = () => {
    onChangeField(name, 'checked', false);
  };

  const onChangeSort = () => {
    onChangeField(name, 'sortable', !sortable);
  };

  const onChangeDefaultSort = (value) => {
    onChangeField(name, 'defSort', value);
  };

  return (
    <List.Item
      className="draggble"
      actions={
        checked
          ? [
              <Tooltip
                title={_t(
                  typeFieldsExludedSort.includes(type)
                    ? '::You cannot sort on fields like: {typeFieldsExludedSort}'
                    : sortable
                    ? '::Sorting for this field is enabled'
                    : '::Sorting for this field is disabled',
                  {
                    typeFieldsExludedSort: typeFieldsExludedSort.join(', ')
                  }
                )}
                key="list-action-sort">
                <Button
                  type="link"
                  key="list-action-sort"
                  onClick={onChangeSort}
                  disabled={typeFieldsExludedSort.includes(type)}>
                  <FontAwesomeIcon icon={faSort} color={sortable ? 'primary' : '#B5B5C3'} />
                </Button>
              </Tooltip>,
              <Button type="link" key="list-action-default-sort" disabled={!sortable}>
                {defSort === 'ascd' ? (
                  <Tooltip
                    title={_t('::Default sorting ascending')}
                    key="list-action-sort-default-desc">
                    <FontAwesomeIcon
                      icon={faSortAmountUp}
                      onClick={() => onChangeDefaultSort('desc')}
                    />
                  </Tooltip>
                ) : defSort === 'desc' ? (
                  <Tooltip
                    title={_t('::Default sorting is descending')}
                    key="list-action-sort-default-remove">
                    <FontAwesomeIcon
                      icon={faSortAmountUpAlt}
                      onClick={() => onChangeDefaultSort('none')}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={_t('::Default sorting is disabled')}
                    key="list-action-sort-default-desc">
                    <FontAwesomeIcon
                      icon={faSortAmountUp}
                      color="#B5B5C3"
                      onClick={() => onChangeDefaultSort('ascd')}
                    />
                  </Tooltip>
                )}
              </Button>,
              <DragHandle key="list-action-drag" />,
              <Tooltip title={_t('::Remove field from view')} key="list-action-remove">
                <Button
                  type="link"
                  key="list-action-remove"
                  disabled={disableMin}
                  onClick={removeFieldFromList}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </Tooltip>
            ]
          : [
              <Tooltip title={_t('::Add field to view')} key="list-action-remove">
                <Button
                  type="link"
                  key="list-action-add"
                  disabled={disableMax}
                  onClick={addNewFieldIntoList}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
              </Tooltip>
            ]
      }>
      <List.Item.Meta title={label && _t(label)} />
    </List.Item>
  );
}

Field.propTypes = {
  frameName: PropTypes.string,
  onChangeField: PropTypes.func,
  disableMax: PropTypes.bool,
  disableMin: PropTypes.bool,
  data: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    checked: PropTypes.bool,
    label: PropTypes.string,
    sortable: PropTypes.bool,
    defSort: PropTypes.string
  })
};

export default sortableElement(Field);
