import React from 'react';
import { Menu, Button } from 'antd';
import { Link } from 'rosis-components/navigation-wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import SendMessageToUser from 'rosis-components/SendMessageToUser';
import { useConfig } from 'rosis-api';
import { bool } from 'system/helper/value';
import { _t } from 'rosis-translation';
import { useAuth } from 'rosis-contexts/auth';
import { links } from 'router/links';

function TopMenu() {
  const { data: messageToAnotherUser } = useConfig('setup', 'messageToAnotherUser');
  const { logout } = useAuth();

  return (
    <Menu className="top-menu">
      <Menu.Item key="profile" icon={<FontAwesomeIcon icon={faUser} />}>
        <Link to={links.myProfile()}>{_t('::My profile')}</Link>
      </Menu.Item>
      {bool(messageToAnotherUser) && (
        <Menu.Item key="message" icon={<FontAwesomeIcon icon={faEnvelope} />}>
          <SendMessageToUser />
        </Menu.Item>
      )}
      <Menu.Item key="logout" icon={<FontAwesomeIcon icon={faPowerOff} />}>
        <Button type="text" onClick={logout}>
          {_t('::Sign out')}
        </Button>
      </Menu.Item>
    </Menu>
  );
}
export default TopMenu;
