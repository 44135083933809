const { useState, useEffect } = require('react');
import { loadLanguageData } from 'rosis-translation';
import { useLang } from 'rosis-api';

const useTranslationsLoader = (language) => {
  const [loading, setLoading] = useState(true);
  const [isRtl, setRtl] = useState(false);
  const [isTranslationInitialized, setTranslationInitialized] = useState(false);

  const { data: langData, refetch: reloadLang } = useLang(language, { enabled: false });

  useEffect(() => {
    if (language) {
      setLoading(true);
      reloadLang();
    }
  }, [language]);

  useEffect(() => {
    if (language && langData) {
      const info = loadLanguageData(language, langData);
      setRtl(info.isRtl);
    }
  }, [language, langData]);

  useEffect(() => {
    if (loading && !!langData && isTranslationInitialized) {
      setLoading(false);
    }
  }, [langData, loading, isTranslationInitialized]);

  useEffect(() => {
    setTranslationInitialized(true);
  }, []);

  return {
    loading,
    isRtl
  };
};

export default useTranslationsLoader;
