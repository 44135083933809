import React, { useEffect, useState } from 'react';
import { Select, Form } from 'antd';
import { _t } from 'rosis-translation';
import { stdKeys } from 'system/helper/trans-key';
import { useUpdateLocal, useRemoveLocal, useLangs, useLocal } from 'rosis-api';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'contexts/translation';

const { Option } = Select;

function DefaultLang() {
  const queryClient = useQueryClient();
  const [selectedLang, setSelectedLang] = useState('');
  const { data: languages } = useLangs();
  const { data: localLang } = useLocal('lang');
  const { changeLang, defaultLang } = useTranslation();
  const { isSuccess: isSuccessChangeLang, mutate: updateLang } = useUpdateLocal(
    queryClient,
    'lang'
  );
  const { isSuccess: isSuccessRemoveLocal, mutate: removeLocal } = useRemoveLocal(
    queryClient,
    'lang'
  );

  useEffect(() => {
    if (isSuccessChangeLang || isSuccessRemoveLocal) {
      changeLang();
    }
  }, [isSuccessChangeLang, isSuccessRemoveLocal]);

  useEffect(() => {
    if (localLang) {
      setSelectedLang(localLang);
    } else {
      setSelectedLang('default');
    }
  }, [localLang, defaultLang]);

  const changeDefaultLang = (value) => {
    if (value === 'default') {
      removeLocal();
    } else {
      updateLang(value);
    }
  };

  return (
    <Form name="defaultLang" autoComplete="off">
      <Form.Item label={_t('::Change language')}>
        <Select
          id="defaultLang"
          value={selectedLang}
          style={{ width: 150 }}
          onSelect={changeDefaultLang}>
          <Option key="default" value="default">
            {_t('::Default language')}
          </Option>
          {languages &&
            languages.map((lang) => (
              <Option key={lang.id} value={lang.id}>
                {_t(stdKeys.lang(lang.id))}
              </Option>
            ))}
        </Select>
      </Form.Item>
    </Form>
  );
}

export default DefaultLang;
