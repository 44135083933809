import React from 'react';
import { Menu } from 'antd';
import { useLocation } from 'rosis-components/navigation-wrapper';
import createMenuListList from '../menuList';

function MenuContent() {
  const location = useLocation();
  const menuList = createMenuListList();
  const { main } = menuList;
  const selected = main.find((el) => location.pathname.startsWith(el.href)) || main[0];
  const selectedKey = selected?.key;
  return <Menu mode="inline" selectedKeys={[selectedKey]} items={menuList.main} />;
}

export default MenuContent;
