import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Card, Typography, Row, Col, Input, Button, Select, Popconfirm, Form, Tooltip } from 'antd';
import { withLayout } from 'layout';
import { useParams, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { quadDot } from 'system/symbol';
import { Link } from 'rosis-components/navigation-wrapper';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import { useAuth } from 'rosis-contexts/auth';
import {
  useEmailDetails,
  useEmailUpdate,
  useEmailLangs,
  useEmailRemove,
  useEmailDefault,
  useSendEmailWithTemplate,
  useResetMailTemplate,
  useSaveTemplateAsDefault,
  useEmailContext
} from 'rosis-api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faRetweet,
  faSave,
  faTimes,
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import AddNewEmailTemplate from 'components/buttons/AddNewEmailTemplate';
import { _t } from 'rosis-translation';
import EmailEditor from 'react-email-editor';
import './EmailTemplates.less';
import { usePageHeader } from 'contexts/pageHeader';
import TitleActions from 'components/TitleActions';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';
import PageTitle from 'components/PageTitle';
import { calcPageTitle } from 'src/utils/calcPageTitle';
import { useChange } from 'contexts/change';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const tools = {
  image: {
    properties: {
      src: {
        value: {
          url: '{:logo}',
          width: 200
        }
      },
      altText: {
        value: 'Logo'
      }
    }
  }
};

function EmailEdit() {
  const { name, lang } = useParams();
  const { isChanged, setIsChanged } = useChange();
  const { user, devMode } = useAuth();
  const { showInfoBar } = useInfoBar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [emailDetails, setEmailDetails] = useState({});
  const [bodyTemplateData, setBodyTemplateData] = useState('');
  const [editorReady, setEditorReady] = useState(false);
  const { data: langs } = useEmailLangs(name);
  const { data: defaultEmails, isLoading: isLoadingDefaultEmails } = useEmailDefault();
  const { data: emailContext } = useEmailContext();
  const { data: details, refetch: refetchDetails } = useEmailDetails(name, lang);
  const {
    isLoading: isLoadingUpdateEmail,
    mutate: update,
    ...updateEmailStatus
  } = useEmailUpdate(queryClient);
  const {
    isLoading: isLoadingSaveAsDefault,
    mutate: saveAsDefault,
    ...saveAsDefaultStatus
  } = useSaveTemplateAsDefault(queryClient);

  useApiFeedback({
    status: {
      isLoading: isLoadingUpdateEmail,
      ...updateEmailStatus
    },
    successMessage: _t('success::Email template saved'),
    errorMessage: _t('error::Email template edit failed'),
    onSuccess: () => {
      setIsChanged(false);
    }
  });

  const {
    isLoading: isLoadingResetTemplate,
    mutate: resetTemplate,
    ...resetTemplateStatus
  } = useResetMailTemplate(queryClient);

  useApiFeedback({
    status: {
      isLoading: isLoadingResetTemplate,
      ...resetTemplateStatus
    },
    successMessage: _t('success::The template has been reset'),
    errorMessage: _t('error::There was an error resetting the template')
  });

  useApiFeedback({
    status: {
      isLoading: isLoadingSaveAsDefault,
      ...saveAsDefaultStatus
    },
    successMessage: _t('success::The template has been saved as default')
  });

  const {
    mutate: sendTestEmail,
    isLoading: isLoadingSendTestEmail,
    ...sendTestEmailStatus
  } = useSendEmailWithTemplate(name);

  useApiFeedback({
    status: {
      isLoading: isLoadingSendTestEmail,
      ...sendTestEmailStatus
    },
    successMessage: _t('success::Test email sent'),
    errorMessage: _t('error::Send test email failed')
  });

  const {
    isSuccess: successRemoveEmail,
    error: errorRemoveEmail,
    isLoading: isLoadingRemoveEmail,
    mutate: remove
  } = useEmailRemove(queryClient);

  const emailEditorRef = useRef(null);

  const onReady = () => {
    emailEditorRef.current.editor.addEventListener('design:updated', function (...args) {
      if (args[0]?.type === 'content:modified') {
        setIsChanged(true);
      }
    });
    setEditorReady(true);
  };

  const cancelEdition = () => {
    setIsChanged(false);
    loadData();
  };

  const changeImagesUrlToVariable = (template) => {
    let stringTemplate = JSON.stringify(template);

    stringTemplate = stringTemplate
      .replace(`${emailContext[':urlLocal']}${emailContext[':logo']}`, '{:logo}')
      .replace(`${emailContext[':urlLocal']}${emailContext[':image']}`, '{:image}')
      .replace(`${emailContext[':urlLocal']}${emailContext[':icon']}`, '{:icon}');

    return stringTemplate;
  };

  const changeVariablesToImageUrl = (template) => {
    let stringTemplate = template;

    if (typeof template !== 'string') {
      stringTemplate = JSON.stringify(template);
    }

    stringTemplate = stringTemplate
      .replace('{:logo}', `${emailContext[':urlLocal']}${emailContext[':logo']}`)
      .replace('{:image}', `${emailContext[':urlLocal']}${emailContext[':image']}`)
      .replace('{:icon}', `${emailContext[':urlLocal']}${emailContext[':icon']}`);

    return JSON.parse(stringTemplate);
  };

  const loadData = () => {
    setEmailDetails(details);
    setBodyTemplateData(details?.bodyTemplateData ? details.bodyTemplateData : '');

    details?.bodyTemplateData &&
      emailEditorRef.current.editor.loadDesign(
        changeVariablesToImageUrl(details?.bodyTemplateData)
      );
  };

  useEffect(() => {
    if (editorReady && details) {
      loadData();
    }
  }, [editorReady, details]);

  useEffect(() => {
    successRemoveEmail && navigate(`/settings/email`);
    errorRemoveEmail &&
      showInfoBar({
        message: _t(errorRemoveEmail?.response?.data || 'error::Email template removing failed'),
        type: 'error'
      });
  }, [successRemoveEmail, errorRemoveEmail]);

  useEffect(() => {
    refetchDetails();
  }, [lang]);

  const saveEmail = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      update({
        label: emailDetails?.label,
        name: emailDetails?.name,
        subject: emailDetails?.subject,
        text: emailDetails?.text,
        lang: emailDetails?.lang,
        body: changeImagesUrlToVariable(html),
        bodyTemplateData: changeImagesUrlToVariable(design)
      });
    });
  };

  const resetEmailTemplate = () => {
    resetTemplate(name);
  };

  const saveTemplateAsDefault = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      saveAsDefault({
        label: emailDetails?.label,
        name: emailDetails?.name,
        subject: emailDetails?.subject,
        text: emailDetails?.text,
        lang: emailDetails?.lang,
        body: changeImagesUrlToVariable(html),
        bodyTemplateData: changeImagesUrlToVariable(design)
      });
    });
  };

  const removeTemplate = () => {
    remove({ name, lang });
  };

  const changeLang = (value) => {
    navigate(`/emailTemplates/${details.name}/${value}`);
  };

  const sendEmail = () => {
    sendTestEmail({
      to: user?.idStr || ''
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    setEmailDetails({ ...emailDetails, [name]: value });

    setIsChanged(true);
  };

  const title = calcPageTitle(_t('::Edit email template'), details?.label || details?.subject);

  const actions = useMemo(() => {
    const result = [
      <Form.Item key="change-lang">
        <Select defaultValue={lang} onChange={changeLang}>
          {Array.isArray(langs) &&
            langs.map((lang) => (
              <Option key={lang} value={lang}>
                {_t(`lang${quadDot}${lang}`)}
              </Option>
            ))}
        </Select>
      </Form.Item>,
      <AddNewEmailTemplate
        key="clone"
        title={_t('duplicate::Duplicate')}
        addIcon={false}
        subject={emailDetails?.subject}
        label={emailDetails?.label}
        text={emailDetails?.text}
        body={emailDetails?.body}
        bodyTemplateData={bodyTemplateData}
        defaultName={name}
        devMode={devMode < 0}
      />
    ];

    if ((defaultEmails || []).includes(name) && lang === 'en') {
      result.push(
        <Popconfirm
          key="reset"
          title={_t('::Reset this template?')}
          onConfirm={resetEmailTemplate}
          disabled={isLoadingResetTemplate}
          okText={_t('::Yes')}
          cancelText={_t('::No')}>
          <Button disabled={isLoadingResetTemplate} className="email-btn">
            <FontAwesomeIcon icon={faRetweet} type="primary" style={{ marginInlineEnd: '10px' }} />{' '}
            {_t('::Reset to default')}
          </Button>
        </Popconfirm>
      );
    } else {
      result.push(
        <Popconfirm
          key="delete"
          title={_t('::Delete this template?')}
          onConfirm={removeTemplate}
          disabled={isLoadingRemoveEmail || ((defaultEmails || []).includes(name) && lang === 'en')}
          okText={_t('::Yes')}
          cancelText={_t('::No')}>
          <Button
            disabled={
              isLoadingRemoveEmail || ((defaultEmails || []).includes(name) && lang === 'en')
            }
            className="email-btn">
            <FontAwesomeIcon icon={faTrash} type="primary" style={{ marginInlineEnd: '10px' }} />{' '}
            {_t('button::Remove')}
          </Button>
        </Popconfirm>
      );
    }

    if (isChanged) {
      result.push(
        <Button
          key="cancel"
          type="button"
          icon={<FontAwesomeIcon icon={faTimes} />}
          onClick={cancelEdition}>
          {_t('button::Cancel')}
        </Button>
      );
    }
    if (isChanged) {
      result.push(
        <Button
          key="save"
          type="primary"
          htmlType="submit"
          disabled={isLoadingUpdateEmail || !editorReady}
          icon={<FontAwesomeIcon icon={faSave} />}
          onClick={saveEmail}>
          {_t('button,store::Save')}
        </Button>
      );
    }

    if (devMode < 0 && isChanged && (defaultEmails || []).includes(name) && lang === 'en') {
      result.push(
        <Popconfirm
          key="saveAsDefault"
          title={_t('::Save this template as default?')}
          onConfirm={saveTemplateAsDefault}
          disabled={isLoadingSaveAsDefault}
          okText={_t('::Yes')}
          cancelText={_t('::No')}>
          <Button disabled={isLoadingSaveAsDefault} className="email-btn">
            <FontAwesomeIcon icon={faSave} type="primary" style={{ marginInlineEnd: '10px' }} />{' '}
            {_t('::Save as default')}
          </Button>
        </Popconfirm>
      );
    }

    return result;
  }, [
    name,
    details,
    lang,
    langs,
    isLoadingUpdateEmail,
    isLoadingResetTemplate,
    isLoadingRemoveEmail,
    editorReady,
    emailDetails,
    isChanged
  ]);

  usePageHeader(
    <TitleActions actions={isLoadingDefaultEmails ? [] : actions}>
      <Tooltip title={_t('button::Back to template list')}>
        <Link key="back" to="/settings/email">
          <Button type="link" className="back_btn">
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        </Link>
      </Tooltip>
      {title}
    </TitleActions>,
    [actions, isLoadingDefaultEmails]
  );

  return (
    <>
      <PageTitle title={title} />

      <Card style={{ minHeight: '600px' }}>
        <Row gutter={16}>
          <Col span={24}>
            <Button type="link" onClick={sendEmail} style={{ paddingInlineStart: 0 }}>
              {_t('button::Send test email')}
            </Button>
          </Col>
          <Col span={24} style={{ marginBottom: '30px' }}>
            <Text>{_t('::Label')}:</Text>
            <Input value={emailDetails?.label} onChange={onChange} name="label" />
          </Col>
          <Col span={24}>
            <Text>{_t('::Email subject')}:</Text>
            <Input value={emailDetails?.subject} onChange={onChange} name="subject" />
          </Col>
          <Col span={24} style={{ marginTop: '30px' }}>
            <Text>{_t('::HTML')}:</Text>
            <EmailEditor ref={emailEditorRef} onReady={onReady} tools={tools} />
          </Col>
          <Col span={24} style={{ marginTop: '30px' }}>
            <Text>{_t('::Text')}:</Text>
            <TextArea rows={4} value={emailDetails?.text} onChange={onChange} name="text" />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default withLayout(EmailEdit);
