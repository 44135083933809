import { useMutation, useQuery } from 'react-query';

import { url, post, getData } from './internal/rest-api';

export const useRoleNamesByModel = (name) =>
  useQuery(['roles', name], () => getData(url`role/names/${name}`));

export const useCreateRole = (queryClient) =>
  useMutation((name) => post(url`role/${name}`).then((result) => result.data), {
    onSuccess: () => {
      queryClient.invalidateQueries('models');
    }
  });
