import React from 'react';
import { Row, Col, Input, Select } from 'antd';
import { _t } from 'rosis-translation';
import filterBy from 'system/helper/filter-by';
import PropTypes from 'prop-types';
import { useSites } from 'rosis-api';

const { Option } = Select;

function FilterUser({ values, onChange }) {
  const { data: sites } = useSites();

  return (
    <Row gutter={16}>
      <Col xs={24} md={12} lg={4} style={{ marginBottom: '15px' }}>
        {_t('user::Login')}
        <Input
          name="idStr"
          value={values?.idStr}
          onChange={(e) => onChange('idStr', e.target.value)}
        />
      </Col>
      <Col xs={24} md={12} lg={4} style={{ marginBottom: '15px' }}>
        {_t('user::Enabled')}
        <Select
          allowClear
          value={values?.enabled}
          style={{ width: '100%' }}
          name="enabled"
          onChange={(value) => onChange('enabled', value)}>
          <Option value={true}>{_t('user::Enabled')}</Option>
          <Option value={false}>{_t('user::Disabled')}</Option>
          <Option value={null}>{_t('user::Not Activated')}</Option>
        </Select>
      </Col>
      <Col xs={24} md={12} lg={5} style={{ marginBottom: '15px' }}>
        {_t('user::Confirmation status')}
        <Select
          allowClear
          defaultValue={values?.confirmation?.$in?.join(',')}
          style={{ width: '100%' }}
          name="confirmation"
          onChange={(value) => onChange('confirmation', value)}>
          <Option value="not-needed">{_t('user::Not needed')}</Option>
          <Option value="pending,pending-adm">{_t('user::Pending')}</Option>
          <Option value="confirmed">{_t('user::Confirmed')}</Option>
        </Select>
      </Col>
      <Col xs={24} md={12} lg={4} style={{ marginBottom: '15px' }}>
        {_t('user::Blocked users')}
        <Select
          allowClear
          style={{ width: '100%' }}
          value={values?.penalty}
          name="penalty"
          onChange={(value) => onChange('penalty', value)}>
          <Option value="blocked">{_t('user::Blocked')}</Option>
          <Option value="notBlocked">{_t('user::Not blocked')}</Option>
        </Select>
      </Col>
      <Col xs={24} md={12} lg={3} style={{ marginBottom: '15px' }}>
        {_t('user::Sites')}
        <Select
          showSearch
          allowClear
          value={values?.sites}
          mode="multiple"
          style={{ width: '100%' }}
          optionFilterProp="children"
          filterOption={filterBy.label}
          onChange={(value) => onChange('sites', value)}>
          {(sites || []).map((item) => (
            <Option key={item.id} value={item.id}>
              {_t(item.title)}
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
}

FilterUser.propTypes = {
  values: PropTypes.shape({
    idStr: PropTypes.string,
    enabled: PropTypes.bool,
    confirmation: PropTypes.object,
    penalty: PropTypes.string,
    sites: PropTypes.arrayOf(PropTypes.string)
  }),
  onChange: PropTypes.func
};

export default FilterUser;
