import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import filterBy from '../../../../system/helper/filter-by';

const Multiselect = React.forwardRef(({ options, val, onChange }, ref) => {
  return (
    <Select
      mode="multiple"
      ref={ref}
      allowClear
      filterOption={filterBy.label}
      value={val || []}
      style={{ width: '100%' }}
      onChange={onChange}>
      {options.map(({ value, label }) => (
        <Select.Option key={value} value={value}>
          {label}
        </Select.Option>
      ))}
    </Select>
  );
});

Multiselect.displayName = 'Multiselect';

Multiselect.propTypes = {
  options: PropTypes.array,
  val: PropTypes.array,
  onChange: PropTypes.func
};

export default Multiselect;
