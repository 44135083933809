import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useConfigs } from 'rosis-api';

const PageTitle = ({ title }) => {
  const { data: config } = useConfigs('opengraph');
  const mainTitle = config?.['admin-title'] || config?.title;
  const fullTitle = title ? `${title} - ${mainTitle}` : mainTitle;
  return (
    <Helmet>
      <title>{fullTitle}</title>
    </Helmet>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string
};

export default PageTitle;
