import React, { useState, useContext, createContext } from 'react';
import PropTypes from 'prop-types';

export const NavigationBlockerContext = createContext();

export function useNavigationBlocker() {
  const { pendingBlockade, setPendingBlockade, isBlockadeNeeded, setIsBlockadeNeeded } =
    useContext(NavigationBlockerContext);
  return {
    pendingBlockade,
    setPendingBlockade,
    isBlockadeNeeded,
    setIsBlockadeNeeded
  };
}

export const NavigationBlockerProvider = ({ children }) => {
  const [isBlockadeNeeded, setIsBlockadeNeeded] = useState(false);
  const [pendingBlockade, setPendingBlockade] = useState(false);
  return (
    <NavigationBlockerContext.Provider
      value={{ pendingBlockade, setPendingBlockade, isBlockadeNeeded, setIsBlockadeNeeded }}>
      {children}
    </NavigationBlockerContext.Provider>
  );
};

NavigationBlockerProvider.propTypes = {
  children: PropTypes.node.isRequired
};
