import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useQueryClient } from 'react-query';
import { useUsersUpdate } from 'rosis-api';
import { _t } from 'rosis-translation';

const AcceptUser = ({ _id, type, title, onSuccess, onFail }) => {
  const queryClient = useQueryClient();
  const {
    mutate: update,
    error: errorAcceptUser,
    isSuccess: isSuccessAcceptUser
  } = useUsersUpdate(queryClient);

  const acceptUser = () => {
    update({ value: true, id: _id });
  };

  useEffect(() => {
    if (isSuccessAcceptUser) {
      onSuccess(_t('success::User has been accepted'));
    }
    if (errorAcceptUser) {
      onFail(_t(errorAcceptUser?.response?.data || 'error::The user has not been accepted'));
    }
  }, [isSuccessAcceptUser, errorAcceptUser]);

  return (
    <Tooltip title={_t('::Accept user')}>
      <Button type={type} className="accept-user_button" onClick={acceptUser}>
        <FontAwesomeIcon icon={faCheck} /> {title}
      </Button>
    </Tooltip>
  );
};

AcceptUser.defaultProps = {
  title: ''
};

AcceptUser.propTypes = {
  _id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
};

export default AcceptUser;
