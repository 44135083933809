import { useQuery, useMutation } from 'react-query';

import { url, getData, del, put, post } from './internal/rest-api';

export const useEmailTemplates = () => useQuery(['emails', 'data'], () => getData(`mail`));

export const useEmailOrphans = () => useQuery(['emails', 'orphans'], () => getData(`mail/orphans`));

export const useEmailLangs = (name) =>
  useQuery(['emails', 'data', name], () => getData(url`mail/${name}`));

export const useEmailDetails = (name, lang) =>
  useQuery(['emails', 'data', name, lang], () => getData(url`mail/${name}/${lang}`));

export const useEmailContext = () => useQuery(['emails', 'context'], () => getData(`mail/context`));

export const useEmailDefault = () => useQuery(['emails', 'default'], () => getData(`mail/default`));

export const useEmailUpdate = (queryClient) =>
  useMutation((data) => put(url`mail/${data.name}/${data.lang}`, data), {
    onSuccess: () => queryClient.invalidateQueries('emails')
  });

export const useEmailRemove = (queryClient) =>
  useMutation((data) => del(url`mail/${data.name}/${data.lang}`), {
    onSuccess: () => queryClient.invalidateQueries('emails')
  });

export const useSendEmail = () => useMutation((data) => post(`mail`, data));

export const useSendEmailWithTemplate = (template) =>
  useMutation((data) => post(url`mail/${template}`, data));

export const useResetMailTemplate = (queryClient) =>
  useMutation((name) => put(url`mail/reset-template/${name}`), {
    onSuccess: () => queryClient.invalidateQueries('emails')
  });

export const useResetPassword = () => useMutation((data) => post(url`mail/resetPassword`, data));

export const useSaveTemplateAsDefault = () =>
  useMutation((data) => put(url`mail/save-as-default`, data));

export const useRemoveOrphans = (queryClient) =>
  useMutation(() => del(url`mail/orphans`),{
    onSuccess: () => queryClient.invalidateQueries('emails')
  });

