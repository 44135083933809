import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import AddNewUser from '../AddNewUser';
import SortByDropdown from './SortByDropdown';
import './../Users.less';
import { usePageHeader } from 'contexts/pageHeader';
import TitleActions from 'components/TitleActions';
import DownloadReportModal from '../DownloadReportModal';
import PageTitle from 'components/PageTitle';
import { calcPageTitle } from 'src/utils/calcPageTitle';

const ToolbarMobile = (props) => {
  const { model, changeSort, userInfo, downloadParams, roles } = props;

  const actions = useMemo(
    () => [
      <SortByDropdown key="sort" changeSort={changeSort} />,
      <AddNewUser key="add" model={model} userInfo={userInfo} />,
      <DownloadReportModal key="download" roles={roles} downloadParams={downloadParams} />
    ],
    [model, userInfo, downloadParams]
  );

  const title = calcPageTitle(_t('::Users'), _t(userInfo?.label));

  usePageHeader(<TitleActions actions={actions}>{title}</TitleActions>, [title, actions]);

  return <PageTitle title={title} />;
};

ToolbarMobile.propTypes = {
  model: PropTypes.string,
  fieldList: PropTypes.array,
  changeSort: PropTypes.func,
  userInfo: PropTypes.object,
  downloadParams: PropTypes.object,
  roles: PropTypes.array
};

export default ToolbarMobile;
