import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import Item from './Item';

const { Option } = Select;

function SFSelect(props) {
  const { mode, options, allowClear } = props;
  const [selectOpts, setSelectOpts] = useState([]);

  useEffect(() => {
    setSelectOpts(
      options.map((option) => {
        const isString = typeof option === 'string';
        const value = isString ? option : option.value;
        const label = isString ? option : option.label;
        return { value, label };
      })
    );
  }, [options]);

  return (
    <Item type="Select" {...props}>
      <Select mode={mode} allowClear={allowClear}>
        {selectOpts.map(({ value, label }) => (
          <Option key={value} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    </Item>
  );
}

SFSelect.propTypes = {
  name: PropTypes.string.isRequired,
  mode: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
      })
    ),
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  required: PropTypes.bool,
  value: PropTypes.string,
  allowClear: PropTypes.bool
};

export default SFSelect;
