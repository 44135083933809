import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, Popconfirm } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { _t } from 'rosis-translation';

const RemoveQuestion = ({ id, value, isUsed, onRemoveQuestion }) => {
  const removeQuestion = () => {
    onRemoveQuestion({ value, id, name: 'deleted' });
  };

  return (
    <>
      <Popconfirm
        title={_t('::Remove this question?')}
        onConfirm={removeQuestion}
        disabled={isUsed}
        okText={_t('::Yes')}
        cancelText={_t('::No')}>
        <Tooltip
          title={
            isUsed
              ? _t('::You cannot delete a question that has already been used')
              : _t('button::Remove')
          }>
          <Button type="link" aria-label={_t('button::Remove')} disabled={isUsed}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Tooltip>
      </Popconfirm>
    </>
  );
};

RemoveQuestion.propTypes = {
  isUsed: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.string,
  onRemoveQuestion: PropTypes.func
};

export default RemoveQuestion;
