import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Result, Card, Typography } from 'antd';
import { useQueryClient } from 'react-query';
import { useConfirmEmailToken, apiUrl } from 'rosis-api';
import { _t } from 'rosis-translation';
import { useImageTitleCfg } from '../hooks/useImageTitleCfg';
import ConfirmationResultSuccess from './ConfirmationResultSuccess';
import './ConfirmEmail.less';

const { Title } = Typography;

function ConfirmEmailSimple({ hash, configPrefix, loginPath }) {
  const queryClient = useQueryClient();
  const { image, title } = useImageTitleCfg({ configPrefix });
  const {
    isSuccess,
    error,
    mutate: confirm,
    data: confirmationResponse
  } = useConfirmEmailToken(queryClient);

  useEffect(() => {
    confirm({ tokenId: hash });
  }, [hash]);

  return (
    <div
      className="confirm-email"
      style={
        image
          ? {
              backgroundImage: `url("${apiUrl}${image}")`
            }
          : {}
      }>
      <Card className="card">
        <Title className="title" level={3}>
          {title}
        </Title>
        {isSuccess && (
          <ConfirmationResultSuccess
            isPin={false}
            isUserEnabled={!!confirmationResponse?.data?.enabled}
            loginPath={loginPath}
          />
        )}
        {error && (
          <Result
            status={'error'}
            title={_t('::Confirm email')}
            subTitle={_t('::There was an error confirming the email address')}></Result>
        )}
      </Card>
    </div>
  );
}

ConfirmEmailSimple.propTypes = {
  hash: PropTypes.string.isRequired,
  configPrefix: PropTypes.string.isRequired,
  loginPath: PropTypes.string.isRequired
};

export default ConfirmEmailSimple;
