import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import { _t } from 'rosis-translation';
import { isUpdatable } from './utils';

function RoleCheckboxAll({ type, mtx, setPrivileges, fields, disabled }) {
  const isChecked = (mtx, key, rightName) => mtx[key]?.includes(rightName);
  const isPossibleToChange = ({ key, type: fieldType }) =>
    type === 'read' || (isChecked(mtx, key, 'read') && isUpdatable(fieldType));
  const change = ({ target }) => {
    const { checked } = target;
    const fieldsToChange = fields
      .filter((field) => checked !== isChecked(mtx, field.key, type) && isPossibleToChange(field))
      .map((field) => field.key);
    setPrivileges(fieldsToChange, type, checked);
  };
  const selectedAll = useMemo(() => {
    if (disabled) {
      return false;
    }
    const result = fields.every(
      (field) => !isPossibleToChange(field) || isChecked(mtx, field.key, type)
    );
    return result;
  }, [disabled, fields, mtx]);

  return (
    <Checkbox checked={selectedAll} disabled={disabled} onChange={change}>
      {_t('::Mark all')}
    </Checkbox>
  );
}

RoleCheckboxAll.propTypes = {
  mtx: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  setPrivileges: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  disabled: PropTypes.bool
};

export default RoleCheckboxAll;
