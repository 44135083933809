import React from 'react';
import PropsTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { SFCheckbox } from 'rosis-setting-form';
import { _t } from 'rosis-translation';

function RequiredField({ className }) {
  const { watch } = useFormContext();
  const type = watch('type');
  return type === 'Calculated' ? null : (
    <SFCheckbox className={className} label={_t('::Required field')} name="required" />
  );
}

RequiredField.propTypes = {
  className: PropsTypes.string
};

export default RequiredField;
