import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import parseValueToQuery from '../../../utils/parseValue';
import StringCompare from '../../../utils/compare/String';
import Item from '../../../Item';

function Text({ value, ...rest }) {
  return <Item {...rest}>{value ? value : ''}</Item>;
}

Text.propTypes = {
  value: PropTypes.string
};

const options = {
  value: 'text',
  get label() {
    return _t('::Text');
  },
  default: true,
  component: Text,
  compareComponent: StringCompare,
  parseValueToQuery: parseValueToQuery.string,
  example: <Text value={'example text'} />
};

export default options;
