import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Skeleton } from 'antd';
import { useQueryClient } from 'react-query';
import { _t } from 'rosis-translation';
import kinds from './kinds';
import { useUpdateConfigByForm } from 'rosis-api';
import './ConfigSettings.less';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';
import useConfigValuesLoader from './useConfigValuesLoader';
import ConfigSectionPanel from './ConfigSectionPanel';
import { useFormControl } from './ConfigFormControl';

function ConfigSettings({ settings, onIsDirtyChange, onSettingsChange }) {
  const [changes, setChanges] = useState({});
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();
  const { mutate: change, ...changeStatus } = useUpdateConfigByForm(queryClient);

  const { isSubmiting, isCanceling, reset } = useFormControl();

  const [form] = Form.useForm();

  const { values, isLoading, refetch, dataUpdatedAt } = useConfigValuesLoader({ settings });

  useEffect(() => {
    if (Object.keys(changes).length) {
      setChanges({});
      form.resetFields();
      onIsDirtyChange(false);
    }
  }, [dataUpdatedAt]);

  useApiFeedback({
    status: changeStatus,
    successMessage: _t('success::Changes saved'),
    errorMessage: _t('error::Update error')
  });

  const changeConfigValue = (name, value, group, isUserChange) => {
    setChanges({ ...changes, [`${group}:${name}`]: value });
    if (isUserChange) {
      onIsDirtyChange(true);
    }
    onSettingsChange({
      [name]: value
    });
  };

  const handleSubmit = () => {
    if (Object.values(errors).find((v) => !!v)) {
      return;
    }

    const data = new FormData();
    for (const key in changes) {
      const elementValue = changes[key] === undefined ? null : changes[key];
      const newValue = elementValue instanceof File ? elementValue : JSON.stringify(elementValue);
      data.append(key, newValue);
    }
    change(data);
    setChanges({});
    setErrors({});
    onIsDirtyChange(false);
  };

  const cancelEdition = () => {
    refetch();
    setErrors({});
    onIsDirtyChange(false);
  };

  useEffect(() => {
    if (isCanceling) {
      cancelEdition();
      reset();
    }
  }, [isCanceling]);

  useEffect(() => {
    if (isSubmiting) {
      form.submit();
      reset();
    }
  }, [isSubmiting]);

  return (
    <Form
      form={form}
      className="app-settings"
      layout="vertical"
      scrollToFirstError={{
        block: 'center'
      }}
      onFinish={handleSubmit}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <ConfigSectionPanel
          settings={settings}
          values={values}
          errors={errors}
          dataUpdatedAt={dataUpdatedAt}
          onErrorsChange={setErrors}
          onChange={changeConfigValue}
        />
      )}
    </Form>
  );
}

const settingsProps = {
  name: PropTypes.string.isRequired,
  group: PropTypes.string,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  kind: PropTypes.oneOf(kinds),
  editorProps: PropTypes.object
};

ConfigSettings.propTypes = {
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      ...settingsProps,
      subsettings: PropTypes.arrayOf(PropTypes.shape(settingsProps))
    })
  ),
  externalForm: PropTypes.object,
  onIsDirtyChange: PropTypes.func,
  onSettingsChange: PropTypes.func
};

ConfigSettings.defaultProps = {
  onIsDirtyChange: () => {},
  onSettingsChange: () => {}
};

export default ConfigSettings;
