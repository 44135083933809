import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import RemoveButton from 'components/RemoveButton';
import { useRemoveModel, useStats } from 'rosis-api';
import { _t } from 'rosis-translation';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';

function RemoveModel({
  name,
  label,
  onSuccess,
  errorMessages,
  confirmText,
  isProtected,
  hasSubsections
}) {
  const queryClient = useQueryClient();
  const { mutate: remove, isLoading: isRemoving, ...removeStatus } = useRemoveModel(queryClient);
  const [cantRemove, setCantRemove] = useState([]);
  const stats = useStats([{ name }]);
  const removeField = () => {
    if (!isRemoving) {
      remove(name);
    }
  };

  useApiFeedback({
    status: {
      ...removeStatus,
      isLoading: isRemoving
    },
    silentSuccess: true,
    errorMessage: _t('section::Error while removing a section'),
    onSuccess
  });

  const addCantRemove = (current, error) => {
    if (current.indexOf(error) === -1) {
      return [...current, error];
    } else {
      return current;
    }
  };

  const removeCantRemove = (current, error) => current.filter((el) => el !== error);

  const checkCantRemove = (condition, error) => {
    if (condition) {
      setCantRemove((current) => addCantRemove(current, error));
    } else {
      setCantRemove((current) => removeCantRemove(current, error));
    }
  };

  useEffect(() => {
    checkCantRemove(isProtected, errorMessages.isProtected);
  }, [isProtected]);

  useEffect(() => {
    checkCantRemove(hasSubsections, errorMessages.hasSubsections);
  }, [hasSubsections]);

  useEffect(() => {
    checkCantRemove(stats?.[0]?.data?.count, errorMessages.hasElements);
  }, [stats?.[0]?.data]);

  return (
    <RemoveButton
      bigButton
      confirmText={confirmText}
      buttonText={label}
      mainText={errorMessages.mainText}
      onConfirm={removeField}
      cantRemove={cantRemove}
      isRemoving={isRemoving}
    />
  );
}

RemoveModel.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  hasSubsections: PropTypes.bool,
  isProtected: PropTypes.bool,
  onSuccess: PropTypes.func,
  errorMessages: PropTypes.objectOf(PropTypes.string).isRequired,
  confirmText: PropTypes.string
};

RemoveModel.defaultProps = {
  onSuccess: () => {}
};

export default RemoveModel;
