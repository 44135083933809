import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import { _t } from 'rosis-translation';
import { makeToText } from 'make-to-text';
import NumberCompare from '../../../utils/compare/Number';
import parseValueToQuery from '../../../utils/parseValue';
import Item from '../../../Item';

const toText = makeToText('Number', 'number', { _t });

function ProgressBar({ value, decimal, min, max, ...rest }) {
  const val = value || 0;
  const minimum = min || 0;
  const maximum = max || 100;
  const precision = decimal || 0;
  const percentage = ((100 * (val - minimum)) / (maximum - minimum)).toFixed(precision);
  return (
    <Item {...rest}>
      <Progress percent={percentage} min={min} max={max} />
    </Item>
  );
}

ProgressBar.propTypes = {
  value: PropTypes.number,
  decimal: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number
};

const options = {
  value: 'progressbar',
  get label() {
    return _t('::Progress bar');
  },
  toText,
  component: ProgressBar,
  compareComponent: NumberCompare,
  parseValueToQuery: parseValueToQuery.number,
  example: <Progress percent={73} showInfo={false} />,
  off: true
};

export default options;
