import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Form, Input, Button, Tooltip, Modal, Tabs } from 'antd';
import { FunctionOutlined, FormOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { _t } from 'rosis-translation';
import { SFInput } from 'rosis-setting-form';

const allowedTypes = {
  //Number: ['Number'],
  //Date: ['Date']
};
function Formula(props) {
  const { name, label, schema, calcFunc, checkFormulaValidity } = props;
  const { watch, setValue, getValues } = useFormContext();
  const [functions, setFunctions] = useState([]);
  const resultType = watch('resultType');

  const [fields, setFields] = useState([]);
  const [activeTab, setActiveTab] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    calcFunc &&
      setFunctions(
        Object.entries(calcFunc)
          .map(([name, rest]) => {
            return { name, ...rest };
          })
          .filter((func) => func.result === resultType)
      );
  }, [calcFunc, resultType]);

  useEffect(() => {
    if (schema) {
      const fields = Object.values(schema.fields).filter(({ type, name: currentFieldName }) => {
        const allowed = allowedTypes[resultType];
        if (allowed) {
          return allowed.includes(type);
        }
        return !['Virt', 'Calc', 'Link'].includes(type) && schema.name !== currentFieldName;
      });
      setFields(fields);
    }
  }, [schema, resultType]);

  const showModal = (tab) => () => {
    setActiveTab(tab);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const addField = (field) => () => {
    addToFormula(`{${field}}`);
  };

  const addFunction = (fun) => () => {
    addToFormula(`${fun}()`);
  };

  const addToFormula = (text) => {
    const current = getValues(name);
    setValue(name, `${current || ''}${text}`);
    handleCancel();
  };

  const tabs = [
    {
      label: _t('::Fields'),
      key: 'fields',
      children: fields.map(({ label, name, type, hint }) => (
        <div key={name}>
          {_t(label)} - <b>{_t(type)}</b>{' '}
          {hint ? (
            <Tooltip title={hint}>
              <QuestionCircleOutlined />
            </Tooltip>
          ) : null}
          <Button type="link" onClick={addField(name)}>
            {_t('Add')}
          </Button>
        </div>
      ))
    },
    {
      label: _t('::Functions'),
      key: 'functions',
      children: functions.length
        ? functions.map(({ name, caption }) => (
            <div key={name}>
              <b>{_t(name)}</b> - {_t(caption)}
              <Button type="link" onClick={addFunction(name)}>
                {_t('button::Add')}
              </Button>
            </div>
          ))
        : _t('::You have to select the result type first')
    }
  ];

  const customDefinitionValidations = {
    validate: checkFormulaValidity
  };

  return (
    <Form.Item label={`${label}:`} required>
      <Input.Group compact>
        <SFInput
          name={name}
          required
          customDefinitionValidations={customDefinitionValidations}
          addonBefore="="
          className="calc-formula"
        />
        <Tooltip title={_t('::Fields')}>
          <Button icon={<FormOutlined />} onClick={showModal('fields')} />
        </Tooltip>
        <Tooltip title="Functions">
          <Button icon={<FunctionOutlined />} onClick={showModal('functions')} />
        </Tooltip>
      </Input.Group>

      <Modal open={isModalVisible} onCancel={handleCancel} footer={null}>
        <Tabs items={tabs} activeKey={activeTab} onChange={setActiveTab} />
      </Modal>
    </Form.Item>
  );
}

Formula.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  schema: PropTypes.object,
  calcFunc: PropTypes.object
};

const formula = {
  get label() {
    return _t('::Formula');
  },
  Field: Formula,
  name: 'formula',
  supportsCalculation: true,
  order: 20
};

export default formula;
