import { useQuery, useMutation } from 'react-query';
import { useState } from 'react';
import { url, getData, get, del, put, post } from './internal/rest-api';
import { bool, time, number, list } from 'system/helper/value';
import { mimeTypes } from 'system/symbol';

const parser = { bool, time, number, list };

export const useConfigList = () => useQuery('confValues', () => getData('value'));

export const useConfig = (group, name, parserKind) =>
  useQuery(['config', group, name], () =>
    getData(url`value/${group}/${name}`).then((data) =>
      parserKind ? parser[parserKind](data) : data
    )
  );

export const useConfigs = (group, config = {}) =>
  useQuery(['config', group], () => getData(url`value/${group}`), config);

export const usePreConfigs = (group, config = {}) =>
  useQuery(['preConfig', group], () => getData(url`value/get-pre/${group}`), config);

export const usePreOgFileConfig = (name, config = {}) =>
  useQuery(
    ['preOgFileConfig', name],
    () =>
      get(url`value/get-pre-ogfile/${name}`, { responseType: 'blob' }).then((response) => {
        const { data, headers } = response;
        const name = headers['x-filename'];
        const [, extension] = name.split('.');
        return new File([data], headers['x-filename'], { type: mimeTypes[extension] });
      }),
    config
  );

export const useAllConfigs = () => useQuery('config', () => getData(url`value/get-all`));

export const useUpdateConfig = (queryClient) =>
  useMutation(({ group, name, value }) => put(url`value/${group}/${name}/${value}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('config');
    }
  });

export const useUpdateConfigByForm = (queryClient) =>
  useMutation((changes) => put(url`value`, changes), {
    onSuccess: () => {
      queryClient.invalidateQueries('config');
      queryClient.invalidateQueries(['lang', 'keys']); //translation keys are also modified during config save
    }
  });

export const useRemoveConfig = (queryClient) =>
  useMutation(({ group, name }) => del(url`value/${group}/${name}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('config');
    }
  });

export const useConfigToDefault = (queryClient) =>
  useMutation(
    ({ group, name }) => put(url`value/to-default/${group}/${name}`).then((data) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('config');
        queryClient.invalidateQueries('preConfig');
      }
    }
  );

export const useValueEncryptor = () => useMutation((payload) => post(url`value/encrypt`, payload));

//COMPLEX

export const useChangeConfigValue = (queryClient) => {
  const updateOperation = useUpdateConfig(queryClient);
  const removeOperation = useRemoveConfig(queryClient);
  const [isRemoving, setIsRemoving] = useState(false);
  const mutate = ({ group, name, value }) => {
    if (!value) {
      setIsRemoving(true);
      return removeOperation.mutate({ group, name });
    }
    setIsRemoving(false);
    return updateOperation.mutate({ group, name, value });
  };
  const lastStatus = isRemoving ? removeOperation : updateOperation;
  return {
    ...lastStatus,
    mutate
  };
};
