import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { _t } from 'rosis-translation';

function LangFilter({ onChangeFilter }) {
  return <Input onChange={onChangeFilter} placeholder={_t('::Find translation')} />;
}

LangFilter.propTypes = {
  onChangeFilter: PropTypes.func
};

export default LangFilter;
