import React from 'react';
import PropTypes from 'prop-types';
import Bowser from 'bowser';

const ClientCell = ({ client }) => {
  const clientInfo = client ? Bowser.getParser(client).parsedResult : {};
  const { browser, os } = clientInfo;

  const browserInfo = browser && `${browser?.name}: ${browser?.version}`;
  const osInfo = os && `${os?.name} ${os?.versionName}`;

  return (
    <div className="logs-client">
      <div>{browserInfo}</div>
      <div>{osInfo}</div>
    </div>
  );
};

ClientCell.propTypes = {
  client: PropTypes.string
};

export default ClientCell;
