import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Button, Modal } from 'antd';
import { useQueryClient } from 'react-query';
import { _t } from 'rosis-translation';

import { useRemoveModel } from 'rosis-api';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';

const RemoveReasons = ({ cantRemove }) => {
  return (
    <div>
      {_t("::You can't remove this section because:")}
      {cantRemove?.map((err) => (
        <div key={err}>{_t(err)}</div>
      ))}
    </div>
  );
};

RemoveReasons.propTypes = {
  cantRemove: PropTypes.array
};

const RemoveButton = ({ cantRemove, confirm }) => (
  <Button type="link" disabled={cantRemove?.length} onClick={confirm}>
    {_t('button::Remove')}
  </Button>
);

RemoveButton.propTypes = {
  cantRemove: PropTypes.array,
  confirm: PropTypes.func
};

function Remove({ name, protectedSection, subsections, refetch, stats }) {
  const [cantRemove, setCantRemove] = useState([]);
  const queryClient = useQueryClient();
  const { mutate: remove, ...removeStatus } = useRemoveModel(queryClient);

  useApiFeedback({
    status: removeStatus,
    errorMessage: _t('section::Deletion failed'),
    onSuccess: () => refetch()
  });

  const confirmRemove = () =>
    Modal.confirm({
      title: _t('::Confirm removing section'),
      okText: _t('button::Yes'),
      cancelText: _t('button::No'),
      onOk: () => remove(name)
    });

  const addCantRemove = (current, error) => {
    if (current.indexOf(error) === -1) {
      return [...current, error];
    } else {
      return current;
    }
  };

  const removeCantRemove = (current, error) => current.filter((el) => el !== error);

  const checkCantRemove = (condition, error) => {
    if (condition) {
      setCantRemove((current) => addCantRemove(current, error));
    } else {
      setCantRemove((current) => removeCantRemove(current, error));
    }
  };

  useEffect(() => {
    const error = _t('::This section is protected by system');
    checkCantRemove(protectedSection, error);
  }, [protectedSection]);

  useEffect(() => {
    const error = _t('::This section has subsections');
    checkCantRemove(subsections?.length, error);
  }, [subsections]);

  useEffect(() => {
    const error = _t('::There are elements of this type in the system');
    checkCantRemove(stats?.[0]?.data?.count, error);
  }, [stats?.[0]?.data]);

  return cantRemove?.length ? (
    <Tooltip
      title={<RemoveReasons cantRemove={cantRemove} />}
      trigger="hover"
      placement="bottomRight">
      <RemoveButton cantRemove={cantRemove} />{' '}
    </Tooltip>
  ) : (
    <RemoveButton confirm={confirmRemove} />
  );
}

Remove.propTypes = {
  name: PropTypes.string.isRequired,
  protectedSection: PropTypes.bool.isRequired,
  subsections: PropTypes.array,
  refetch: PropTypes.func,
  stats: PropTypes.array
};

Remove.defaultProps = {
  refetch: () => {}
};

export default Remove;
