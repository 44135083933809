import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker as AntdDatePicker } from 'rosis-date-picker';
import { datetime } from 'rosis-date-picker';
import { isInConstraints } from '.';
import { dateFormats } from 'system/symbol';

const getConstraintUnit = (editAs) => {
  switch (editAs) {
    case 'year':
      return 'year';
    case 'month':
      return 'month';
    case 'datetime':
      return 'day';
    default:
      return 'day';
  }
};

const roundDate = (value, editAs, roundUp) => {
  if (!value) {
    return value;
  }
  if (editAs === 'datetime') {
    return value;
  }
  const roundPeriod = editAs;
  const result = roundUp ? value.endOf(roundPeriod) : value.startOf(roundPeriod);
  return result;
};

const mapToPickerProps = (editAs) => {
  switch (editAs) {
    case 'year':
      return { picker: 'year', format: dateFormats.year };
    case 'month':
      return { picker: 'month', format: dateFormats.month };
    case 'datetime':
      return { showTime: true, format: dateFormats.datetime };
    default:
      return { format: dateFormats.date };
  }
};

const DatePicker = React.forwardRef((props, ref) => {
  const { value, editAs, min, max, onChange, roundUp } = props;
  const dateValue = value ? datetime(value) : value;
  const disabledDate = (d) => !d || !isInConstraints(d, getConstraintUnit(editAs), min, max);

  const editAsSpecificProps = mapToPickerProps(editAs);

  useEffect(() => {
    const effectiveVal = roundDate(dateValue, editAs, roundUp);
    if (effectiveVal !== dateValue) {
      onChange(effectiveVal);
    }
  }, [editAs]);

  const change = (val) => {
    const effectiveVal = roundDate(val, editAs, roundUp);
    onChange(effectiveVal);
  };

  return (
    <AntdDatePicker
      ref={ref}
      {...props}
      {...editAsSpecificProps}
      value={dateValue}
      disabledDate={disabledDate}
      onChange={change}
    />
  );
});

DatePicker.displayName = 'DatePicker';

DatePicker.propTypes = {
  value: PropTypes.object,
  editAs: PropTypes.string,
  min: PropTypes.object,
  max: PropTypes.object,
  onChange: PropTypes.func,
  roundUp: PropTypes.bool
};

export default DatePicker;
