'use strict';

const def = "s1000 m60 h60 d24"
    , rex = /^(\d+)([smhd])/
    , div = { s:60, m:60, h:24, d:1000000 }
    , tu = {}
;

let n = 1;
def.split(/\s+/).forEach(t => {
    tu[t.slice(0, 1)] = n *= ~~t.slice(1);
});

/**
 * Conversion from text e.g. "2d6h10m" to number of milliseconds or other way round
 * @param time
 * @returns {string|number}
 */
function period (time) {
    if (typeof(time) === 'string') {
        let sign = 1;
        if(time.startsWith('-')) {
            sign = -1;
            time = time.substring(1);
        }
        let match, ms = 0;
        while ((match = time.match(rex))) {
            ms += Number(match[1]) * tu[match[2]];
            time = time.replace(rex, '');
        }
        return sign * ms;
    } else {
        let str = '';
        if (time % 1000 === 0) {
            time = time/1000;
        }
        let prefix = '';
        if (time<0) {
            prefix = '-';
            time = Math.abs(time);
        }
        Object.keys(div).forEach(u => {
            const next = Math.floor(time/div[u]);
            const val = time - div[u] * next;
            time = next;
            if (val) {
                str = val.toString() + u + str;
            }
        });
        return str ?  prefix + str : '0s';
    }
}

module.exports = period;

