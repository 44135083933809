export const storageKeys = {
  expiryTime: 'expiry-time'
};

const storages = {
  [storageKeys.expiryTime]: sessionStorage
};

export const setItem = (key, value) => storages[key].setItem(key, value);

export const getItem = (key) => storages[key].getItem(key);

export const removeItem = (key) => storages[key].removeItem(key);
