import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'rosis-components/navigation-wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable, faExclamationCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { _t } from 'rosis-translation';
import { links } from 'router/links';

const Table = () => (
  <Tooltip title={_t('::View')}>
    <span className="fa-layers fa-fw">
      <FontAwesomeIcon className="view-icon" icon={faTable} size="lg" />
    </span>
  </Tooltip>
);
const TableWithInfo = () => (
  <Tooltip
    placement="left"
    title={_t(
      '::In the view for this section, there are fields to which this role is not have privileges'
    )}>
    <span className="fa-layers fa-fw">
      <FontAwesomeIcon className="view-icon" icon={faTable} size="lg" />
      <FontAwesomeIcon className="view-icon__info" icon={faExclamationCircle} />
    </span>
  </Tooltip>
);
const TableWithPlus = () => (
  <Tooltip
    placement="left"
    title={_t('::In the view for this section, they are not defined fields')}>
    <span className="fa-layers fa-fw">
      <FontAwesomeIcon
        className="view-icon"
        style={{ color: '#B5B5C3' }}
        icon={faTable}
        size="lg"
      />
      <FontAwesomeIcon className="view-icon__plus" icon={faPlusCircle} />
    </span>
  </Tooltip>
);

// addView, modifyView, displayView

const CollapseExtra = ({ name, mtx, view }) => {
  const [displayIcon, setDisplayIcon] = useState();
  const currentMtx = mtx[name];
  useEffect(() => {
    if (currentMtx && view) {
      Object.keys(view).length === 0 ? setDisplayIcon('addView') : null;
      let viewArray = Object.keys(view).map((key) => key);
      let mtxArray = Object.keys(currentMtx)
        .map((key) => {
          if (key !== '*' && currentMtx[key].indexOf('read') !== -1) {
            return key;
          }
        })
        .filter((el) => el !== undefined);
      viewArray.length > 0 ? setDisplayIcon('displayView') : null;
      viewArray.forEach((item) => {
        if (!mtxArray.includes(item)) {
          setDisplayIcon('modifyView');
        }
      });
    }
  }, [mtx, view]);

  return (
    <Link key="read" to={links.sectionDetailsGroup(name, 'view')}>
      {displayIcon === 'addView' ? (
        <TableWithPlus />
      ) : displayIcon === 'modifyView' ? (
        <TableWithInfo />
      ) : (
        <Table />
      )}
    </Link>
  );
};

CollapseExtra.propTypes = {
  name: PropTypes.string,
  mtx: PropTypes.object,
  view: PropTypes.object
};

export default CollapseExtra;
