import React, { useEffect, useState } from 'react';
import { Table, Tooltip, Empty, Skeleton } from 'antd';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { useUsersUpdate, useElements, useLocal, useConfig } from 'rosis-api';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import { useChange } from 'contexts/change';
import { useAuth } from 'rosis-contexts/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import RemoveUser from '../RemoveUser';
import ConfirmUser from '../ConfirmUser';
import FilterUser from '../FilterUser';
import Pagination from 'components/Pagination';
import './../Users.less';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';
import EnableButton from '../EnableButton';
import RowAction from '../RowActions';
import useActiveUsers from '../useActiveUsers';
import useUsersCriteria from '../useUsersCriteria';

function UsersByRole({ name, noData, onUpdateFilter }) {
  const queryClient = useQueryClient();
  const { data: removalAllowed } = useConfig('conf', 'removalAllowed', 'bool');
  const { data: pinTime } = useConfig('setup', 'rescue-pin-TTL');
  const { filters: recentFilters, setFilters: storeRecentFilters } = useChange();
  const { user } = useAuth();

  const { activeUsers } = useActiveUsers();

  const { showInfoBar } = useInfoBar();

  const [isLoadingLocal, setIsLoadingLocal] = useState(true);
  const { data: localPerPage, isLoading: isLoadingLocalPerPage } = useLocal('perPage');

  const {
    page,
    elementsPerPage,
    changePagination,
    changeSortBy,
    filters,
    changeFilters,
    requestCriteria
  } = useUsersCriteria({ role: name, recentFilters, onChangeFiltering: storeRecentFilters });

  useEffect(() => {
    onUpdateFilter(requestCriteria);
  }, [requestCriteria]);

  const isElementLoadingEnable = !isLoadingLocal;

  const { data, refetch, isLoading } = useElements(
    'user',
    isElementLoadingEnable ? requestCriteria : { find: new Date().getTime() },
    {
      enabled: isElementLoadingEnable
    }
  );

  const { elements, next } = data || {};

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { mutate: update, ...userUpdateStatus } = useUsersUpdate(queryClient, name);

  useApiFeedback({
    status: userUpdateStatus,
    errorMessage: _t('error::User update was failed'),
    silentSuccess: true
  });

  useEffect(() => {
    if (!isLoadingLocalPerPage) {
      changePagination({ current: 1, perPage: localPerPage || 10 });
      setIsLoadingLocal(false);
    }
  }, [isLoadingLocalPerPage, localPerPage]);

  function renderActions({ _id, enabled }) {
    const disabled = _id === user._id;
    return (
      <EnableButton
        id={_id}
        disabled={disabled}
        checked={enabled}
        onChange={(value) => update({ value, id: _id, name })}
      />
    );
  }

  const onSuccess = (message) => {
    refetch();
    showInfoBar({
      message,
      type: 'success'
    });
  };

  const onFail = (message) => {
    showInfoBar({
      message,
      type: 'error'
    });
  };

  function actionButtons({ _id, penalty, enabled }) {
    return (
      <RowAction
        _id={_id}
        actionComplete={actionComplete}
        enabled={enabled}
        name={name}
        onFail={onFail}
        onSuccess={onSuccess}
        penalty={penalty}
        user={user}
        pinTime={pinTime}
      />
    );
  }

  const columns = [
    {
      title: _t('user::Login'),
      dataIndex: 'idStr',
      key: 'idStr',
      sorter: true,
      render: (idStr, { _id }) => (
        <div>
          {idStr}
          {activeUsers.includes(_id) && (
            <Tooltip title={_t('::On-line')}>
              <FontAwesomeIcon
                icon={faDotCircle}
                size="xs"
                style={{ marginLeft: 10, color: '#d9f7be' }}
                pulse={true}
              />
            </Tooltip>
          )}
        </div>
      )
    },
    {
      title: _t('user::Site'),
      dataIndex: 'sites',
      key: 'sites',
      render: (site) => <div className="user-site_ellipsis">{site && site.join(', ')}</div>
    },
    {
      title: _t('user::Enable'),
      key: 'enabled',
      render: renderActions
    },
    {
      title: _t('user::Confirmation status'),
      key: 'confirmation ',
      render: ({ confirmation, idStr }) => (
        <ConfirmUser
          confirmation={confirmation}
          idStr={idStr}
          onSuccess={onSuccess}
          onFail={onFail}
        />
      )
    },
    {
      title: _t('user::Actions'),
      key: 'actions',
      render: actionButtons
    }
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    switch (order) {
      case 'ascend':
        changeSortBy({ [field]: 1 });
        break;
      case 'descend':
        changeSortBy({ [field]: -1 });
        break;
      default:
        changeSortBy(null);
        break;
    }
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };
  const hasSelected = selectedRowKeys.length > 0;

  const actionComplete = () => {
    setSelectedRowKeys([]);
  };

  return (
    <section className="data-table">
      {hasSelected && (
        <RemoveUser
          role={name}
          id={selectedRowKeys}
          table={true}
          disabled={!hasSelected || selectedRowKeys.includes(user._id)}
          actionComplete={actionComplete}
          onSuccess={onSuccess}
          onFail={onFail}
        />
      )}
      <FilterUser values={filters} onChange={changeFilters} />
      {!isElementLoadingEnable || isLoading ? (
        <Skeleton />
      ) : (
        <Table
          columns={columns}
          size="middle"
          loading={isLoading}
          rowSelection={removalAllowed && rowSelection}
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={noData ? noData : _t('user::No users')}
              />
            )
          }}
          dataSource={elements}
          onChange={handleTableChange}
          rowKey="_id"
        />
      )}

      <Pagination
        currentPage={page}
        next={next}
        elementsPerPage={elementsPerPage}
        countOfElements={elements?.length}
        onChangePagination={changePagination}
      />
    </section>
  );
}

UsersByRole.propTypes = {
  name: PropTypes.string,
  noData: PropTypes.string,
  onUpdateFilter: PropTypes.func
};

export default UsersByRole;
