import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ConfigSettings from 'components/ConfigSettings';
import { _t } from 'rosis-translation';
import TokenExpire from './TokenExpire';
import { calcSettingPageTitle } from '../utils';
import PageTitle from 'components/PageTitle';
import { ConfigFormControlProvider } from 'components/ConfigSettings/ConfigFormControl';
import ConfigPageHeader from 'components/ConfigSettings/ConfigPageHeader';
import { useChange } from 'contexts/change';

function EmailConfigTab({ title }) {
  const { isChanged, setIsChanged } = useChange();
  const settings = useMemo(
    () => [
      {
        name: 'content',
        label: _t('email_settings::Email content settings'),
        subsettings: [
          {
            name: 'from',
            group: 'mail',
            label: _t('email_settings::Sender'),
            kind: 'string'
          },
          {
            name: 'footerText',
            group: 'mail',
            label: _t('email_settings::Email footer'),
            kind: 'string',
            showAs: 'textarea'
          }
        ]
      },
      {
        name: 'connection',
        label: _t('email_server_connection::Email server connection configuration'),
        subsettings: [
          {
            name: 'host',
            group: 'mail',
            label: _t('email_server_connection::Server address'),
            kind: 'string'
          },
          {
            name: 'port',
            group: 'mail',
            label: _t('email_server_connection::Server port'),
            kind: 'number'
          },
          {
            name: 'login',
            group: 'mail',
            label: _t('email_server_connection::User name used to login to server'),
            kind: 'string'
          },
          {
            name: 'password',
            group: 'mail',
            label: _t('email_server_connection::Password used to login to server'),
            kind: 'encrypted'
          },
          {
            name: 'secure',
            group: 'mail',
            label: _t('email_server_connection::Use TLS connection'),
            kind: 'bool'
          },
          {
            name: 'ignoreTLS',
            group: 'mail',
            label: _t('email_server_connection::Do not use TLS even when server support it'),
            kind: 'bool'
          },
          {
            name: 'tlsRejectUnauthorized',
            group: 'mail',
            label: _t(
              'email_server_connection::Reject connections which are not authorized with the list of supplied CAs'
            ),
            kind: 'bool'
          },
          {
            name: 'maxConnections',
            group: 'mail',
            label: _t(
              'email_server_connection::Highest number of simultaneous connections allowed'
            ),
            kind: 'number'
          }
        ]
      },
      {
        name: 'other',
        label: _t('::Other'),
        subsettings: [
          {
            name: 'tokenTtl',
            group: 'setup',
            label: _t('::Email links expiration time'),
            kind: 'custom',
            CustomComponent: TokenExpire
          },
          {
            name: 'delay',
            group: 'mail',
            label: _t('email_settings::Delay between emails when sending batch (in milliseconds)'),
            kind: 'number'
          }
        ]
      }
    ],
    [_t, TokenExpire]
  );

  const pageTitle = calcSettingPageTitle(title);

  return (
    <ConfigFormControlProvider>
      <PageTitle title={pageTitle} />
      <ConfigPageHeader pageTitle={pageTitle} isDirty={isChanged} />
      <ConfigSettings settings={settings} onIsDirtyChange={setIsChanged} />
    </ConfigFormControlProvider>
  );
}

EmailConfigTab.propTypes = {
  title: PropTypes.string
};

export default EmailConfigTab;
