import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const PageHeaderContext = createContext();

export function usePageHeaderContext() {
  const { pageHeader, setPageHeader, onPageHeaderChange } = useContext(PageHeaderContext);
  return {
    pageHeader,
    setPageHeader,
    onPageHeaderChange
  };
}

export const usePageHeader = (header, dependencies = [], disabled = false) => {
  const { setPageHeader } = usePageHeaderContext();
  useEffect(() => {
    if (!disabled) {
      setPageHeader(header);
    }
    //  return () => setPageHeader(null);
  }, [...dependencies, disabled]);
};

export const InPageHeaderContext = ({ children }) => {
  const setPageHeader = (value) => {
    setContextState({
      setPageHeader,
      pageHeader: value
    });
  };
  const [contextState, setContextState] = useState({
    pageHeader: null,
    setPageHeader: setPageHeader
  });
  return <PageHeaderContext.Provider value={contextState}>{children}</PageHeaderContext.Provider>;
};

InPageHeaderContext.propTypes = {
  children: PropTypes.node.isRequired
};
