import React from 'react';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import Item from '../../../Item';

const FTRadio = React.forwardRef((props, ref) => {
  const { value, opts, onChange } = props;
  return (
    <Item {...props}>
      <RadioEditor ref={ref} value={value} opts={opts} onChange={onChange} />
    </Item>
  );
});

FTRadio.displayName = 'FTRadio';

FTRadio.propTypes = {
  value: PropTypes.string,
  opts: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func
};

const RadioEditor = React.forwardRef(({ value, opts, onChange }, ref) => {
  const editorValue = [value];
  const change = (checked, option) => {
    if (onChange) {
      const newValue = checked ? option : null;
      onChange(newValue);
    }
  };
  return (
    <Checkbox.Group ref={ref} value={editorValue}>
      {(opts || [])?.map((option) => (
        <Checkbox key={option} value={option} onChange={(e) => change(e.target?.checked, option)}>
          {_t(option)}
        </Checkbox>
      ))}
    </Checkbox.Group>
  );
});

RadioEditor.displayName = 'RadioEditor';

RadioEditor.propTypes = {
  value: PropTypes.string,
  opts: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func
};

const radio = {
  value: 'radio',
  get label() {
    return _t('::Radio');
  },
  component: FTRadio,
  example: <FTRadio opts={['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5']} />
};

export default radio;
