const dayjs = require('dayjs');
const weekday = require('dayjs/plugin/weekday');
const localeData = require('dayjs/plugin/localeData');
const duration = require('dayjs/plugin/duration');
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(duration);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const datetime = dayjs;

module.exports = datetime;
