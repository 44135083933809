import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import Item from './Item';

function SFInput(props) {
  const { addonBefore, addonAfter, disabled } = props;
  return (
    <Item type="Input" {...props}>
      <Input disabled={disabled} addonBefore={addonBefore} addonAfter={addonAfter} />
    </Item>
  );
}

SFInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  addonBefore: PropTypes.string,
  addonAfter: PropTypes.string
};

export default SFInput;
