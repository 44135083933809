import React from 'react';
import { Col } from 'antd';
import { useConfigs, apiUrl } from 'rosis-api';

function BackgroundImage() {
  const { data: config } = useConfigs('opengraph');
  const image = config?.['admin-image'] || config?.image;
  return (
    <Col md={16}>
      <div
        className="login-background"
        style={image ? { backgroundImage: `url("${apiUrl}${image}")` } : {}}></div>
    </Col>
  );
}

export default BackgroundImage;
