import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Input, Button, Typography, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { CheckCircleFilled } from '@ant-design/icons';
import { Link } from 'rosis-components/navigation-wrapper';
import { useResetPassword, apiUrl } from 'rosis-api';
import { _t } from 'rosis-translation';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';
const { Title } = Typography;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};

const ForgotPanel = ({ title, image, loginLink, pinLink }) => {
  const [form] = Form.useForm();
  const [isSuccess, setSuccess] = useState();
  const { mutate: reset, isLoading: isLoadingSendLink, ...sendLinkStatus } = useResetPassword();

  useApiFeedback({
    status: {
      isLoading: isLoadingSendLink,
      ...sendLinkStatus
    },
    silentSuccess: false,
    errorMessage: _t('error::Send mail with reset link failed'),
    progressMessage: _t('::Sending mail with reset link'),
    onSuccess: () => setSuccess(true)
  });

  const resetPassword = (data) => {
    reset({
      to: data.idStr,
      action: {
        pwToken: data.idStr
      }
    });
  };
  return (
    <div
      className="forgot-password"
      style={
        image
          ? {
              backgroundImage: `url("${apiUrl}${image}")`,
              height: '100vh',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }
          : {}
      }>
      <Card className="forgot-password__card" title={title}>
        <Title level={3} className="forgot-password__title">
          {_t('::Forgot password')}
        </Title>
        <Form
          {...layout}
          className="forgot-password__form"
          name="login"
          form={form}
          requiredMark={false}
          onFinish={resetPassword}>
          <Form.Item
            name="idStr"
            label={_t('::Login')}
            rules={[{ required: true, message: _t('::Please input your login') }]}>
            <Input />
          </Form.Item>

          <Form.Item className="buttons">
            <Button type="primary" htmlType="submit" loading={isLoadingSendLink}>
              {_t('button::Send reset link')}
            </Button>
            <div>
              <Link to={pinLink}>
                <Button type="link">{_t('::PIN code')}</Button>
              </Link>
              <Tooltip title={_t('::Please use the PIN code you received from the system administrator in order to unlock your account.')}>
                <FontAwesomeIcon
                  className="pin-code__info"
                  icon={faInfoCircle}
                />
              </Tooltip>
            </div>
          </Form.Item>
          {isSuccess && (
            <div>
              <p>
                <b style={{ color: '#009460', fontSize: 16 }}>
                  <CheckCircleFilled twoToneColor="#009460" />{' '}
                  {_t('forgot_password::Password reset sent')}
                </b>
              </p>
              <p>
                {_t(
                  "forgot_password::We've emailed you instructions for setting your password, if an account exists with the login you entered. You should receive them shortly."
                )}
              </p>
              <p>
                {_t(
                  "forgot_password::If you don't receive an email, please make sure you've entered the correct login."
                )}
              </p>
            </div>
          )}
          <div>
            <Link to={loginLink}>
              <Button type="link">{_t('::Back to Sign in')}</Button>
            </Link>
          </div>
        </Form>
      </Card>
    </div>
  );
};

ForgotPanel.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  loginLink: PropTypes.string.isRequired,
  pinLink: PropTypes.string.isRequired,
};

export default ForgotPanel;
