import { quadDot } from '../system/symbol';
import { stdKeys } from '../system/helper/trans-key';

const rtlKey = stdKeys.lang('rtl');
let locale = 'en';
const translations = {};

const applyReplacements = (text, replacements) => {
  if (!text) {
    return text;
  }
  let result = text;
  Object.entries(replacements).forEach(([key, value]) => {
    result = result.replaceAll(`{${key}}`, value);
  });
  return result;
};

const getDefaultValue = (key, replacements = {}) => {
  if (typeof key !== 'string') {
    return null;
  }
  let defaultValue = key?.split(quadDot).pop();
  defaultValue = applyReplacements(defaultValue, replacements);
  return defaultValue;
};

const loadLanguageData = (language, _translations) => {
  locale = language;
  translations[locale] = _translations;
  const isRtl = Object.keys(_translations).includes(rtlKey);
  return {
    isRtl
  };
};

const translation = (key, replacements = {}) => {
  let result = translations[locale]?.[key];
  result = applyReplacements(result, replacements);
  if (!result) {
    result = getDefaultValue(key, replacements);
  }
  return result;
};

export { translation as _t, loadLanguageData };
