import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { _t } from 'rosis-translation';
import DatePicker from './DatePicker';
import RelativeInput from './RelativeInput';

import { isRelativeValuePattern } from './index';

const RELATIVE = 'relative';
const FIXED = 'fixed';

const calcModeFromValue = (val) => {
  const isRelative = !val || isRelativeValuePattern.test(val);
  return isRelative ? RELATIVE : FIXED;
};

const RelativeOrFixedDateInput = (props) => {
  const { value, onChange } = props;
  const [editorValue, setEditorValue] = useState(value);
  const [selectedMode, setSelectedMode] = useState(calcModeFromValue(value));

  useEffect(() => {
    if (value !== editorValue) {
      setEditorValue(value);
      setSelectedMode(calcModeFromValue(value));
    }
  }, [value]);

  const changeMode = (selection) => {
    setSelectedMode(selection);
    setEditorValue(null);
    onChange(null);
  };

  const changeValue = (newValue) => {
    setEditorValue(newValue);
    onChange(newValue);
  };

  return (
    <div className="rof-date-input-wrapper">
      <Select className="rof-date-input-mode" value={selectedMode} onChange={changeMode}>
        <Select.Option value={RELATIVE}>{_t('form_settings_date::Relative')}</Select.Option>
        <Select.Option value={FIXED}>{_t('form_settings_date::Fixed')}</Select.Option>
      </Select>
      <div className="rof-date-input-value">
        {selectedMode === RELATIVE && (
          <RelativeInput {...props} onChange={changeValue}></RelativeInput>
        )}
        {selectedMode === FIXED && <DatePicker {...props} onChange={changeValue}></DatePicker>}
      </div>
    </div>
  );
};

RelativeOrFixedDateInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func
};

export default RelativeOrFixedDateInput;
