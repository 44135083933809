import React from 'react';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import TitleActions from 'components/TitleActions';
import { usePageHeader } from 'contexts/pageHeader';
import { _t } from 'rosis-translation';
import { useFormControl } from './ConfigFormControl';

const ConfigPageHeader = ({ isDirty, pageTitle }) => {
  const { cancelEdition, submitEdition } = useFormControl();
  usePageHeader(
    <TitleActions
      actions={
        isDirty
          ? [
              <Button
                key="cancel"
                type="button"
                icon={<FontAwesomeIcon icon={faTimes} />}
                onClick={cancelEdition}>
                {_t('button::Cancel')}
              </Button>,
              <Button
                key="submit"
                icon={<FontAwesomeIcon icon={faSave} />}
                type="primary"
                onClick={submitEdition}>
                {_t('button,store::Save')}
              </Button>
            ]
          : []
      }>
      {pageTitle}
    </TitleActions>,
    [pageTitle, isDirty, cancelEdition, submitEdition]
  );
};

export default ConfigPageHeader;
