import { Grid } from 'antd';
const { useBreakpoint } = Grid;

let result = null;

const useCheckBreakpoint = (size) => {
  const breakpoints = useBreakpoint();
  result = breakpoints[size] ?? result;
  return result;
};

export const useIsMobile = () => useCheckBreakpoint('xs');
export const useIsBigMobile = () => useCheckBreakpoint('sm');
export const useIsTablet = () => useCheckBreakpoint('md');
export const useIsSmallDesktop = () => useCheckBreakpoint('lg');
export const useIsDesktop = () => useCheckBreakpoint('xl');
export const useIsBigDesktop = () => useCheckBreakpoint('xxl');
