import React, { useState } from 'react';
import { Form, Typography, Row, Col, Button, Space } from 'antd';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { useChangePassword, useConfigs } from 'rosis-api';
import PswChecklist from 'rosis-components/PasswordScreens/PswChecklist';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';
import ChangePasswordForm from 'components/ChangePasswordForm';

const { Text, Title } = Typography;

function ChangePassword({ pswdExpired, expiredDays, goToDashboard, onSuccessChangePassword }) {
  const queryClient = useQueryClient();
  const { data: pubConfig } = useConfigs('pub');
  const { pswdWidget } = { ...pubConfig };
  const [lastUpdateResult, setLastUpdateResult] = useState(null);

  const {
    mutate: changePassword,
    isLoading: isLoadingChangePassword,
    ...changePasswordStatus
  } = useChangePassword(queryClient);

  const [passwd, setPasswd] = useState('');

  const [form] = Form.useForm();

  const onFinish = (values) => {
    changePassword({ current: values.oldPassword, password: values.password });
  };

  useApiFeedback({
    status: {
      isLoading: isLoadingChangePassword,
      ...changePasswordStatus
    },
    successMessage: _t('success::Password has been changed. Please login to the system.'),
    errorMessage: (err) => {
      if ([404].includes(err.response?.status)) {
        return _t('error::The old password is incorrect');
      }
      if (err?.response?.data) {
        return _t(err?.response?.data);
      }
      return _t('error::Error while changing password');
    },
    silentError: true,
    onSuccess: (msg) => {
      onSuccessChangePassword();
      setLastUpdateResult({ status: 'success', message: msg });
    },
    onError: (msg) => {
      setLastUpdateResult({ status: 'error', message: msg });
    }
  });

  return (
    <div className="set-new-pswd">
      <Row>
        <Col span={24}>
          <Title level={3}>
            {pswdExpired
              ? _t('::Your password has expired')
              : _t('::Your password will expire in {expiredDays} days', { expiredDays })}
          </Title>
          <Text className="expired-pswd">{_t('::Please change your password.')}</Text>
        </Col>
        <Col span={24}>
          <ChangePasswordForm
            setPasswd={setPasswd}
            onFinish={onFinish}
            externalForm={form}
            lastUpdateResult={lastUpdateResult}
            setLastUpdateResult={setLastUpdateResult}
          />
        </Col>

        <Col span={24} className="form-button">
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isLoadingChangePassword}
              onClick={() => form.submit()}>
              {_t('button,store::Save')}
            </Button>
            {!pswdExpired ? (
              <Button htmlType="button" onClick={goToDashboard}>
                {_t('button::Remind me later')}
              </Button>
            ) : null}
          </Space>
        </Col>
        <Col span={24}>
          {pswdWidget === 'yes' && !lastUpdateResult && <PswChecklist password={passwd} />}
        </Col>
      </Row>
    </div>
  );
}

ChangePassword.propTypes = {
  pswdExpired: PropTypes.bool,
  expiredDays: PropTypes.number,
  goToDashboard: PropTypes.func,
  onSuccessChangePassword: PropTypes.func
};

export default ChangePassword;
