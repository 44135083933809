import { useQuery, useMutation, useQueries } from 'react-query';

import { url, getData, del, put, post } from './internal/rest-api';
import { useMultipleProjections, useProjection } from './internal/utils';

import { stdKeys } from 'system/helper/trans-key';
import { _t } from 'rosis-translation';

const _useLangs = () => useQuery('lang', () => getData('lang/list'));

const _useLang = (lang, config = {}) =>
  useQuery(['lang', 'details', lang], () => getData(url`lang/${lang}`), config);

const _useMultipleLangs = (langs) =>
  useQueries(
    langs.map((lang) => ({
      queryKey: ['lang', 'details', lang],
      queryFn: () => getData(url`lang/${lang}`)
    }))
  );

export const useLangKeys = () => useQuery(['lang', 'keys'], () => getData('lang/keys'));

export const useTranslationListByName = (name) =>
  useQuery(['polyglote', name], () => getData(url`lang/polyglote`, { params: { name } }), {
    enabled: false
  });

export const useDefaultLang = () => useQuery(['lang', 'default'], () => getData(`lang/default`));

export const useMissingTranslations = () =>
  useQuery(['lang', 'missing-phrases'], () => getData(`lang/missing-phrases`));

export const useSetDefaultLang = (queryClient) =>
  useMutation((lang) => put(url`lang/default/${lang}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('lang');
    }
  });

export const useEditLang = (queryClient) =>
  useMutation(({ lang, key, value }) => put(url`lang/${lang}/${key}/${value}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('lang');
    }
  });

export const useRemoveLang = (queryClient) =>
  useMutation(({ lang, key }) => del(url`lang/${lang}/${key}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('lang');
    }
  });

export const useAddMissingTranslation = () =>
  useMutation(({ language, missingTranslations }) =>
    post('lang/new', { lang: language, name: missingTranslations })
  );

//COMPLEX

export const useLang = (lang, config = {}) => {
  const queryResults = _useLang(lang, config);
  return useProjection(queryResults, (data) => data.dict);
};

export const useLangs = () => {
  const queryResults = _useLangs();
  return useProjection(queryResults, (data) =>
    data.map((lang) => ({ id: lang, title: _t(stdKeys.lang(lang)), subtitle: lang }))
  );
};

export const useLangAssigment = (langs) => {
  const allLangsResults = _useMultipleLangs(langs);
  return useMultipleProjections(allLangsResults, (langResults) => {
    if (!langResults.length) {
      return {
        isSuccess: true,
        data: {}
      };
    }
    const isAnyLoading = langResults.some((lr) => lr.isLoading);
    if (isAnyLoading) {
      return {
        isLoading: true,
        isSuccess: false,
        isError: false,
        error: null,
        data: {}
      };
    }
    const firstError = langResults.find((lr) => !lr.isError).error;
    if (firstError) {
      return {
        isLoading: false,
        isSuccess: false,
        isError: true,
        error: firstError,
        errorUpdatedAt: Math.max(
          ...langResults.filter((d) => d.errorUpdatedAt).map((d) => d.errorUpdatedAt)
        ),
        data: {}
      };
    }
    const rtlKey = stdKeys.lang('rtl'),
      data = {};
    langResults
      .filter((key) => key !== rtlKey)
      .forEach((langResult, index) => {
        Object.entries(langResult.data.dict).forEach(([key, value]) => {
          if (!data[key]) {
            data[key] = [];
          }
          if (value) {
            data[key].push(langs[index]);
          }
        });
      });
    return {
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
      dataUpdatedAt: Math.max(
        ...langResults.filter((d) => d.dataUpdatedAt).map((d) => d.dataUpdatedAt)
      ),
      data
    };
  });
};

export const useCreateLang = (queryClient) =>
  useMutation(
    ({ langKey, langInfo }) => {
      const updates = langInfo.map((item) => put(url`lang/${item.id}/${langKey}/${item.name}`));
      return Promise.all(updates);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('lang');
      }
    }
  );
