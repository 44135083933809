import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'contexts/translation';
import { _t } from 'rosis-translation';
import { useSetDefaultLang, useDefaultLang } from 'rosis-api';

const { Item } = Form;
function DefaultLang({ lang }) {
  const queryClient = useQueryClient();
  const { changeLang } = useTranslation();
  const { mutate: setDefault, isSuccess } = useSetDefaultLang(queryClient);
  const { data: defaultLang } = useDefaultLang();
  const changeDefault = ({ target }) => {
    const { checked } = target;
    if (checked) {
      setDefault(lang);
    }
  };

  useEffect(() => {
    changeLang();
  }, [isSuccess]);

  return (
    <Item onChange={changeDefault}>
      <Checkbox
        checked={lang === defaultLang}
        open={lang === defaultLang}
        disabled={lang === defaultLang}>
        {_t('::Default language')}
      </Checkbox>
      {lang === defaultLang && (
        <Tooltip title={_t('::You can set other language as default from languages list')}>
          <FontAwesomeIcon icon={faInfoCircle} color="var(--roche-main)" />
        </Tooltip>
      )}
    </Item>
  );
}

DefaultLang.propTypes = { lang: PropTypes.string };

export default DefaultLang;
