import React from 'react';
import PropTypes from 'prop-types';
import BaseDate from './BaseDate';

const Year = React.forwardRef(({ val, onChange }, ref) => {
  return <BaseDate ref={ref} val={val} onChange={onChange} pickerKind="date" />;
});

Year.displayName = 'Year';

Year.propTypes = {
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func
};

export default Year;
