import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Row, Col, Select, Button, Form, Input, Collapse, Space, Checkbox } from 'antd';
import { useQueryClient } from 'react-query';
import { useModelsLabels } from 'rosis-components/ModelLabelsProvider';
import {
  useGenerateData,
  useGetCountOfGenerateData,
  useModels,
  useRemoveDemoData,
  useLocal,
  useUpdateLocal,
  useConfig,
  useChangeConfigValue
} from 'rosis-api';
import { _t } from 'rosis-translation';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import { useBreadcrumbs } from 'rosis-contexts/breadcrumbs';
import TitleActions from 'components/TitleActions';

const { Option } = Select;
const { Text } = Typography;

const model = {
  person: 'patient',
  count: 10
};

function GenerateDemoData() {
  const queryClient = useQueryClient();
  const { data: models } = useModels();
  const { modelLabels } = useModelsLabels();
  const { showInfoBar } = useInfoBar();

  const { data: localData } = useLocal('generateData');
  const { data: countDocgen } = useGetCountOfGenerateData();
  const { mutate: saveLocal } = useUpdateLocal(queryClient, 'generateData');
  const {
    isSuccess: successCreate,
    error: errorCreate,
    isLoading,
    data: responseGenerate,
    mutate: generate
  } = useGenerateData(queryClient);
  const {
    isSuccess: successRemove,
    error: errorRemove,
    isLoading: isLoadingRemove,
    mutate: remove
  } = useRemoveDemoData(queryClient);

  const [param, setParam] = useState(model);
  const { setBreadcrumbs } = useBreadcrumbs();

  const [isGenerateReport, setGenerateReport] = useState(false);
  const toggleGenerateReport = () => setGenerateReport(!isGenerateReport);

  const { data: password } = useConfig('setup', 'dummyPassword');
  const { data: selectValuesForGenerateData } = useConfig('setup', 'selectValuesForGenerateData');
  const {
    mutate: changeConfigValue,
    isSuccess: changeConfigValueSuccess,
    error: changeConfigValueError
  } = useChangeConfigValue(queryClient);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: '',
        link: '/'
      },
      {
        label: _t('::Generate demo data'),
        link: '/generateDemoData'
      }
    ]);
  }, []);

  const handleChange = (name, value) => {
    setParam({ ...param, [name]: value });
  };

  const generateData = () => {
    saveLocal(param.person);
    generate(param);
  };

  useEffect(() => {
    if (successCreate) {
      showInfoBar({
        message: _t('success::Generated successfully'),
        type: 'success'
      });
      if (isGenerateReport && responseGenerate) {
        const blob = new Blob([responseGenerate.data], {
          type: responseGenerate.headers['content-type']
        });
        window.open(URL.createObjectURL(blob));
      }
    }
    if (errorCreate) {
      showInfoBar({
        message: _t(errorCreate?.response?.data || 'error::Generating failed'),
        type: 'error'
      });
    }
  }, [successCreate, errorCreate, responseGenerate]);

  useEffect(() => {
    localData && setParam({ ...param, person: localData });
  }, [localData]);

  useEffect(() => {
    if (successRemove) {
      showInfoBar({
        message: _t('success::Removed successfully'),
        type: 'success'
      });
    }
    if (errorRemove) {
      showInfoBar({
        message: _t(errorRemove?.response?.data || 'error::Removing failed'),
        type: 'error'
      });
    }
  }, [successRemove, errorRemove]);

  useEffect(() => {
    if (changeConfigValueSuccess) {
      showInfoBar({
        message: _t('success::Password changed'),
        type: 'success'
      });
    }
    if (changeConfigValueError) {
      showInfoBar({
        message: _t(
          changeConfigValueError?.response?.data || 'error::Error while changing password'
        ),
        type: 'error'
      });
    }
  }, [changeConfigValueSuccess, changeConfigValueError]);
  const passwordLabel = _t('demodata::Common password for generated users');

  const actions = useMemo(() => {
    const result = [
      <Button key="generate" type="primary" onClick={generateData} disabled={isLoading}>
        {_t('demodata::Generate data')}
      </Button>
    ];
    if (countDocgen) {
      result.push(
        <Button
          key="remove"
          type="text"
          danger
          onClick={remove}
          disabled={isLoadingRemove}
          className="btn-remove">
          {_t('demodata::Remove demo data')}
        </Button>
      );
    }
    return result;
  }, [countDocgen, param]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <TitleActions actions={actions}>{_t('demodata::Generate demo data')}</TitleActions>
      <Collapse>
        <Collapse.Panel header={_t('demodata::Settings')}>
          <Form
            initialValues={{
              password
            }}
            onFinish={({ password }) => {
              changeConfigValue({ group: 'setup', name: 'dummyPassword', value: password });
            }}>
            <Form.Item
              label={passwordLabel}
              name="password"
              rules={[{ required: true, message: _t("::It's required") }]}>
              <Input></Input>
            </Form.Item>
            <Button type="primary" htmlType="submit" disabled={isLoadingRemove}>
              {_t('demodata::Change')}
            </Button>
          </Form>
        </Collapse.Panel>
      </Collapse>
      <Row gutter={16} align="middle">
        <Col md={4}>
          <Select
            style={{ width: '100%' }}
            onChange={(value) => handleChange('person', value)}
            value={param?.person}>
            {models &&
              Array.isArray(models) &&
              models.map((model) => (
                <Option key={model.name} value={model.name} label={_t(modelLabels[model.name])}>
                  {_t(modelLabels[model.name])}
                </Option>
              ))}
          </Select>
        </Col>
        <Col md={4}>
          <Select
            style={{ width: '100%' }}
            value={param.count}
            onChange={(value) => handleChange('count', value)}>
            {selectValuesForGenerateData && Array.isArray(selectValuesForGenerateData)
              ? selectValuesForGenerateData.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))
              : null}
          </Select>
        </Col>
        <Col md={4}>
          <Checkbox onClick={toggleGenerateReport}>{_t('demodata::Download summary')}</Checkbox>
        </Col>
        <Col md={12}>
          {responseGenerate?.data?.password && (
            <Text>
              {passwordLabel}
              <br />
              <b>{responseGenerate.data.password}</b>
            </Text>
          )}
        </Col>
      </Row>
    </Space>
  );
}

export default GenerateDemoData;
