import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import Item from './Item';

const { TextArea } = Input;

function SFTextarea(props) {
  const { rows, defaultValue } = props;
  return (
    <Item type="Textarea" {...props}>
      <TextArea rows={rows} defaultValue={defaultValue} />
    </Item>
  );
}

SFTextarea.defaultProps = {
  rows: 4
};

SFTextarea.propTypes = {
  defaultValue: PropTypes.string,
  rows: PropTypes.number
};

export default SFTextarea;
