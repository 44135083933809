import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Typography } from 'antd';
import { useCheckPinCode, apiUrl } from 'rosis-api';
import { Link, useNavigate } from 'rosis-components/navigation-wrapper';
import PinCodeInput from 'rosis-components/PinCodeAuthentication/PinCodeInput';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import { _t } from 'rosis-translation';
const { Title } = Typography;

const PinCodePanel = ({ title, image, loginLink }) => {
  const navigate = useNavigate();
  const { showInfoBar } = useInfoBar();
  const [pin, setPin] = useState({ pin: '' });
  const { isLoading, isSuccess, isError, refetch } = useCheckPinCode(pin?.pin);
  const [isValid, setIsValid] = useState(false);

  const onProcced = () => {
    refetch();
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(`/confirmEmail/${pin?.pin}/ext`);
    }
    if (isError) {
      showInfoBar({
        message: _t('error::Pin code not found'),
        type: 'error'
      });
    }
  }, [isSuccess, isError]);

  return (
    <div
      className="pin-code"
      style={
        image
          ? {
              backgroundImage: `url("${apiUrl}${image}")`
            }
          : {}
      }>
      <Card className="pin-code__card" title={title}>
        <Title level={3} className="pin-code__title">
          {_t('::PIN code')}
        </Title>
        <p>
          {_t(
            '::Please provide the PIN code given you by the System Administrator, to be able to change secret question answer and set a new password.'
          )}
        </p>
        <PinCodeInput onChange={setPin} onValidityChange={setIsValid} />
        <Button
          type="primary"
          className="btn-procced"
          disabled={!isValid}
          loading={isLoading}
          onClick={onProcced}>
          {_t('button::Procced')}
        </Button>

        <div>
          <Link to={loginLink}>
            <Button className="pin-code__link" type="link">
              {_t('::Back to Sign in')}
            </Button>
          </Link>
        </div>
      </Card>
    </div>
  );
};

PinCodePanel.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  loginLink: PropTypes.string.isRequired
};

export default PinCodePanel;
