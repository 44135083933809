import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { _t } from 'rosis-translation';
import { useConfig, useSchema } from 'rosis-api';
import symbol from 'system/symbol';
import RoleCheckbox from './RoleCheckbox';

const modelPrivilegesSettings = symbol.privileges.model;

function ModelPrivileges({ frameName, modelPrivileges, setModelPrivileges }) {
  const [privilegesCheckboxes, setPrivilegesCheckboxes] = useState(null);
  const { data: removalAllowed } = useConfig('conf', 'removalAllowed', 'bool');
  const { data: schema } = useSchema(frameName);
  const isRoot = schema?.extra?.person && schema?.extra?.dynaRoot;

  useEffect(() => {
    const readPrivileges = modelPrivileges.includes('read');
    setPrivilegesCheckboxes(
      Object.entries(modelPrivilegesSettings).map(([key, label]) => {
        const disabled = key === 'read' ? false : !readPrivileges;
        if (key === 'delete' && !removalAllowed && isRoot) {
          return null;
        }
        return (
          <RoleCheckbox
            key={key}
            type={key}
            label={_t(label)}
            privileges={modelPrivileges}
            disabled={disabled}
            setPrivileges={setModelPrivileges}
          />
        );
      })
    );
  }, [modelPrivileges, removalAllowed]);

  return <Form.Item>{privilegesCheckboxes}</Form.Item>;
}

ModelPrivileges.propTypes = {
  frameName: PropTypes.string.isRequired,
  modelPrivileges: PropTypes.array.isRequired,
  setModelPrivileges: PropTypes.func.isRequired
};

export default ModelPrivileges;
