import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, notification } from 'antd';
import { useAuth } from 'rosis-contexts/auth';
import { useLocation, Link } from './navigation-wrapper';
import { HomeOutlined } from '@ant-design/icons';
import { useBreadcrumbs } from 'rosis-contexts/breadcrumbs';

const getShortLabel = (label) => {
  if (!label) {
    return label;
  }
  return label.length > 50 ? label.substring(0, 35) + '...' + label.slice(-10) : label;
};

function Navigation({ showEmpty, layoutForFloat }) {
  const { pathname } = useLocation();
  const { breadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const { messages, setMessages } = useAuth();
  const [displayMessages, setDisplayMessages] = useState([]);


  useEffect(() => {
    if (Array.isArray(messages) && messages.length > 0) {

      if(JSON.stringify(messages) !== JSON.stringify(displayMessages)){
        setDisplayMessages([...messages]);
      }
      
    }
  }, [messages]);

  useEffect(() => {

    if(displayMessages.length > 0){

      messages.forEach((item) => {
        notification.open({
          message: item?.from,
          description: item?.value,
          duration: 0
        });
      });
  
      setMessages([]);
      setDisplayMessages([]);
    }


  }, [displayMessages])

  useEffect(() => {
    setBreadcrumbs([
      {
        label: '',
        link: '/'
      }
    ]);
  }, [pathname]);

  const isVisible = showEmpty || breadcrumbs.length > 1;
  const style = layoutForFloat ? { paddingBlock: 2 } : { marginBottom: 5 };

  return isVisible ? (
    <Breadcrumb className="navigation-breadcrumb" style={style}>
      {breadcrumbs.map((item) => {
        return (
          <Breadcrumb.Item key={item.link}>
            <Link to={item.link}>
              {item.label === '' ? <HomeOutlined /> : getShortLabel(item.label)}
            </Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  ) : null;
}

Navigation.propTypes = {
  showEmpty: PropTypes.bool,
  layoutForFloat: PropTypes.bool
};

Navigation.defaultProps = {
  showEmpty: false,
  layoutForFloat: false
};

export default Navigation;
