import React from 'react';
import { useParams } from 'rosis-components/navigation-wrapper';
import { withNotLoggedLayout } from 'layout';
import { links } from 'router/links';
import ResetPasswordPanel from 'rosis-components/PasswordScreens/ResetPasswordPanel';

function ResetPassword() {
  const { hash } = useParams();
  return <ResetPasswordPanel configPrefix="admin" hash={hash} loginPath={links.login()} />;
}

export default withNotLoggedLayout(ResetPassword);
