import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Skeleton, List, Button } from 'antd';
import { Link } from 'rosis-components/navigation-wrapper';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import { useElements } from 'rosis-api';
import { useChange } from 'contexts/change';
import TitleSection from './TitleSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faUserClock } from '@fortawesome/free-solid-svg-icons';
import RejectUser from 'components/buttons/RejectUser';
import AcceptUser from 'components/buttons/AcceptUser';
import { _t } from 'rosis-translation';
import useUsersCriteria from 'views/Users/useUsersCriteria';
import { links, useNavigation } from 'router/links';

const LinkToUserList = ({ filters, elements }) => {
  const navigation = useNavigation();
  const { setFilters } = useChange();
  const targetRole = elements?.[0]?.role;

  const goToUsersTable = () => {
    setFilters({
      ...filters
    });

    navigation.usersForRole(targetRole);
  };

  return (
    <Button
      type="link"
      style={{ padding: 0 }}
      disabled={!elements?.length}
      onClick={() => goToUsersTable()}>
      {_t('::Show more')}
    </Button>
  );
};

LinkToUserList.propTypes = {
  elements: PropTypes.array,
  filters: PropTypes.object
};

function Users({ title, filters, displayNew, modelLabels, onChange }) {
  const { showInfoBar } = useInfoBar();
  const [isLoadingParams, setIsLoadingParams] = useState(true);

  const { changePagination, changeSortBy, requestCriteria, sortBy, elementsPerPage } =
    useUsersCriteria({
      recentFilters: filters,
      onChangeFiltering: () => {}
    });

  useEffect(() => {
    changePagination({ current: 1, perPage: 15 });
    if (elementsPerPage) {
      changeSortBy({ _createdAt: -1 });
    }
  }, [elementsPerPage]);

  useEffect(() => {
    if (sortBy && elementsPerPage) {
      setIsLoadingParams(false);
    }
  }, [elementsPerPage, sortBy]);

  const { data, isLoading } = useElements('user', requestCriteria, {
    enabled: !isLoadingParams
  });
  const { elements } = data || {};

  const onSuccess = (message) => {
    onChange();
    showInfoBar({
      message,
      type: 'success'
    });
  };

  const onFail = (message) => {
    showInfoBar({
      message,
      type: 'error'
    });
  };

  return (
    <Card
      title={
        <TitleSection
          title={_t(title)}
          icon={<FontAwesomeIcon icon={displayNew ? faUserPlus : faUserClock} color="#86888d" />}
        />
      }
      extra={<LinkToUserList filters={filters} elements={elements} />}
      className="users-section">
      {isLoading ? (
        <Skeleton />
      ) : (
        <List
          className="users-section_list"
          loading={isLoading}
          itemLayout="horizontal"
          dataSource={elements}
          renderItem={(item) => (
            <List.Item
              actions={
                displayNew
                  ? []
                  : [
                      <AcceptUser
                        key="accept"
                        _id={item._id}
                        type="link"
                        onSuccess={onSuccess}
                        onFail={onFail}
                      />,
                      <RejectUser
                        key="reject"
                        _id={item._id}
                        type="link"
                        onSuccess={onSuccess}
                        onFail={onFail}
                      />
                    ]
              }>
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  title={<Link to={links.userDetails(item.role, item._id)}>{item.idStr}</Link>}
                  description={_t(modelLabels[item.role])}
                />
              </Skeleton>
            </List.Item>
          )}
        />
      )}
    </Card>
  );
}

Users.propTypes = {
  title: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  displayNew: PropTypes.bool.isRequired,
  modelLabels: PropTypes.object,
  onChange: PropTypes.func
};

Users.defaultProps = {
  modelLabels: {}
};

export default Users;
