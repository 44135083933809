import React from 'react';
import PropTypes from 'prop-types';
import Item from './Item';

function SimpleText(props) {
  const { value, label, hint, className } = props;
  return (
    <Item className={className} label={label} hint={hint}>
      {value ? value.toString() : ''}
    </Item>
  );
}

SimpleText.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  hint: PropTypes.string,
  className: PropTypes.string
};

export default SimpleText;
