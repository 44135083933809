import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, Dropdown } from 'antd';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsAlt,
  faEllipsisH,
  faPen,
  faPlusCircle,
  faClone,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { _t } from 'rosis-translation';
import { useCloneModel, useStats } from 'rosis-api';
import { Link } from 'rosis-components/navigation-wrapper';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';
import Remove from './Remove';
import CloneModal from '../CloneModal';
import { links } from 'router/links';

function Actions({
  name,
  children,
  person,
  dynaRoot,
  dragHandleProps,
  refetch,
  protected: protectedSection
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [modalVisible, setModalVisible] = useState(false);
  const {
    mutate: cloneModel,
    data: cloneResponse,
    ...cloneModelStatus
  } = useCloneModel(queryClient);

  const stats = useStats([{ name }]);

  useApiFeedback({
    status: cloneModelStatus,
    errorMessage: _t('section::Cloning failed'),
    silentSuccess: true,
    onSuccess: () => {
      navigate(`/sections/${cloneResponse.data.name}`);
    }
  });

  const handleClone = ({ label, linkTo }) => cloneModel({ name, label, linkTo });

  const items = useMemo(() => {
    const result = [];

    if (dynaRoot) {
      result.push({
        key: 'create',
        label: <Button type="link">{_t('button::Add new subsection')}</Button>,
        onClick: () => navigate(`/sections/create/${name}`),
        icon: <FontAwesomeIcon icon={faPlusCircle} color="var(--roche-main)" />
      });
    }

    if (!person) {
      result.push({
        key: 'clone',
        label: <Button type="link">{_t('button::Duplicate')}</Button>,
        onClick: () => setModalVisible(true),
        icon: <FontAwesomeIcon icon={faClone} color="var(--roche-main)" />,
        disabled: person
      });
    }

    result.push({
      key: 'remove',
      label: (
        <Remove
          name={name}
          protectedSection={protectedSection}
          subsections={children}
          refetch={refetch}
          stats={stats}
        />
      ),
      icon: (
        <FontAwesomeIcon
          icon={faTrash}
          color={
            protectedSection || children?.length || stats?.[0]?.data?.count
              ? 'var(--roche-disabled-gray)'
              : 'var(--roche-main)'
          }
        />
      )
    });

    return result;
  }, [dynaRoot, person]);

  return (
    <div className="table-dnd_row_main_actions">
      <Tooltip title={_t('button::Edit')}>
        <Link to={links.sectionDetails(name)}>
          <Button type="link">
            <FontAwesomeIcon icon={faPen} />
          </Button>
        </Link>
      </Tooltip>
      <Dropdown menu={{ items }}>
        <Button type="link" className="list-action">
          <FontAwesomeIcon icon={faEllipsisH} />
        </Button>
      </Dropdown>
      {dragHandleProps ? (
        <span type="link" className="action-drag" {...dragHandleProps}>
          <Tooltip title={_t('button::Move')}>
            <FontAwesomeIcon icon={faArrowsAlt} />
          </Tooltip>
        </span>
      ) : null}

      <CloneModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onClone={handleClone}
      />
    </div>
  );
}

Actions.propTypes = {
  name: PropTypes.string,
  children: PropTypes.array,
  dynaRoot: PropTypes.bool,
  person: PropTypes.bool,
  protected: PropTypes.bool,
  dragHandleProps: PropTypes.object,
  refetch: PropTypes.func
};

export default Actions;
