import React, { useEffect } from 'react';
import { Button, Tooltip } from 'antd';
import { _t } from 'rosis-translation';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText, faUser, faUserAltSlash } from '@fortawesome/free-solid-svg-icons';
import { useSendEmailWithTemplate } from 'rosis-api';

function ConfirmUser({ confirmation, idStr, onSuccess, onFail }) {
  const {
    isSuccess: successSendEmail,
    error: errorSendEmail,
    isLoading,
    mutate: send
  } = useSendEmailWithTemplate('confirmAccount');

  useEffect(() => {
    successSendEmail && onSuccess(_t('success::Activation link was resent'));
    errorSendEmail &&
      onFail(_t(errorSendEmail?.response?.data || 'error::Resend an activation email failed'));
  }, [successSendEmail, errorSendEmail]);

  const isSelfRegister = confirmation === 'pending' ? false : true;

  const resendEmail = () => {
    send({
      to: idStr,
      action: {
        accountConfirmationToken: {
          idStr: idStr,
          setPassword: isSelfRegister
        }
      }
    });
  };

  return (
    <div>
      <Tooltip
        placement="bottomLeft"
        title={
          confirmation === 'confirmed'
            ? _t('::Confirmed')
            : confirmation === 'not-needed'
            ? _t('::Not needed')
            : _t('::Unconfirmed')
        }>
        <FontAwesomeIcon
          icon={
            confirmation === 'confirmed' || confirmation === 'not-needed' ? faUser : faUserAltSlash
          }
        />
      </Tooltip>
      {confirmation !== 'confirmed' && confirmation !== 'not-needed' && (
        <Tooltip placement="bottomLeft" title={_t('::Resend an activation email')}>
          <Button type="link" loading={isLoading} disabled={isLoading}>
            <FontAwesomeIcon icon={faEnvelopeOpenText} onClick={resendEmail} />
          </Button>
        </Tooltip>
      )}
    </div>
  );
}

ConfirmUser.propTypes = {
  idStr: PropTypes.string,
  confirmation: PropTypes.oneOf(['confirmed', 'pending', 'pending-adm', 'not-needed']),
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
};

export default ConfirmUser;
