import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Form, Button } from 'antd';
import { _t } from 'rosis-translation';
import { SFSelect } from 'rosis-setting-form';
import extensionList from './utils/extension-list';
import './utils/extensions.less';

const getExtensionByType = (type) => {
  const all = Object.entries(extensionList);
  let result = all;
  if (type !== 'all') {
    result = all.filter(([, types]) => {
      return types.includes(type);
    });
  }
  return result.map(([extension]) => extension);
};

const concatExtensions = (current, newOne) => {
  newOne.forEach((newExtension) => current.includes(newExtension) || current.push(newExtension));
  return current;
};

const removeExtensions = (current, removing) => {
  removing.forEach((removingEl) => current.splice(current.indexOf(removingEl), 1));
  return current;
};

const allExtensions = Object.keys(extensionList);

const options = allExtensions.map((extension) => {
  return { label: extension, value: extension };
});

function ExtensionSelect() {
  const [isSelected, setIsSelected] = useState({});
  const { setValue, watch } = useFormContext();
  const value = watch('extensions') || [];

  const selectGroup = (type) => {
    const group = getExtensionByType(type);
    const newExtensions = checkHasAllFromGroup(type, group)
      ? removeExtensions(value, group)
      : concatExtensions(value, group);
    setValue('extensions', [...newExtensions]);
  };

  const checkHasAllFromGroup = (type, group) => {
    group = group || getExtensionByType(type);
    return group.every((ext) => value.includes(ext));
  };

  const checkIsPrimary = (type) => (checkHasAllFromGroup(type) ? 'primary' : null);

  useEffect(() => {
    setIsSelected({
      default: checkIsPrimary('default'),
      all: checkIsPrimary('all'),
      doc: checkIsPrimary('doc'),
      image: checkIsPrimary('image'),
      video: checkIsPrimary('video'),
      audio: checkIsPrimary('audio')
    });
  }, [value]);

  return (
    <section className="file-type-extensions">
      <SFSelect name="extensions" mode="multiple" allowClear required options={options} noLabel />
      <div className="file-type-extensions__buttons">
        <Button type={isSelected.default} onClick={() => selectGroup('default')}>
          {_t('::Default')}
        </Button>
        <Button type={isSelected.doc} onClick={() => selectGroup('doc')}>
          {_t('::Document')}
        </Button>
        <Button type={isSelected.image} onClick={() => selectGroup('image')}>
          {_t('::Image')}
        </Button>
        <Button type={isSelected.video} onClick={() => selectGroup('video')}>
          {_t('::Video')}
        </Button>
        <Button type={isSelected.audio} onClick={() => selectGroup('audio')}>
          {_t('::Audio')}
        </Button>
        <Button type={isSelected.all} onClick={() => selectGroup('all')}>
          {_t('::All')}
        </Button>
      </div>
    </section>
  );
}

function Extensions() {
  return (
    <Form.Item label={_t('::Extensions')} required>
      <ExtensionSelect />
    </Form.Item>
  );
}

const extensions = {
  name: 'extensions',
  get label() {
    return _t('::Allowed extensions');
  },
  Field: Extensions,
  order: 1
};

export default extensions;
