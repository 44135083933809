import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { List, Tooltip } from 'antd';
import { _t } from 'rosis-translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Actions from '../Actions';

function FieldList({ frameName, fields, cantRemove, fieldTypes, onMarkToRemove }) {
  return (
    <Droppable droppableId="fields">
      {(provided) => (
        <section ref={provided.innerRef} {...provided.droppableProps}>
          <List className="schema-panel__list" itemLayout="horizontal">
            {fields.map((field, index) => (
              <Draggable key={field.name} draggableId={field.name} index={index}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps}>
                    <List.Item
                      className="schema-panel__list__item"
                      actions={[
                        <Actions
                          key="actions"
                          frameName={frameName}
                          record={field}
                          onMarkToRemove={onMarkToRemove}
                          cantRemove={cantRemove?.[field.name]}
                          dragHandleProps={provided.dragHandleProps}
                        />
                      ]}>
                      <List.Item.Meta
                        title={
                          <div>
                            <div>
                              <span className="schema-panel__label">{_t('::Field label')}: </span>{' '}
                              <span
                                className={classNames(
                                  field?.required && 'require-star',
                                  field?.hide && 'hide-field'
                                )}>
                                {_t(field?.label)}{' '}
                              </span>
                              {field?.conditions && (
                                <Tooltip
                                  title={_t('icon::Conditional field')}
                                  className="conditions-icon">
                                  [ <FontAwesomeIcon icon={faEyeSlash} /> ]
                                </Tooltip>
                              )}
                            </div>
                            <div>
                              <span className="schema-panel__label">{_t('::Field type')}: </span>{' '}
                              {fieldTypes[field.type]?.label || field.type}
                            </div>
                          </div>
                        }
                      />
                    </List.Item>
                  </div>
                )}
              </Draggable>
            ))}

            {provided.placeholder}
          </List>
        </section>
      )}
    </Droppable>
  );
}

FieldList.propTypes = {
  frameName: PropTypes.string,
  fields: PropTypes.array,
  cantRemove: PropTypes.object,
  fieldTypes: PropTypes.object,
  onMarkToRemove: PropTypes.func
};

export default FieldList;
