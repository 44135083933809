import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { EditField } from 'rosis-field-types';
import { quadDot } from 'system/symbol';
import { useFormContext, Controller } from 'react-hook-form';
import { _t } from 'rosis-translation';
import { prepareAdditionalOptions, getAdditionalOptionsValidators } from './utils';

const isDefaultAvailable = (type) => !!type && !['File', 'Uniq', 'Calculated'].includes(type);

const getEditorProps = (formProps) => {
  // exclude name to prevent antd form.input from value handling
  const filteredFields = Object.entries(formProps).filter(
    ([key]) => !['name', 'hint'].includes(key)
  );
  return Object.fromEntries(filteredFields);
};

const isEmpty = (value) => value === undefined || value === null || value === '';

const validate = (value, otherValues, { hide, required, conditions, additionalValidators }) => {
  const isValueEmpty = isEmpty(value);
  if (typeof value === 'string') {
    value = value?.split(quadDot).pop();
  }
  if (isValueEmpty && required) {
    if (hide) {
      return _t('field_definition::Required field should have default value when hidden');
    }
    if (conditions?.length) {
      return _t(
        'field_definition::Required field with visiblity conditions should have default value'
      );
    }
  }
  for (const { name, validate } of additionalValidators) {
    const error = validate(value, otherValues[name]);
    if (error) {
      return error;
    }
  }
  return null;
};

const parseValue = (value) => {
  if (Array.isArray(value)) {
    return value.map((option) => option?.split(quadDot).pop());
  } else if (typeof value === 'string') {
    return value?.split(quadDot).pop();
  }
  return value;
};

const checkIsRequired = (value, required, hide, conditions) =>
  !!(isEmpty(value) && required ? hide || conditions?.length : false);

function DefaultValue({ name, conditions, className }) {
  const { watch, control, setValue, getValues } = useFormContext();
  const formProps = watch();
  const { type, editAs, hide } = formProps;
  const [editorType, setEditorType] = useState();
  const editorProps = getEditorProps(formProps);
  const parsedProps = {
    ...editorProps,
    ...prepareAdditionalOptions(formProps)
  };
  const [additionalValidators, setAdditionalValidators] = useState([]);

  useEffect(() => {
    if (editorType) {
      setValue(name, null);
    }
    setEditorType(type);
  }, [type, setValue]);

  useEffect(() => {
    setAdditionalValidators(getAdditionalOptionsValidators(type));
  }, [type, setAdditionalValidators]);

  return isDefaultAvailable(type) ? (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: (val) =>
          validate(val, getValues(), {
            conditions,
            hide,
            required: formProps.required,
            additionalValidators
          })
      }}
      render={({ field, fieldState }) => {
        const { value, onChange, ref } = field;
        const { error, invalid } = fieldState;
        const displayValue = parseValue(value);
        const required = checkIsRequired(value, parsedProps.required, hide, conditions);
        return (
          <EditField
            {...parsedProps}
            className={className}
            required={required}
            ref={ref}
            type={type}
            editAs={editAs}
            label={_t('field_definition::Default value')}
            validateStatus={invalid ? 'error' : 'success'}
            help={error?.message}
            customRules={[]}
            value={displayValue}
            onChange={onChange}
          />
        );
      }}
    />
  ) : null;
}

DefaultValue.propTypes = {
  name: PropTypes.string,
  conditions: PropTypes.array,
  className: PropTypes.string
};

export default DefaultValue;
