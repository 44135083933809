import { useQuery, useMutation } from 'react-query';

import { url, getData, del, put } from './internal/rest-api';

export const useLocal = (name) => useQuery(['local', name], () => getData(url`local/${name}`));

export const useCurrent = (name) =>
  useQuery(['current', name], () => getData(url`${name}/current`));

export const useUpdateLocal = (queryClient, name) =>
  useMutation((value) => put(url`local/${name}/${value}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(['local', name]);
    }
  });

export const useRemoveLocal = (queryClient, name) =>
  useMutation(() => del(url`local/${name}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(['local', name]);
    }
  });
