import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { makeToText } from 'make-to-text';
import Item from '../../../Item';
import parseValueToQuery from '../../../utils/parseValue';
import Multiselect from '../../../utils/compare/Multiselect';

const toText = makeToText('Boolean', 'truefalse', { _t });
function TrueFalse({ value, ...rest }) {
  return <Item {...rest}>{toText(value)}</Item>;
}

TrueFalse.propTypes = {
  value: PropTypes.bool
};

const TrueFalseCompare = React.forwardRef((props, ref) => {
  return (
    <Multiselect
      {...props}
      ref={ref}
      options={[
        { value: true, label: _t('::True') },
        { value: false, label: _t('::False') }
      ]}
    />
  );
});

TrueFalseCompare.displayName = 'TrueFalseCompare';

const options = {
  value: 'truefalse',
  get label() {
    return _t('::True/False');
  },
  component: TrueFalse,
  compareComponent: TrueFalseCompare,
  parseValueToQuery: parseValueToQuery.multi,
  toText,
  example: <TrueFalse value={false} />
};

export default options;
