import { useEffect, useState } from 'react';
import { useLoggedUsers } from 'rosis-api';

const INACTIVITY_THRESHOLD = 60 * 1000;

const useActiveUsers = () => {
  const { data: loggedUsers } = useLoggedUsers();
  const [activeUsers, setActiveUsers] = useState([]);

  useEffect(() => {
    const currentDate = new Date().getTime();

    if (loggedUsers) {
      const userList = Object.entries(loggedUsers)
        .filter(([, date]) => currentDate - parseInt(date) < INACTIVITY_THRESHOLD)
        .map(([id]) => id);

      setActiveUsers(userList);
    }
  }, [loggedUsers]);
  return { activeUsers };
};

export default useActiveUsers;
