import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { _t } from 'rosis-translation';
import { useQueryClient } from 'react-query';
import { useUserRemove } from 'rosis-api';

const RejectUser = ({ _id, type, title, onSuccess, onFail }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const queryClient = useQueryClient();
  const {
    mutate: reject,
    error: errorRejectUser,
    isSuccess: isSuccessRejectUser
  } = useUserRemove(queryClient);

  useEffect(() => {
    if (isSuccessRejectUser) {
      onSuccess(_t('success::User has been rejected'));
    }
    if (errorRejectUser) {
      onFail(_t(errorRejectUser?.response?.data || 'error::User rejection failed'));
    }
    hideModal();
  }, [isSuccessRejectUser, errorRejectUser]);

  const rejectUser = () => {
    reject({ id: _id });
  };

  const showModal = () => {
    setVisibleModal(true);
  };

  const hideModal = () => {
    setVisibleModal(false);
  };

  return (
    <>
      <Tooltip placement="bottomLeft" title={_t('::Reject user')}>
        <Button type={type} className="reject-user_button" onClick={showModal}>
          <FontAwesomeIcon icon={faBan} /> {title}
        </Button>
      </Tooltip>
      <Modal
        title={_t('::Reject user?')}
        open={visibleModal}
        onOk={rejectUser}
        onCancel={hideModal}
        okText={_t('::Reject')}
        cancelText={_t('::Cancel')}>
        <p>{_t('::User will be rejected')}</p>
      </Modal>
    </>
  );
};

RejectUser.defaultProps = {
  title: ''
};

RejectUser.propTypes = {
  _id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
};

export default RejectUser;
