import React from 'react';
import PropTypes from 'prop-types';
import { Input, InputNumber } from 'antd';
import { SwapRightOutlined } from '@ant-design/icons';
import { _t } from 'rosis-translation';
import nullify from '../../../../system/helper/nullify';

const separatorWidth = 30;

const separatorStyle = {
  width: separatorWidth,
  border: 'solid 1px',
  pointerEvents: 'none',
  height: '100%',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'var(--roche-gray-text)'
};

const flexItemStyle = { width: `calc(50% - ${separatorWidth / 2}px)` };

const Number = React.forwardRef(({ val, onChange, onOk }, ref) => {
  const [from, to] = val || [];

  const handleChangeFrom = (value) => onChange([nullify(value), nullify(to)]);

  const handleChangeTo = (value) => onChange([nullify(from), nullify(value)]);

  return (
    <>
      <Input.Group compact>
        <InputNumber
          placeholder={_t('::From')}
          ref={ref}
          style={flexItemStyle}
          value={from}
          onChange={handleChangeFrom}
          onPressEnter={onOk}
          max={to}
        />
        <SwapRightOutlined style={separatorStyle} />
        <InputNumber
          placeholder={_t('::To')}
          style={flexItemStyle}
          value={to}
          onChange={handleChangeTo}
          onPressEnter={onOk}
          min={from}
        />
      </Input.Group>
    </>
  );
});

Number.displayName = 'Number';

Number.propTypes = {
  val: PropTypes.number,
  onChange: PropTypes.func,
  onOk: PropTypes.func
};

export default Number;
