import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

const InfoBarAlert = ({ type, message, onClose }) => {
  switch (type) {
    case 'progress':
      return (
        <Alert
          message={message}
          showIcon
          icon={<LoadingOutlined spin />}
          type="info"
          closable
          onClose={onClose}></Alert>
      );
    default:
      return <Alert message={message} showIcon type={type} closable onClose={onClose}></Alert>;
  }
};

InfoBarAlert.propTypes = {
  type: PropTypes.oneOf(['success', 'error', 'info', 'warning', 'progress']).isRequired,
  message: PropTypes.node.isRequired,
  onClose: PropTypes.func
};

InfoBarAlert.defaultProps = {
  onClose: () => {}
};

export default InfoBarAlert;
