import React, { useEffect, useMemo } from 'react';
import { Card, Tabs } from 'antd';
import { useParams } from 'rosis-components/navigation-wrapper';
import { withLayout } from 'layout';
import { _t } from 'rosis-translation';
import { useAuth } from 'rosis-contexts/auth';
import './Settings.less';
import { useBreadcrumbs } from 'rosis-contexts/breadcrumbs';
import { useNavigation } from 'router/links';
import { tabDefs, useTabs } from './tabs';

function Settings() {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { devMode } = useAuth();
  const { section, subsection } = useParams();
  const activeSection = section || 'main';
  const navigation = useNavigation();
  const tabs = useTabs(tabDefs, devMode);

  const activeMainTab = useMemo(
    () => tabs.find((tab) => tab.key === activeSection),
    [activeSection]
  );

  useEffect(() => {
    if (activeMainTab) {
      const breadcrumbs = [
        {
          label: '',
          link: '/'
        },
        {
          label: _t('::Settings'),
          link: '/settings'
        },
        {
          label: activeMainTab.label,
          link: `/settings/${activeSection}`
        }
      ];
      const { subSettings } = activeMainTab;
      if (subSettings) {
        const tab = subSettings.find((tab) => tab.id === subsection) || subSettings[0];
        breadcrumbs.push({
          label: _t(tab.label),
          link: `/settings/${activeSection}` + subsection
        });
      }
      setBreadcrumbs(breadcrumbs);
    }
  }, [activeMainTab, subsection]);

  const handleChange = (activeKey) => navigation.settingsGroup(activeKey);

  return (
    <Card>
      <Tabs items={tabs} activeKey={activeSection} destroyInactiveTabPane onChange={handleChange} />
    </Card>
  );
}

export default withLayout(Settings);
