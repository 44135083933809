import React, { createContext, useCallback, useContext, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import AppLoading from 'rosis-components/AppLoading';
import ExpireModal from 'rosis-components/ExpireModal';
import { getItem, storageKeys } from 'rosis-storage';
import { useWhoami, useLogout, useConfigs, useMtx } from 'rosis-api';
import period from 'system/helper/period';

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const useExpirePassword = (
  appLoginPoint,
  auth,
  pubConfigs,
  isLoggedUserToApp,
  setIsLoggedUserToApp
) => {
  const [showChangePassword, setShowChangePassword] = useState(null);
  const [pswdExpired, setPswdExpired] = useState(null);
  const [expiredDays, setExpiredDays] = useState(null);

  useEffect(() => {
    if (auth && pubConfigs) {
      if (auth?.user?.loginPoint === appLoginPoint && auth?.user?.passwdChanged) {
        const oneDay = 1000 * 60 * 60 * 24;
        const pswdChanged = new Date(auth.user.passwdChanged).getTime();
        const currentDate = new Date();
        const timeDifference = currentDate.getTime() - pswdChanged;
        const daysLeftInMilliseconds =
          pswdChanged + period(pubConfigs[`${appLoginPoint}-passwdTTL`]) - currentDate.getTime();
        const daysLeft = new Date(daysLeftInMilliseconds);
        const passwdTTL = pubConfigs[`${appLoginPoint}-passwdTTL`];
        const changePswdFlag =
          period(passwdTTL) - period(pubConfigs[`${appLoginPoint}-passwdChangeReminder`]);
        if (timeDifference > period(passwdTTL)) {
          setPswdExpired(true);
          setShowChangePassword(true);
        } else if (timeDifference > changePswdFlag) {
          setExpiredDays(parseInt(daysLeft / oneDay));
          setShowChangePassword(true);
          setIsLoggedUserToApp(true);
        } else {
          setShowChangePassword(false);
          setIsLoggedUserToApp(true);
        }
      }
    }
  }, [auth, pubConfigs]);

  return { expiredDays, pswdExpired, isLoggedUserToApp, showChangePassword, setIsLoggedUserToApp, setShowChangePassword };
};

const useExpireTime = (logout, isLoggedUserToApp) => {
  const [expiryTime, setExpiryTime] = useState(null);
  useEffect(() => {
    if (isLoggedUserToApp) {
      const interval = setInterval(async () => {
        const expiryTime = getItem(storageKeys.expiryTime);
        if (expiryTime) {
          const expiryDate = dayjs(Number.parseInt(expiryTime));
          const now = dayjs();
          const diff = expiryDate.diff(now, 'second');
          setExpiryTime(diff);
          if (diff <= 0 && isLoggedUserToApp) {
            logout();
          }
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [logout, isLoggedUserToApp]);
  return { expiryTime, setExpiryTime };
};

const useAuthObj = (whoamiRefetchInterval) => {
  const {
    data: auth,
    isLoading,
    refetch: refetchAuth
  } = useWhoami({
    refetchInterval: parseInt(whoamiRefetchInterval) || 10000
  });
  return { auth, isLoading, refetchAuth };
};

export const AuthContextProvider = ({ children, appLoginPoint }) => {
  const [isLoggedUserToApp, setIsLoggedUserToApp] = useState(false);
  const [messages, setMessages] = useState([]);
  const { data: pubConfigs } = useConfigs('pub');
  const { whoamiRefetchInterval } = pubConfigs || {};
  const { auth, isLoading, refetchAuth } = useAuthObj(whoamiRefetchInterval);

  const { expiredDays, pswdExpired, showChangePassword, setShowChangePassword } = useExpirePassword(
    appLoginPoint,
    auth,
    pubConfigs,
    isLoggedUserToApp,
    setIsLoggedUserToApp
  );
  const { data: allMtx } = useMtx({
    enabled: isLoggedUserToApp
  });

  const { mutateAsync: apiLogout } = useLogout();
  const logout = useCallback(async () => {
    await apiLogout();
    localStorage.removeItem('csrf-token');
    window.location = `${process.env["PUBLIC_URL"]}/login`;
  }, [apiLogout]);

  const { expiryTime, setExpiryTime } = useExpireTime(logout, isLoggedUserToApp);

  useEffect(() => {
    if (showChangePassword === false && expiredDays !== null) {
      setIsLoggedUserToApp(true);
    }
  }, [showChangePassword, expiredDays]);

  const contextValue = useMemo(() => {
    const { user, ...envData } = auth || {};
    if(auth?.message){
      setMessages([...auth?.message]);
    }
    
    const isLoggedUser = !!user && user.loginPoint === appLoginPoint;
    return {
      user,
      messages,
      setMessages,
      ...envData,
      isLoggedUser,
      showChangePassword,
      setShowChangePassword,
      pswdExpired,
      expiredDays,
      isLoggedUserToApp,
      setIsLoggedUserToApp,
      refetchAuth,
      mtx: allMtx,
      logout
    };
  }, [
    auth,
    refetchAuth,
    allMtx,
    logout,
    showChangePassword,
    pswdExpired,
    isLoggedUserToApp,
    expiredDays
  ]);

  return (
    <AuthContext.Provider value={contextValue}>
      {isLoading ? <AppLoading /> : children}

      {auth ? <ExpireModal expiryTime={expiryTime} setExpiryTime={setExpiryTime} /> : null}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node,
  appLoginPoint: PropTypes.string.isRequired
};
