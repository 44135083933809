import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { _t } from 'rosis-translation';
import symbol from 'system/symbol';
import FieldPermissions from './FieldPermissions';
import ModelPrivileges from './ModelPrivileges';

const { Title } = Typography;
const { privileges } = symbol;

function CollapsePanel({ name, mtx, setMtx, onChangeMtxItem }) {
  const [currentMtx, setCurrentMtx] = useState();
  const modelPrivileges = currentMtx?.['*'] || [];

  useEffect(() => {
    setCurrentMtx(mtx ? { ...mtx[name] } : {});
  }, [mtx]);

  useEffect(() => {}, [privileges]);

  const setModelPrivileges = (type, value) => {
    const newMtx = currentMtx || {};
    if (value) {
      newMtx['*'] = [type, ...modelPrivileges];
    } else {
      newMtx['*'] = modelPrivileges.filter((privilege) => privilege !== type);
    }
    mtx[name] = newMtx;
    setMtx({ ...mtx });
    onChangeMtxItem(name, '*', type, value);
  };

  const setFieldPrivileges = (field, type, value) => {
    const modifiedFields = Array.isArray(field) ? field : [field];
    modifiedFields.forEach((f) => {
      const fieldPrivileges = currentMtx[f] || [];
      if (value) {
        mtx[name][f] = [type, ...fieldPrivileges];
      } else {
        mtx[name][f] = fieldPrivileges.filter((privilege) => privilege !== type);
      }
    });
    setMtx({ ...mtx });
    onChangeMtxItem(name, field, type, value);
  };

  return currentMtx ? (
    <>
      <Title level={5}>{_t('::Section')}</Title>
      <ModelPrivileges
        frameName={name}
        modelPrivileges={modelPrivileges}
        setModelPrivileges={setModelPrivileges}
      />
      <Title level={5}>{_t('::Attributes')}:</Title>
      <FieldPermissions
        frameName={name}
        mtx={currentMtx}
        modelPrivileges={modelPrivileges}
        setPrivileges={setFieldPrivileges}
      />
    </>
  ) : null;
}

CollapsePanel.propTypes = {
  name: PropTypes.string.isRequired,
  mtx: PropTypes.object.isRequired,
  setMtx: PropTypes.func.isRequired,
  onChangeMtxItem: PropTypes.func.isRequired
};

export default CollapsePanel;
