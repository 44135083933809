import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import RoleCheckbox from './RoleCheckbox';
import symbol from 'system/symbol';

const rolePrivilegesSettings = symbol.privileges.role;

function MainPrivileges({ mtx, setMtx, onChangeMtxItem }) {
  const [mainPrivileges, setMainPrivileges] = useState([]);
  const [privilegesCheckboxes, setPrivilegesCheckboxes] = useState(null);

  useEffect(() => {
    setMainPrivileges(mtx?.['*']?.['*'] || []);
  }, [mtx]);

  useEffect(() => {
    setPrivilegesCheckboxes(
      Object.entries(rolePrivilegesSettings).map(([key, label]) => (
        <RoleCheckbox
          key={key}
          type={key}
          label={_t(label)}
          privileges={mainPrivileges}
          setPrivileges={handleMainPrivilege}
        />
      ))
    );
  }, [mainPrivileges]);

  const handleMainPrivilege = (name) => {
    const oldValue = mainPrivileges.includes(name);
    const newPrivileges = oldValue
      ? mainPrivileges.filter((_name) => _name !== name)
      : [...mainPrivileges, name];
    setMtx({ ...mtx, ...{ ['*']: { ['*']: newPrivileges } } });
    onChangeMtxItem('*', '*', name, !oldValue);
  };

  return (
    <div className="main-privileges">
      <h4>{_t('Main Privileges')}</h4>
      {privilegesCheckboxes}
    </div>
  );
}
MainPrivileges.propTypes = {
  mtx: PropTypes.object,
  setMtx: PropTypes.func,
  onChangeMtxItem: PropTypes.func.isRequired
};

export default MainPrivileges;
