import React from 'react';
import PropTypes from 'prop-types';
import { datetime } from 'rosis-date-picker';
import { _t } from 'rosis-translation';
import { makeToText } from 'make-to-text';
import MonthCompare from '../../../utils/compare/Month';
import parseValueToQuery from '../../../utils/parseValue';
import Item from '../../../Item';

const toText = makeToText('Date', 'month', { _t });
function Month({ value, ...rest }) {
  return <Item {...rest}>{toText(value)}</Item>;
}

Month.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const options = {
  value: 'month',
  get label() {
    return _t('::Month');
  },
  toText,
  component: Month,
  compareComponent: MonthCompare,
  parseValueToQuery: parseValueToQuery.date,
  hide: ({ editAs }) => ['year'].includes(editAs),
  example: <Month value={datetime('1995-11-25 11:22:00')} />
};

export default options;
