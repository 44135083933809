import * as displayAs from './displayAs';
import * as editAs from './editAs';
import * as additionalOptions from './additionalOptions';
import makeType from '../../makeType';

const number = makeType({
  name: 'Number',
  label: '::Number',
  displayAs,
  editAs,
  additionalOptions
});

export default number;
