import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload, Button } from 'antd';
import { FolderOpenOutlined } from '@ant-design/icons';
import { _t } from 'rosis-translation';
import { apiUrl } from 'rosis-api';

function ConfigImage({ onChange, value, editorRef, ...editorProps }) {
  const [files, setFiles] = useState();

  useEffect(() => {
    if (value) {
      const newValue =
        typeof value === 'string'
          ? {
              name: value.split('/').pop(),
              url: `${apiUrl}${value}`
            }
          : value;
      setFiles([newValue]);
    } else {
      setFiles([]);
    }
  }, [value]);

  const handleChange = ({ file }) => {
    if (file.status === 'removed') {
      onChange(null);
    } else {
      onChange(file);
    }
  };

  return files ? (
    <Upload
      maxCount={1}
      accept="image/*"
      beforeUpload={() => false}
      listType="picture"
      onChange={handleChange}
      onRemove={() => onChange(null)}
      fileList={files}
      showUploadList={{ showRemoveIcon: false }}
      {...editorProps}
      ref={editorRef}>
      <Button icon={<FolderOpenOutlined />}>{_t('button::Select image')}</Button>
    </Upload>
  ) : null;
}

ConfigImage.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string
    })
  ]),
  onChange: PropTypes.func,
  editorRef: PropTypes.shape({ current: PropTypes.object })
};

export default ConfigImage;
