import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';

const UserMessage = ({ message }) => {
  return (
    <div>
      <span className="message-key">{_t('logs-user-message::Username')}</span>
      <span>{message}</span>
    </div>
  );
};

UserMessage.propTypes = {
  message: PropTypes.string
};

export default UserMessage;
