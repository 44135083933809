import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { Input } from 'antd';
import Item from '../../../Item';

const { TextArea } = Input;

const FTTextArea = React.forwardRef((props, ref) => {
  const { value, onChange } = props;
  return (
    <Item {...props}>
      <TextArea ref={ref} value={value} onChange={onChange} />
    </Item>
  );
});

FTTextArea.displayName = 'FTTextArea';

FTTextArea.propTypes = { value: PropTypes.string, onChange: PropTypes.func };

const options = {
  value: 'textarea',
  get label() {
    return _t('::Textarea');
  },
  component: FTTextArea,
  example: <FTTextArea name="textarea" value={_t('::Example text')} />
};

export default options;
