import React from 'react';
import { _t } from 'rosis-translation';
import { SFNumber } from 'rosis-setting-form';

function Step(props) {
  return <SFNumber {...props} />;
}

const step = {
  get label() {
    return _t('::Step');
  },
  name: 'step',
  Field: Step,
  order: 30
};

export default step;
