import { saveExpireTime } from './utils';

const { NODE_ENV } = process.env;

let csfrToken;

const goToLogin = () => {
  localStorage.removeItem('csrf-token');
  window.location = `${process.env['PUBLIC_URL']}/login`;
};

const getCsrfTokenFromLocalStorage = () => localStorage.getItem('csrf-token');

export const getCsrfToken = () => {
  return NODE_ENV === 'development' ? getCsrfTokenFromLocalStorage() : getCsrfTokenFromMetaTag();
};

export const isLoggedIn = () => !!getCsrfToken();

const getCsrfTokenFromMetaTag = () => {
  const metaToken = document.querySelector('meta[name=ctoq]');
  return metaToken?.content;
};

export const addCsfrTokenInterceptor = (apiInstance) => {
  apiInstance.interceptors.request.use((config) => {
    if (!csfrToken) {
      csfrToken = getCsrfToken();
    }
    if (csfrToken) {
      config.headers.common['x-ctoq'] = csfrToken;
    }
    return config;
  });
};

export const addAccessErrorsInterceptor = (apiInstance) => {
  apiInstance.interceptors.response.use(
    function (response) {
      saveExpireTime(response.headers['x-idlenessuntil']);
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (
        error.response &&
        originalRequest.url.indexOf('whoami') >= 0 &&
        error.response.status === 403
      ) {
        goToLogin();
      }
      const { pathname } = window.location;
      if (
        error.response &&
        401 === error.response.status &&
        !originalRequest._retry &&
        pathname !== `${process.env['PUBLIC_URL']}/login` &&
        pathname !== `${process.env['PUBLIC_URL']}/forgotPassword`
      ) {
        originalRequest._retry = true;
        if (originalRequest.url.indexOf('whoami') >= 0) {
          goToLogin();
        }
      }
      return Promise.reject(error);
    }
  );
};

export const serverErrorInterceptor = (apiInstance) => {
  apiInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error && (error.code === 'ECONNABORTED' || error.response.status >= 500)) {
        const errorUrl = `${process.env['PUBLIC_URL']}/server-error`;
        if (window.location.pathname !== errorUrl) {
          window.location = errorUrl;
        }
      }
      return Promise.reject(error);
    }
  );
};
