const datetime = require('rosis-date-time-provider');
const { dateFormats } = require('system/symbol');

const dateFix = (date) => {
  date = new Date(date);
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  return datetime(date);
};

const dataToText = (value, format) =>
  value ? dateFix(value).format(format || dateFormats.date) : '';

const toText = {
  Boolean: {
    yesno: function (value) {
      const { _t } = this;
      return value ? _t('::Yes') : _t('::No');
    },
    truefalse: function (value) {
      const { _t } = this;
      return value ? _t('::True') : _t('::False');
    }
  },
  Date: {
    date: function (value) {
      return dataToText(value);
    },
    age: function (value) {
      if (!value) return '';
      if (datetime(value, dateFormats.date).isValid()) {
        const now = datetime();
        const duration = datetime.duration(now.diff(value));
        return duration.years();
      } else {
        return value;
      }
    },
    month: function (value) {
      return dataToText(value, dateFormats.month);
    },
    year: function (value) {
      return dataToText(value, dateFormats.year);
    }
  },
  DateTime: {
    datetime: function (value) {
      return value ? datetime(value).format(dateFormats.datetime) : '';
    }
  },
  Multi: {
    text: function (value) {
      const { _t } = this;
      return value ? value.map((v) => _t(v)).join(', ') : '';
    }
  },
  Number: {
    number: function (value, options) {
      const { decimal } = options || {};
      if ((!value && value !== 0) || isNaN(value)) {
        return '';
      }
      return decimal ? value.toFixed(decimal) : value;
    }
  },
  Select: {
    text: function (value) {
      const { _t } = this;
      return value ? _t(value) : '';
    }
  }
};

const makeToText = (type, displayAs, context = {}) => {
  if (type && toText[type]) {
    if (displayAs) {
      return toText[type][displayAs].bind(context);
    } else {
      const [defaultToText] = Object.keys(toText[type]);
      return toText[type][defaultToText].bind(context);
    }
  } else {
    return function (value) {
      return value ? value.toString() : '';
    }.bind(context);
  }
};

module.exports = { makeToText };
