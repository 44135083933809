import React from 'react';
import { _t } from 'rosis-translation';
import PropTypes from 'prop-types';
import { Slider } from 'antd';
import Item from '../../../Item';

const FTSlider = React.forwardRef((props, ref) => {
  const { min, max, step, value, onChange } = props;
  return (
    <Item {...props}>
      <Slider ref={ref} value={value} min={min} max={max} step={step} onChange={onChange} />
    </Item>
  );
});

FTSlider.displayName = 'FTSlider';

FTSlider.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func
};

const options = {
  value: 'slider',
  get label() {
    return _t('::Slider');
  },
  component: FTSlider,
  off: true
};

export default options;
