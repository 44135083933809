import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'rosis-components/navigation-wrapper';
import { _t } from 'rosis-translation';
import { links } from 'router/links';

function TitleRow({ model, expended, handleExtend }) {
  const { name, label, children } = model;
  return (
    <div className="table-dnd_row_main_title">
      <div className="title">
        <Link to={links.sectionDetails(name)}>{_t(label)}</Link>
        {children?.length ? (
          <button
            type="button"
            className={classNames('expend-btn', expended && 'expended')}
            onClick={() => handleExtend(name)}
          />
        ) : null}
      </div>
    </div>
  );
}

TitleRow.propTypes = {
  model: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.array
  }),
  expended: PropTypes.bool,
  handleExtend: PropTypes.func
};

export default TitleRow;
