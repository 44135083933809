import React from 'react';
import { Typography, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import './TitleActions.less';

const { Title } = Typography;

function TitleActions({ children, actions }) {
  return (
    <Title className="title-actions" level={3}>
      <Row justify="space-between">
        <Col className="title-actions__title" md={12}>
          {children}
        </Col>
        <Col className="title-actions__actions" md={12}>
          {actions?.map((action) => action)}
        </Col>
      </Row>
    </Title>
  );
}

TitleActions.propTypes = {
  children: PropTypes.node.isRequired,
  actions: PropTypes.array
};

export default TitleActions;
