import React, { useState, useEffect } from 'react';
import { Select, Form, Input } from 'antd';
import { useSecretQuestionsForUsers, useConfig } from 'rosis-api';
import { _t } from 'rosis-translation';

const { Option } = Select;

function QuestionsList() {
  const { data: questions } = useSecretQuestionsForUsers();
  const { data: questionsForUser } = useConfig('pub', 'questionsForUser');
  const [numberOfQuestion, setNumberOfQuestion] = useState(1);
  const [questionsList, setQuestionsList] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([])

  useEffect(() => {

    if (questionsForUser) {
      setNumberOfQuestion(parseInt(questionsForUser));
      setSelectedQuestions([...Array(parseInt(questionsForUser)).keys()].map(() => ''))
    }
    if(questions){
      setQuestionsList(questions)
    }
  }, [questionsForUser, questions]);

  const markAsUsed = () => {
    setQuestionsList(questionsList.map((question) =>  ({ ...question, used: selectedQuestions.includes(question._id) }) ))
  }

  const onChange = (value, index) => {
    
    const newState = selectedQuestions.map((q, i) =>
      i === index ? value : q
    );

    setSelectedQuestions(newState)

    
  }

  useEffect(() => {


    if(questionsList.length) markAsUsed();

  },[selectedQuestions])



  return (
    <Form.List name="secretQuestions">
      {() => {
        return (
          <div>
            {[...Array(numberOfQuestion).keys()].map((field, index) => (
              <div key={field}>
                <Form.Item
                  name={[index, 'id']}
                  label={_t('::Secret question')}
                  autoComplete="off"
                  rules={[{ required: true, message: _t('error::Please select question') }]}>
                  <Select style={{ width: '100%' }} optionLabelProp="label" onChange={(value) => onChange(value, index)}>
                    {(questionsList || []).filter((question) => !question?.used).map((question) => (
                      <Option key={question._id} value={question._id} label={_t(question.value)}>
                        <div className="demo-option-label-item">{_t(question.value)}</div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={_t('::Answer')}
                  name={[index, 'value']}
                  rules={[{ required: true, message: _t('::Please input your answer') }]}>
                  <Input.Password autoComplete="off" visibilityToggle={true} />
                </Form.Item>
              </div>
            ))}
          </div>
        );
      }}
    </Form.List>
  );
}

export default QuestionsList;
