import React from 'react';
import { useParams } from 'rosis-components/navigation-wrapper';
import { withNotLoggedLayout } from 'layout';
import ConfirmEmailSimple from 'rosis-components/ConfirmEmail/ConfirmEmailSimple';
import ConfirmEmailWithPassword from 'rosis-components/ConfirmEmail/ConfirmEmailWithPassword';

import { links } from 'router/links';

function ConfirmEmail() {
  const { hash, mode } = useParams();

  if (mode === 'ext') {
    return (
      <ConfirmEmailWithPassword
        hash={hash}
        isPin={hash.length === 6}
        configPrefix="admin"
        loginPath={links.login()}
      />
    );
  }
  return <ConfirmEmailSimple hash={hash} configPrefix="admin" loginPath={links.login()} />;
}

export default withNotLoggedLayout(ConfirmEmail);
