import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, Radio, Select } from 'antd';
import { apiUrl } from 'rosis-api';
import { _t } from 'rosis-translation';

const DownloadReportModal = ({ roles, downloadParams }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [typeOfUsers, setTypeOfUsers] = useState(['all']);
  const [reportFormat, setReportFormat] = useState('csv');

  const rolesList = useMemo(() => {
    return [
      { value: 'all', label: _t('::All users') },
      ...(roles || []).map((role) => ({
        value: role.key,
        label: role.label
      }))
    ];
  }, [roles]);

  const onChangeSelect = (value) => {
    let lastIndex = value.length - 1;

    if (value[lastIndex] === 'all') {
      setTypeOfUsers(['all']);
    } else {
      setTypeOfUsers(value.filter((item) => item !== 'all'));
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    let params = new URLSearchParams('');
    if (downloadParams.find) {
      if (typeOfUsers.includes('all')) {
        downloadParams.find = { ...downloadParams.find, role: undefined };
      } else {
        downloadParams.find = { ...downloadParams.find, role: typeOfUsers };
      }

      params.append('find', JSON.stringify(downloadParams.find));
    }
    if (downloadParams.sites) {
      params.append('sites', JSON.stringify(downloadParams.sites));
    }

    setIsModalOpen(false);
    window.open(`${apiUrl}/user/${reportFormat}?${params}`, '_blank');
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button onClick={showModal} icon={<FontAwesomeIcon icon={faDownload} />}>
        {_t('button::Download')}
      </Button>
      <Modal
        title={<span className="font-bold">{_t('::Download users report')}</span>}
        open={isModalOpen}
        onOk={handleOk}
        okText={_t('button::Download')}
        cancelText={_t('::Cancel')}
        onCancel={handleCancel}>
        <p>{_t('::Users')}:</p>
        <Select
          value={typeOfUsers}
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          options={rolesList}
          onChange={onChangeSelect}
        />
        <p className="report-format">{_t('::Format')}:</p>
        <Radio.Group onChange={(e) => setReportFormat(e.target.value)} value={reportFormat}>
          <Radio value={'csv'}>{_t('button::CSV')}</Radio>
          <Radio value={'excel'}>{_t('button::Excel')}</Radio>
        </Radio.Group>
      </Modal>
    </>
  );
};

DownloadReportModal.propTypes = {
  downloadParams: PropTypes.object,
  roles: PropTypes.array
};

export default DownloadReportModal;
