import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RemoveButton from 'components/RemoveButton';
import { _t } from 'rosis-translation';
import ConditionFieldSelect from './ConditionFieldSelect';
import EqualOrNotEqual from './EqualOrNotEqual';
import FieldValue from './FieldValue';
import LogicalOperator from './LogicalOperator';
import { fieldProps } from './utils';

function Condition({ fields, condition, index, onChange, onRemove }) {
  const [logicalOperator, fieldName, isEqual, value] = condition;
  const [selectedField, setSelectedField] = useState();

  useEffect(() => {
    fields && setSelectedField(fields.find((field) => field.name === fieldName));
  }, [fields, condition]);

  const handleChange = (name, value) => {
    const copy = [...condition];
    switch (name) {
      case 'operator':
        copy[0] = value;
        break;
      case 'field':
        copy[1] = value;
        copy[3] = null;
        break;
      case 'isEqual':
        copy[2] = value;
        break;
      case 'value':
        copy[3] = value;
        break;
    }
    onChange(copy, index);
  };

  return (
    <>
      <LogicalOperator
        operator={logicalOperator}
        onChange={(value) => handleChange('operator', value)}
      />
      <ConditionFieldSelect
        fields={fields}
        fieldName={fieldName}
        onChange={(value) => handleChange('field', value)}
      />
      <EqualOrNotEqual isEqual={isEqual} onChange={(value) => handleChange('isEqual', value)} />
      <FieldValue
        value={value}
        selectedField={selectedField}
        onChange={(value) => handleChange('value', value)}
      />
      <RemoveButton mainText={_t('button::Remove')} onConfirm={() => onRemove(index)} />
    </>
  );
}
Condition.propTypes = {
  fields: PropTypes.arrayOf(fieldProps),
  condition: PropTypes.array,
  index: PropTypes.number,
  onChange: PropTypes.func,
  onRemove: PropTypes.func
};

export default Condition;
