import { useQuery, useMutation } from 'react-query';

import { url, getData, del, put } from './internal/rest-api';

export const useGenerateData = (queryClient) =>
  useMutation(({ person, count }) => put(url`docgen/${person}/${count}`), {
    onSuccess: () => {
      queryClient.invalidateQueries();
    }
  });

export const useGetCountOfGenerateData = () =>
  useQuery('docgenCount', () => getData(`docgen/have`));

export const useRemoveDemoData = (queryClient) =>
  useMutation(() => del(`docgen/all`), {
    onSuccess: () => {
      queryClient.invalidateQueries();
    }
  });
