import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { Select } from 'antd';
import Item from '../../../Item';

const { Option } = Select;

const FTSelect = React.forwardRef((props, ref) => {
  const { value, opts, onChange } = props;
  return (
    <Item {...props} initialValue={value}>
      <SelectEditor ref={ref} value={value} opts={opts} onChange={onChange} />
    </Item>
  );
});

FTSelect.displayName = 'FTSelect';

FTSelect.propTypes = {
  value: PropTypes.string,
  opts: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func
};

const SelectEditor = React.forwardRef(({ value, opts, onChange }, ref) => {
  const change = (value) => {
    if (onChange) {
      onChange(value || null);
    }
  };
  return (
    <Select ref={ref} value={value} onChange={change} allowClear>
      {(opts || []).map((option) => (
        <Option key={option} value={option}>
          {_t(option)}
        </Option>
      ))}
    </Select>
  );
});

SelectEditor.displayName = 'SelectEditor';

SelectEditor.propTypes = {
  value: PropTypes.string,
  opts: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func
};

const select = {
  value: 'select',
  get label() {
    return _t('::Select');
  },
  example: <FTSelect opts={['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5']} />,
  component: FTSelect,
  default: true
};

export default select;
