import React, { useEffect, useState } from 'react';
import { _t } from 'rosis-translation';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { SFCustom } from 'rosis-setting-form';

function Example({ name, type, options }) {
  const { watch } = useFormContext();
  const value = watch(name);
  const [example, setExample] = useState();

  useEffect(() => {
    if (type && value) {
      const currentOption = options.find((opt) => opt.value === value);
      setExample(currentOption?.example);
    }
  }, [type, value]);

  return example ? <SFCustom label={_t('::Example') + ':'}>{example}</SFCustom> : null;
}

Example.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
};

export default Example;
