import React from 'react';
import { useParams, Link } from 'rosis-components/navigation-wrapper';
import { Button } from 'antd';
import { _t } from 'rosis-translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { links } from 'router/links';

function AddButton() {
  const { name: frameName } = useParams();
  return (
    <Link to={links.sectionFieldCreate(frameName)}>
      <Button type="primary" icon={<FontAwesomeIcon icon={faPlusCircle} />}>
        {_t('button::Add field')}
      </Button>
    </Link>
  );
}

export default AddButton;
