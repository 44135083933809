import React, { useState, useMemo } from 'react';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import UsersByRole from './UsersByRole';
import { useNavigate } from 'react-router-dom';
import { _t } from 'rosis-translation';
import { useConfig } from 'rosis-api';
import ToolbarDesktop from './ToolbarDesktop';
import './../Users.less';

function UsersDesktop({ models, role }) {
  const navigate = useNavigate();
  const activeKey = role || 'admin';
  const { data: loginPointsList } = useConfig('conf', 'showLoginPointsUsers');

  const [downloadParams, setDownloadParams] = useState({});

  const updateFilter = ({ find, sites }) => {
    setDownloadParams({ find, sites });
  };

  const changeActiveKey = (key) => {
    navigate(`/users/${key}`);
  };

  const tabs = useMemo(
    () =>
      loginPointsList
        ? models
            .filter((field) => loginPointsList.split(/\s+/).includes(field.loginPoint))
            .map(({ name, label, labels, loginPoint }) => ({
              key: name,
              label: _t(label),
              loginPoint,
              children: (
                <UsersByRole name={name} noData={_t(labels?.none)} onUpdateFilter={updateFilter} />
              )
            }))
            .reverse()
        : [],
    [models, loginPointsList]
  );

  const userInfo = useMemo(() => tabs.find((field) => field.key === activeKey), [tabs, activeKey]);

  return (
    <Tabs
      items={tabs}
      tabPosition="top"
      activeKey={activeKey}
      destroyInactiveTabPane={true}
      onChange={changeActiveKey}
      tabBarExtraContent={
        <ToolbarDesktop
          model={activeKey}
          userInfo={userInfo}
          downloadParams={downloadParams}
          roles={tabs}
        />
      }
    />
  );
}

UsersDesktop.propTypes = {
  models: PropTypes.array.isRequired,
  role: PropTypes.string
};

export default UsersDesktop;
