import React, { useEffect } from 'react';
import { Card } from 'antd';
import { withLayout } from 'layout';
import { useParams } from 'rosis-components/navigation-wrapper';
import { _t } from 'rosis-translation';
import { useModels } from 'rosis-api';
import UsersDesktop from './Desktop/UsersDesktop';
import UsersMobile from './Mobile/UsersMobile';
import './Users.less';
import { useBreadcrumbs } from 'rosis-contexts/breadcrumbs';
import { useIsSmallDesktop } from 'rosis-components/hooks/useScreenSizes';

function Users() {
  const { role } = useParams();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { data: models } = useModels();
  const isDesktop = useIsSmallDesktop();

  useEffect(() => {
    setBreadcrumbs([
      {
        label: '',
        link: '/'
      },
      {
        label: _t('::Users'),
        link: '/users'
      }
    ]);
  }, [role]);
  return (
    <Card>
      {models ? (
        isDesktop ? (
          <UsersDesktop models={models} role={role} />
        ) : (
          <UsersMobile models={models} role={role} />
        )
      ) : null}
    </Card>
  );
}

export default withLayout(Users);
