import React, { useState } from 'react';
import { Button, Dropdown } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountUpAlt, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import { DownOutlined } from '@ant-design/icons';
import { _t } from 'rosis-translation';
import './../Users.less';

const SortByDropdown = ({ changeSort }) => {
  const [visible, setVisible] = useState(false);
  const [sortBy, setSortBy] = useState(null);

  const fieldList = [
    {
      key: 'idStr',
      title: _t('user::Login')
    }
  ];

  const handleMenuClick = ({ key }) => {
    let order = key.split('-')[1];
    let field = key.split('-')[0];
    switch (order) {
      case 'ascend':
        setSortBy({ [field]: 1 });
        changeSort({ [field]: 1 });
        break;
      case 'descend':
        setSortBy({ [field]: -1 });
        changeSort({ [field]: -1 });
        break;
      default:
        setSortBy(null);
        changeSort(null);
        break;
    }
  };

  const items = fieldList.map((field) => ({
    key: `${field?.key}-${sortBy?.[field?.key] === 1 ? 'descend' : 'ascend'}`,
    label: field?.title,
    icon: (
      <FontAwesomeIcon
        icon={sortBy?.[field?.key] === 1 ? faSortAmountUpAlt : faSortAmountDownAlt}
      />
    )
  }));

  return (
    <Dropdown
      menu={{ items, onClick: handleMenuClick }}
      open={visible}
      onOpenChange={() => setVisible(!visible)}>
      <Button className="font-bold">
        {_t('Sort by')} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

SortByDropdown.propTypes = {
  changeSort: PropTypes.func
};

export default SortByDropdown;
