import React, { useEffect } from 'react';
import { Typography, Form, Alert, Input, Button, Row, Col } from 'antd';
import { UserGearSolid } from 'assets/icons';
import PropTypes from 'prop-types';
import { Link } from 'rosis-components/navigation-wrapper';
import { _t } from 'rosis-translation';
import PswChecklist from 'rosis-components/PasswordScreens/PswChecklist';
import QuestionsList from 'rosis-components/QuestionsList';
import { links } from 'router/links';

const { Title } = Typography;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};

function LoginPanel({ hasAdmin, pswdWidget, capsLock, passwd, setPasswd, onKeyDown, onFinish }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!passwd) {
      form.setFieldValue('password', '');
    }
  }, [passwd]);

  return (
    <>
      <Title level={4}>
        {!hasAdmin
          ? _t(
              '::As the first person attempting to log in, you will be registered as an Administrator. Please enter your email and choose the password.'
            )
          : _t('::Sign in')}
      </Title>
      <div>
        <UserGearSolid /> {_t('::Admin panel')}
      </div>
      <Form {...layout} name="basic" onFinish={onFinish} form={form}>
        <Form.Item
          label={_t('::Login')}
          name="idStr"
          rules={[{ required: true, message: _t('::Please input your login') }]}>
          <Input autoComplete="username" onKeyDown={onKeyDown} />
        </Form.Item>
        {hasAdmin || <QuestionsList />}
        <Form.Item
          label={_t('::Password')}
          name="password"
          rules={[{ required: true, message: _t('::Please input your password') }]}>
          <Input
            type="password"
            autoComplete="current-password"
            onChange={(e) => setPasswd(e.target.value)}
            onKeyDown={onKeyDown}
          />
        </Form.Item>
        {capsLock && (
          <Alert
            message={_t('::Caps lock is on')}
            style={{ marginBlockEnd: '15px' }}
            type="warning"
            showIcon
          />
        )}
        {hasAdmin || (
          <>
            <Form.Item
              label={_t('Confirm Password')}
              validateTrigger="onSubmit"
              name="confirm"
              dependencies={['password']}
              rules={[
                { required: true, message: _t('::Please input password again') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(_t('::The two passwords that you entered do not match'))
                    );
                  }
                })
              ]}>
              <Input type="password" autoComplete="new-password" onKeyDown={onKeyDown} />
            </Form.Item>
            {pswdWidget === 'yes' && <PswChecklist password={passwd} />}
          </>
        )}
        <Row justify="space-between">
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {_t('button::Sign in')}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Link to={links.forgotPassword()}>
              <Button type="link">{_t('::Forgot password?')}</Button>
            </Link>
          </Col>
        </Row>
      </Form>
    </>
  );
}

LoginPanel.propTypes = {
  hasAdmin: PropTypes.bool,
  pswdWidget: PropTypes.string,
  capsLock: PropTypes.bool,
  passwd: PropTypes.string,
  setPasswd: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFinish: PropTypes.func
};

export default LoginPanel;
