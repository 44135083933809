import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { Link } from 'rosis-components/navigation-wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import MarkToRemoveButton from 'components/buttons/MarkToRemoveButton';
import { _t } from 'rosis-translation';
import './Schema.less';
import { links } from 'router/links';

const DragHandle = ({ dragHandleProps }) => (
  <span type="link" className="action-drag" {...dragHandleProps}>
    <Tooltip title={_t('button::Change order')}>
      <FontAwesomeIcon icon={faArrowsAlt} />
    </Tooltip>
  </span>
);

DragHandle.propTypes = {
  dragHandleProps: PropTypes.object
};
function Actions({ frameName, record, cantRemove, dragHandleProps, onMarkToRemove }) {
  const { name, selected, type } = record;

  return (
    <>
      <Tooltip title={type !== 'Virt' && _t('button::Edit')}>
        <Link key="edit" to={links.sectionField(frameName, name)}>
          <Button type="link" key="action-edit" className="action-edit" disabled={type === 'Virt'}>
            <FontAwesomeIcon icon={faPen} />
          </Button>
        </Link>
      </Tooltip>
      <MarkToRemoveButton
        mainText={_t("::You can't remove this section because:")}
        onMarkToRemove={onMarkToRemove}
        name={name}
        selected={selected}
        cantRemove={cantRemove}
      />
      <DragHandle key="action-drag" dragHandleProps={dragHandleProps} />
    </>
  );
}

Actions.propTypes = {
  frameName: PropTypes.string,
  cantRemove: PropTypes.arrayOf(PropTypes.string),
  record: PropTypes.shape({
    name: PropTypes.string,
    selected: PropTypes.bool,
    type: PropTypes.string
  }),
  dragHandleProps: PropTypes.object,
  onMarkToRemove: PropTypes.func
};

export default Actions;
