import { fieldTypes } from 'rosis-field-types';
import { datetime } from 'rosis-date-picker';

const parseResult = (value, type) => {
  switch (type) {
    case 'Array':
      return value ? value.split('\n').filter((el) => el) : value;
    case 'Date':
      return datetime.isDayjs(value) ? value?.format('YYYY-MM-DDTHH:mm:ss') : value;
    default:
      return value;
  }
};

export const prepareAdditionalOptions = (data) => {
  const typeSettings = fieldTypes[data.type];
  if (!typeSettings) {
    return {};
  }
  const result = {};
  for (const option of typeSettings.additionalOptions) {
    const { name, valueType } = option;
    result[name] = parseResult(data[name], valueType);
  }
  return result;
};

export const getAdditionalOptionsValidators = (type) => {
  const typeSettings = fieldTypes[type];
  if (!typeSettings) {
    return [];
  }
  const result = typeSettings.additionalOptions
    .map((af) => ({ name: af.name, validate: af.validate }))
    .filter((v) => !!v.validate);
  return result;
};
