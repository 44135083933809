import React, { useState, useEffect } from 'react';
import { useParams } from 'rosis-components/navigation-wrapper';
import CollapsePanel from './../Role/CollapsePanel';
import { useMtx, useModels, useUpdateMtxItem, useUpdateMtxManyItems } from 'rosis-api';
import { Form, Select, Skeleton } from 'antd';
import { _t } from 'rosis-translation';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';
import filterByAllowedLoginPoints from '../../utils/filterByAllowedLoginPoints';

const { Option } = Select;

export default function Privileges() {
  const { name } = useParams();
  const [mtx, setMtx] = useState({});
  const { data: allMtx } = useMtx();
  const [roles, setRoles] = useState();
  const [role, setRole] = useState();

  const { data: models } = useModels();

  const { mutate: updateMtxManyItems, ...updateMtxManyItemsStatus } = useUpdateMtxManyItems();

  useApiFeedback({
    status: updateMtxManyItemsStatus,
    silentProgress: true,
    silentSuccess: true
  });

  const { mutate: updateMtxItem, ...updateMtxItemStatus } = useUpdateMtxItem();

  useApiFeedback({
    status: updateMtxItemStatus,
    silentProgress: true,
    silentSuccess: true
  });

  useEffect(() => {
    models &&
      setRoles(
        models.filter(filterByAllowedLoginPoints).map((model) => {
          return { ...model, children: null };
        })
      );
  }, [models]);

  useEffect(() => {
    allMtx && setMtx(allMtx[role] || {});
  }, [role, allMtx]);

  useEffect(() => {
    role || setRole(roles?.[0].key);
  }, [roles]);

  const changeMtxItem = (frame, fields, right, value) => {
    if (Array.isArray(fields)) {
      updateMtxManyItems({
        role,
        frame,
        fields,
        right,
        value
      });
    } else {
      updateMtxItem({
        role,
        frame,
        field: fields,
        right,
        value
      });
    }
  };

  return (
    <>
      <Form>
        <Form.Item label={_t('section_privileges::Role')}>
          <Select value={role} onChange={setRole}>
            {roles?.map((role) => (
              <Option key={role.key} value={role.key}>
                {_t(role.label)}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>

      {role ? (
        <CollapsePanel name={name} mtx={mtx} setMtx={setMtx} onChangeMtxItem={changeMtxItem} />
      ) : (
        <Skeleton />
      )}
    </>
  );
}
