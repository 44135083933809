import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import classNames from 'classnames';
import Navigation from 'rosis-components/Navigation';
import './Content.less';
import { InPageHeaderContext } from 'contexts/pageHeader';
import PageHeader from './PageHeader';

const { Content } = Layout;

function AppContent({ children, footer, notLogged }) {
  return (
    <Layout className="site-layout">
      <Content
        className={classNames('admin-content', notLogged ? 'not-logged-content' : 'app-content')}>
        <InPageHeaderContext>
          {!notLogged && (
            <div className="page-toolbar">
              {<Navigation layoutForFloat />}
              <PageHeader />
            </div>
          )}
          {children}
          {footer}
        </InPageHeaderContext>
      </Content>
    </Layout>
  );
}

AppContent.propTypes = {
  children: PropTypes.any.isRequired,
  notLogged: PropTypes.bool,
  footer: PropTypes.node
};

export default AppContent;
