import React from 'react';
import { useFormContext } from 'react-hook-form';
import { SFNumber } from 'rosis-setting-form';
import { _t } from 'rosis-translation';

function Max(props) {
  const { watch } = useFormContext();
  const min = watch('min');
  return <SFNumber {...props} min={min || 0} step={1} />;
}

const max = {
  get label() {
    return _t('::Max length');
  },
  Field: Max,
  name: 'max',
  order: 20
};

export default max;
