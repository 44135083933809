import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { RetweetOutlined, DeleteOutlined } from '@ant-design/icons';
import { _t } from 'rosis-translation';
import { Select, Skeleton, Form, Input, Row, Col, Divider, Button, Tooltip } from 'antd';
import {
  useConfigs,
  usePreConfigs,
  useConfigList,
  useUpdateConfig,
  useRemoveConfig,
  useConfigToDefault
} from 'rosis-api';

const { Option } = Select;

const layout = {
  labelCol: {
    span: 24
  },
  wrapperCol: {
    span: 12
  }
};

function Config({ onSuccess, onFail }) {
  const queryClient = useQueryClient();
  const [selectedValue, setSelectedValue] = useState(null);
  const [data, setData] = useState({});
  const [addNewForm] = Form.useForm();
  const {
    mutate: update,
    isSuccess: isUpdated,
    error: errorUpdated
  } = useUpdateConfig(queryClient);
  const {
    mutate: revert,
    isSuccess: isReverted,
    error: errorReverted
  } = useConfigToDefault(queryClient);
  const {
    mutate: remove,
    isSuccess: isRemoved,
    error: errorRemoved
  } = useRemoveConfig(queryClient);
  const { data: values, isLoading: isLoadingValues } = useConfigList();
  const {
    data: valuesByName,
    isLoading: isLoadingValuesByName,
    refetch
  } = useConfigs(selectedValue, {
    enabled: !!selectedValue
  });
  const {
    data: preValues,
    isLoading: isLoadingPreValues,
    refetch: refetchPreValues
  } = usePreConfigs(selectedValue, {
    enabled: !!selectedValue
  });

  useEffect(() => {
    if (valuesByName) setData(valuesByName);
  }, [valuesByName]);

  useEffect(() => {
    if (values) {
      setSelectedValue(values[0]);
    }
  }, [values]);

  useEffect(() => {
    if (selectedValue) {
      refetch();
      refetchPreValues();
    }
  }, [selectedValue]);

  const handleChange = (value) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    if (isUpdated) {
      onSuccess(_t('success::Configuration has been changed'));
      addNewForm.resetFields();
    }
    errorUpdated &&
      onFail(_t(errorUpdated?.response?.data || 'error::Configuration change failed'));
  }, [isUpdated, errorUpdated]);

  useEffect(() => {
    if (isReverted) {
      onSuccess(_t('success::The default value has been restored'));
      refetch();
      refetchPreValues();
    }
    errorReverted &&
      onFail(
        _t(
          errorReverted?.response?.data ||
            'error::An error occurred while restoring the default value'
        )
      );
  }, [isReverted, errorReverted]);

  useEffect(() => {
    if (isRemoved) {
      onSuccess(_t('success::Configuration variable removed'));
      refetch();
      refetchPreValues();
    }
    errorRemoved &&
      onFail(
        _t(
          errorRemoved?.response?.data ||
            'error::There was a problem deleting a configuration variable'
        )
      );
  }, [isRemoved, errorRemoved]);

  const updateConfig = (key, value) => {
    if (valuesByName[key] !== value) {
      let config = {
        group: selectedValue,
        name: key,
        value: value
      };

      update(config);
    }
  };

  const setDefault = (key) => {
    revert({
      group: selectedValue,
      name: key
    });
  };

  const removeConfig = (key) => {
    remove({
      group: selectedValue,
      name: key
    });
  };

  return (
    <Row>
      <Col span={24}>
        {isLoadingValues ? (
          <Skeleton />
        ) : (
          <Row>
            <Col xs={24} lg={18}>
              <Divider orientation="left">{_t('::Add new')}</Divider>
              <Form layout="inline" form={addNewForm}>
                <Form.Item label={_t('::Name')} name="name">
                  <Input />
                </Form.Item>
                <Form.Item label={_t('::Value')} name="value">
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() =>
                      updateConfig(
                        addNewForm.getFieldValue('name'),
                        addNewForm.getFieldValue('value')
                      )
                    }>
                    {_t('button::Save')}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col xs={24} lg={6}>
              <Divider orientation="left">{_t('::Group')}</Divider>
              <Select
                value={selectedValue}
                style={{ width: '100%', marginBottom: 15 }}
                onChange={handleChange}>
                {Array.isArray(values) &&
                  values.map((value) => (
                    <Option key={value} value={value}>
                      {value}
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>
        )}
      </Col>
      <Divider />
      <Col span={24}>
        {isLoadingValuesByName || isLoadingPreValues ? (
          <Skeleton />
        ) : (
          <Form {...layout} name="nest-messages">
            {Object.keys(data || {}).map((key, value) => (
              <Form.Item initialValue={valuesByName[key]} key={key} name={[key, value]} label={key}>
                <Input.Group compact>
                  <Input
                    value={data[key]}
                    style={{
                      width: 'calc(100% - 50px)'
                    }}
                    onChange={(e) => setData({ ...data, [key]: e.target.value })}
                    onBlur={(e) => updateConfig(key, e.target.value)}
                  />
                  {preValues[key] !== undefined && valuesByName[key] !== preValues[key] ? (
                    <Tooltip title={_t('::Reset to default')}>
                      <Button icon={<RetweetOutlined />} onClick={() => setDefault(key)} />
                    </Tooltip>
                  ) : (
                    preValues[key] === undefined && (
                      <Tooltip title={_t('::Remove')}>
                        <Button icon={<DeleteOutlined />} onClick={() => removeConfig(key)} />
                      </Tooltip>
                    )
                  )}
                </Input.Group>
              </Form.Item>
            ))}
          </Form>
        )}
      </Col>
    </Row>
  );
}

Config.propTypes = {
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
};

export default Config;
