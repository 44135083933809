import React, { useEffect, useMemo, useState } from 'react';
import { _t } from 'rosis-translation';
import { Form, Input, Card, Button, Select, Row, Col, Switch } from 'antd';
import { withLayout } from 'layout';
import { useQueryClient } from 'react-query';
import { useParams } from 'rosis-components/navigation-wrapper';
import { EditField } from 'rosis-field-types';
import { useSignUp, useFieldsWithRole, useSites, getNewCounter } from 'rosis-api';
import { useBreadcrumbs } from 'rosis-contexts/breadcrumbs';
import { useModelsLabels } from 'rosis-components/ModelLabelsProvider';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';
import { usePageHeader } from 'contexts/pageHeader';
import TitleActions from 'components/TitleActions';
import PageTitle from 'components/PageTitle';
import useIsSitesEditionAvailable from './useIsSitesEditionAvailable';
import { useNavigation } from 'router/links';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useChange } from 'contexts/change';

const { Option } = Select;

function CreateUser() {
  const queryClient = useQueryClient();
  const { modelLabels } = useModelsLabels();
  const { role } = useParams();
  const { isChanged, setIsChanged } = useChange();
  const navigation = useNavigation();
  const { data: sitesList } = useSites();
  const { data: fieldsInfo } = useFieldsWithRole(role);
  const { fields, extra } = fieldsInfo || {};
  const { data: isSitesEditionAvailable } = useIsSitesEditionAvailable({ extra });
  const [uniqFields, setUniqFields] = useState();
  const { setBreadcrumbs } = useBreadcrumbs();
  const [fieldsList, setFieldsList] = useState({});
  const {
    mutate: create,
    isLoading: isCreateUserLoading,
    ...createUserStatus
  } = useSignUp(queryClient);

  useApiFeedback({
    status: {
      isLoading: isCreateUserLoading,
      ...createUserStatus
    },
    successMessage: _t('success::User created'),
    errorMessage: _t('error::User creating failed'),
    silentSuccess: true,
    onSuccess: (message) => {
      setIsChanged(false);
      navigation.forced.usersForRole(role, {
        infoBarMessage: {
          message,
          type: 'success'
        }
      });
    }
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if (fields) {
      const list = Object.values(fields).filter((field) => field.signup && !field.hide);
      setFieldsList(list);
      setUniqFields(list.filter((field) => field.type === 'Uniq'));
    }
  }, [fields]);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: '',
        link: '/'
      },
      {
        label: _t('::Users'),
        link: '/users'
      },
      {
        label: _t(modelLabels?.[role]),
        link: `/users/${role}`
      },
      {
        label: _t('::Create'),
        link: `/users/create/${role}`
      }
    ]);
  }, [modelLabels]);

  const createUser = async (data) => {
    for (const uniqField of uniqFields) {
      if (!uniqField.enumerable) {
        const { name, step, prefix, length, paddingWith } = uniqField;
        const uniqValue = await getNewCounter(role, name, step, paddingWith, prefix, length);
        data[name] = uniqValue;
      }
    }
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      const dataValue = value === undefined ? null : data[key];
      const newValue = dataValue instanceof File ? dataValue : JSON.stringify(dataValue);
      formData.set(key, newValue);
    }
    if (data.sites !== undefined) {
      formData.set('sites', JSON.stringify(data.sites));
    }
    formData.set('role', role);
    create({ role, formData });
  };

  const cancelEdition = () => {
    navigation.usersForRole(role);
  };

  const title = _t('::Create user');

  const actions = useMemo(
    () =>
      [
        <Button
          key="cancel"
          type="button"
          icon={<FontAwesomeIcon icon={faTimes} />}
          onClick={cancelEdition}>
          {_t('button::Cancel')}
        </Button>,
        <Button
          key="save"
          type="primary"
          loading={isCreateUserLoading}
          htmlType="submit"
          icon={<FontAwesomeIcon icon={faSave} />}
          onClick={() => form.submit()}>
          {_t('button,store::Save')}
        </Button>
      ].filter((v) => !!v),
    [isCreateUserLoading, isChanged]
  );

  usePageHeader(<TitleActions actions={actions}>{title}</TitleActions>, [title, actions]);

  return (
    <>
      <PageTitle title={title} />
      <Card className="create-user">
        <Row gutter="16">
          <Col md={12}>
            <Form
              name="createUser"
              form={form}
              scrollToFirstError={{
                block: 'center'
              }}
              onFinish={createUser}>
              <Form.Item
                label={_t('new-user::Login name')}
                name="idStr"
                rules={[{ required: true, message: _t('new-user::Please input login name') }]}>
                <Input type="email" onChange={() => setIsChanged(true)} />
              </Form.Item>
              {fieldsList
                ? Object.values(fieldsList)
                    .filter((current) => !['Link', 'Virt'].includes(current.type))
                    .map((current) => (
                      <EditField
                        key={current.name}
                        {...current}
                        form={form}
                        getNewCounter={getNewCounter}
                        onChange={() => setIsChanged(true)}
                      />
                    ))
                : null}
              {isSitesEditionAvailable ? (
                <Form.Item name="sites" label={_t('new-user::Join sites')}>
                  <Select mode={'multiple'} onChange={() => setIsChanged(true)}>
                    {sitesList && Array.isArray(sitesList)
                      ? sitesList.map((site) => (
                          <Option key={site.id} value={site.id} label={site.title}>
                            <div>{site.title}</div>
                          </Option>
                        ))
                      : null}
                  </Select>
                </Form.Item>
              ) : null}
              <Form.Item name="enabled" label={_t('new-user::Activate user')} initialValue={true}>
                <Switch defaultChecked={true} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default withLayout(CreateUser);
