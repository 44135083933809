import * as displayAs from './displayAs';
import * as editAs from './editAs';
import * as additionalOptions from './additionalOptions';
import makeType from '../../makeType';

const text = makeType({
  name: 'Uniq',
  label: '::Unique ID',
  displayAs,
  editAs,
  additionalOptions
});

export default text;
