import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'rosis-components/navigation-wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConfig } from 'rosis-api';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { _t } from 'rosis-translation';
import './Users.less';
import { links } from 'router/links';

const AddNewUser = (props) => {
  const { data: addNewUserFlag } = useConfig('setup', 'addNewUser');
  const { model, userInfo } = props;
  return (
    <>
      {(model === 'admin' || (userInfo?.loginPoint === 'doctor' && addNewUserFlag === 'yes')) && (
        <Link key="create" to={links.userCreate(model)}>
          <Button
            icon={
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{ marginInlineEnd: '10px' }}
                className="font-bold"
              />
            }
            type="primary"
            className="font-bold"
            key="create-user">
            {_t(`${_t(userInfo?.label || '')}`)}
          </Button>
        </Link>
      )}
    </>
  );
};

AddNewUser.propTypes = {
  model: PropTypes.string,
  userInfo: PropTypes.object
};

export default AddNewUser;
