import React, { useMemo } from 'react';
import { Button, Dropdown } from 'antd';
import { Link } from 'rosis-components/navigation-wrapper';
import { useAuth } from 'rosis-contexts/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { CaretDownOutlined } from '@ant-design/icons';
import { useConfig } from 'rosis-api';
import { bool } from 'system/helper/value';
import SendMessageToUser from 'rosis-components/SendMessageToUser';
import { _t } from 'rosis-translation';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { links } from 'router/links';

function TopMenuContent() {
  const { user } = useAuth();
  const { data: messageToAnotherUser } = useConfig('setup', 'messageToAnotherUser');
  const { logout } = useAuth();

  const items = useMemo(() => {
    const result = [];

    result.push({
      key: 'profile',
      label: <Link to={links.myProfile()}>{_t('::My profile')}</Link>,
      icon: <FontAwesomeIcon icon={faUser} />
    });

    if (bool(messageToAnotherUser)) {
      result.push({
        key: 'message',
        label: <SendMessageToUser />,
        icon: <FontAwesomeIcon icon={faEnvelope} />
      });
    }

    result.push({
      key: 'logout',
      label: (
        <Button type="text" onClick={logout}>
          {_t('::Sign out')}
        </Button>
      ),
      icon: <FontAwesomeIcon icon={faPowerOff} />
    });

    return result;
  }, [messageToAnotherUser]);

  return (
    <Dropdown menu={{ items }}>
      <Button
        icon={
          <FontAwesomeIcon
            style={{ fontSize: '25px', marginInlineEnd: '10px' }}
            icon={faUserCircle}
          />
        }
        type="text"
        size="large"
        style={{ color: '#fff' }}
        onClick={(e) => e.preventDefault()}>
        {user?.idStr ? user.idStr : ''} <CaretDownOutlined />
      </Button>
    </Dropdown>
  );
}

export default TopMenuContent;
