import React, { useState, useEffect } from 'react';
import { useParams } from 'rosis-components/navigation-wrapper';
import { _t } from 'rosis-translation';
import { withLayout } from 'layout';
import { useModels } from 'rosis-api';
import RoleEdit from './RoleEdit';
import PrivilegesLayout from './PrivilegesLayout';
import { useBreadcrumbs } from 'rosis-contexts/breadcrumbs';
import './Role.less';
import { useNavigation } from 'router/links';
import filterByAllowedLoginPoints from '../../utils/filterByAllowedLoginPoints';

function Privileges() {
  const navigation = useNavigation();
  const [model, setModel] = useState();
  const { role } = useParams();
  const { setBreadcrumbs } = useBreadcrumbs();
  const [roles, setRoles] = useState();
  const { data: models, isFetching: isModelsFetching } = useModels();

  useEffect(() => {
    if (models) {
      const newRoles = models.filter(filterByAllowedLoginPoints).map((model) => {
        return { ...model, children: null };
      });
      setRoles(newRoles);
      const newRole = newRoles.find((r) => r.key === role);
      setModel(newRole);
    }
  }, [models, role]);

  const goToFirstRole = (options) => navigation.privilegesForRole(roles[0].key, options);

  const handleRemove = () =>
    goToFirstRole({
      state: {
        infoBarMessage: {
          message: _t('::The role was removed successfully'),
          type: 'success'
        }
      }
    });

  useEffect(() => {
    if (roles) {
      if (!role) {
        goToFirstRole();
        return;
      }

      setBreadcrumbs([
        {
          label: '',
          link: '/'
        },
        {
          label: _t('::Privileges'),
          link: '/privileges'
        },
        {
          label: _t(roles.find((r) => r.key === role)?.label),
          link: `/privileges/${role}`
        }
      ]);
    }
  }, [isModelsFetching, role, roles]);

  return (
    <PrivilegesLayout
      model={model}
      role={role}
      onRemove={handleRemove}
      label={roles?.length ? _t(roles.find((r) => r.name === role)?.label) : role}>
      <RoleEdit role={role} roles={roles} />
    </PrivilegesLayout>
  );
}

export default withLayout(Privileges);
