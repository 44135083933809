import { useMutation, useQuery, useQueryClient } from 'react-query';
import { removeItem, storageKeys } from 'rosis-storage';

import { url, getData, post, del, executeWithRefresh } from './internal/rest-api';
import { useProjection } from './internal/utils';
import { isLoggedIn } from './internal/interceptors';

const { NODE_ENV } = process.env;

export const useWhoami = (config) =>
  useQuery('whoami', () => executeWithRefresh(() => getData(`whoami`), (res) => isLoggedIn() && !res), {
    ...config,
    refetchOnWindowFocus: 'always',
    staleTime: 0
  });

const addCsfrTokenToLocalStore = (token) => localStorage.setItem('csrf-token', token);

const addCsfrTokenToMetaTag = (token) => {
  const meta = document.querySelector('meta[name=ctoq]') || document.createElement('meta');
  meta.name = 'ctoq';
  meta.content = token;
  document.getElementsByTagName('head')[0].appendChild(meta);
};

const addCsfrToken = (res) => {
  const csfrToken = res.headers['x-ctoq'];
  if (csfrToken) {
    if (NODE_ENV === 'development') {
      addCsfrTokenToLocalStore(csfrToken);
    } else {
      addCsfrTokenToMetaTag(csfrToken);
    }
  }
  return res;
};

export const useLogin = () => {
  const { refetch: refetchWhoAmi } = useWhoami();
  return useMutation(
    (params) =>
      post(url`login`, params)
        .then(addCsfrToken)
        .then((res) => res.data),
    {
      onSuccess: async () => {
        await refetchWhoAmi();
      }
    }
  );
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  const { refetch: refetchWhoAmi } = useWhoami();
  return useMutation(() => del('login'), {
    onSuccess: async () => {
      removeItem(storageKeys.expiryTime);
      await refetchWhoAmi();
      await queryClient.removeQueries();
    }
  });
};

export const useIsSystemReady = () => useQuery('system-ready', () => getData(url`ready`));

export const useMarkSystemAsReady = () => {
  const queryClient = useQueryClient();
  return useMutation(() => post(url`ready/true`), {
    onSuccess: async () => {
      queryClient.invalidateQueries('system-ready');
    }
  });
};

// COMPLEX

export const useMyUserId = () => {
  const queryResults = useWhoami();
  return useProjection(queryResults, (data) => ({
    userId: data?.user?._id,
    role: data?.user?.role
  }));
};
