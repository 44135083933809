import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { SFCheckbox } from 'rosis-setting-form';
import { _t } from 'rosis-translation';

function Registration({ isPerson, className }) {
  const { watch } = useFormContext();
  const type = watch('type');
  return !isPerson || type === 'Calculated' ? null : (
    <SFCheckbox className={className} label={_t('::Show on the registration form')} name="signup" />
  );
}

Registration.propTypes = {
  isPerson: PropTypes.bool,
  className: PropTypes.string
};

export default Registration;
