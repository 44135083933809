import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import { DragDropContext } from 'react-beautiful-dnd';
import { fieldTypes } from 'rosis-field-types';
import FieldTable from './FieldTable';
import FieldList from './FieldList';
import { useCanRemoveField } from 'rosis-api';
import { useIsTablet } from 'rosis-components/hooks/useScreenSizes';

function Fields({ frameName, fields, updateFields, onMarkToRemove }) {
  const { data: cantRemove, isLoading } = useCanRemoveField(frameName);
  const isTabletOrDesktop = useIsTablet();
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    updateFields(result.source.index, result.destination.index);
  };

  const List = isTabletOrDesktop ? FieldTable : FieldList;

  return isLoading && !fields.length ? (
    <Skeleton />
  ) : (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <List
          frameName={frameName}
          fields={fields}
          onMarkToRemove={onMarkToRemove}
          cantRemove={cantRemove}
          fieldTypes={fieldTypes}
        />
      </DragDropContext>
    </>
  );
}

Fields.propTypes = {
  frameName: PropTypes.string,
  fields: PropTypes.array,
  updateFields: PropTypes.func,
  onMarkToRemove: PropTypes.func
};

export default Fields;
