import React, { useState, useEffect } from 'react';
import { useParams } from 'rosis-components/navigation-wrapper';
import { useQueryClient } from 'react-query';
import { Form, Card, Typography, Checkbox } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { withLayout } from 'layout';
import { SFInput, SFForm } from 'rosis-setting-form';
import {
  useLangKeys,
  useLang,
  useEditLang,
  useRemoveLang,
  apiUrl,
  useLocal,
  useUpdateLocal
} from 'rosis-api';
import TranslationTable from 'components/TranslationTable';
import { Download } from 'components/buttons';
import TitleActions from 'components/TitleActions';
import './EditLang.less';
import { _t } from 'rosis-translation';
import { stdKeys } from 'system/helper/trans-key';
import DefaultLang from './DefaultLang';
import LangFilter from './LangFilter';
import Direction from './Direction';
import { useBreadcrumbs } from 'rosis-contexts/breadcrumbs';
import { usePageHeader } from 'contexts/pageHeader';
import PageTitle from 'components/PageTitle';

const { Title } = Typography;

function EditLang() {
  const { lang } = useParams();
  const queryClient = useQueryClient();
  const form = Form.useForm();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { data: showMissingTranslations } = useLocal('showMissingTranslations');
  const { mutate: saveLocal } = useUpdateLocal(queryClient, 'showMissingTranslations');
  const { data: keys } = useLangKeys();
  const { data: translation, isLoading: translationIsLoading } = useLang(lang);
  const { mutate: edit } = useEditLang(queryClient);
  const { mutate: remove } = useRemoveLang(queryClient);
  const [allTranslation, setAllTranslation] = useState();
  const [displayTranslation, setDiplayTranslation] = useState();
  const [missingTranslations, setMissingTranslations] = useState(false);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    setBreadcrumbs([
      {
        label: '',
        link: '/'
      },
      {
        label: _t('::Languages'),
        link: '/lang'
      },
      {
        label: _t(stdKeys.lang(lang)),
        link: `/lang/${lang}`
      }
    ]);
  }, [lang]);

  const onChangeFilter = (e) => {
    let value = '';

    if (typeof e === 'string') {
      value = e;
    } else {
      value = e.target.value;
      setFilter(value);
    }

    if (value?.length > 1 && missingTranslations) {
      setDiplayTranslation(
        (allTranslation || []).filter(
          (element) =>
            element.key.toLowerCase().includes(value.toLowerCase()) &&
            (element.trans === undefined || element.trans.length < 1)
        )
      );
    } else if (value?.length > 1 && !missingTranslations) {
      setDiplayTranslation(
        (allTranslation || []).filter((element) =>
          element.key.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setDiplayTranslation(
        missingTranslations
          ? (allTranslation || []).filter(
              (element) => element.trans === undefined || element.trans.length < 1
            )
          : allTranslation
      );
    }
  };

  useEffect(() => {
    const rtlKey = stdKeys.lang('rtl');
    if (translation && keys) {
      setAllTranslation(
        keys
          .filter((key) => key !== rtlKey)
          .map((key) => {
            return { key, trans: translation[key] };
          })
      );
    }
  }, [keys, translation]);

  useEffect(() => {
    if (missingTranslations && allTranslation) {
      setDiplayTranslation(
        (allTranslation || []).filter(
          (element) => element.trans === undefined || element.trans.length < 1
        )
      );
    } else {
      setDiplayTranslation(allTranslation);
    }
    onChangeFilter(filter);
  }, [missingTranslations, allTranslation]);

  useEffect(() => {
    if (showMissingTranslations === 'yes') {
      setMissingTranslations(true);
    }
  }, [showMissingTranslations]);

  const changeDirection = (e) => {
    const { value } = e.target;
    const key = stdKeys.lang('rtl');
    if (value === 'rtl') {
      edit({ lang, key, value: 'true' });
    } else {
      remove({ lang, key });
    }
  };

  const onChangeMissingTranslations = (e) => {
    const { checked } = e.target;

    setMissingTranslations(checked);
    if (checked) {
      saveLocal('yes');
    } else {
      saveLocal('no');
    }
  };

  const title = _t(stdKeys.lang(lang));

  usePageHeader(
    <TitleActions
      actions={
        !translationIsLoading
          ? [
              <Download
                key="download"
                link={`${apiUrl}/lang/get/${lang}${missingTranslations ? '/gaps' : ''}`}>
                <>
                  <FontAwesomeIcon icon={faDownload} />
                  &nbsp;
                  {_t('button::Download')}
                </>
              </Download>
            ]
          : []
      }>
      {title}
    </TitleActions>,
    [translationIsLoading, missingTranslations]
  );

  return (
    <>
      <PageTitle title={title} />
      <Card className="lang">
        {translationIsLoading ? null : (
          <SFForm>
            <SFInput
              label={_t('::Language code')}
              name="shortname"
              initialValue={lang}
              disabled={true}
              required
            />

            <Direction
              form={form}
              direction={translation?.[stdKeys.lang('rtl')] ? 'rtl' : 'ltr'}
              changeDirection={changeDirection}
            />
            <DefaultLang lang={lang} />
            <Title level={4}>{_t('::Translation')}</Title>
            <Checkbox
              className="only-missing"
              name="missingTranslations"
              checked={missingTranslations}
              onChange={onChangeMissingTranslations}>
              {_t('::Show missing translation only')}
            </Checkbox>
            <LangFilter onChangeFilter={onChangeFilter} />
            <TranslationTable lang={lang} translations={displayTranslation} />
          </SFForm>
        )}
      </Card>
    </>
  );
}

export default withLayout(EditLang);
