import { useQuery, useMutation } from 'react-query';

import { url, getData, del, post, put } from './internal/rest-api';
import { useProjection } from './internal/utils';

import { _t } from 'rosis-translation';

const _useModel = (name) => useQuery(['models', name], () => getData(url`model/${name}`));

export const _useModels = (config = {}) => useQuery('models', () => getData('model'), config);

export const useModelsLabel = (config = {}) =>
  useQuery(
    'models-label',
    () =>
      getData('model').then((resp) => {
        return Object.fromEntries(resp.map((model) => [[model.name], model?.extra?.label]));
      }),
    config
  );

export const useFieldsLabel = (config = {}) =>
  useQuery(
    'fields-label',
    () =>
      getData('schema/*/full').then((resp) => {
        return Object.fromEntries(resp.map((model) => [[model.name], model?.fields]));
      }),
    config
  );

export const useModelsTree = () =>
  useQuery('models-tree', () => getData('model/tree').then((resp) => resp));

export const useChildren = (frameName, config = {}) =>
  useQuery(
    ['model', 'children', frameName],
    () => getData(url`model/children/${frameName}`),
    config
  );

export const useModelList = () => {
  const queryResults = useModel();
  return useProjection(queryResults, (data) =>
    data.filter((model) => !model.extra.system).map((model) => model.name)
  );
};

export const useFramesForPatient = (config) => {
  const queryResults = _useModels(config);
  return useProjection(queryResults, (data) =>
    data
      .filter((model) => model.extra.parentName)
      .map((model) => ({
        name: model.name,
        label: model?.extra?.label,
        hidden: model?.extra?.hide
      }))
  );
};

export const useRemoveModel = (queryClient) =>
  useMutation((name) => del(url`model/${name}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('models');
    }
  });

export const useCreateModel = (queryClient) =>
  useMutation((model) => post('model', model), {
    onSuccess: () => queryClient.invalidateQueries('models')
  });

export const useCloneModel = (queryClient) =>
  useMutation(({ name, label, linkTo }) => put(url`model/clone/${name}`, { label, linkTo }), {
    onSuccess: () => queryClient.invalidateQueries('models')
  });
export const useOrderModel = (queryClient) =>
  useMutation(({ name, order }) => put(url`model/order/${name}/${order}`), {
    onSuccess: () => queryClient.invalidateQueries('models')
  });

export const useResetModel = () => useMutation(({ name }) => put(url`model/reset/${name}`));

//COMPLEX

export const useModels = () => {
  const queryResult = _useModels();
  return useProjection(queryResult, (data) => {
    const items = data
      .filter((el) => !el.extra?.system && !el.extra?.index && !el.extra?.off)
      .map((el) => {
        const { name, fields, extra } = el;
        return {
          key: name,
          name: name,
          parent: Object.values(fields).find((field) => field.type === 'Link')?.name,
          ...extra
        };
      });
    const roots = items
      .filter((item) => item.parent === 'user')
      .sort((a, b) => b.key.localeCompare(a.key)) //desired order [patient,doctor,admin]
      .reduce((res, item) => ({ ...res, [item.key]: item }), {});
    let currentLeafs = roots;
    while (Object.keys(currentLeafs).length) {
      const nextLevelLeafes = {};
      for (const item of items) {
        const parent = currentLeafs[item.parent];
        if (parent) {
          if (!parent.children) {
            parent.children = [];
          }
          const prevIndex = parent.children.findIndex((current) => current._order > item._order);
          if (prevIndex > -1) {
            parent.children.splice(prevIndex, 0, item);
          } else {
            parent.children.push(item);
          }
          nextLevelLeafes[item.key] = item;
        }
      }
      currentLeafs = nextLevelLeafes;
    }
    return Object.values(roots);
  });
};

export const useFrameAndFields = () => {
  const queryResult = _useModels();
  return useProjection(queryResult, (data) => {
    const result = data.filter(
      (item) => (item?.extra?.person || item?.extra?.dynaRoot) && !item?.extra?.off
    );
    return result;
  });
};

export const useModel = (name) => {
  const queryResult = _useModel(name);
  return useProjection(queryResult, (data) => {
    const [model] = data;
    const fields = Object.values(model.fields)
      .filter((field) => {
        return field?.type !== 'Link';
      })
      .map((field) => {
        return {
          key: field.name,
          label: field.label || field.name,
          required: field.required,
          type: field.type,
          order: field._order
        };
      })
      .sort((f1, f2) => f1.order - f2.order);
    const result = {
      ...model,
      fields
    };
    return result;
  });
};

export const useModelsWhichCanBeParent = () => {
  const queryResult = _useModels();
  return useProjection(queryResult, (data) => {
    const result = data
      .filter((el) => el.extra?.dynaRoot)
      .map(({ name, extra }) => {
        return { value: name, label: _t(extra.label) };
      });
    return result;
  });
};
