import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { _t } from 'rosis-translation';
import { fieldProps } from './utils';

const { Option } = Select;

function ConditionFieldSelect({ fields, fieldName, onChange }) {
  return (
    <Select
      className="conditions__element__field"
      placeholder={_t('::Field name')}
      value={fieldName}
      onChange={onChange}>
      {fields.map((field) => (
        <Option key={field.name} value={field.name}>
          {_t(field.label)}
        </Option>
      ))}
    </Select>
  );
}

ConditionFieldSelect.propTypes = {
  fields: PropTypes.arrayOf(fieldProps),
  fieldName: PropTypes.string,
  onChange: PropTypes.func
};

export default ConditionFieldSelect;
