import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import GeneralSecuritySettings from './GeneralSettingsTab';
import SecretQuestionsTab from './SecretQuestionsTab';
import { _t } from 'rosis-translation';

function SecuritySettings({ title }) {
  const tabs = [
    {
      label: _t('::General'),
      key: 'general',
      children: <GeneralSecuritySettings title={title} />
    },
    {
      label: _t('::Secret questions'),
      key: 'questions',
      children: <SecretQuestionsTab title={title} />
    }
  ];
  return <Tabs items={tabs} defaultActiveKey="templates" destroyInactiveTabPane />;
}

SecuritySettings.propTypes = {
  title: PropTypes.string
};

export default SecuritySettings;
