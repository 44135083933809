import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { makeToText } from 'make-to-text';
import NumberCompare from '../../../utils/compare/Number';
import parseValueToQuery from '../../../utils/parseValue';
import Item from '../../../Item';

const toText = makeToText('Number', 'number', { _t });

function Number({ value, decimal, ...rest }) {
  return <Item {...rest}>{toText(value, { decimal })}</Item>;
}

Number.propTypes = {
  value: PropTypes.number,
  decimal: PropTypes.number
};

const options = {
  value: 'number',
  get label() {
    return _t('::Number');
  },
  default: true,
  toText,
  component: Number,
  compareComponent: NumberCompare,
  parseValueToQuery: parseValueToQuery.number,
  example: <Number value={23} />
};

export default options;
