import React, { useEffect, useState, useCallback } from 'react';
import { Form, Card, Skeleton, DatePicker, Select, Input, Row, Col } from 'antd';
import { withLayout } from 'layout';
import debounce from 'lodash/debounce';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useModelsLabels } from 'rosis-components/ModelLabelsProvider';
import { Download } from 'components/buttons';
import { useLogs, useLocal, useFullSchema, apiUrl } from 'rosis-api';
import { _t } from 'rosis-translation';
import { quadDot } from 'system/symbol';
import filterBy from 'system/helper/filter-by';
import Pagination from 'components/Pagination';
import './Logs.less';
import LogsDesktop from './LogsDesktop';
import LogsMobile from './LogsMobile';
import MessageRenderer from './MessageRenderers';
import ClientCell from './ClientCell';
import DateCell from './DateCell';
import EventCell from './EventCell';
import { useBreadcrumbs } from 'rosis-contexts/breadcrumbs';
import { usePageHeader } from 'contexts/pageHeader';
import TitleActions from 'components/TitleActions';
import { useIsSmallDesktop } from 'rosis-components/hooks/useScreenSizes';
import PageTitle from 'components/PageTitle';

const { Option } = Select;

const excludeColumns = [
  '_id',
  'group',
  'level',
  '_deleted',
  '_updatedBy',
  '_updatedAt',
  '_createdBy'
];

function Logs() {
  const { modelLabels } = useModelsLabels();
  const [filter, setFilter] = useState({});
  const sortBy = { _createdAt: -1 };
  const { data: localPerPage } = useLocal('perPageLogs');
  const { data: schema } = useFullSchema('log');
  const [elementsPerPage, setElementsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [creator, setCreator] = useState('');
  const [eventList, setEventList] = useState({});
  const [columns, setColumns] = useState([]);
  const isDesktop = useIsSmallDesktop();
  const {
    data: logs,
    isLoading,
    refetch
  } = useLogs({
    page,
    elementsPerPage,
    sortBy,
    find: filter,
    creator
  });

  const { elements, next } = logs || {};
  const { setBreadcrumbs } = useBreadcrumbs();

  const renderList = (name, value, record) => {
    if (name === '_createdAt') {
      return <DateCell _createdAt={value} />;
    } else if (name === 'client') {
      return <ClientCell client={value} />;
    } else if (name === 'value') {
      return <MessageRenderer event={record.event} message={value} />;
    } else if (name === 'event') {
      return <EventCell event={value} eventList={eventList} />;
    } else {
      return value;
    }
  };

  const exportToCSV = () => {
    let params = new URLSearchParams('');
    if (filter) {
      params.append('find', JSON.stringify(filter));
    }
    if (sortBy) {
      params.append('sort', JSON.stringify(sortBy));
    }
    if (creator) {
      params.append('creator', creator);
    }

    return params;
  };

  const onChange = useCallback(
    debounce((changedValues, allValues) => {
      const { value, end, start, client, event, creator } = allValues;

      setFilter({
        _createdAt: start || end ? `${start || ''}..${end || ''}` : undefined,
        value: value ? { $regex: value } : undefined,
        event: event || undefined,
        client: client ? { $regex: client } : undefined
      });
      setCreator(creator);
    }, 1000)
  );

  const disabledDate = (current, mode) => {
    if (mode === 'start') {
      return current && filter?.end && current < filter.end;
    } else if (mode === 'end') {
      return current && filter?.start && current < filter.start;
    }
  };

  useEffect(() => {
    setPage(1);
    refetch();
  }, [filter, creator]);

  useEffect(() => {
    if (schema?.fields?.event?.opts) {
      const { opts } = schema?.fields?.event;
      let list = opts.map((opt) => [opt.split(quadDot)[0], opt]);

      setEventList(Object.fromEntries(list));
    }
  }, [schema]);

  useEffect(() => {
    let cols = [];
    if (schema?.fields) {
      Object.keys(schema?.fields).forEach((item) => {
        if (!excludeColumns.includes(item)) {
          const { name, label } = schema?.fields[item] || {};
          cols.push({
            title: _t(label),
            dataIndex: name,
            key: name,
            render: (value, record) => renderList(name, value, record)
          });
        }
      });
      setColumns(cols);
    }
  }, [eventList]);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: '',
        link: '/'
      },
      {
        label: modelLabels && _t(modelLabels['log']),
        link: '/logs'
      }
    ]);
  }, [modelLabels]);

  useEffect(() => {
    localPerPage && setElementsPerPage(localPerPage);
  }, [localPerPage]);

  const title = modelLabels && _t(modelLabels['log']);

  usePageHeader(
    <TitleActions
      actions={[
        <Download key="download" link={`${apiUrl}/log/csv?${exportToCSV()}`}>
          <FontAwesomeIcon icon={faDownload} />
          &nbsp;
          {_t('button::Download')}
        </Download>
      ]}>
      {title}
    </TitleActions>,
    [filter, sortBy, creator]
  );

  return (
    <>
      <PageTitle title={title} />
      <Card className="logs">
        <Form className="filter-form" layout="vertical" onValuesChange={onChange}>
          <Row gutter={12}>
            <Col xs={24} md={6} xl={4}>
              <Form.Item name="start" label={_t('logs::Start date')}>
                <DatePicker
                  showTime
                  disabledDate={(current) => disabledDate(current, 'start')}
                  placeholder=""
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={6} xl={4}>
              <Form.Item name="end" label={_t('logs::End date')}>
                <DatePicker
                  showTime
                  disabledDate={(current) => disabledDate(current, 'end')}
                  placeholder=""
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={6} xl={4}>
              <Form.Item name="value" label={_t(schema?.fields?.value?.label || '')}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={6} xl={4}>
              <Form.Item name="client" label={_t(schema?.fields?.client?.label || '')}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={6} xl={4}>
              <Form.Item name="event" label={_t(schema?.fields?.event?.label || '')}>
                <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                  filterOption={filterBy.label}>
                  {Object.keys(eventList).map((key) => (
                    <Option key={key} value={key}>
                      {_t(eventList[key])}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={6} xl={4}>
              <Form.Item name="creator" label={_t(schema?.fields?.creator?.label || '')}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            {isDesktop && (
              <LogsDesktop elements={elements} isLoading={isLoading} columns={columns} />
            )}
            {!isDesktop && (
              <LogsMobile elements={elements} isLoading={isLoading} columns={columns} />
            )}
            <Pagination
              currentPage={page}
              next={next}
              countOfElements={elements?.length}
              elementsPerPage={elementsPerPage}
              setElementsPerPage={setElementsPerPage}
              changePage={setPage}
              localPerPage="perPageLogs"
            />
          </>
        )}
      </Card>
    </>
  );
}

export default withLayout(Logs);
