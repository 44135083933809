import React from 'react';
import PropTypes from 'prop-types';
import { datetime } from 'rosis-date-picker';
import { _t } from 'rosis-translation';
import { makeToText } from 'make-to-text';
import DateCompare from '../../../utils/compare/Date';
import parseValueToQuery from '../../../utils/parseValue';
import Item from '../../../Item';

const toText = makeToText('Date', 'date', { _t });
function Date({ value, ...rest }) {
  return <Item {...rest}>{toText(value)}</Item>;
}

Date.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const options = {
  value: 'date',
  get label() {
    return _t('::Date');
  },
  toText,
  default: true,
  component: Date,
  compareComponent: DateCompare,
  parseValueToQuery: parseValueToQuery.date,
  hide: ({ editAs }) => ['month', 'year'].includes(editAs),
  example: <Date value={datetime('1995-11-25')} />
};

export default options;
