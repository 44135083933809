import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { Select, Form, Checkbox } from 'antd';
import TranslationTable from 'components/TranslationTable';
import { _t } from 'rosis-translation';
import { stdKeys } from 'system/helper/trans-key';
import { useLangs, useDefaultLang, useLang, useSchema, useLocal, useUpdateLocal } from 'rosis-api';

const { Option } = Select;

const getTranslation = (key, translations) => {
  return { key, trans: translations[key] };
};

const getTranslations = (keys, translations) => {
  return keys.map((key) => getTranslation(key, translations));
};

function Translations({ frameName, fields }) {
  const queryClient = useQueryClient();
  const [allTranslation, setAllTranslation] = useState();
  const [currentLang, setCurrentLang] = useState();
  const [displayTranslations, setDisplayTranslations] = useState();
  const { data: showMissingTranslations } = useLocal('showMissingTranslations');
  const { mutate: saveLocal } = useUpdateLocal(queryClient, 'showMissingTranslations');
  const { data: languages } = useLangs();
  const { data: defaultLang, isSuccess: isDefaultLangSuccess } = useDefaultLang();
  const { data: translations } = useLang(currentLang);
  const { data: schema } = useSchema(frameName);
  const [allFields, setAllFields] = useState();
  const [missingTranslations, setMissingTranslations] = useState(false);

  useEffect(() => {
    setAllFields(schema.fields);
  }, [schema]);

  useEffect(() => {
    if (showMissingTranslations === 'yes') {
      setMissingTranslations(true);
    }
  }, [showMissingTranslations]);

  useEffect(() => {
    if (fields && translations && allFields) {
      const fieldKeys = fields.map((field) => field.label).sort();
      const fieldKeysHint = fields.filter((field) => field.hint).map((field) => field.hint);
      const sectionHint = schema?.extra?.hint ? [schema?.extra?.hint] : [];
      const keys = [
        ...Object.values(schema?.extra?.labels),
        ...fieldKeys,
        ...fieldKeysHint,
        ...sectionHint
      ];
      setAllTranslation(getTranslations(keys, translations));
    }
  }, [fields, allFields, translations]);

  useEffect(() => {
    defaultLang && setCurrentLang(defaultLang);
  }, [defaultLang]);

  useEffect(() => {
    setDisplayTranslations(allTranslation);
  }, [allTranslation]);

  useEffect(() => {
    if (missingTranslations && allTranslation) {
      setDisplayTranslations(allTranslation.filter(({ trans }) => !trans));
    } else {
      setDisplayTranslations(allTranslation);
    }
  }, [missingTranslations, allTranslation]);

  const changeMissingTranslations = ({ target }) => {
    if (target.checked) {
      setMissingTranslations(true);
      saveLocal('yes');
    } else {
      setMissingTranslations(false);
      saveLocal('no');
    }
  };

  return (
    <div className="lang">
      {isDefaultLangSuccess ? (
        <Form>
          <Form.Item name="language" label={_t('::Language')} initialValue={defaultLang}>
            <Select style={{ width: 200 }} onChange={setCurrentLang}>
              {languages?.map(({ id }) => (
                <Option key={id} value={id}>
                  {_t(stdKeys.lang(id))}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Checkbox
            className="only-missing"
            name="missingTranslations"
            checked={missingTranslations}
            onChange={changeMissingTranslations}>
            {_t('::Show missing translation only')}
          </Checkbox>
        </Form>
      ) : null}
      <TranslationTable lang={currentLang} translations={displayTranslations} />
    </div>
  );
}

Translations.propTypes = {
  frameName: PropTypes.string,
  fields: PropTypes.array
};

export default Translations;
