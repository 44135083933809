import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { makeToText } from 'make-to-text';
import Item from '../../../Item';
import parseValueToQuery from '../../../utils/parseValue';
import Multiselect from '../../../utils/compare/Multiselect';

const toText = makeToText('Boolean', 'yesno', { _t });

function YesNo({ value, ...rest }) {
  return <Item {...rest}>{toText(value)}</Item>;
}

YesNo.propTypes = {
  value: PropTypes.bool
};

const YesNoCompare = React.forwardRef((props, ref) => {
  return (
    <Multiselect
      {...props}
      ref={ref}
      options={[
        { value: true, label: _t('::Yes') },
        { value: false, label: _t('::No') }
      ]}
    />
  );
});

YesNoCompare.displayName = 'YesNoCompare';

const options = {
  value: 'yesno',
  get label() {
    return _t('::Yes/No');
  },
  component: YesNo,
  compareComponent: YesNoCompare,
  parseValueToQuery: parseValueToQuery.multi,
  toText,
  default: true,
  example: <YesNo value={true} />
};

export default options;
