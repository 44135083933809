import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { _t } from 'rosis-translation';
import { useSchema } from 'rosis-api';
import { allowedTypes } from './utils';
import Condition from './Condition';
import './Conditions.less';

function Conditions({ modelName, fieldName, conditions, setConditions, className }) {
  const { data: schema } = useSchema(modelName);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    schema &&
      setFields(
        Object.values(schema.fields)
          .filter((field) => {
            const isAllowedType = allowedTypes.includes(field.type);
            const isDiffrentFieldName = fieldName ? fieldName !== field.name : true;
            return isAllowedType && isDiffrentFieldName;
          })
          .map((field) => {
            if (typeof field.opts === 'string') {
              field.opts = field.opts.split('\n');
            }
            return field;
          })
      );
  }, [schema]);

  const handleChange = (updatedCondition, index) =>
    setConditions(
      conditions.map((current, currentIndex) =>
        index === currentIndex ? updatedCondition : current
      )
    );

  const handelRemove = (index) =>
    setConditions(
      conditions
        .filter((current, currentIndex) => index !== currentIndex)
        .map((current, currentIndex) =>
          currentIndex === 0 ? [null, ...current.slice(1)] : current
        )
    );

  const addNewCondition = () => {
    setConditions([...conditions, [conditions.length ? 'and' : null, null, true]]);
  };

  return (
    <div className={className}>
      <div className="field-form__header">{_t('::Show if')}</div>
      <div className="conditions">
        <div className="conditions__elements">
          {conditions.map((condition, index) => {
            return (
              <Condition
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                index={index}
                fields={fields}
                condition={condition}
                onChange={handleChange}
                onRemove={handelRemove}
              />
            );
          })}
        </div>
        <Button onClick={addNewCondition}>{_t('button::Add condition')}</Button>
      </div>
    </div>
  );
}

Conditions.propTypes = {
  modelName: PropTypes.string.isRequired,
  fieldName: PropTypes.string,
  conditions: PropTypes.array,
  setConditions: PropTypes.func,
  className: PropTypes.string
};

export default Conditions;
