import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { _t } from 'rosis-translation';

function SFSubmit({ loading, label }) {
  return (
    <Button type="primary" htmlType="submit">
      {loading ? <FontAwesomeIcon icon={faSpinner} pulse /> : label || _t('button::Submit')}
    </Button>
  );
}

SFSubmit.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.string
};

export default SFSubmit;
