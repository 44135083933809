import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Pagination, Row, Col, Select, Button } from 'antd';
import { useQueryClient } from 'react-query';
import { _t } from 'rosis-translation';
import { useUpdateLocal } from 'rosis-api';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

function TablePagination({
  defaultPage,
  currentPage,
  elementsPerPage,
  setElementsPerPage,
  changePage,
  localPerPage,
  countOfElements,
  next,
  count,
  onChangePagination
}) {
  const queryClient = useQueryClient();
  const [prev, setPrev] = useState(true);
  const { mutate: saveLocal } = useUpdateLocal(queryClient, localPerPage);

  const handlePrev = () => {
    changePage((page) => --page);
    onChangePagination({ current: currentPage - 1, perPage: elementsPerPage });
  };
  const handleNext = () => {
    changePage((page) => ++page);
    onChangePagination({ current: currentPage + 1, perPage: elementsPerPage });
  };

  const handleElementsPerPage = (elPerPage) => {
    saveLocal(elPerPage);
    setElementsPerPage(elPerPage);
    changePage(1);
    onChangePagination({ current: 1, perPage: elPerPage });
  };

  useEffect(() => {
    setPrev(currentPage > 1);
  }, [currentPage]);

  const limitStart = elementsPerPage * (currentPage - 1) + 1;
  const limitEnd = countOfElements
    ? elementsPerPage * (currentPage - 1) + countOfElements
    : elementsPerPage * currentPage;

  return (
    <Row className="table-pagination" justify="space-between">
      <Col className="table-pagination__elements">
        <span className="table-pagination__info">
          {_t('pagination::Showing {limitStart} to {limitEnd}', { limitStart, limitEnd })}
          {count ? _t('pagination::of {count} entries', { count }) : null}
        </span>
        <Select
          className="table-pagination__select"
          defaultValue={defaultPage}
          value={elementsPerPage}
          onChange={handleElementsPerPage}>
          {[5, 10, 20, 50, 75, 100].map((number) => (
            <Select.Option key={number} value={number}>
              {number}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col>
        {count ? (
          <Pagination
            defaultCurrent={1}
            pageSize={elementsPerPage}
            total={count}
            onChange={changePage}
            showSizeChanger={false}
          />
        ) : (
          <ul className="ant-pagination">
            <li
              title={_t('pagination::Previous Page')}
              className={classNames('ant-pagination-prev', prev || 'ant-pagination-disabled')}>
              <Button className="ant-pagination-item-link" disabled={!prev} onClick={handlePrev}>
                <LeftOutlined />
              </Button>
            </li>
            <li className="ant-pagination-item">{currentPage}</li>
            <li
              title={_t('pagination::Next Page')}
              className={classNames('ant-pagination-next', next || 'ant-pagination-disabled')}>
              <Button className="ant-pagination-item-link" disabled={!next} onClick={handleNext}>
                <RightOutlined />
              </Button>
            </li>
          </ul>
        )}
      </Col>
    </Row>
  );
}

TablePagination.propTypes = {
  defaultPage: PropTypes.number,
  currentPage: PropTypes.number,
  elementsPerPage: PropTypes.number,
  setElementsPerPage: PropTypes.func,
  count: PropTypes.number,
  countOfElements: PropTypes.number,
  localPerPage: PropTypes.string,
  next: PropTypes.bool,
  changePage: PropTypes.func,
  onChangePagination: PropTypes.func
};

TablePagination.defaultProps = {
  defaultPage: 10,
  localPerPage: 'perPage',
  countOfElements: 0,
  changePage: () => {},
  setElementsPerPage: () => {},
  onChangePagination: () => {}
};

export default TablePagination;
