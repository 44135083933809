import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, Modal, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { _t } from 'rosis-translation';

const { TextArea } = Input;

const EditQuestion = ({ value, id, isUsed, onChangeQuestion }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [question, setQuestion] = useState(value);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    onChangeQuestion({ value: question, oldValue: value, id: id, name: 'value' });
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange = (e) => {
    const { value } = e.target;

    setQuestion(value);
  };

  return (
    <>
      <Tooltip
        title={
          isUsed
            ? _t('::You cannot edit a question that has already been used')
            : _t('button::Edit')
        }>
        <Button
          type="link"
          aria-label={
            isUsed
              ? _t('::You cannot edit a question that has already been used')
              : _t('button::Edit')
          }
          onClick={showModal}
          disabled={isUsed}>
          <FontAwesomeIcon icon={faPen} />
        </Button>
      </Tooltip>
      <Modal
        title={_t('::Edit question')}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}>
        <TextArea rows={4} value={question} onChange={onChange} />
      </Modal>
    </>
  );
};

EditQuestion.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  isUsed: PropTypes.bool,
  onChangeQuestion: PropTypes.func
};

export default EditQuestion;
