import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const parseNumberToString = (value) => value.toString().padStart(2, '0');

const timeToString = (time) => {
  const minutes = parseNumberToString(Math.floor(time / 60));
  const secondes = parseNumberToString(time % 60);
  return `${minutes}:${secondes}`;
};

function PinCodeTimer({ currentTime }) {
  const displayTime = useMemo(() => timeToString(currentTime), [currentTime]);
  return <span className="pin-code__time-expired">{displayTime}</span>;
}

PinCodeTimer.propTypes = {
  currentTime: PropTypes.number.isRequired
};

export default PinCodeTimer;
