import React from 'react';
import { withNotLoggedLayout } from 'layout';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { _t } from 'rosis-translation';

function Error404() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Result
      status="404"
      title={_t('404')}
      subTitle={_t('::Sorry, the page you requested does not exist.')}
      style={{ minHeight: '100vh' }}
      extra={[
        <Button type="primary" key="go-back" onClick={goBack}>
          {_t('::Go back')}
        </Button>
      ]}
    />
  );
}

export default withNotLoggedLayout(Error404);
