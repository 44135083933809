import React from 'react';
import { Card } from 'antd';
import { usePageHeaderContext } from 'contexts/pageHeader';

const PageHeader = () => {
  const { pageHeader } = usePageHeaderContext();
  return <Card>{pageHeader}</Card>;
};

export default PageHeader;
