import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { _t } from 'rosis-translation';
import PropTypes from 'prop-types';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLock } from '@fortawesome/free-solid-svg-icons';
import { useSendEmailWithTemplate } from 'rosis-api';

function ResetPasswordUser({ idStr }) {
  const { showInfoBar } = useInfoBar();
  const {
    isSuccess: successSendEmail,
    error: errorSendEmail,
    isLoading,
    mutate: send
  } = useSendEmailWithTemplate('resetPassword');

  useEffect(() => {
    if (successSendEmail) {
      showInfoBar({
        message: _t('success::Reset password succeeded'),
        type: 'success'
      });
    }
    if (errorSendEmail) {
      showInfoBar({
        message: _t(errorSendEmail?.response?.data || 'error::Reset password failed'),
        type: 'error'
      });
    }

    hideModal();
  }, [successSendEmail, errorSendEmail]);

  const [visibleModal, setVisibleModal] = useState(false);

  const resetUser = () => {
    send({
      to: idStr,
      action: {
        pwToken: idStr
      }
    });
  };

  const showModal = () => {
    setVisibleModal(true);
  };

  const hideModal = () => {
    setVisibleModal(false);
  };

  return (
    <>
      <Button
        icon={<FontAwesomeIcon icon={faUserLock} style={{ marginInlineEnd: '10px' }} />}
        className="font-bold"
        loading={isLoading}
        onClick={showModal}>
        {_t('button::Reset password')}
      </Button>

      <Modal
        title={_t('::Do you want reset password for this user?')}
        open={visibleModal}
        confirmLoading={isLoading}
        onOk={resetUser}
        onCancel={hideModal}
        okText={_t('button,password::Reset')}
        cancelText={_t('button::Cancel')}></Modal>
    </>
  );
}

ResetPasswordUser.propTypes = {
  role: PropTypes.string,
  idStr: PropTypes.string
};

export default ResetPasswordUser;
