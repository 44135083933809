import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'rosis-components/navigation-wrapper';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';

const useInfoBarFromRouting = () => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate(true);
  const { showInfoBar, hideAllInfoBars } = useInfoBar();
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (state?.infoBarMessage) {
      setMessage(state.infoBarMessage)
      navigate(pathname, { ...state, infoBarMessage: null });
    } else {
      hideAllInfoBars();
    }
  }, [pathname, showInfoBar, hideAllInfoBars]);

  useEffect(() => {
    if(message){
      showInfoBar(message);
      setMessage(null);
    }

  }, [message])
};

export default useInfoBarFromRouting;
