import React, { useEffect, useState } from 'react';
import { _t } from 'rosis-translation';
import { usePasswordChecklist } from 'rosis-api';
import PropTypes from 'prop-types';
import { List, Typography } from 'antd';

const { Text } = Typography;

function PswChecklist({ password }) {
  const { data: checklist } = usePasswordChecklist();
  const [pswdParam, setPswdParam] = useState([]);

  useEffect(() => {
    if (checklist) {
      const paramItems = checklist.checklistRules.map((item) => ({
        name: item.label,
        regex: item.pattern
      }));
      setPswdParam(paramItems);
    }
  }, [checklist]);

  if (!pswdParam.length) {
    return null;
  }

  return (
    <>
      <Text strong>{_t('::Password requirements')}</Text>
      <List
        size="small"
        style={{ marginBottom: '15px' }}
        dataSource={pswdParam}
        renderItem={(item) => (
          <List.Item
            style={{ padding: '2px', color: password.match(item.regex) ? 'green' : 'red' }}>
            {_t(item.name, checklist.values)}
          </List.Item>
        )}
      />
    </>
  );
}

PswChecklist.propTypes = {
  password: PropTypes.string,
  regex: PropTypes.string
};

export default PswChecklist;
