import React, { useMemo, useState } from 'react';
import { Form, Button, Row, Col, Alert } from 'antd';
import { useQueryClient } from 'react-query';
import { _t } from 'rosis-translation';
import { useChangePassword, useConfigs } from 'rosis-api';
import PswChecklist from 'rosis-components/PasswordScreens/PswChecklist';
import ChangePasswordForm from 'components/ChangePasswordForm';

import './ChangePassword.less';
import { usePageHeader } from 'contexts/pageHeader';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';
import { useChange } from 'contexts/change';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import TitleActions from 'components/TitleActions';

function ChangePassword() {
  const { isChanged, setIsChanged } = useChange();
  const queryClient = useQueryClient();
  const { data: pubConfig } = useConfigs('pub');
  const { pswdWidget } = { ...pubConfig };
  const [lastUpdateResult, setLastUpdateResult] = useState(null);
  const [passwd, setPasswd] = useState('');

  const [form] = Form.useForm();

  const {
    mutate: changePassword,
    isLoading: isLoadingChangePassword,
    ...changePasswordStatus
  } = useChangePassword(queryClient);

  const cancelEdition = () => {
    form.resetFields();
    setPasswd('');
    setIsChanged(false);
  };

  const actions = useMemo(
    () =>
      isChanged
        ? [
            <Button
              key="cancel"
              type="button"
              icon={<FontAwesomeIcon icon={faTimes} />}
              onClick={cancelEdition}>
              {_t('button::Cancel')}
            </Button>,
            <Button
              key="submit"
              icon={<FontAwesomeIcon icon={faSave} />}
              type="primary"
              disabled={isLoadingChangePassword}
              onClick={() => form.submit()}>
              {_t('button,store::Save')}
            </Button>
          ]
        : [],
    [isChanged, isLoadingChangePassword, form]
  );

  const onFinish = (values) => {
    changePassword({ current: values.oldPassword, password: values.password });
  };

  usePageHeader(<TitleActions actions={actions}>{_t('::My profile')}</TitleActions>, [actions]);

  useApiFeedback({
    status: {
      isLoading: isLoadingChangePassword,
      ...changePasswordStatus
    },
    successMessage: _t('success::Password has been changed'),
    errorMessage: (err) => {
      if ([404].includes(err.response?.status)) {
        return _t('error::The old password is incorrect');
      }
      if (err?.response?.data) {
        return _t(err?.response?.data);
      }
      return _t('error::Error while changing password');
    },
    silentSuccess: true,
    silentError: true,
    onSuccess: (msg) => {
      setLastUpdateResult({ status: 'success', message: msg });
      setIsChanged(false);
    },
    onError: (msg) => {
      setLastUpdateResult({ status: 'error', message: msg });
    }
  });

  return (
    <div className="change-password">
      <Row>
        <Col span={24} md={12} xl={8}>
          <Alert
            message={_t('::Once the password is changed, you will be signed out.')}
            type="warning"
            className="logout-warning"
            showIcon
          />
          <ChangePasswordForm
            setPasswd={setPasswd}
            onFinish={onFinish}
            externalForm={form}
            lastUpdateResult={lastUpdateResult}
            setLastUpdateResult={setLastUpdateResult}
            onFormValueChange={() => setIsChanged(true)}
          />
        </Col>
      </Row>
      {pswdWidget === 'yes' && !lastUpdateResult && <PswChecklist password={passwd} />}
    </div>
  );
}

export default ChangePassword;
