import React, { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Card, Skeleton, Button } from 'antd';
import { DragDropContext } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import { withLayout } from 'layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileUpload, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Upload, Download } from 'components/buttons';
import TitleActions from 'components/TitleActions';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import { useModels, useOrderModel, useLocal, useUpdateLocal, apiUrl } from 'rosis-api';
import { _t } from 'rosis-translation';
import { useBreadcrumbs } from 'rosis-contexts/breadcrumbs';
import { usePageHeader } from 'contexts/pageHeader';
import PageTitle from 'components/PageTitle';
import { links } from 'router/links';
import Header from './Header';
import Models from './Models';
import './Model.less';

function SchemaModel() {
  const queryClient = useQueryClient();
  const { data: models, isLoading, refetch } = useModels();
  const {
    error: errorChangeOrder,
    isLoading: orderIsLoading,
    mutate: changeOrder
  } = useOrderModel(queryClient);
  const { setBreadcrumbs } = useBreadcrumbs();
  const [modelList, setModelList] = useState([]);
  const [extendedModels, setExtendedModels] = useState([]);
  const { data: extended } = useLocal('extendedModels');
  const { mutate: saveModelCollapse } = useUpdateLocal(queryClient, 'extendedModels');
  const { showInfoBar } = useInfoBar();

  useEffect(() => {
    setBreadcrumbs([
      {
        label: '',
        link: '/'
      },
      {
        label: _t('::Sections'),
        link: '/sections'
      }
    ]);
  }, []);

  useEffect(() => {
    extended && !extendedModels.length && setExtendedModels(extended.split(','));
  }, [extended]);

  useEffect(() => {
    if (errorChangeOrder) {
      showInfoBar({
        message: _t(errorChangeOrder?.response?.data || 'error::Error while changing order'),
        type: 'error'
      });
    }
  }, [errorChangeOrder]);

  useEffect(() => {
    if (models && !orderIsLoading) {
      setModelList(models.filter((model) => model.person));
    }
  }, [models, orderIsLoading]);

  const findModel = (name, list) => {
    for (const model of list) {
      if (model.name === name) return model;
      else if (model.children) {
        const foundedModel = findModel(name, model.children);
        if (foundedModel) return foundedModel;
      }
    }
    return null;
  };

  const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
  };

  const handleDragEnd = ({ destination, source, draggableId }) => {
    if (!destination) return null;
    const { index, droppableId } = destination;
    const parentModel = findModel(droppableId, modelList);
    parentModel.children = reorder(parentModel.children, source.index, index);
    setModelList([...modelList]);

    changeOrder({ name: draggableId, order: index });
  };

  const addOrRemoveModel = (modelName) => {
    if (extendedModels.includes(modelName)) {
      return extendedModels.filter((current) => current !== modelName);
    } else {
      return [...extendedModels, modelName];
    }
  };

  const handleExtend = (modelName) => {
    const newExtended = addOrRemoveModel(modelName);
    setExtendedModels(newExtended);
    saveModelCollapse(newExtended.join(','));
  };

  const onSuccess = () => {
    refetch();
    showInfoBar({
      message: _t('info::Upload succeeded'),
      type: 'success'
    });
  };

  const onError = (err, message) => {
    showInfoBar({
      message: _t(message || 'info::Upload failed'),
      type: 'error'
    });
  };

  const title = _t('::Sections');

  usePageHeader(
    <TitleActions
      actions={[
        <Link to={links.sectionCreate()} key="create">
          <Button type="primary" icon={<FontAwesomeIcon icon={faPlusCircle} />}>
            {_t('::Section')}
          </Button>
        </Link>,
        <Upload
          key="upload"
          action={`${apiUrl}/frame/upload-all`}
          accept=".rbsm"
          onSuccess={onSuccess}
          onError={onError}>
          <>
            <FontAwesomeIcon icon={faFileUpload} />
            &nbsp;
            {_t('button::Upload all')}
          </>
        </Upload>,
        <Download key="export" link={`${apiUrl}/frame/download/true`}>
          <FontAwesomeIcon icon={faDownload} />
          &nbsp;
          {_t('button::Download all')}
        </Download>
      ]}>
      {title}
    </TitleActions>
  );

  return (
    <>
      <PageTitle title={title} />
      <Card>
        {isLoading ? (
          <Skeleton />
        ) : (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Header />
            <Models
              modelList={modelList}
              extendedModels={extendedModels}
              handleExtend={handleExtend}
            />
          </DragDropContext>
        )}
      </Card>
    </>
  );
}

export default withLayout(SchemaModel);
