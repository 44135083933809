import React, { useState, useEffect } from 'react';
import { Card, Typography, Skeleton, Table, Empty } from 'antd';
import { withLayout } from 'layout';
import { Link } from 'rosis-components/navigation-wrapper';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import { useSites, useSiteUsage } from 'rosis-api';
import RemoveSite from './RemoveSite';
import AddNewSite from './AddNewSite';
import { _t } from 'rosis-translation';
import { stdKeys } from 'system/helper/trans-key';
import './Sites.less';
import { useBreadcrumbs } from 'rosis-contexts/breadcrumbs';
import { usePageHeader } from 'contexts/pageHeader';
import PageTitle from 'components/PageTitle';
import { links } from 'router/links';

const { Title } = Typography;

function Sites() {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { showInfoBar } = useInfoBar();
  const { data: pages, isLoading } = useSites();
  const { data: siteUsage } = useSiteUsage();
  const [siteList, setSiteList] = useState([]);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: '',
        link: '/'
      },
      {
        label: _t('areas::Sites'),
        link: '/sites'
      }
    ]);
  }, []);

  useEffect(() => {
    siteUsage &&
      pages &&
      setSiteList(
        pages.map((item) => ({
          ...item,
          canRemove: !!siteUsage[item.title],
          count: siteUsage[item.title] || 0
        }))
      );
  }, [siteUsage, pages]);

  const columns = [
    {
      title: _t('site::Site name'),
      dataIndex: 'title',
      key: 'title',
      render: (title, record) => (
        <Link key="edit" to={links.sitesDetails(title)} state={{ defaultLang: record.lang }}>
          {title}
        </Link>
      )
    },
    {
      title: _t('site::Number of users'),
      dataIndex: 'count',
      key: 'count'
    },
    {
      title: _t('site::Default language'),
      dataIndex: 'lang',
      key: 'lang',
      render: (lang) => _t(stdKeys.lang(lang))
    },
    {
      title: _t('site::Actions'),
      key: 'actions',
      render: (record) => (
        <RemoveSite
          site={record.title}
          disabled={
            record.canRemove
              ? _t("::You can't remove this site because it has users")
              : siteList?.length <= 1
              ? _t("::You can't remove last site")
              : null
          }
          onSuccess={onSuccess}
          onFail={onFail}
        />
      )
    }
  ];

  const onSuccess = (message) => {
    showInfoBar({
      message,
      type: 'success'
    });
  };

  const onFail = (message) => {
    showInfoBar({
      message,
      type: 'error'
    });
  };

  const title = _t('areas::Sites');

  usePageHeader(
    <div className="site-title">
      <Title level={3}>{title}</Title>
      <AddNewSite onSuccess={onSuccess} onFail={onFail} />
    </div>
  );

  return (
    <>
      <PageTitle title={title} />
      <Card className="site">
        {isLoading ? (
          <Skeleton />
        ) : (
          <Table
            className="lang"
            columns={columns}
            size="middle"
            loading={isLoading}
            pagination={false}
            locale={{
              emptyText: (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={_t('site::No sites')} />
              )
            }}
            dataSource={siteList}
            rowKey="id"
          />
        )}
      </Card>
    </>
  );
}

export default withLayout(Sites);
