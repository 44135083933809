import React, { useEffect } from 'react';
import { withNotLoggedLayout } from 'layout';
import { useAuth } from 'rosis-contexts/auth';
import AppLoading from 'rosis-components/AppLoading';
import ForgotPanel from 'rosis-components/ForgotPanel';
import { useConfigs } from 'rosis-api';
import { links, useNavigation } from 'router/links';
import './ForgotPassword.less';

function ForgotPassword() {
  const { isLoggedUser } = useAuth();
  const navigation = useNavigation();
  const { data: config, isLoading } = useConfigs('opengraph');
  const image = config?.['admin-image'] || config?.image;
  const title = config?.['admin-title'] || config?.title;

  useEffect(() => {
    if (isLoggedUser) {
      navigation.home();
    }
  }, [isLoggedUser]);

  return isLoading ? (
    <AppLoading />
  ) : (
    <ForgotPanel title={title} image={image} loginLink={links.login()} pinLink={links.pinCode()} />
  );
}

export default withNotLoggedLayout(ForgotPassword);
