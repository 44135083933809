import React from 'react';
import AppLayout from './AppLayout';

const withAppLayout = (Component, options = {}) =>
  function ContextLayout() {
    return (
      <AppLayout {...options}>
        <Component />
      </AppLayout>
    );
  };

export default withAppLayout;
