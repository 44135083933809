const dayjs = require('dayjs');
import datetime from 'rosis-date-time-provider';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';

const callAndCast = (fn, ...args) => {
  const res = fn(...args);
  if (!res || !dayjs.isDayjs(res)) {
    return res;
  }
  return datetime(res);
};

const overwriteDays = (cfg) => {
  const res = Object.entries(cfg).map(([key, val]) => [
    key,
    typeof val === 'function' ? (...args) => callAndCast(val, ...args) : overwriteDays(val)
  ]);
  return Object.fromEntries(res);
};

const wrappedConfig = overwriteDays(dayjsGenerateConfig);

export default wrappedConfig;
