import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import Item from '../../../Item';
function Link({ value, filePath, useFileInfo, ...rest }) {
  const fileId = value && typeof value === 'string' ? value : null;
  const { data: file } = useFileInfo(fileId, { enabled: !!fileId }) || {};

  return (
    <Item {...rest}>
      {file ? (
        <a download href={file?.link || `${filePath}/${file._id}`}>
          {file?.link || file?.filename}
        </a>
      ) : value?.name ? (
        <a href={value?.link}>
          {value?.name}
        </a>
      ) : null}
    </Item>
  );
}

Link.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.shape({
      link: PropTypes.string,
      name: PropTypes.string
    }),
    PropTypes.string
  ]),
  filePath: PropTypes.string,
  useFileInfo: PropTypes.func
};

Link.defaultProps = {
  useFileInfo: () => {}
};

const options = {
  value: 'link',
  get label() {
    return _t('::Link');
  },
  component: Link,
  example: <Link value={{ name: 'download.file', link: '#' }} />,
  default: true
};

export default options;
