import React from 'react';
import { useFormContext } from 'react-hook-form';
import { _t } from 'rosis-translation';
import { SFNumber } from 'rosis-setting-form';

function Max(props) {
  const { watch } = useFormContext();
  const [min, step, decimal] = watch(['min', 'step', 'decimal']);
  return <SFNumber {...props} min={min} step={step} precision={decimal} allowClear />;
}

const validate = (val, max) => {
  if (val === null || val === undefined) {
    return null;
  }
  if (max === null || max === undefined) {
    return null;
  }
  if (val > max) {
    return _t('field_definition::Value must be lower than Max value', { max });
  }
};

const max = {
  get label() {
    return _t('::Max');
  },
  name: 'max',
  Field: Max,
  order: 20,
  validate
};

export default max;
