import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layout, ConfigProvider } from 'antd';
import ErrorBoundary from 'rosis-components/ErrorBoundary';
import Header from './Header';
import Menu from './Menu';
import MobileMenu from './MobileMenu';
import Content from './Content';
import useInfoBarFromRouting from 'rosis-components/hooks/use-info-bar-from-routing';
import useDocumentDirection from './use-document-direction';
import AppFooter from './Footer';
import { useIsTablet } from 'rosis-components/hooks/useScreenSizes';

function AppLayout({ children }) {
  const isTabletOrDesktop = useIsTablet();
  const [visible, setVisible] = useState(false);

  useInfoBarFromRouting();
  const { direction } = useDocumentDirection();

  return (
    <ConfigProvider direction={direction}>
      <ErrorBoundary>
        {<Header isTabletOrDesktop={isTabletOrDesktop} setVisible={setVisible} />}
        <Layout>
          {isTabletOrDesktop ? (
            <Menu />
          ) : (
            <MobileMenu direction={direction} visible={visible} setVisible={setVisible} />
          )}
          <Content footer={<AppFooter />}>{children}</Content>
        </Layout>
      </ErrorBoundary>
    </ConfigProvider>
  );
}

AppLayout.propTypes = {
  children: PropTypes.any.isRequired
};

export default AppLayout;
