import React from 'react';
import PropTypes from 'prop-types';
import { Form, InputNumber, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useFormContext } from 'react-hook-form';
import { _t } from 'rosis-translation';
import Item from './Item';

function SFNumber(props) {
  const { name, label, min, max, step, precision, allowClear, required } = props;
  const { setValue } = useFormContext();
  return (
    <Form.Item label={`${label}:`} required={required}>
      <Item type="Number" noLabel noStyle {...props}>
        <InputNumber min={min} max={max} step={step} precision={precision} />
      </Item>
      {allowClear ? (
        <Button
          type="link"
          size="small"
          onClick={() =>
            setValue(name, null, { shouldValidate: true, shouldDirty: true, shoudTouch: true })
          }>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      ) : null}
    </Form.Item>
  );
}

SFNumber.defaultProps = {
  errorMessage: _t("::It's required")
};

SFNumber.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  clearBtn: PropTypes.bool,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  precision: PropTypes.number,
  allowClear: PropTypes.bool
};

export default SFNumber;
