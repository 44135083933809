const { useMemo } = require('react');
import { validateRules } from 'rc-field-form/lib/utils/validateUtil';

const useValidation = ({ settings, errors, onErrorsChange }) => {
  const validators = useMemo(() => {
    return Object.fromEntries(settings.map((s) => [s.name, s.validationRules]));
  }, [settings]);

  const performValidation = (name, value) => {
    const validator = validators[name];

    if (validator) {
      validateRules([name], value, validator, {})
        .then(() => {})
        .catch((err) => {
          const errorText = err.length ? err.map((e) => e.errors).join(', ') : null;

          const newErrors = { ...errors, [name]: errorText };

          onErrorsChange(newErrors);
        });
    }
  };

  return {
    performValidation
  };
};

export default useValidation;
