import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faDatabase,
  faUserTag,
  faUserFriends,
  faGlobeAmericas,
  faCogs,
  faUsers,
  faUserShield
} from '@fortawesome/free-solid-svg-icons';
import TopMenuContent from './layout/TopMenuContent';
import { _t } from 'rosis-translation';
import { NavLink } from 'rosis-components/navigation-wrapper';
import { links } from 'router/links';

const createMenuList = () => {
  const top = [
    {
      key: 'top-menu-content',
      content: <TopMenuContent />
    }
  ];
  const main = [
    {
      key: 'dashboard',
      label: _t('::System status'),
      href: links.dashboard(),
      icon: <FontAwesomeIcon icon={faTachometerAlt} />
    },
    {
      key: 'sections',
      label: _t('::Sections'),
      href: links.sections(),
      icon: <FontAwesomeIcon className="ant-menu-item-icon" icon={faDatabase} />
    },
    {
      key: 'privileges',
      label: _t('::Privileges'),
      href: links.privileges(),
      icon: <FontAwesomeIcon className="ant-menu-item-icon" icon={faUserTag} />
    },
    {
      key: 'lang',
      label: _t('::Languages'),
      href: links.langs(),
      icon: <FontAwesomeIcon className="ant-menu-item-icon" icon={faGlobeAmericas} />
    },
    {
      key: 'sites',
      label: _t('areas::Sites'),
      href: links.sites(),
      icon: <FontAwesomeIcon className="ant-menu-item-icon" icon={faUserFriends} />
    },
    {
      key: 'users',
      label: _t('::Users'),
      href: links.users(),
      icon: <FontAwesomeIcon className="ant-menu-item-icon" icon={faUsers} />
    },
    {
      key: 'settings',
      label: _t('::Settings'),
      href: links.settings(),
      icon: <FontAwesomeIcon className="ant-menu-item-icon" icon={faCogs} />
    },
    {
      key: 'logs',
      label: _t('::Security log'),
      href: links.logs(),
      icon: <FontAwesomeIcon className="ant-menu-item-icon" icon={faUserShield} />
    }
  ]
    .map((item) => {
      const { label, href } = item;
      item.label = <NavLink to={href}>{label}</NavLink>;
      return item;
    })
    .filter((item) => !item.hidden);
  return {
    top,
    main
  };
};
export default createMenuList;
