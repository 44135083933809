import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { _t } from 'rosis-translation';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [['bold', 'italic', 'underline'], ['link'], ['clean']]
};

const formats = [_t('bold'), _t('italic'), _t('underline'), _t('link')];

function MKEditor({ value, onChange }) {
  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={onChange}
      modules={modules}
      formats={formats}
    />
  );
}

MKEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default MKEditor;
