import React, { useState, useMemo } from 'react';
import { Radio, Row, Col } from 'antd';
import { _t } from 'rosis-translation';
import PropTypes from 'prop-types';
import ToolbarMobile from './ToolbarMobile';
import { useNavigate } from 'react-router-dom';
import UsersByRoleMobile from './UsersByRoleMobile';
import { useConfig } from 'rosis-api';
import './../Users.less';

const getIdFromKey = (data, key) => data.find((field) => field.key === key)?.labels?.none;

function UsersMobile({ models, role }) {
  const navigate = useNavigate();
  const { data: loginPointsList } = useConfig('conf', 'showLoginPointsUsers');
  const activeKey = role || 'admin';
  const [sortBy, setSortBy] = useState(null);

  const tabs = useMemo(
    () =>
      loginPointsList
        ? models
            .map((model) => ({
              ...model,
              value: model.key,
              label: _t(model.label)
            }))
            .filter((field) => loginPointsList.split(/\s+/).includes(field.loginPoint))
            .reverse()
        : [],
    [models, loginPointsList]
  );

  const userInfo = useMemo(() => tabs.find((field) => field.key === activeKey), [tabs, activeKey]);

  const onChangeTabs = (e) => {
    const { value } = e.target;

    navigate(`/users/${value}`);
  };

  const changeSortFields = (data) => {
    setSortBy(data);
  };

  const [downloadParams, setDownloadParams] = useState({});

  const updateFilter = ({ find, sites }) => {
    setDownloadParams({ find, sites });
  };

  return (
    <Row gutter={[12, 12]}>
      <Col span={24} className="users-mobile">
        {tabs && (
          <Radio.Group
            options={tabs}
            optionType="button"
            buttonStyle="solid"
            onChange={onChangeTabs}
            value={activeKey}
          />
        )}
      </Col>
      <Col span={24}>
        <ToolbarMobile
          model={activeKey}
          changeSort={changeSortFields}
          userInfo={userInfo}
          downloadParams={downloadParams}
          roles={tabs}
        />
      </Col>
      <Col span={24}>
        <UsersByRoleMobile
          name={activeKey}
          sort={sortBy}
          noData={getIdFromKey(tabs, activeKey) ? _t(getIdFromKey(tabs, activeKey)) : ''}
          onUpdateFilter={updateFilter}
        />
      </Col>
    </Row>
  );
}

UsersMobile.propTypes = {
  models: PropTypes.array,
  role: PropTypes.string
};

export default UsersMobile;
