'use strict';

const falseEquiv = ['', 'false', 'n', 'no', 'off', '0']
    , period = require('./period')
    , split = require('./split')
;

module.exports = {
    bool:   value => value != null && !~falseEquiv.indexOf(value.toString().toLowerCase()),
    time:   value => period(value || ""), // e.g. '3m30s' to msec (dhms only)
    number: value => Number(value),
    list:   value => split(value, /\s*[\s,]\s*/),
    string: value => value ? value.toString() : "",
};
