import PropTypes from 'prop-types';
import { Form, Row, Col, Table, List, Typography } from 'antd';
import ConfigValuePanel from 'components/ConfigSettings/ConfigValuePanel';
import useValidation from 'components/ConfigSettings/useValidation';
import { useIsSmallDesktop } from 'rosis-components/hooks/useScreenSizes';
import React, { useEffect, useMemo, useState } from 'react';

const { Title } = Typography;

function GridSectionEditor({
  label,
  settings,
  values,
  errors,
  titleColumns,
  dataUpdatedAt,
  onGetCellIds,
  onErrorsChange,
  onChange
}) {
  const { performValidation } = useValidation({ settings, errors, onErrorsChange });
  const [currentValues, setCurrentValues] = useState({});
  const [editedValue, setEditedValue] = useState();

  const calcNewValues = (name, value, group, oldValues) => {
    const groupValue = oldValues[group]
      ? { ...oldValues[group], [name]: value }
      : { [name]: value };
    return { ...oldValues, [group]: groupValue };
  };

  const calcDisplayValue = (group, name) => {
    if (currentValues?.[group]?.[name] !== undefined) {
      return currentValues?.[group]?.[name];
    }
    return values?.[group]?.[name];
  };

  const change = (name, value, group, isUserChange) => {
    setEditedValue({ name, value, group, isUserChange });
  };

  useEffect(() => {
    if (editedValue) {
      const { name, value, group, isUserChange } = editedValue;
      setCurrentValues(calcNewValues(name, value, group, currentValues));
      onChange(name, value, group, isUserChange);
      performValidation(name, value);
    }
  }, [editedValue]);

  const isDesktop = useIsSmallDesktop();

  const columns = useMemo(() => {
    const dataColumnsMap = {};
    settings.forEach((item) => {
      const [, dataKey] = onGetCellIds(item);
      if (!dataColumnsMap[dataKey]) {
        dataColumnsMap[dataKey] = {
          title: item.label,
          dataIndex: dataKey,
          render: (val, row) => {
            const settings = row.settings[dataKey];
            return (
              <ConfigValuePanel
                key={settings.name}
                editorProps={settings.editorProps}
                CustomComponent={settings.CustomComponent}
                name={settings.name}
                label={!isDesktop ? settings.label : ''}
                error={errors[settings.name]}
                value={val}
                dataUpdatedAt={dataUpdatedAt}
                kind={settings.kind}
                group={settings.group}
                onChange={change}
              />
            );
          }
        };
      }
    });
    return [
      ...titleColumns.map((tc) => ({ ...tc, isTitle: true })),
      ...Object.values(dataColumnsMap)
    ];
  }, [settings, errors, isDesktop]);

  useEffect(() => {
    setCurrentValues({});
  }, [dataUpdatedAt]);

  const dataSource = useMemo(() => {
    const rowsMap = {};
    settings.forEach((item) => {
      const [rowId, dataKey] = onGetCellIds(item);
      let row = rowsMap[rowId];
      if (!row) {
        row = {
          key: rowId
        };
        rowsMap[rowId] = row;
      }
      row[dataKey] = calcDisplayValue(item.group, item.name);
      if (!row.settings) {
        row.settings = {};
      }
      row.settings[dataKey] = item;
    });
    return Object.values(rowsMap);
  }, [settings, values, currentValues]);

  return (
    <div>
      <Form.Item label={<label style={{ fontWeight: 'bold' }}>{label}</label>}>
        <Row>
          <Col span={24}>
            {isDesktop ? (
              <Table dataSource={dataSource} columns={columns} rowKey="key" pagination={false} />
            ) : (
              <List
                bordered
                dataSource={dataSource}
                rowKey="key"
                renderItem={(item) => (
                  <List.Item>
                    <div>
                      <Title className="list-title" level={5}>
                        <Row>
                          {columns
                            .filter((col) => col.isTitle)
                            .map((col) => (
                              <Col key={col.dataIndex}>{col.render(item[col.dataIndex], item)}</Col>
                            ))}
                        </Row>
                      </Title>
                      {columns
                        .filter((col) => !col.isTitle)
                        .map((col) => col.render(item[col.dataIndex], item))}
                    </div>
                  </List.Item>
                )}
              />
            )}
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
}

GridSectionEditor.propTypes = {
  label: PropTypes.string,
  dataUpdatedAt: PropTypes.number,
  errors: PropTypes.shape(),
  values: PropTypes.object,
  titleColumns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      dataIndex: PropTypes.string
    })
  ),
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.string,
      name: PropTypes.string,
      label: PropTypes.string,
      kind: PropTypes.string,
      editorProps: PropTypes.object
    })
  ),
  onGetCellIds: PropTypes.func.isRequired,
  onErrorsChange: PropTypes.func,
  onChange: PropTypes.func
};

GridSectionEditor.defaultProps = {
  titleColumns: [],
  onChange: () => {}
};

export default GridSectionEditor;
