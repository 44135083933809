import { datetime } from 'rosis-date-picker';
import { relativeDateConstraintPattern } from 'system/symbol';

export const isRelativeValuePattern = relativeDateConstraintPattern;

const rosisToDateUnit = {
  Y: 'years',
  M: 'months',
  D: 'days',
  d: 'days'
};

export const isInConstraints = (val, roundUnit, min, max) => {
  if (!val) {
    return true;
  }
  const minConstraint = calcConstraintValue(min);
  const maxConstraint = calcConstraintValue(max);
  return (
    (!maxConstraint || val.isSameOrBefore(calcConstraintValue(maxConstraint), roundUnit)) &&
    (!minConstraint || val.isSameOrAfter(calcConstraintValue(minConstraint), roundUnit))
  );
};

export const calcConstraintValue = (val) => {
  if (!val) {
    return val;
  }
  if (datetime.isDayjs(val)) {
    return val;
  }
  const parsed = isRelativeValuePattern.exec(val);
  if (!parsed) {
    return datetime(val);
  }
  const count = Number(parsed[1]);
  const unit = rosisToDateUnit[parsed[2]];
  return datetime().add(count, unit);
};
