import React, { useMemo, useState } from 'react';
import { _t } from 'rosis-translation';
import ConfigSettings from 'components/ConfigSettings';
import PropTypes from 'prop-types';
import { ConfigFormControlProvider } from 'components/ConfigSettings/ConfigFormControl';
import ConfigPageHeader from 'components/ConfigSettings/ConfigPageHeader';
import PageTitle from 'components/PageTitle';
import { calcSettingPageTitle } from '../utils';
import { useChange } from 'contexts/change';
import { bool } from 'system/helper/value';

const UserSettingsTab = ({ title, appName }) => {
  const { isChanged, setIsChanged } = useChange();
  const [hidden, setHidden] = useState(false);
  const settings = useMemo(() => {
    const baseSettings = [
      {
        name: 'allowSignup',
        group: 'pub',
        label: _t('::Show registration form	'),
        kind: 'bool'
      },
      {
        name: 'displayRole',
        group: 'pub',
        label: _t('::Show role selection on registration form'),
        kind: 'bool',
        hidden: hidden
      },
      {
        name: 'displaySites',
        group: 'pub',
        label: _t('::Show site selection on registration form'),
        kind: 'bool',
        hidden: hidden
      }
    ];

    return appName
      ? baseSettings.map((setting) => ({
          ...setting,
          name: `${appName}-${setting.name}`
        }))
      : baseSettings;
  }, [appName, hidden]);

  const onSettingsChange = (value) => {
    if (value[`${appName}-allowSignup`]) {
      setHidden(!bool(value[`${appName}-allowSignup`]));
    }
  };

  const pageTitle = calcSettingPageTitle(title);

  return (
    <ConfigFormControlProvider>
      <PageTitle title={pageTitle} />
      <ConfigPageHeader pageTitle={pageTitle} isDirty={isChanged} />
      <ConfigSettings
        settings={settings}
        onIsDirtyChange={setIsChanged}
        onSettingsChange={onSettingsChange}
      />
    </ConfigFormControlProvider>
  );
};

UserSettingsTab.propTypes = {
  title: PropTypes.string,
  appName: PropTypes.string
};

export default UserSettingsTab;
