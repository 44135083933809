import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import parseValueToQuery from '../../../utils/parseValue';
import StringCompare from '../../../utils/compare/String';
import Item from '../../../Item';

function Email({ value, ...rest }) {
  return (
    <Item {...rest}>
      <a href={`mailto:${value}`}>{value}</a>
    </Item>
  );
}

Email.propTypes = {
  value: PropTypes.string
};

const options = {
  value: 'email',
  get label() {
    return _t('::Email');
  },
  component: Email,
  compareComponent: StringCompare,
  parseValueToQuery: parseValueToQuery.string,
  example: <Email value={'test@test.com'} />
};

export default options;
