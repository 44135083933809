import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { SFTextarea } from 'rosis-setting-form';

function Options(props) {
  const { value } = props;
  return <SFTextarea {...props} defaultValue={value} required />;
}

Options.propTypes = {
  value: PropTypes.string
};

const validate = (val, options) => {
  if (!val) {
    return null;
  }
  if (!options) {
    return null;
  }
  const optionsArray = options.split('\n');
  if (!optionsArray.includes(val)) {
    return _t('field_definition::Value must be one of the Options', { options });
  }
  return null;
};

const options = {
  get label() {
    return _t('::Options');
  },
  Field: Options,
  name: 'opts',
  valueType: 'Array',
  order: -910,
  validate
};

export default options;
