import { useEffect, useState } from 'react';
import { _t } from 'rosis-translation';
import { useInfoBar } from '../InfoBar/infoBar';

const getErrorText = (error, errorMessage) => {
  if (errorMessage && typeof errorMessage === 'function') {
    return errorMessage(error);
  }
  if (error?.response?.data) {
    return _t(error?.response?.data);
  }
  return errorMessage;
};

export const useApiFeedback = ({
  status,
  progressMessage,
  errorMessage,
  successMessage,
  silentProgress,
  silentSuccess,
  silentError,
  onSuccess,
  onError
}) => {
  const { showInfoBar, hideInfoBar } = useInfoBar();
  const { isLoading, isSuccess, error } = status;
  const [loadingMessageId, setLoadingMessageId] = useState(null);

  useEffect(() => {
    if (!silentProgress && isLoading && loadingMessageId === null) {
      const messageId = showInfoBar({
        type: 'progress',
        message: progressMessage || _t('::Operation in progress')
      });
      setLoadingMessageId(messageId);
    } else if (!silentProgress && !isLoading && loadingMessageId !== null) {
      hideInfoBar(loadingMessageId);
      setLoadingMessageId(null);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess) {
      const message = successMessage || _t('::Operation succeeded');
      if (!silentSuccess) {
        showInfoBar({
          type: 'success',
          message
        });
      }
      if (onSuccess) {
        onSuccess(message);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      const message = getErrorText(error, errorMessage) || _t('::Operation failed');
      if (!silentError) {
        showInfoBar({
          type: 'error',
          message
        });
      }
      if (onError) {
        onError(message, error);
      }
    }
  }, [error]);
};
