import React from 'react';
import PropTypes from 'prop-types';
import { SFItem } from 'rosis-setting-form';
import RelativeOrFixedDateInput from './RelativeOrFixedDateInput';

function RelativeOrFixedDate({ name, label, editAs, min, max, roundUp }) {
  return (
    <SFItem type="Date" name={name} label={label}>
      <RelativeOrFixedDateInput editAs={editAs} min={min} max={max} roundUp={roundUp} />
    </SFItem>
  );
}

RelativeOrFixedDate.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  editAs: PropTypes.string,
  min: PropTypes.object,
  max: PropTypes.object,
  roundUp: PropTypes.bool
};

export default RelativeOrFixedDate;
