import React from 'react';
import { useFormContext } from 'react-hook-form';
import { _t } from 'rosis-translation';
import { SFNumber } from 'rosis-setting-form';

function Min(props) {
  const { watch } = useFormContext();
  const [max, step, decimal] = watch(['max', 'step', 'decimal']);
  return <SFNumber {...props} max={max} step={step} precision={decimal} allowClear />;
}

const validate = (val, min) => {
  if (val === null || val === undefined) {
    return null;
  }
  if (min === null || min === undefined) {
    return null;
  }
  if (val < min) {
    return _t('field_definition::Value must be greater than Min value', { min });
  }
};

const min = {
  get label() {
    return _t('::Min');
  },
  name: 'min',
  Field: Min,
  order: 10,
  validate
};

export default min;
