import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from 'rosis-components/PrivateRoute';
import { _t } from 'rosis-translation';
import {
  Dashboard,
  Schema,
  SchemaModel,
  FieldForm,
  FrameForm,
  Login,
  Role,
  Languages,
  EditLang,
  Sites,
  SiteDetails,
  Settings,
  Users,
  EditUser,
  ResetPassword,
  CreateUser,
  MyProfile,
  ForgotPassword,
  ConfirmEmail,
  PinCode,
  EmailEdit,
  Logs
} from 'views';
import Error404 from 'rosis-components/404';
import { NavigationBlockerProvider } from 'rosis-components/navigation-blocker';
import ServerError from 'rosis-components/ServerError';
import { NavigationBlockModal } from './NavigationBlockModal';

function AppRouter() {
  return (
    <NavigationBlockerProvider>
      <NavigationBlockModal
        title={_t('::Leave this page')}
        okText={_t('::Confirm')}
        cancelText={_t('::Cancel')}>
        {_t('::There are unsaved changes. Are you sure want to leave this page?')}
      </NavigationBlockModal>
      <Router basename="/adm">
        <Routes>
          <Route path="/" element={<PrivateRoute onlyFor="admin" element={<Dashboard />} />} />
          <Route
            path="/dashboard"
            element={<PrivateRoute onlyFor="admin" element={<Dashboard />} />}
          />
          <Route
            path="/sections/create/:name"
            element={<PrivateRoute onlyFor="admin" element={<FrameForm />} />}
          />
          <Route
            path="/sections/create"
            element={<PrivateRoute onlyFor="admin" element={<FrameForm />} />}
          />
          <Route
            path="/sections/:name/field/:fieldName"
            element={<PrivateRoute onlyFor="admin" element={<FieldForm />} />}
          />
          <Route
            path="/sections/:name/:tab"
            element={<PrivateRoute onlyFor="admin" element={<Schema />} />}
          />
          <Route
            path="/sections/:name"
            element={<PrivateRoute onlyFor="admin" element={<Schema />} />}
          />
          <Route
            path="/sections"
            element={<PrivateRoute onlyFor="admin" element={<SchemaModel />} />}
          />
          <Route path="/privileges" element={<PrivateRoute onlyFor="admin" element={<Role />} />} />
          <Route
            path="/privileges/:role"
            element={<PrivateRoute onlyFor="admin" element={<Role />} />}
          />
          <Route
            path="/lang/:lang"
            element={<PrivateRoute onlyFor="admin" element={<EditLang />} />}
          />
          <Route path="/lang" element={<PrivateRoute onlyFor="admin" element={<Languages />} />} />
          <Route
            path="/sites/:name"
            element={<PrivateRoute onlyFor="admin" element={<SiteDetails />} />}
          />
          <Route path="/sites" element={<PrivateRoute onlyFor="admin" element={<Sites />} />} />
          <Route
            path="/settings"
            element={<PrivateRoute onlyFor="admin" element={<Settings />} />}
          />
          <Route
            path="/settings/:section"
            element={<PrivateRoute onlyFor="admin" element={<Settings />} />}
          />
          <Route
            path="/settings/:section/:subsection"
            element={<PrivateRoute onlyFor="admin" element={<Settings />} />}
          />
          <Route
            path="/users/create/:role"
            element={<PrivateRoute onlyFor="admin" element={<CreateUser />} />}
          />
          <Route
            path="/users/:role/:id"
            element={<PrivateRoute onlyFor="admin" element={<EditUser />} />}
          />
          <Route
            path="/users/:role"
            element={<PrivateRoute onlyFor="admin" element={<Users />} />}
          />
          <Route path="/users" element={<PrivateRoute onlyFor="admin" element={<Users />} />} />
          <Route
            path="/profile"
            element={<PrivateRoute onlyFor="admin" element={<MyProfile />} />}
          />
          <Route
            path="/emailTemplates/:name/:lang"
            element={<PrivateRoute onlyFor="admin" element={<EmailEdit />} />}
          />
          <Route path="/logs" element={<PrivateRoute onlyFor="admin" element={<Logs />} />} />
          <Route path="/server-error" element={<ServerError />} />
          <Route path="/login" element={<Login />} />
          <Route path="/resetPassword/:hash" element={<ResetPassword />} />
          <Route path="/pincode" element={<PinCode />} />
          <Route path="/confirmEmail/:hash" element={<ConfirmEmail />} />
          <Route path="/confirmEmail/:hash/:mode" element={<ConfirmEmail />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
    </NavigationBlockerProvider>
  );
}

export default AppRouter;
