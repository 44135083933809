import React, { useEffect, useState } from 'react';
import { Card, Typography, Row, Col } from 'antd';
import { withLayout } from '../../layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useModelsLabels } from 'rosis-components/ModelLabelsProvider';
import TitleSection from './components/TitleSection';
import { useStats, useModels, useVersions } from 'rosis-api';
import Logs from './components/Logs';
import Users from './components/Users';

import { _t } from 'rosis-translation';
import './Dashboard.less';
import { useBreadcrumbs } from 'rosis-contexts/breadcrumbs';
import { usePageHeader } from 'contexts/pageHeader';
import PageTitle from 'components/PageTitle';

const { Title } = Typography;

export function Versions() {
  const { data: versions, isLoading } = useVersions();
  return isLoading ? null : (
    <Card title={<span className="dashboard-title__section">{_t('admin::Versions')}</span>}>
      <Row>
        {Object.entries(versions).map(([name, value]) => {
          return (
            <Col key={name} xs={24} lg={8}>
              <span className="dashboard-version__name">{name}</span>: {value}
            </Col>
          );
        })}
      </Row>
    </Card>
  );
}

export function Stats() {
  const [fields, setFields] = useState([]);
  const { data: models } = useModels();
  const stats = useStats(fields);

  useEffect(() => {
    models && setFields(models.filter((item) => item.person));
  }, [models]);

  return (
    <>
      {stats && (
        <Row gutter={12}>
          {Array.isArray(stats) &&
            stats.map(
              (stat) =>
                stat?.data?.label && (
                  <Col
                    key={stat?.data?.label}
                    xs={24}
                    sm={12}
                    md={8}
                    style={{ marginBottom: '15px' }}>
                    <Card
                      title={
                        <TitleSection
                          title={_t(stat?.data?.label)}
                          icon={<FontAwesomeIcon icon={faUser} color="#86888d" />}
                        />
                      }>
                      <Row>
                        <Col xs={24} xl={12}>
                          {_t('::Count of records')}:{' '}
                          <span className="dashboard-stats__value">{stat?.data?.count}</span>
                        </Col>
                        <Col xs={24} xl={12}>
                          {_t('::Disk usage')}:{' '}
                          <span className="dashboard-stats__value">{stat?.data?.sizeStr}</span>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                )
            )}
        </Row>
      )}
    </>
  );
}

function Dashboard() {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { modelLabels } = useModelsLabels();
  const [changed, setChanged] = useState(0);
  useEffect(() => {
    setBreadcrumbs([
      {
        label: '',
        link: '/'
      }
    ]);
  }, []);

  const title = _t('admin::System status');
  usePageHeader(<Title level={3}>{title}</Title>);

  const onChange = () => {
    setChanged(changed + 1);
  };

  return (
    <>
      <div style={{ margin: '20px' }}>
        <PageTitle title={title} />
      </div>
      <div className="dashboard">
        <Row gutter={16}>
          <Col xs={24} md={12} lg={8}>
            <Logs label={modelLabels?.['log'] || 'db-name::Security log'} changed={changed} />
          </Col>
          <Col xs={24} md={12} lg={8} key="new-users">
            <Users
              title="::New users"
              modelLabels={modelLabels}
              displayNew={true}
              filters={{
                confirmation: 'confirmed',
                enabled: true
              }}
            />
          </Col>
          <Col xs={24} md={12} lg={8} key="users-waiting">
            <Users
              title="::Users waiting for activation"
              modelLabels={modelLabels}
              displayNew={false}
              onChange={onChange}
              filters={{
                enabled: null
              }}
            />
          </Col>
        </Row>

        <Stats />
        <Versions />
      </div>
    </>
  );
}

export default withLayout(Dashboard);
