import React, { useEffect, useMemo, useState } from 'react';
import { Card, Typography, Tabs } from 'antd';
import { withLayout } from 'layout';
import { useParams, useLocation } from 'rosis-components/navigation-wrapper';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import { useElements, useModels, useConfig, useLocal, useNumberOfUserPerSite } from 'rosis-api';
import DefaultLang from './DefaultLang';
import { _t } from 'rosis-translation';
import './Sites.less';
import { useBreadcrumbs } from 'rosis-contexts/breadcrumbs';
import { usePageHeader } from 'contexts/pageHeader';
import UserList from './UserList';
import PageTitle from 'components/PageTitle';

const { Title } = Typography;

function SiteDetails() {
  const { name } = useParams();
  const { state } = useLocation();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { showInfoBar } = useInfoBar();
  const [activeKey, setActiveKey] = useState('admin');
  const { data: loginPointsList } = useConfig('conf', 'showLoginPointsUsers');
  const { data: localPerPage } = useLocal('perPage');
  const [elementsPerPage, setElementsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const { data: numberOfUsers } = useNumberOfUserPerSite(name);
  const { data, refetch, isLoading } = useElements('user', {
    page,
    elementsPerPage,
    sort: { idStr: 1 },
    find: {
      role: activeKey,
      sites: name
    }
  });
  const { data: models } = useModels();

  const tabs = useMemo(() => {
    if (models && Array.isArray(models) && loginPointsList) {
      const tabList = models
        .filter((field) => loginPointsList.split(/\s+/).includes(field.loginPoint))
        .map(({ label, key }) => ({
          label: `${_t(label)} (${numberOfUsers?.[name]?.[key] || 0})`,
          key,
          children: (
            <UserList
              isLoading={isLoading}
              data={data}
              page={page}
              setPage={setPage}
              elementsPerPage={elementsPerPage}
              setElementsPerPage={setElementsPerPage}
            />
          )
        }))
        .reverse();
      return [
        ...tabList,
        {
          label: `${_t('::Patients')} (${numberOfUsers?.[name]?.patient || 0})`,
          disabled: true,
          key: 'patient'
        }
      ];
    } else {
      return [];
    }
  }, [models, loginPointsList, data, page, elementsPerPage, numberOfUsers]);

  useEffect(() => {
    localPerPage && setElementsPerPage(localPerPage);
  }, [localPerPage]);

  useEffect(() => {
    refetch();
  }, [activeKey]);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: '',
        link: '/'
      },
      {
        label: _t('areas::Sites'),
        link: '/sites'
      },
      {
        label: name,
        link: `/sites/${name}`
      }
    ]);
  }, []);

  const onSuccess = (message) => {
    showInfoBar({
      message,
      type: 'success'
    });
  };

  const onFail = (message) => {
    showInfoBar({
      message,
      type: 'error'
    });
  };

  const title = name;

  usePageHeader(
    <div className="site-title">
      <Title level={3}>{title}</Title>
      <DefaultLang
        onFail={onFail}
        onSuccess={onSuccess}
        siteName={name}
        defaultLang={state?.defaultLang}
      />
    </div>,
    [state, name]
  );

  const onChangeTabs = (activeKey) => {
    setActiveKey(activeKey);
  };

  return (
    <>
      <PageTitle title={title} />
      <Card className="site">
        <Tabs
          items={tabs}
          tabPosition="top"
          activeKey={activeKey}
          destroyInactiveTabPane={true}
          onChange={onChangeTabs}
        />
      </Card>
    </>
  );
}

export default withLayout(SiteDetails);
