import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import parseValueToQuery from '../../../utils/parseValue';
import StringCompare from '../../../utils/compare/String';
import Item from '../../../Item';

function Phone({ value, ...rest }) {
  return (
    <Item {...rest}>
      <a href={`tel:${value}`}>{value}</a>
    </Item>
  );
}

Phone.propTypes = {
  value: PropTypes.string
};

const options = {
  value: 'phone',
  get label() {
    return _t('::Phone');
  },
  component: Phone,
  compareComponent: StringCompare,
  parseValueToQuery: parseValueToQuery.string,
  example: <Phone value={'123 123 123'} />
};

export default options;
