import * as displayAs from './displayAs';
import * as editAs from './editAs';
import * as additionalOptions from './additionalOptions';
import makeType from '../../makeType';

const file = makeType({
  name: 'File',
  label: '::File',
  displayAs,
  editAs,
  additionalOptions
});

export default file;
