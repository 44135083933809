import React, { useState } from 'react';
import { Layout } from 'antd';
import MenuContent from './MenuContent';

const { Sider } = Layout;

function AppMenu() {
  const [collapsed, setCollapse] = useState(false);
  return (
    <Sider className="main-menu" collapsible collapsed={collapsed} onCollapse={setCollapse}>
      <MenuContent />
    </Sider>
  );
}

export default AppMenu;
