import React from 'react';
import { SFNumber } from 'rosis-setting-form';
import { _t } from 'rosis-translation';

function NumberOfCharacters(props) {
  return <SFNumber {...props} min={0} step={1} />;
}

const max = {
  get label() {
    return _t('::Number of characters');
  },
  Field: NumberOfCharacters,
  name: 'length',
  order: 10
};

export default max;
