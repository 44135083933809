import React from 'react';
import PropTypes from 'prop-types';
import { datetime } from 'rosis-date-picker';
import { _t } from 'rosis-translation';
import { makeToText } from 'make-to-text';
import Number from '../../../utils/compare/Number';
import parseValueToQuery from '../../../utils/parseValue';
import Item from '../../../Item';

const toText = makeToText('Date', 'age', { _t });
function Age({ value, ...rest }) {
  return <Item {...rest}>{toText(value)}</Item>;
}

Age.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
};

const options = {
  value: 'age',
  get label() {
    return _t('::Age');
  },
  toText,
  component: Age,
  compareComponent: Number,
  parseValueToQuery: parseValueToQuery.age,
  example: <Age value={datetime('1995-11-25')} />
};

export default options;
