import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'rosis-components/navigation-wrapper';
import { useQueryClient } from 'react-query';
import { Card, Typography, Skeleton, Button, Spin, Dropdown } from 'antd';
import { withLayout } from 'layout';
import { SFInput, SFSelect, SFForm, SFCheckbox } from 'rosis-setting-form';
import { fieldTypes, isRelativeValuePattern } from 'rosis-field-types';
import { datetime } from 'rosis-date-picker';
import { quadDot } from 'system/symbol';
import Conditions from 'components/Conditions';
import AdditionalOptions from './AdditionalOptions';
import DisplayOrEditAs from './DisplayOrEditAs';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import { useSchema, useCreateField, useUpdateField, useConfig } from 'rosis-api';
import { _t } from 'rosis-translation';
import { prepareAdditionalOptions } from './utils';
import RequiredField from './RequiredField';
import Registration from './Registration';
import DefaultValue from './DefaultValue';
import './FieldForm.less';
import { useBreadcrumbs } from 'rosis-contexts/breadcrumbs';
import { usePageHeader } from 'contexts/pageHeader';
import TitleActions from 'components/TitleActions';
import { useChange } from 'contexts/change';
import PageTitle from 'components/PageTitle';
import { calcPageTitle } from 'src/utils/calcPageTitle';
import { useNavigation } from 'router/links';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { WarningOutlined } from '@ant-design/icons';

const { Title } = Typography;

const formatFields = (obj) => {
  if (!obj) return null;
  const res = { ...obj };
  const parse = (value, type) => {
    switch (type) {
      case 'Number':
        return new Number(value);
      case 'Date':
        return value && !isRelativeValuePattern.test(value) ? datetime(value) : value;
      case 'Array':
        value = value.map((option) => option.split(quadDot).pop());
        return value.join('\n');
      default:
        return value;
    }
  };
  const typeSettings = fieldTypes[obj.type];
  for (const option of typeSettings.additionalOptions) {
    const { name, valueType } = option;
    res[name] = parse(res[name], valueType);
  }
  return res;
};

function FieldForm() {
  const { name, fieldName } = useParams();
  const navigation = useNavigation();
  const { showInfoBar } = useInfoBar();
  const queryClient = useQueryClient();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { data: queryManagementEnabled } = useConfig('conf', 'queryManagementEnabled', 'bool');
  const isNewField = fieldName === 'create';
  const { data: schema, refetch, dataUpdatedAt } = useSchema(name);
  const [isLoading, setLoading] = useState(true);
  const [field, setField] = useState();
  const [submitLabel, setSubmitLabel] = useState();
  const [isCreateAnother, setIsCreateAnother] = useState();
  const [conditions, setConditions] = useState([]);
  const { isChanged, setIsChanged } = useChange();
  const form = useForm({ field });

  const fieldTypesList = Object.values(fieldTypes);
  const options = fieldTypesList.map(({ label, name }) => {
    return { value: name, label };
  });

  const handleAddConditions = (value) => {
    setConditions(value);
    setIsChanged(true);
  };

  useEffect(() => {
    if (schema) {
      const { fields } = schema;
      const field = fields[fieldName];
      if (field?.hint?.includes(quadDot)) {
        field.hint = field?.hint?.split(quadDot)[1];
      }

      const formattedFields = formatFields(field) || { monitorable: true };

      setField(formattedFields);
      form.reset(formattedFields);
      setConditions(JSON.parse(field?.conditions || '[]'));
      setLoading(false);
      setIsChanged(false);
    }
  }, [dataUpdatedAt]);

  const {
    isLoading: isCreating,
    isSuccess: isCreated,
    error: createError,
    mutate: create,
    reset: resetCreate
  } = useCreateField(queryClient, name);
  const {
    isLoading: isUpdating,
    isSuccess: isUpdated,
    error: updateError,
    mutate: update
  } = useUpdateField(queryClient, name, fieldName, field?._order);

  useEffect(() => {
    if (isCreated || isUpdated) {
      setIsChanged(false);
      const messageKey = isCreated
        ? 'success::Field {fieldLabel} added'
        : 'success::Field {fieldLabel} updated';
      const infoBarMessage = {
        message: _t(messageKey, { fieldLabel: submitLabel }),
        type: 'success'
      };
      const options = { state: { infoBarMessage } };

      if (isUpdated) {
        navigation.forced.sectionDetails(name, options);
      } else if (isCreated && !isCreateAnother) {
        navigation.forced.sectionDetailsGroup(name, 'privileges', options);
      } else {
        showInfoBar(infoBarMessage);
        setIsCreateAnother(false);
        resetCreate();
        form.reset({});
      }
    }
  }, [name, submitLabel, isCreated, isUpdated]);

  useEffect(() => {
    if (schema) {
      const { extra } = schema;
      setBreadcrumbs([
        {
          label: '',
          link: '/'
        },
        {
          label: _t('::Sections'),
          link: '/sections'
        },
        {
          label: _t(extra.label),
          link: `/sections/${name}`
        },
        isNewField
          ? {
              label: _t('::Add new field'),
              link: `/sections/${name}/field/create`
            }
          : {
              label: _t(field?.label),
              link: `/sections/${name}/field/${fieldName}`
            }
      ]);
    }
  }, [schema, fieldName, field]);

  useEffect(() => {
    if (createError) {
      showInfoBar({
        message: _t(createError?.response?.data || 'Error::Unsuccessful create'),
        type: 'error'
      });
    }
    if (updateError) {
      showInfoBar({
        message: _t(updateError?.response?.data || 'Error::Unsuccessful update'),
        type: 'error'
      });
    }
  }, [createError, updateError]);

  const prepareConditions = (conditions) => {
    conditions = conditions.filter(([, field]) => field);
    return conditions?.length ? JSON.stringify(conditions) : null;
  };
  const prepareResult = (data) => {
    const result = {
      ...data,
      ...prepareAdditionalOptions(data)
    };
    result.conditions = prepareConditions(conditions);
    if (isNewField) {
      const maxOrder = Object.values(schema.fields).reduce(
        (res, fld) => (fld._order > res ? fld._order : res),
        -1
      );
      result._order = maxOrder + 1;
    }

    return result;
  };

  const onSubmit = (result) => {
    if (!(isCreating || isUpdating)) {
      result = prepareResult(result);
      if (isNewField) {
        setSubmitLabel(result.label);
        create(result);
      } else {
        setSubmitLabel(_t(field.label));
        update(result);
      }
    }
  };

  const createAnother = (result) => {
    setIsCreateAnother(true);
    onSubmit(result);
  };

  const cancelEdition = () => {
    if (!isNewField) {
      refetch();
    } else {
      navigation.sectionDetails(name);
    }
  };

  const title = calcPageTitle(_t(schema?.extra?.label) || '', _t(field?.label));

  const items = [
    {
      label: _t('button,store::Save and create another'),
      key: 'crete',
      onClick: form.handleSubmit((result) => createAnother(result))
    }
  ];

  const actions = useMemo(
    () => [
      isNewField || isChanged ? (
        <Button
          key="cancel"
          type="button"
          icon={<FontAwesomeIcon icon={faTimes} />}
          onClick={cancelEdition}>
          {_t('button::Cancel')}
        </Button>
      ) : null,
      isNewField ? (
        <div key="submit">
          <Dropdown.Button
            menu={{
              items
            }}
            icon={<FontAwesomeIcon icon={faChevronDown} />}
            type="primary"
            onClick={form.handleSubmit(onSubmit)}
            loading={isCreating || isUpdating}
            disabled={isCreating || isUpdating}>
            {_t('button,store::Save and assign privileges')}
          </Dropdown.Button>
        </div>
      ) : (
        <Button
          key="submit"
          type="primary"
          form="edit-field-form"
          onClick={form.handleSubmit(onSubmit)}
          loading={isCreating || isUpdating}
          disabled={isCreating || isUpdating}
          icon={<FontAwesomeIcon icon={faSave} />}>
          {_t('button,store::Save')}
        </Button>
      )
    ],
    [form, conditions]
  );

  usePageHeader(
    <TitleActions actions={actions}>
      {schema ? (
        <Title style={{ marginBlockStart: 0 }} level={3}>
          {title}
        </Title>
      ) : (
        <Spin />
      )}
    </TitleActions>,
    [schema, actions, title]
  );

  return (
    <>
      <PageTitle title={title} />
      <Card className="field-form">
        {isLoading ? (
          <Skeleton loading={isLoading} active />
        ) : (
          <SFForm
            id="edit-field-form"
            form={form}
            onSubmit={onSubmit}
            onChange={() => setIsChanged(true)}>
            {isNewField ? (
              <SFInput className="field-form__label" label={_t('::Label')} name="label" required />
            ) : (
              <input name="label" required type="hidden" />
            )}
            {isNewField ? (
              <SFSelect
                className="field-form__type"
                label={_t('kind::Type')}
                name="type"
                options={options}
                required
              />
            ) : (
              <div className="field-form__type">
                <div className="field-form__header">{_t('kind::Type')}</div>
                {_t(fieldTypes[field.type]?.label)}
                <input name="type" required type="hidden" />
              </div>
            )}
            <div className="field-form__properties field-form__header">{_t('::Properties')}</div>
            <DisplayOrEditAs
              className="field-form__display-as"
              name="displayAs"
              initialValue={field?.displayAs}
            />
            <DisplayOrEditAs
              className="field-form__edit-as"
              name="editAs"
              initialValue={field?.editAs}
            />
            <RequiredField className="field-form__required" />
            <Registration className="field-form__registration" isPerson={schema.extra?.person} />
            {queryManagementEnabled ? (
              <SFCheckbox
                className="field-form__monitorable"
                label={_t('field-property::Allowed for monitoring')}
                name="monitorable"
              />
            ) : null}
            {queryManagementEnabled ? (
              <SFCheckbox
                className="field-form__monitoring-status"
                label={_t('field-property::Allow changing of monitoring status')}
                name="monitoringStatus"
              />
            ) : null}
            <DefaultValue className="field-form__default" name="default" conditions={conditions} />
            <AdditionalOptions schema={schema} />
            <SFInput className="field-form__hint" label={_t('::Hint')} name="hint" />
            <div className="hint-section-input" style={{ order: 2011 }}>
              <WarningOutlined />
              <small>
                {_t(
                  '::If you change the hint, all its translations will be removed'
                )}
              </small>
            </div>
            <SFCheckbox className="field-form__hide" label={_t('::Hide field')} name="hide" />
            <Conditions
              className="field-form__conditions"
              modelName={name}
              fieldName={fieldName}
              conditions={conditions}
              setConditions={handleAddConditions}
            />
          </SFForm>
        )}
      </Card>
    </>
  );
}

export default withLayout(FieldForm);
