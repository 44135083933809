import React from 'react';
import { _t } from 'rosis-translation';
import Item from '../../../Item';

const Default = (props) => {
  return (
    <Item {...props} className="calculated-field-saving">
      {_t('::Result will be displayed after saving')}
    </Item>
  );
};

const options = {
  value: 'default',
  component: Default,
  default: true
};

export default options;
