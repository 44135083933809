'use strict';

const quadDot = ":".repeat(2)
    , s = 1000
    , m = 60 * s
    , h = 60 * m
    , d = 24 * h
;

module.exports = {
    dbVersion: // LEAVE ALL PREVIOUS VERSIONS DESCRIBED HERE 
        // 1 // 2022-09-11 added new field 'label' to email template model
        // 2 // 2022-10-29 added lang-id to sites; added new array-field 'sites' to model 'user'
        // 3 // 2022-12-06 add _root field to all subframes; remove model 'party'
        // 4 // 2022-12-14 remove 'dynaRoot' flag from stored models (this flag is set in runtime)
        // 5 // 2022-12-19 add rootName field to model 'trail'; reset model 'trail' due to field name change
        // 6 // 2023-01-02 remove existing recaptcha configuration
        // 7 // 2023-01-03 Change query and monitoring status privileges
        // 8 // 2023-02-03 Extend enums in token kind
        // 9 // 2023-02-09 Reset 'user' model after extended enum
        // 10 // 2023-02-13 Remove '*Off' models and collections
        // 11 // 2023-03-13 Email templates reset to default
        // 12 // 2023-03-15 Removed 'No lock' option in user blocking
		// 13 // 2023-04-06 Email templates reset to default
        // 14 // 2023-04-06 Email templates - changing the variable to the logo
        15 // 2023-05-29 Force setup:csvDelimiter to comma
    ,
    quadDot,
    multiSplitter: /\s*[,;]\s*/,
    multiSeparator: ', ',
    excelSafeChar: "'", // Excel saves formulas with this char in front of them
    BOM: '\uFEFF',
    lineEnd: "\r\n",
    cookie: {
        sameSite: 'strict'
    },
    current: "current",
    defSite: 'Earth',
    defLimit: 20,
    modToName: { adm: 'admin', phy: 'doctor' },
    nameToMod: { admin: 'adm', doctor: 'phy' },
    panels: {
        admin: "::Admin panel",
        doctor: "::Physician panel",
    },
    sslCiphers: [
        "ECDHE-RSA-AES256-SHA384",
        "DHE-RSA-AES256-SHA384",
        "ECDHE-RSA-AES256-SHA256",
        "DHE-RSA-AES256-SHA256",
        "ECDHE-RSA-AES128-SHA256",
        "DHE-RSA-AES128-SHA256",
        "HIGH",
        "!aNULL",
        "!eNULL",
        "!EXPORT",
        "!DES",
        "!RC4",
        "!MD5",
        "!PSK",
        "!SRP",
        "!CAMELLIA",
        // "!ECDHE",
    ],
    routesAllowedWhenPasswordExpired: {
        "GET/whoami": true,
        "PUT/user/passwd": true,
        'GET/lang/current': true,
        'GET/value/pub/generatePsw': true,
        'GET/value/pub': true,
        'POST/jwt-refresh': true,
    },
    privileges: {
        dict: {
            "ADD_QUERY": "add_query",
            "ANSWER_QUERY": "answer_query",
            "CLOSE_QUERY": "close_query",
            "CANCEL_QUERY": "cancel_query",
            "MONITORING_STATUS": "monitoring_status",
            "FREEZE_UNFREEZE": "freeze_unfreeze",
            "LOCK_UNLOCK": "lock_unlock"
        },
        role: {
            "add_query": "privileges::Add query",
            "answer_query": "privileges::Answer query",
            "close_query": "privileges::Close query",
            "cancel_query": "privileges::Cancel query",
            "monitoring_status": "privileges::Add monitoring status",
            "freeze_unfreeze": "privileges::Freeze/Unfreeze",
            "lock_unlock": "privileges::Lock/Unlock"
        },
        model: {
            "read": "privileges::Read",
            "create": "privileges::Create",
            "update": "privileges::Update",
            "delete": "privileges::Delete",
            "audit": "privileges::View audit trail"
        },
        field: {
            "read": "privileges::Read",
            "update": "privileges::Update"
        },
        actions: {
            "privilege_granted": "privileges::Privilege granted",
            "privilege_revoked": "privileges::Privilege revoked"
        }
    },
    webStat: { // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
        OK: 200,
        NO_RESPONSE: 204,
        MOVED: 301,
        BAD_REQUEST: 400,
        UNAUTHORIZED: 401,
        FORBIDDEN: 403,
        NOT_FOUND: 404,
        METHOD_NOT_ALLOWED: 405,
        CONFLICT: 409,
        LOCKED: 423,
        TOKEN_EXPIRED: 498,
        ERROR: 500,
        NOT_IMPLEMENTED: 501,
        SERVICE_UNAVAILABLE: 503,
        WEB_SERVER_IS_DOWN: 521,
    },
    mimeTypes: {
        '': "application/octet-stream",
        html: "text/html",
        jpeg: "image/jpeg",
        jpg: "image/jpeg",
        png: "image/png",
        gif: "image/gif",
        ico: "image/x-icon",
        js: "text/javascript",
        css: "text/css",
        ttf: "font/ttf",
        txt: "text/plain",
    },
    defGfx: ['icon', 'logo', 'image'],
    secrets: ["session", "cookie", "jwt", "ref", "encrypt-key", "nonce-script"],
    dateFormats: {
        datetime: "YYYY-MM-DD hh:mm:ss A",
        date: "YYYY-MM-DD",
        month: "YYYY-MM",
        year: "YYYY",
    },
    TU: { s, m, h, d },
    qpinKinds: {
        RESCUE_PIN: 'rescue-pin',
    },
    tokenKinds: {
        ACCOUNT_CONFIRM: "account-confirm",
        PASSWORD_RESET: "password-reset",
        INIT_USER: 'init-user',
    },
    tokenExpiry: {
        "account-confirm": "setup:accountConfirmTtl",
        "password-reset": "setup:passwordResetTtl",
        "init-user": "setup:initUserTtl",
    },
    relativeDateConstraintPattern: /^(-?\d+)([YMDd])$/,
    regexSpecialCharacters: ".+*?^$()[]{}|",
    fieldWithoutPermission: "####",
    confirmationStatuses: {
        pending: {label: "user::Pending"},
        confirmed: {label: "user::Confirmed"},
        "not-needed": {label: "user::Not needed"},
    },
    trail: {
        reasons: {
            "data-entry-error": "data-modification-reason::Data entry error",
            "new-information": "data-modification-reason::New information",
            "per-query-resolution": "data-modification-reason::Per query resolution",
        },
        events: {
            CREATE: "Create",
            EDIT: "Edit",
            DELETE: "Delete",
            RESTORE: "Restore",
        },
    },
    queryMonitor: {
        changeReasons: ["per-query-resolution"],
        queryStages: {
            doctorActionPending: "doctor-action-pending",
            monitorActionPending: "monitor-action-pending",
            completed: "completed",
        },
        clinicalMonitoringStatusPrefix: "clinical-monitoring-status",
    },
    queryStatuses: {
        open: {
            label: "query-status::Open",
            actionLabel: "query-action::Query",
            requiredMessage: true,
            stage: "doctor-action-pending",
            permission: "add_query",
        },
        requeried: {
            label: "query-status::Re-queried",
            actionLabel: "query-action::Requery",
            requiredMessage: true,
            stage: "doctor-action-pending",
            permission: "add_query",
        },
        answered: {
            label: "query-status::Answered",
            actionLabel: "query-action::Answer",
            requiredMessage: true,
            stage: "monitor-action-pending",
            permission: "answer_query",
        },
        cancelled: {
            label: "query-status::Cancelled",
            actionLabel: "query-action::Cancel Query",
            stage: "completed",
            permission: "cancel_query",
        },
        closed: {
            label: "query-status::Closed",
            actionLabel: "query-action::Close Query",
            stage: "completed",
            permission: "close_query",
        },
    },
    queryFieldsLabels: {
        ident: {
            label: "qry-details-report::Thread ID",
            visible: true,
            reportOrder: 1,
        },
        idStr: {
            label: "qry-details-report::Patient ID",
            visible: false,
            reportOrder: 3,
        },
        frameName: {
            label: "qry-details-report::Patient",
            visible: true,
            reportOrder: 5,
        },
        site: {
            label: "qry-details-report::Site",
            type: "Array",
        },
        sites: {
            label: "qry-details-report::Site",
            type: "Array",
            visible: true,
            reportOrder: 4,
        },
        stat: {
            label: "qry-details-report::Query status",
            type: "Status",
            visible: true,
            reportOrder: 7,
            horizontal: "center",
        },
        daysOpen: {
            label: "qry-details-report::Days open",
            visible: false,
        },
        started: {
            label: "qry-details-report::Created",
            type: "Date",
            visible: true,
            reportOrder: 8,
            horizontal: "center",
        },
        starterRole: {
            label: "qry-details-report::Role",
            type: "String",
            visible: true
        },
        finished: {
            label: "qry-details-report::Updated",
            type: "Date",
            visible: false,
        },
        frame: {
            label: "qry-details-report::Section",
            visible: true,
            reportOrder: 2,
        },
        field: {
            label: "qry-details-report::Field name",
            visible: true,
            reportOrder: 6,
        },
        starter: {
            label: "qry-details-report::Query open by",
            visible: true,
            reportOrder: 9,
        },
        text: {
            label: "qry-details-report::Query Text",
            type: "Conversation",
            visible: true,
            reportOrder: 10,
            fixedWidth: 65,
        },
        daysToAnswer: {
            label: "qry-details-report::Days to Answered",
            visible: true,
            reportOrder: 11,
            type: "Integer",
            horizontal: 'center',
            fixedWidth: 12,
        },
        daysToClosed: {
            label: "qry-details-report::Days to Closed",
            visible: true,
            reportOrder: 12,
            type: "Integer",
            horizontal: 'center',
            fixedWidth: 12,
        },
        daysToCancelled: {
            label: "qry-details-report::Days to Cancelled",
            visible: true,
            reportOrder: 13,
            type: "Integer",
            horizontal: 'center',
            fixedWidth: 12,
        },
    },
    apiTimeout: 5000,
    emailEditorUrl: 'https://editor.unlayer.com'
};
