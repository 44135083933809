import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { Upload, Button, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { UploadOutlined } from '@ant-design/icons';
import Item from '../../../Item';

function UploadButton(props) {
  const { value, name, extensions, form, useFileInfo } = props;
  const fileId = value && typeof value === 'string' ? value : null;
  const { data, isLoading } = useFileInfo(fileId, { enabled: !!fileId }) || {};
  const [file, setFile] = useState(data);

  useEffect(() => {
    setFile(data);
  }, [data]);

  const handleChange = ({ file }) => {
    setFile(file);
    const isInvalidExtension =
      file && extensions && !extensions.includes(getFileExtension(file.name));
    if (isInvalidExtension) {
      file.uploadError = 'invalid-extension';
    }
    form.setFieldsValue({ [name]: file });
  };
  const handleRemove = () => {
    setFile(null);
    form.setFieldsValue({ [name]: null });
  };

  const getFileExtension = (fullName) => {
    if (!fullName) {
      return fullName;
    }
    const lastDotIndex = fullName.lastIndexOf('.');
    if (lastDotIndex <= 0) {
      // 0 included because dot on first index is not start of the extension
      return '';
    }
    return fullName.slice(lastDotIndex + 1).toLowerCase();
  };

  return (
    <Item {...props}>
      {isLoading && <Spin />}
      {!isLoading && file && (
        <div className="file-list">
          <div className="file-list__file-element">
            <span>{file.name || file.filename}</span>{' '}
            <Button type="link" onClick={handleRemove}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        </div>
      )}
      {!isLoading && !file && (
        <Upload
          maxCount={1}
          beforeUpload={() => false}
          showUploadList={false}
          onChange={handleChange}
          accept={extensions?.map((ext) => `.${ext}`).join(', ')}>
          <Button icon={<UploadOutlined />}>{_t('button::Upload')}</Button>
        </Upload>
      )}
    </Item>
  );
}

UploadButton.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  extensions: PropTypes.array,
  form: PropTypes.object,
  useFileInfo: PropTypes.func
};

UploadButton.defaultProps = {
  useFileInfo: () => {}
};

const options = {
  value: 'button',
  get label() {
    return _t('edit-as::Button');
  },
  component: UploadButton,
  example: <UploadButton />,
  default: true
};

export default options;
