import React from 'react';
import { _t } from 'rosis-translation';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

function WarningInfoLang({ lang, missingTranslations }) {
  const displayWarning = missingTranslations && missingTranslations[lang];

  return (
    <>
      {displayWarning ? (
        <Tooltip title={_t('::Some phrases need to be translated')}>
          <FontAwesomeIcon
            icon={faInfoCircle}
            color="#EA8A22"
            style={{ marginInlineEnd: '58px' }}
          />
        </Tooltip>
      ) : null}
    </>
  );
}

WarningInfoLang.propTypes = {
  lang: PropTypes.string,
  missingTranslations: PropTypes.object
};

WarningInfoLang.defaultProps = {
  missingTranslations: {}
};

export default WarningInfoLang;
