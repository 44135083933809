import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputNumber } from 'antd';

function PinCodeInputElement({
  index,
  refProp,
  value,
  autoFocus,
  onChange,
  changeFocus,
  changeValue,
  blur
}) {
  const [current, setCurrent] = useState(value);

  useEffect(() => setCurrent(value), [value]);

  const stopEvent = (event) => {
    event.returnValue = false;
    if (event.preventDefault) event.preventDefault();
  };

  const handleFocus = () => {
    setCurrent('');
  };
  const handleBlur = () => {
    setCurrent(value);
  };
  const handleKeyPress = (e) => {
    validate(e, e.key);
  };
  const handlePaste = (e) => {
    const value = window.event.clipboardData.getData('text/plain');
    validate(e, value, () => {
      onChange(value);
      stopEvent(e);
    });
  };
  const handleKeyDown = (e) => {
    if (['Backspace', 'Delete'].includes(e.key)) {
      if (index > 0) {
        changeFocus(index - 1);
      } else {
        blur(index);
      }
      changeValue(index, null);
      setCurrent(null);
    }
  };
  const validate = (event, value, callback) => {
    const regex = /[0-9]|\./;
    if (!regex.test(value)) {
      stopEvent(event);
    } else {
      callback && callback();
    }
  };
  return (
    <InputNumber
      className="pin-code__input-code-element"
      value={current}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      min={0}
      step={1}
      ref={(el) => {
        if (el) {
          el.onfocus = handleFocus;
          el.onblur = handleBlur;
          el.onkeypress = handleKeyPress;
          el.onpaste = handlePaste;
          el.onkeydown = handleKeyDown;
        }

        return (refProp.current[index] = el);
      }}
      onChange={onChange}
      stringMode
      keyboard={false}
    />
  );
}

PinCodeInputElement.propTypes = {
  index: PropTypes.number.isRequired,
  refProp: PropTypes.shape({ current: PropTypes.arrayOf(PropTypes.instanceOf(Element)) })
    .isRequired,
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  changeFocus: PropTypes.func,
  changeValue: PropTypes.func,
  blur: PropTypes.func
};

export default PinCodeInputElement;
