import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { _t } from 'rosis-translation';
import { usePing } from 'rosis-api';
import { useAuth } from 'rosis-contexts/auth';
import './ExpireModal.less';

function ExpireModal({ expiryTime }) {
  const { logout } = useAuth();
  const { mutate: ping } = usePing();

  const isOpen = useMemo(() => expiryTime && expiryTime <= 30 && expiryTime >= 0, [expiryTime]);

  return (
    <Modal
      className="expire-modal"
      wrapClassName="expire-modal__wrapper"
      open={isOpen}
      mask={false}
      closable={false}
      footer={[
        <Button key="sing-out" onClick={() => logout()}>
          {_t('::Sign out')}
        </Button>,
        <Button key="stay" onClick={() => ping()} type="primary">
          {_t('::Stay signed in ({time})', { time: expiryTime || 0 })}
        </Button>
      ]}>
      <div className="expire-modal__title">{_t('::Your session is about to expire')}</div>
      <div className="expire-modal__desc">
        {_t(
          "::You're being locked out due to inactivity. Please choose to stay signed in or to sign out. Otherwise, you will signed out automatically."
        )}
      </div>
    </Modal>
  );
}

ExpireModal.propTypes = {
  expiryTime: PropTypes.number
};

export default ExpireModal;
