import React from 'react';
import { withNotLoggedLayout } from 'layout';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'rosis-contexts/auth';
import AppLoading from 'rosis-components/AppLoading';
import PinCodePanel from 'rosis-components/PinCodePanel';
import { useConfigs } from 'rosis-api';
import './PinCode.less';

function PinCode() {
  const { isLoggedUser } = useAuth();
  const { data: config, isLoading } = useConfigs('opengraph');
  const image = config?.['doctor-image'] || config?.image;
  const title = config?.['doctor-title'] || config?.title;

  if (isLoggedUser) {
    return <Navigate to="/" />;
  }

  return isLoading ? (
    <AppLoading />
  ) : (
    <PinCodePanel title={title} image={image} loginLink="/login" />
  );
}

export default withNotLoggedLayout(PinCode);
