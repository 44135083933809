import { _t } from 'rosis-translation';
import { makeToText } from 'make-to-text';

const parseToText = (value, options = {}) => {
  const { type, displayAs } = options;
  const toText = makeToText(type, displayAs, { _t });
  return toText(value, options);
};
const makeType = ({ name, label, displayAs, editAs, additionalOptions }) => ({
  name: name,
  get label() {
    return _t(label);
  },
  displayAs: displayAs ? Object.values(displayAs).reverse() : [],
  editAs: editAs ? Object.values(editAs).reverse() : [],
  additionalOptions: additionalOptions ? Object.values(additionalOptions).reverse() : [],
  toText: parseToText
});

export default makeType;
