import React from 'react';
import ConfigValuePanel from './ConfigValuePanel';
import useValidation from './useValidation';

function ConfigSectionPanel({ settings, values, errors, dataUpdatedAt, onErrorsChange, onChange }) {
  const { performValidation } = useValidation({ settings, errors, onErrorsChange });
  const change = (name, value, group, isUserChange) => {
    onChange(name, value, group, isUserChange);
    performValidation(name, value);
  };

  return settings.map(
    ({
      name,
      label,
      hidden,
      description,
      kind,
      showAs,
      group,
      editorProps,
      options,
      CustomComponent,
      subsettings
    }) =>
      subsettings ? (
        CustomComponent ? (
          <CustomComponent
            key={`${group}:${name}`}
            settings={subsettings}
            values={values}
            dataUpdatedAt={dataUpdatedAt}
            errors={errors}
            onErrorsChange={onErrorsChange}
            onChange={change}
            label={label}
            {...editorProps}
          />
        ) : (
          <div key={`${group}:${name}`}>
            <h3>{label}</h3>
            <ConfigSectionPanel
              settings={subsettings}
              values={values}
              dataUpdatedAt={dataUpdatedAt}
              errors={errors}
              onChange={onChange}
              onErrorsChange={onErrorsChange}
            />
          </div>
        )
      ) : !hidden ? (
        <ConfigValuePanel
          key={`${group}:${name}`}
          name={name}
          label={label}
          description={description}
          value={values?.[group]?.[name]}
          dataUpdatedAt={dataUpdatedAt}
          kind={kind}
          group={group}
          error={errors[name]}
          showAs={showAs}
          options={options}
          editorProps={editorProps}
          CustomComponent={CustomComponent}
          onChange={change}
        />
      ) : null
  );
}

export default ConfigSectionPanel;
