import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

const String = React.forwardRef(({ val, onChange, onOk }, ref) => {
  const handleChange = ({ target }) => onChange(target.value);

  return <Input onChange={handleChange} onPressEnter={onOk} value={val} ref={ref} />;
});

String.displayName = 'String';

String.propTypes = {
  val: PropTypes.string,
  onChange: PropTypes.func,
  onOk: PropTypes.func
};

export default String;
