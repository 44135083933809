import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { getFieldsList } from './utils';
import { useCallback } from 'react';

const formatDisplayName = (fields, val) => {
  return val;
};

const parseDisplayName = (fields, val) => {
  return val;
};

const useDisplayName = ({ extra, fields, onDisplayChange }) => {
  const [displayName, setDisplayName] = useState(extra.display);
  const [displayNameText, setDisplayNameText] = useState(formatDisplayName(fields, extra.display));
  const [fieldsList, setFieldsList] = useState([]);

  const debounceChange = useCallback(
    debounce((val) => {
      onDisplayChange(val);
    }, 300),
    []
  );

  useEffect(() => {
    if (!fields) {
      return;
    }
    const newFieldsList = getFieldsList(fields);
    setFieldsList(newFieldsList);
  }, [fields]);

  const makeNewDisplayName = (name, positions) => {
    const addedValue = `{${name}}`;
    if (displayName) {
      if (positions) {
        const [start, end] = positions;
        const newDisplayName = displayName.slice(0, start) + displayName.slice(end);
        const substringBeforeCursorPosition = newDisplayName.slice(0, start);
        const substringAfterCursorPosition = newDisplayName.slice(start);
        return `${substringBeforeCursorPosition}${addedValue}${substringAfterCursorPosition}`;
      } else {
        return `${displayName} ${addedValue}`;
      }
    } else {
      return addedValue;
    }
  };

  const addField = (name, positions) => {
    const newValue = makeNewDisplayName(name, positions);
    setDisplayName(newValue);
    setDisplayNameText(formatDisplayName(fields, newValue));
    onDisplayChange(newValue);
  };

  const changeDisplayName = (val) => {
    const parsedValue = parseDisplayName(fields, val);
    setDisplayName(parsedValue);
    setDisplayNameText(val);
    debounceChange(parsedValue);
  };

  return {
    fieldsList,
    displayName,
    displayNameText,
    addField,
    changeDisplayName
  };
};

export default useDisplayName;
