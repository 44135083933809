import React from 'react';
import PropTypes from 'prop-types';
import { Result, Card, Typography, Row, Col } from 'antd';
import { useQueryClient } from 'react-query';
import { _t } from 'rosis-translation';
import { useConfigs, apiUrl, useConfirmEmailToken, useCheckToken, useRegex } from 'rosis-api';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';
import PasswordForm from 'rosis-components/PasswordScreens/PasswordForm';
import { useImageTitleCfg } from '../hooks/useImageTitleCfg';
import ConfirmationResultSuccess from './ConfirmationResultSuccess';
import './ConfirmEmail.less';

const { Title } = Typography;

function ConfirmEmailWithPassword({ hash, configPrefix, loginPath, isPin }) {
  const queryClient = useQueryClient();
  const { image, title } = useImageTitleCfg({ configPrefix });

  const { data: regex } = useRegex();
  const { data: pubConfig } = useConfigs('pub');
  const { data: checkToken, isLoading: isLoadingCheckToken } = useCheckToken({ tokenId: hash });
  const { pswdWidget } = { ...pubConfig };
  const {
    mutate: confirm,
    isSuccess: isConfirmationSuccess,
    isLoading: isConfirmationLoading,
    data: confirmationResponse,
    ...confirmationStatus
  } = useConfirmEmailToken(queryClient);

  useApiFeedback({
    status: {
      isLoading: isConfirmationLoading,
      isSuccess: isConfirmationSuccess,
      ...confirmationStatus
    },
    errorMessage: _t('error::There was an error during account confirmation'),
    silentSuccess: true
  });

  const submit = (values) => {
    confirm({
      tokenId: hash,
      password: values.password,
      secretQuestions: values?.secretQuestions
    });
  };

  return (
    <div
      className="confirm-email"
      style={
        image
          ? {
              backgroundImage: `url("${apiUrl}${image}")`
            }
          : {}
      }>
      <Card className="card">
        <Title className="title" level={3}>
          {title}
        </Title>
        <Row>
          <Col span={24}>
            {isConfirmationSuccess && (
              <ConfirmationResultSuccess
                isPin={isPin}
                isUserEnabled={!!confirmationResponse?.data?.enabled}
                loginPath={loginPath}
              />
            )}
            {!checkToken && !isLoadingCheckToken ? (
              <Result
                status={'error'}
                title={_t('::This link expired')}
                subTitle={_t('::Contact system administrator for new link')}></Result>
            ) : null}
            {checkToken && !isConfirmationSuccess && (
              <>
                <Row>
                  <Col span={24} className="disclaimer">
                    {isPin
                      ? _t('::Set a new secret question and password')
                      : _t('::To start using application you need to set-up your initial password')}
                  </Col>
                </Row>
                <PasswordForm
                  onFormSubmit={submit}
                  showPasswordChecklist={pswdWidget === 'yes'}
                  passwordValidationPattern={regex}
                  isSubmitInProgress={isConfirmationLoading}
                  isSecretQuestionVisible={true}
                />
              </>
            )}
          </Col>
        </Row>
      </Card>
    </div>
  );
}

ConfirmEmailWithPassword.propTypes = {
  hash: PropTypes.string.isRequired,
  isPin: PropTypes.bool.isRequired,
  configPrefix: PropTypes.string.isRequired,
  loginPath: PropTypes.string.isRequired
};

export default ConfirmEmailWithPassword;
