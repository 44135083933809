import React from 'react';
import { _t } from 'rosis-translation';
import { useFormContext } from 'react-hook-form';
import { datetime } from 'rosis-date-picker';
import { calcConstraintValue } from '../../../utils';
import RelativeOrFixedDate from '../../../utils/RelativeOrFixedDate';

function Max(props) {
  const { watch } = useFormContext();
  const min = watch('min');
  return <RelativeOrFixedDate {...props} min={min} roundUp />;
}

const validate = (val, max) => {
  if (val === null || val === undefined) {
    return null;
  }
  if (max === null || max === undefined) {
    return null;
  }

  if (datetime(val).isAfter(calcConstraintValue(max))) {
    return _t('field_definition::Value must be lower than Max value', { max });
  }
};

const maxDate = {
  name: 'max',
  get label() {
    return _t('::Max');
  },
  Field: Max,
  valueType: 'Date',
  order: 1,
  validate
};

export default maxDate;
