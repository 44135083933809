import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Select, Input, message, Form } from 'antd';
import { _t } from 'rosis-translation';
import { stdKeys } from 'system/helper/trans-key';
import { useCreateLang } from 'rosis-api';
import { useQueryClient } from 'react-query';
import langList from 'src/assets/langList.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import filterBy from 'system/helper/filter-by';
import PropTypes from 'prop-types';
import { useNavigation } from 'router/links';

const { Option } = Select;

function AddNewLang({ existingLangs }) {
  const queryClient = useQueryClient();
  const navigation = useNavigation();
  const [visible, setVisible] = useState(false);
  const [listOfLanguages, setListOfLanguages] = useState([]);
  const {
    mutate: create,
    isLoading: isLoadingCreate,
    isSuccess: isCreated,
    isError: isErrorCreated
  } = useCreateLang(queryClient);

  const [form] = Form.useForm();

  const mainTranslationDefaultLabel = _t('new_language_name_translated_to::Selected language');
  const [mainTranslationLabel, setMainTranslationLabel] = useState(mainTranslationDefaultLabel);

  const langCodeRef = useRef();

  useEffect(() => {
    if (existingLangs) {
      try {
        setListOfLanguages(
          langList.filter((lang) => !existingLangs.map((item) => item.id).includes(lang.value))
        );
      } catch {
        setListOfLanguages(langList);
      }
    }
  }, [existingLangs]);

  useEffect(() => {
    if (isErrorCreated) {
      message.error(_t('error::Error while adding a new language'));
    }
    if (isCreated) {
      navigation.langDetails(form.getFieldValue('langCode'));
    }
  }, [isCreated, isErrorCreated]);

  useEffect(() => {
    if (visible && langCodeRef) {
      setTimeout(() => {
        langCodeRef.current.focus();
      }, 0);
    }
  }, [visible, langCodeRef]);

  const hideModal = () => {
    setVisible(false);
    form.resetFields();
    setMainTranslationLabel(mainTranslationDefaultLabel);
  };

  const showModal = () => {
    setVisible(true);
  };

  const addLanguage = (values) => {
    const { langCode } = values;
    const langInfo = [{ id: langCode, name: values.translatedName }];
    const otherTranslations = existingLangs.map((lang) => ({
      id: lang.id,
      name: values[`translatedName-${lang.id}`]
    }));
    langInfo.push(...otherTranslations);
    create({ langKey: stdKeys.lang(langCode), langInfo });
  };

  return (
    <>
      <Button type="primary" onClick={showModal} className="lang-btn">
        <FontAwesomeIcon icon={faPlusCircle} /> {_t('button::Language')}
      </Button>
      <Modal
        title={_t('::Add new language')}
        open={visible}
        confirmLoading={isLoadingCreate}
        className="add-new-language-modal"
        onOk={() => {
          form.validateFields().then((values) => {
            addLanguage(values);
          });
        }}
        onCancel={hideModal}
        okText={_t('::Add')}
        cancelText={_t('::Cancel')}>
        <Form
          form={form}
          name="langForm"
          autoComplete="off"
          labelCol={{ span: 12 }}
          onValuesChange={(changedValues, values) => {
            if (Object.keys(changedValues).includes('langCode')) {
              const { langCode } = changedValues;
              const languageData = listOfLanguages.find((l) => l.value === langCode);
              const label = languageData ? languageData.name : mainTranslationDefaultLabel;
              setMainTranslationLabel(label);
              const nonNameValues = Object.fromEntries(
                Object.entries(values).map(([key, value]) =>
                  !key.startsWith('translatedName') ? [key, value] : [key, '']
                )
              );
              form.setFieldsValue(nonNameValues); //clear translated name values
            }
          }}>
          <Form.Item
            name="langCode"
            rules={[{ required: true, message: _t('error::Language code is required') }]}>
            <Select
              showSearch
              style={{ width: '100%' }}
              ref={langCodeRef}
              placeholder={_t('::Select language')}
              optionFilterProp="children"
              filterOption={filterBy.label}>
              {listOfLanguages.map((lang) => (
                <Option key={lang.value} value={lang.value}>
                  {`${lang.value} - ${lang.name}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <h3>{_t('::Language name translated to...')}</h3>
          <div className="translated-names-panel">
            <Form.Item
              name="translatedName"
              label={mainTranslationLabel}
              rules={[
                { required: true, message: _t('error::Translated language name is required') }
              ]}>
              <Input />
            </Form.Item>
            {existingLangs &&
              existingLangs.map((lang) => (
                <Form.Item
                  key={`translatedName-${lang.id}`}
                  name={`translatedName-${lang.id}`}
                  label={lang.title}
                  rules={[
                    { required: true, message: _t('error::Translated language name is required') }
                  ]}>
                  <Input />
                </Form.Item>
              ))}
          </div>
        </Form>
      </Modal>
    </>
  );
}

AddNewLang.propTypes = {
  existingLangs: PropTypes.array
};

export default AddNewLang;
