import React, { createContext, useContext, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { message as messageProvider } from 'antd';

import { useConfig } from 'rosis-api';
import InfoBarAlert from './InfoBarAlert';

export const InfoBarContext = createContext();

export function useInfoBar() {
  const { showInfoBar, hideInfoBar, hideAllInfoBars } = useContext(InfoBarContext);
  return {
    showInfoBar,
    hideInfoBar,
    hideAllInfoBars
  };
}

let counter = 0;

messageProvider.config({
  maxCount: 8
});

export const InfoBarProvider = ({ children }) => {
  const { data: configData, error: configError } = useConfig('pub', 'messageAutoCloseTime');
  const isAutoCloseEnabled = !!configData;
  const autoCloseTime = isAutoCloseEnabled ? Number(configData) : 0;

  useEffect(() => {
    if (configError) {
      console.error(configError);
    }
  }, [configError]);

  const showInfoBar = useCallback(
    ({ type, message }) => {
      const id = ++counter;
      messageProvider.open({
        key: id,
        className: 'info-bar-alert',
        content: (
          <InfoBarAlert type={type} message={message} onClose={() => messageProvider.destroy(id)} />
        ),
        duration: type === 'success' ? autoCloseTime : 0,
        style: { padding: 0 },
        onClick: () => {
          messageProvider.destroy(id);
        }
      });
      return id;
    },
    [autoCloseTime]
  );
  const hideInfoBar = useCallback((messageId) => {
    messageProvider.destroy(messageId);
  }, []);
  const hideAllInfoBars = useCallback(() => {
    messageProvider.destroy();
  }, []);
  return (
    <InfoBarContext.Provider value={{ showInfoBar, hideInfoBar, hideAllInfoBars }}>
      {children}
    </InfoBarContext.Provider>
  );
};

InfoBarProvider.propTypes = {
  children: PropTypes.node.isRequired
};
