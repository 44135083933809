import React, { useEffect, useMemo, useState } from 'react';
import { Card, Form, Button, Row, Col, Typography, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes, faUserCircle, faUserClock } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'rosis-components/navigation-wrapper';
import { _t } from 'rosis-translation';
import { withLayout } from 'layout';
import { useQueryClient } from 'react-query';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import { EditField } from 'rosis-field-types';
import { DisplayField } from 'rosis-field-types';
import { useModelsLabels } from 'rosis-components/ModelLabelsProvider';
import RemoveUser from './RemoveUser';
import RejectUser from 'components/buttons/RejectUser';
import AcceptUser from 'components/buttons/AcceptUser';
import ResetPasswordUser from './ResetPasswordUser';
import {
  useUserDetails,
  useUserSimpleData,
  useUserEdit,
  useFieldsWithRole,
  getNewCounter,
  useMtxForRoleAndModel,
  useCanEdit,
  useConfig
} from 'rosis-api';
import { useAuth } from 'rosis-contexts/auth';
import { useBreadcrumbs } from 'rosis-contexts/breadcrumbs';
import { usePageHeader } from 'contexts/pageHeader';
import TitleActions from 'components/TitleActions';
import AssignSitesToUser from './AssignSitesToUser';
import PageTitle from 'components/PageTitle';
import useIsSitesEditionAvailable from './useIsSitesEditionAvailable';
import { useNavigation } from 'router/links';
import { useChange } from 'contexts/change';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';

const { Title } = Typography;

function EditUser() {
  const queryClient = useQueryClient();
  const { modelLabels } = useModelsLabels();
  const { id, role } = useParams();
  const { isChanged, setIsChanged } = useChange();
  const { data: fieldsInfo } = useFieldsWithRole(role);
  const { fields: fieldsList, extra } = fieldsInfo || {};
  const { data: isSitesEditionAvailable } = useIsSitesEditionAvailable({ extra });
  const { setBreadcrumbs } = useBreadcrumbs();
  const { showInfoBar } = useInfoBar();
  const navigation = useNavigation();
  const { data: removalAllowed } = useConfig('conf', 'removalAllowed', 'bool');

  const { user } = useAuth();
  const { data: mtxData, isLoading: isMtxLoading } = useMtxForRoleAndModel(user?.role, role);
  const permissions = mtxData?.[user?.role]?.[role]?.['*'] || [];
  const canRead = permissions.includes('read');
  const [userId, setUserId] = useState();

  const {
    data: userInfo,
    refetch: refetchUserInfo,
    dataUpdatedAt
  } = useUserDetails({ user: id, model: role }, { enabled: !isMtxLoading && canRead });
  const { data: userBaseInfo } = useUserSimpleData({ id });
  const {
    mutate: edit,
    isLoading: isLoadingEditUser,
    ...editUserStatus
  } = useUserEdit(queryClient);

  const isWaiting =
    userBaseInfo && (userBaseInfo?.enabled === null || userBaseInfo?.enabled === undefined);

  useApiFeedback({
    status: {
      isLoading: isLoadingEditUser,
      ...editUserStatus
    },
    successMessage: _t('success::User edit successfully'),
    errorMessage: _t('error::User edit failed'),
    onSuccess: () => {
      setIsChanged(false);
    }
  });

  const { data: canEditMap } = useCanEdit(role);

  const [personDataForm] = Form.useForm();

  useEffect(() => {
    personDataForm.resetFields();
    setIsChanged(false);
  }, [userInfo, dataUpdatedAt]);

  const [sites, setSites] = useState(null);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: '',
        link: '/'
      },
      {
        label: _t('::Users'),
        link: '/users'
      },
      {
        label: _t(modelLabels?.[role]),
        link: `/users/${role}`
      },
      {
        label: userBaseInfo?.idStr,
        link: `/users/${role}/${id}`
      }
    ]);
  }, [userBaseInfo?.idStr]);

  useEffect(() => {
    if (userBaseInfo) {
      setSites(userBaseInfo?.sites || []);
      setUserId(userBaseInfo?._id);
    }
  }, [userBaseInfo]);

  const saveUser = (values) => {
    const formData = new FormData();
    const editedId = userInfo._id;
    formData.append('_id', editedId);
    for (const field in values) {
      let elementValue = values[field] === undefined ? null : values[field];
      formData.append(field, elementValue);
    }
    edit({ formData, role, editedId });
  };

  const cancelEdition = () => {
    refetchUserInfo();
  };

  const onSuccess = (message) => {
    navigation.usersForRole(role, {
      state: {
        infoBarMessage: {
          message,
          type: 'success'
        }
      }
    });
  };

  const onFail = (message) => {
    showInfoBar({
      message,
      type: 'error'
    });
  };

  const visibleFields = fieldsList
    ? Object.values(fieldsList).filter((current) => !['Link', 'Virt'].includes(current.type))
    : [];

  const isUserProfileEditorVisible = userInfo && !!visibleFields.length;

  const actions = useMemo(() => {
    const result = [];

    if (isWaiting) {
      result.push(
        <RejectUser
          key="reject"
          _id={id}
          type="default"
          title={_t('::Reject')}
          onSuccess={onSuccess}
          onFail={onFail}
        />,
        <AcceptUser
          key="accept"
          type="primary"
          title={_t('::Accept')}
          _id={id}
          onSuccess={onSuccess}
          onFail={onFail}
        />
      );
    } else {
      !removalAllowed ||
        result.push(
          <RemoveUser
            key="remove"
            role={role}
            id={userId}
            disabled={userId === user._id}
            table={false}
            onSuccess={onSuccess}
            onFail={onFail}
          />,
          <ResetPasswordUser key="reset-password" role={role} idStr={userBaseInfo?.idStr} />
        );
    }

    if (isUserProfileEditorVisible && isChanged) {
      result.push(
        <Button
          key="cancel"
          type="button"
          icon={<FontAwesomeIcon icon={faTimes} />}
          onClick={cancelEdition}>
          {_t('button::Cancel')}
        </Button>
      );
    }
    if (isUserProfileEditorVisible && isChanged) {
      result.push(
        <Button
          key="save"
          type="primary"
          htmlType="submit"
          disabled={isLoadingEditUser}
          icon={<FontAwesomeIcon icon={faSave} />}
          onClick={() => personDataForm.submit()}>
          {_t('button,store::Save')}
        </Button>
      );
    }
    return result;
  }, [role, userId, user, isLoadingEditUser, isUserProfileEditorVisible, isChanged, isWaiting]);

  const title = _t('::User info');

  usePageHeader(<TitleActions actions={actions}>{title}</TitleActions>, [actions, title]);

  return (
    <>
      <PageTitle title={title} />
      <Card className="edit-user">
        <Row gutter="16">
          <Col>
            <Title level={3}>
              <FontAwesomeIcon icon={faUserCircle} size="lg" />
              {userBaseInfo?.idStr}
              {isWaiting && (
                <span className="waiting-user">
                  <FontAwesomeIcon icon={faUserClock} />
                  <span>{_t('::Waiting for activation')}</span>
                </span>
              )}
            </Title>
          </Col>
        </Row>
        <Title level={3}>{_t('::Sites')}</Title>
        <Row gutter="16">
          <Col>
            <Space>
              <label>{_t('::Assigned sites:')}</label>
              {sites?.length ? sites.join(', ') : _t('site::No sites')}
              {isSitesEditionAvailable && (
                <AssignSitesToUser key="sites" userId={userId} sites={sites} />
              )}
            </Space>
          </Col>
        </Row>
        {isUserProfileEditorVisible && (
          <>
            <Title level={3}>{_t('::User profile data')}</Title>
            <Row gutter="16">
              <Col md={12}>
                <Form
                  form={personDataForm}
                  scrollToFirstError={{
                    block: 'center'
                  }}
                  onFinish={saveUser}>
                  {visibleFields.map((current) => {
                    const canEdit = canEditMap?.[current.name];
                    return canEdit ? (
                      <EditField
                        key={current.name}
                        value={userInfo[current.name]}
                        {...current}
                        getNewCounter={getNewCounter}
                        form={personDataForm}
                        onChange={() => setIsChanged(true)}
                      />
                    ) : (
                      <DisplayField
                        key={current.name}
                        value={userInfo[current.name]}
                        {...current}
                        getNewCounter={getNewCounter}
                        form={personDataForm}
                      />
                    );
                  })}
                </Form>
              </Col>
            </Row>
          </>
        )}
      </Card>
    </>
  );
}

export default withLayout(EditUser);
