import React, { useEffect } from 'react';
import { _t } from 'rosis-translation';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { Button, Skeleton, Tooltip } from 'antd';
import { _useModels, useResetModel } from 'rosis-api';

function ResetModel({ onSuccess, onFail }) {
  const queryClient = useQueryClient();
  const { data: models, isLoading: isLoadingModels } = _useModels();
  const {
    error: errorResetModel,
    isSuccess: isSuccessResetModel,
    mutate: reset
  } = useResetModel(queryClient);

  useEffect(() => {
    isSuccessResetModel && onSuccess(_t('success::Model has been reset'));
    errorResetModel && onFail(_t(errorResetModel?.response?.data || 'error::Model reset failed'));
  }, [isSuccessResetModel, errorResetModel]);

  return (
    <>
      <div
        className="ant-btn ant-btn-dangerous ant-btn-round"
        style={{ margin: '10px 0', cursor: 'default' }}>
        autoUpdate = true
      </div>
      <p>{_t('::Click the model which you want to reset')}</p>
      {isLoadingModels ? (
        <Skeleton />
      ) : (
        models.map((model) => (
          <Tooltip title={_t(model?.extra?.label) || model.name} key={model.name}>
            <Button
              danger={model?.extra?.autoUpdate}
              shape="round"
              style={{ margin: 10 }}
              onClick={() => reset({ name: model.name })}>
              {model.name}
            </Button>
          </Tooltip>
        ))
      )}
    </>
  );
}

ResetModel.propTypes = {
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
};

export default ResetModel;
