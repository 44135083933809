import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import EnableButton from './EnableButton';
import AddNewQuestion from './AddNewQuestion';
import EditQuestion from './EditQuestion';
import RemoveQuestion from './RemoveQuestion';
import TitleActions from 'components/TitleActions';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePageHeader } from 'contexts/pageHeader';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import { useSecretQuestions, useUpdateQuestion, useConfig } from 'rosis-api';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';
import { _t } from 'rosis-translation';
import { Table, Typography, Button } from 'antd';

const { Title } = Typography;

function SecretQuestionsTab() {
  const queryClient = useQueryClient();
  const { showInfoBar } = useInfoBar();
  const { data: questionsForUser } = useConfig('pub', 'questionsForUser');
  const { mutate: updateQuestions, ...updateQuestionsStatus } = useUpdateQuestion(queryClient);
  const [questions, setQuestions] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [numberOfQuestion, setNumberOfQuestion] = useState(1);
  const [questionExist, setQuestionExist] = useState(false);
  const { data, isLoading } = useSecretQuestions();

  useEffect(() => {
    if (data) {
      setQuestions(data);
    }
  }, [data]);

  useEffect(() => {
    if (questionsForUser) {
      setNumberOfQuestion(parseInt(questionsForUser));
    }
  }, [questionsForUser]);

  useApiFeedback({
    status: updateQuestionsStatus,
    silentProgress: true,
    successMessage: _t('::The secret questions have been saved'),
    errorMessage: _t('fields::Error while saving secret questions'),
    onSuccess: () => setIsChanged(false)
  });

  const onDelete = ({ value, id }) => {
    const newState = id
      ? questions.map((q) => (q._id === id ? { ...q, deleted: true } : q))
      : questions.filter((q) => q.value !== value);
    setQuestions(newState);
    setIsChanged(true);
  };

  const onChange = ({ value, oldValue, id, name }) => {
    const newState = questions.map((q) =>
      q._id === id || q.value === oldValue ? { ...q, [name]: value } : q
    );
    setQuestions(newState);
    setIsChanged(true);
  };

  const addNewQuestion = (value) => {
    setQuestions([value, ...questions]);
    setIsChanged(true);
  };

  function renderEnableSwitch({ _id, enabled }) {
    return <EnableButton id={_id} enabled={enabled} onChange={onChange} />;
  }

  function actionButtons({ _id, value, used }) {
    return (
      <>
        <EditQuestion id={_id} value={value} isUsed={used} onChangeQuestion={onChange} />
        <RemoveQuestion id={_id} value={value} isUsed={used} onRemoveQuestion={onDelete} />
      </>
    );
  }

  const cancelEdition = () => {
    setQuestions([...data]);
    setIsChanged(false);
  };

  const submitEdition = () => {
    const enabledQuestions = questions.filter((question) => question.enabled && !question.deleted);

    if (enabledQuestions.length >= numberOfQuestion) {
      updateQuestions(questions);
      setQuestionExist(false);
    } else {
      showInfoBar({
        message: _t(
          'error::At least {numberOfQuestion} questions must be enabled to save questions',
          {
            numberOfQuestion
          }
        ),
        type: 'error'
      });
    }
  };

  const columns = [
    {
      title: _t('::Question'),
      dataIndex: 'value',
      key: 'value'
    },
    {
      title: _t('::Enabled'),
      key: 'enabled',
      render: renderEnableSwitch
    },
    {
      title: _t('::Actions'),
      key: 'actions',
      render: actionButtons
    }
  ];

  const title = _t('::Secret question');

  usePageHeader(
    <TitleActions
      actions={
        isChanged
          ? [
              <Button
                key="cancel"
                type="button"
                icon={<FontAwesomeIcon icon={faTimes} />}
                onClick={cancelEdition}>
                {_t('button::Cancel')}
              </Button>,
              <Button
                key="submit"
                icon={<FontAwesomeIcon icon={faSave} />}
                type="primary"
                onClick={submitEdition}>
                {_t('button,store::Save')}
              </Button>
            ]
          : []
      }>
      {title}
    </TitleActions>,
    [isChanged, questions, questionExist]
  );

  return (
    <div>
      <Title level={5}>{title}</Title>
      <AddNewQuestion
        questions={questions}
        onAddNewQuestion={addNewQuestion}
        questionExist={questionExist}
        setQuestionExist={setQuestionExist}
      />
      <Table
        columns={columns}
        dataSource={questions.filter((question) => !question.deleted)}
        loading={isLoading}
        pagination={false}
        rowKey="value"
      />
    </div>
  );
}

export default SecretQuestionsTab;
