import React from 'react';
import PropTypes from 'prop-types';
import symbol from 'system/symbol';
import { _t } from 'rosis-translation';

const { panels } = symbol;

function LoginPoint({ name }) {
  const loginPointLabel = panels[name];
  return name ? (
    <div style={{ display: 'none' }}>
      <b>{_t('::Login point')}:</b> {_t(loginPointLabel)}
    </div>
  ) : null;
}

LoginPoint.propTypes = {
  name: PropTypes.string
};

export default LoginPoint;
