import React from 'react';
import PropTypes from 'prop-types';
import DndRow from './DndRow';

function Models({ modelList, extendedModels, handleExtend }) {
  return modelList.map((model, index) => (
    <DndRow
      key={model.name}
      index={index}
      model={model}
      extendedModels={extendedModels}
      handleExtend={handleExtend}
      lastOne={modelList.length - 1 === index}
    />
  ));
}

Models.propTypes = {
  modelList: PropTypes.array,
  extendedModels: PropTypes.array,
  handleExtend: PropTypes.func
};

export default Models;
