import React, { useMemo } from 'react';
import ConfigSettings from 'components/ConfigSettings';
import { ConfigFormControlProvider } from 'components/ConfigSettings/ConfigFormControl';
import ConfigPageHeader from 'components/ConfigSettings/ConfigPageHeader';
import PageTitle from 'components/PageTitle';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { useConfig } from 'rosis-api';
import { queryMonitor } from 'system/symbol';
import { calcSettingPageTitle } from '../utils';
import FieldMonitoringStatuses from './FieldMonitoringStatusesEditor';
import { useChange } from 'contexts/change';

function MainSettings({ title }) {
  const { isChanged, setIsChanged } = useChange();
  const { data: queryManagementEnabled } = useConfig('conf', 'queryManagementEnabled', 'bool');

  const settings = useMemo(
    () =>
      [
        {
          name: 'addNewUser',
          group: 'setup',
          label: _t('::Allow adding users from the admin panel'),
          kind: 'bool'
        },
        {
          name: queryMonitor.clinicalMonitoringStatusPrefix,
          label: _t('::Clinical Monitoring statuses'),
          group: '_lang',
          hidden: !queryManagementEnabled,
          kind: 'custom',
          CustomComponent: FieldMonitoringStatuses
        }
      ].filter((item) => !item.hidden),
    [queryManagementEnabled]
  );

  const pageTitle = calcSettingPageTitle(title);

  return (
    <ConfigFormControlProvider>
      <PageTitle title={pageTitle} />
      <ConfigPageHeader pageTitle={pageTitle} isDirty={isChanged} />
      <ConfigSettings settings={settings} onIsDirtyChange={setIsChanged} />
    </ConfigFormControlProvider>
  );
}

MainSettings.propTypes = {
  title: PropTypes.string
};

export default MainSettings;
