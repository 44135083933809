import React from 'react';
import { _t } from 'rosis-translation';
import { SFSelect } from 'rosis-setting-form';

function ResultType(props) {
  const opts = [
    { value: 'Number', label: _t('::Number') },
    { value: 'String', label: _t('::Text') },
    { value: 'Date', label: _t('::Date') }
  ];

  return <SFSelect {...props} required options={opts} />;
}

const result = {
  get label() {
    return _t('::Result type');
  },
  name: 'resultType',
  Field: ResultType,
  order: -980
};

export default result;
