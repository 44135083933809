import { useQuery, useMutation } from 'react-query';

import { url, getData, del, put, post } from './internal/rest-api';
import { useProjection } from './internal/utils';

export const useSchema = (frame, config = {}) =>
  useQuery(['schema', frame], () => getData(url`schema/${frame}`), {
    enabled: !!frame,
    ...config
  });

export const useFullSchema = (frame, config = {}) =>
  useQuery(['schema', frame], () => getData(url`schema/${frame}/full`, config));

export const useCanRemoveField = (frame) =>
  useQuery(['schema', frame, 'get-nails'], () => getData(url`schema/get-nails/${frame}`));

export const useUpdateExtra = (queryClient, frame) =>
  useMutation((data) => put(url`schema/display/${frame}/${data}`), {
    onSuccess: () => queryClient.invalidateQueries('schema')
  });

export const useUpdateSchema = (queryClient, frame) =>
  useMutation((data) => put(url`schema/extra/${frame}`, data), {
    onSuccess: () => queryClient.invalidateQueries(['schema', frame])
  });

export const useRemoveField = (queryClient, frame) =>
  useMutation((field) => del(url`schema/${frame}/${field}`), {
    onSuccess: () => queryClient.invalidateQueries(['schema', frame])
  });

export const useMoveField = (queryClient, frame) =>
  useMutation(
    ({ name, newIndex }) =>
      put(`schema/set-order`, {
        model: frame,
        field: name,
        order: newIndex
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(['schema', frame])
    }
  );

export const useCreateField = (queryClient, frame) =>
  useMutation((data) => post(url`schema/${frame}`, data), {
    onSuccess: () => queryClient.invalidateQueries(['schema', frame])
  });

export const useUpdateField = (queryClient, frame, field, order) =>
  useMutation((data) => put(url`schema/${frame}`, { ...data, name: field, _order: order }), {
    onSuccess: () => queryClient.invalidateQueries(['schema', frame])
  });

// COMPLEX

export const useField = (frame, field, isNew) => {
  const queryResult = useSchema(frame, { enabled: isNew });
  return useProjection(queryResult, (data) => data.fields[field]);
};

export const useFields = (frame, isNew) => {
  const queryResult = useSchema(frame, { enabled: isNew });
  return useProjection(queryResult, (data) => data.fields || {});
};
