import escapeStringRegexp from 'escape-string-regexp';
import { datetime } from 'rosis-date-picker';
import nullify from '../../../system/helper/nullify';

const parseRange = (values) => {
  const query = {};
  const min = nullify(values?.[0]);
  const max = nullify(values?.[1]);
  if (max === null && min === null) return null;
  min === null || (query['$gte'] = min);
  max === null || (query['$lte'] = max);
  return query;
};
const paresDateRange = (values) => {
  const [min, max] = values || [];
  if (!(max || min)) return null;
  return values.join('..');
};

const parseValueToQuery = {
  normal: (value) => value,
  number: (values) => parseRange(values),
  date: (values) => paresDateRange(values),
  string: (value) => (value ? { $regex: `${escapeStringRegexp(value)}` } : null),
  multi: (values) => (values && values.length ? { $in: values } : null),
  age: (values) => {
    const [min, max] = values || [];
    const maxDate = min ? datetime().subtract(min, 'year').format('YYYY-MM-DD') : null;
    const minDate = max ? datetime().subtract(max, 'year').format('YYYY-MM-DD') : null;
    return paresDateRange([minDate, maxDate]);
  }
};

export default parseValueToQuery;
