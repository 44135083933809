import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigationBlocker } from 'rosis-components/navigation-blocker';

export const ChangeContext = createContext();

export function useChange() {
  const {
    isChanged,
    setIsChanged: setContextIsChanged,
    filters,
    setFilters
  } = useContext(ChangeContext);
  const { setIsBlockadeNeeded } = useNavigationBlocker();

  const setIsChanged = useCallback(
    (val) => {
      setContextIsChanged(val);
      setIsBlockadeNeeded(val);
    },
    [setContextIsChanged, setIsBlockadeNeeded]
  );

  useEffect(() => {
    return () => setIsChanged(false);
  }, []);
  return {
    isChanged,
    setIsChanged,
    filters,
    setFilters
  };
}

export const ChangeProvider = ({ children }) => {
  const [isChanged, setIsChanged] = useState(false);
  const [filters, setFilters] = useState(null);
  return (
    <ChangeContext.Provider value={{ isChanged, setIsChanged, filters, setFilters }}>
      {children}
    </ChangeContext.Provider>
  );
};

ChangeProvider.propTypes = {
  children: PropTypes.node.isRequired
};
