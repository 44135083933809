import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { _t } from 'rosis-translation';

const CollapseHeader = ({ label, mtx }) => {
  const isRead = Array.isArray(mtx) && mtx.indexOf('read') >= 0;

  return (
    <div className="collapse-header">
      {_t(label)}
      {isRead ? (
        <FontAwesomeIcon icon={faCheckCircle} className="permission-icon permission-icon-on" />
      ) : (
        <FontAwesomeIcon icon={faTimesCircle} className="permission-icon permission-icon-off" />
      )}
      <span className="permission-text">{_t('::Read')}</span>
    </div>
  );
};

CollapseHeader.propTypes = {
  label: PropTypes.string,
  mtx: PropTypes.array
};

export default CollapseHeader;
