import React, { useEffect, useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { _t } from 'rosis-translation';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useRemoveSite } from 'rosis-api';

function RemoveSite({ site, disabled, onSuccess, onFail }) {
  const queryClient = useQueryClient();
  const {
    isSuccess: successRemoveSite,
    error: errorRemoveSite,
    isLoading: isLoadingRemoveSite,
    mutate: remove
  } = useRemoveSite(queryClient, site);

  useEffect(() => {
    if (successRemoveSite) {
      onSuccess(_t('success::Site removed'));
    }
    if (errorRemoveSite) {
      onFail(_t(errorRemoveSite?.response?.data || 'error::Site remove failed'));
    }
    hideModal();
  }, [successRemoveSite, errorRemoveSite]);

  const [visibleModal, setVisibleModal] = useState(false);

  const removeSite = () => {
    remove(site);
  };

  const showModal = () => {
    setVisibleModal(true);
  };

  const hideModal = () => {
    setVisibleModal(false);
  };

  return (
    <>
      <Tooltip placement="bottomLeft" title={disabled || _t('site::Remove site')}>
        <Button type="link" disabled={!!disabled || isLoadingRemoveSite}>
          <FontAwesomeIcon icon={faTrash} onClick={showModal} />
        </Button>
      </Tooltip>

      <Modal
        title={_t('site::Remove site?')}
        open={visibleModal}
        onOk={removeSite}
        onCancel={hideModal}
        okText={_t('site::Remove')}
        cancelText={_t('site::Cancel')}>
        <p>{_t('area::Site will be removed')}</p>
      </Modal>
    </>
  );
}

RemoveSite.propTypes = {
  site: PropTypes.string,
  disabled: PropTypes.string,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
};

export default RemoveSite;
