import React, { useEffect, useState, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { Card, Skeleton, Tabs, Button, Modal, Checkbox, Radio, Spin } from 'antd';
import { useQueryClient } from 'react-query';
import { useParams } from 'rosis-components/navigation-wrapper';
import { withLayout } from 'layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import RemoveModel from 'components/RemoveModel';
import RemoveFields from 'components/RemoveFields';
import { Upload, Download } from 'components/buttons';
import Fields from './Fields';
import Translations from './Translations';
import Settings from './Settings';
import TitleActions from 'components/TitleActions';
import AddButton from './AddButton';
import { useSchema, useMoveField, apiUrl } from 'rosis-api';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import { _t } from 'rosis-translation';
import ViewEdit from './View/ViewEdit';
import Privileges from './Privileges';
import './Schema.less';
import { useBreadcrumbs } from 'rosis-contexts/breadcrumbs';
import { usePageHeader } from 'contexts/pageHeader';
import PageTitle from 'components/PageTitle';
import { calcPageTitle } from 'src/utils/calcPageTitle';
import { useNavigation } from 'router/links';

const getFieldsList = (fields) =>
  Object.values(fields)
    .filter((field) => field.type != 'Link')
    .sort((f1, f2) => f1._order - f2._order);

function ExportModal({ visible, setVisible, frameName }) {
  const [withChildren, setWithChildren] = useState(true);
  return (
    <Modal
      open={visible}
      title={_t('export::Download subsection structure')}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="cancel" onClick={() => setVisible(false)} style={{ marginInline: 10 }}>
          {_t('button::Cancel')}
        </Button>,
        <Download
          key="export"
          type="primary"
          onClick={() => setVisible(false)}
          link={`${apiUrl}/frame/download/${withChildren}/${frameName}`}>
          {_t('button::Download')}
        </Download>
      ]}>
      <Checkbox checked={withChildren} onChange={(e) => setWithChildren(e.target.checked)}>
        {_t('export::With subsections')}
      </Checkbox>
    </Modal>
  );
}

ExportModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  frameName: PropTypes.string.isRequired
};

function ImportModal({ visible, setVisible, frameName, refetch }) {
  const { showInfoBar } = useInfoBar();
  const [isMerge, setIsMerge] = useState(true);

  const onSuccess = () => {
    refetch();
    setVisible(false);
    showInfoBar({
      message: _t('info::Upload succeeded'),
      type: 'success'
    });
  };

  const onError = (err, message) => {
    setVisible(false);
    showInfoBar({
      message: _t(message || 'info::Upload failed'),
      type: 'error'
    });
  };

  return (
    <Modal
      open={visible}
      title={_t('::Upload subsection structure')}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="cancel" onClick={() => setVisible(false)} style={{ marginInline: 10 }}>
          {_t('button::Cancel')}
        </Button>,
        <Upload
          key="upload"
          action={`${apiUrl}/frame/upload-branch/${isMerge}/${frameName}`}
          accept=".rbss"
          onSuccess={onSuccess}
          onError={onError}>
          {_t('button::Upload')}
        </Upload>
      ]}>
      <Radio.Group value={isMerge} onChange={(e) => setIsMerge(e.target.value)}>
        <Radio value={true}>{_t('::Upload and merge with the current frame')}</Radio>
        <Radio value={false}>{_t('::Upload into the new child-frame')}</Radio>
      </Radio.Group>
    </Modal>
  );
}

ImportModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  frameName: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired
};

function Schema() {
  const queryClient = useQueryClient();
  const navigation = useNavigation();
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [fieldsList, setFieldsList] = useState([]);
  const { setBreadcrumbs } = useBreadcrumbs();
  const { showInfoBar } = useInfoBar();
  const { name: frameName, tab } = useParams();
  const { isLoading, data: model, refetch } = useSchema(frameName);
  const { fields, extra } = model || {};
  const { mutate: move, error: moveError } = useMoveField(queryClient, frameName);

  useEffect(() => {
    fields && setFieldsList(getFieldsList(fields));
  }, [fields]);

  const onMarkToRemove = (name) => {
    setFieldsList(
      fieldsList.map((field) =>
        field.name === name ? { ...field, selected: !field.selected } : field
      )
    );
  };

  useEffect(() => {
    moveError &&
      showInfoBar({
        message: _t(moveError?.response?.data || 'error::Error while moving'),
        type: 'error'
      });

    fields && setFieldsList(getFieldsList(fields));
  }, [moveError]);

  const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
  };

  const updateOrderFields = (sourceIndex, newIndex) => {
    const { name } = fieldsList[sourceIndex];
    move({ name, newIndex });
    setFieldsList([...reorder(fieldsList, sourceIndex, newIndex)]);
  };

  const onSuccessRemoveModel = (message) => {
    navigation.sections({
      state: {
        infoBarMessage: {
          message,
          type: 'success'
        }
      }
    });
  };

  const fieldsActions = useMemo(
    () =>
      extra
        ? [
            <AddButton key="add" />,
            !extra?.dynaRoot ? null : (
              <Button key="import" onClick={() => setImportModalVisible(true)}>
                <FontAwesomeIcon icon={faFileUpload} />
                &nbsp;
                {_t('button::Upload')}
              </Button>
            ),
            !extra?.dynaRoot ? null : (
              <Button key="export" onClick={() => setExportModalVisible(true)}>
                <FontAwesomeIcon icon={faDownload} />
                &nbsp;
                {_t('button::Download')}
              </Button>
            ),
            <RemoveModel
              key="remove"
              name={frameName}
              label={_t(extra.label)}
              isProtected={extra.protected}
              onSuccess={onSuccessRemoveModel}
              hasSubsections={!!extra.children?.length}
              confirmText={_t('::Confirm removing a section')}
              errorMessages={{
                mainText: _t("::You can't remove this section because:"),
                isProtected: _t('::This section is protected by system'),
                hasSubsections: _t('::This section has subsections'),
                hasElements: _t('::There are elements of this type in the system')
              }}
            />,
            <RemoveFields key="remove-fields" fields={fieldsList} frameName={frameName} />
          ]
        : null,
    [extra, frameName, fieldsList]
  );

  const activeTab = tab || 'fields';

  const actions = activeTab === 'fields' ? fieldsActions : [];

  const tabOptions = {
    fields: {
      label: _t('::Fields'),
      content: (
        <Fields
          frameName={frameName}
          fields={fieldsList}
          updateFields={updateOrderFields}
          onMarkToRemove={onMarkToRemove}
        />
      )
    },
    translations: {
      label: _t('::Translations'),
      content: <Translations frameName={frameName} fields={fieldsList} />
    },
    settings: {
      label: _t('::Settings'),
      content: <Settings frameName={frameName} fields={fieldsList} extra={extra} />
    },
    view: {
      label: _t('::View'),
      content: <ViewEdit frameName={frameName} extra={extra} />
    }
  };

  if (!extra?.person || extra?.dynaRoot) {
    tabOptions.privileges = {
      label: _t('::Privileges'),
      content: <Privileges frameName={frameName} />
    };
  }
  const handleChange = (activeKey) =>
    navigation.sectionDetailsGroup(frameName, activeKey === 'fields' ? '' : activeKey);

  useEffect(() => {
    const breadcrumbs = [
      {
        label: '',
        link: '/'
      },
      {
        label: _t('::Sections'),
        link: '/sections'
      },
      {
        label: _t(extra?.label),
        link: `/sections/${frameName}`
      }
    ];
    if (tab) {
      breadcrumbs.push({
        label: tabOptions[tab]?.label,
        link: `/sections/${frameName}/${tab}`
      });
    }
    setBreadcrumbs(breadcrumbs);
  }, [frameName, model, tab]);

  const title = extra?.label
    ? calcPageTitle(_t(extra?.label), tabOptions[activeTab].label)
    : _t('::Section');

  usePageHeader(
    <TitleActions actions={actions}>
      {extra?.label ? (
        <>
          {_t(extra?.label)}: {tabOptions[activeTab].label}
        </>
      ) : (
        <Spin />
      )}
    </TitleActions>,
    [extra, activeTab, fieldsList]
  );

  const tabs = useMemo(() => {
    if (tabOptions) {
      return Object.entries(tabOptions).map(([key, { label, content }]) => ({
        label,
        key,
        children: content
      }));
    } else return [];
  });

  return (
    <>
      <PageTitle title={title} />
      <Card className="schema-panel">
        {isLoading ? (
          <Skeleton />
        ) : (
          <Tabs
            activeKey={activeTab}
            items={tabs}
            onChange={handleChange}
            destroyInactiveTabPane={true}
          />
        )}
        <ExportModal
          visible={exportModalVisible}
          setVisible={setExportModalVisible}
          frameName={frameName}
        />
        <ImportModal
          visible={importModalVisible}
          setVisible={setImportModalVisible}
          frameName={frameName}
          refetch={refetch}
        />
      </Card>
    </>
  );
}

export default withLayout(Schema);
