import React, { useState, useEffect } from 'react';
import { Modal, Button, Select, Input, Tooltip } from 'antd';
import { useQueryClient } from 'react-query';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import { _t } from 'rosis-translation';
import filterBy from '../system/helper/filter-by';
import { useSendMessage, useLoggedUsers } from 'rosis-api';

const { Option } = Select;
const { TextArea } = Input;

const SendMessageToUser = () => {
  const queryClient = useQueryClient();
  const { showInfoBar } = useInfoBar();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data: loggedUsers } = useLoggedUsers();
  const {
    isSuccess: successSendMessage,
    error: errorSendMessage,
    isLoading: isLoadingSendMessage,
    mutate: send
  } = useSendMessage(queryClient);
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState(null);
  const [listLoggedUsers, setListLoggedUsers] = useState([]);

  useEffect(() => {
    let userList = [];
    let currentDate = new Date();
    currentDate = currentDate.getTime();

    if (loggedUsers) {
      loggedUsers.forEach((item) => {
        if (currentDate - parseInt(item.last) < 60000) {
          userList.push(item.id);
        }
      });

      setListLoggedUsers(userList);
    }
  }, [loggedUsers]);

  useEffect(() => {
    if (successSendMessage) {
      showInfoBar({
        message: _t('success::Message has been sent'),
        type: 'success'
      });
      setMessage(null);
      setUser(null);
    }
    if (errorSendMessage) {
      showInfoBar({
        message: _t(errorSendMessage?.response?.data || 'error::Sending the message has failed'),
        type: 'error'
      });
    }
    setIsModalVisible(false);
  }, [successSendMessage, errorSendMessage]);

  const handleOk = () => {
    send({
      go: 'Send',
      msg: message,
      uid: user
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="text" onClick={showModal}>
        {_t('::Send message to another user')}
      </Button>
      <Modal
        title={_t('::Send message to another user')}
        open={isModalVisible}
        confirmLoading={isLoadingSendMessage}
        onOk={handleOk}
        onCancel={handleCancel}>
        <Select
          showSearch
          placeholder={_t('::Select user')}
          style={{ width: '100%', marginBottom: '15px' }}
          optionFilterProp="children"
          value={user}
          onChange={setUser}
          filterOption={filterBy.label}>
          {Array.isArray(loggedUsers) &&
            loggedUsers.map((user) => (
              <Option key={user?.idStr} value={user?.id}>
                {listLoggedUsers.includes(user?.id) ? (
                  <Tooltip title={_t('::On-line')}>
                    <span role="img" aria-label="online">
                      ◾{' '}
                    </span>
                  </Tooltip>
                ) : (
                  <span role="img" aria-label="offline">
                    ◽{' '}
                  </span>
                )}
                {user?.idStr}
              </Option>
            ))}
        </Select>
        <TextArea
          rows={4}
          placeholder={_t('::Enter message')}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default SendMessageToUser;
