import React from 'react';
import NotLoggedLayout from './NotLoggedLayout';

const withNotLoggedLayout = (Component, options = {}) =>
  function ContextLayout() {
    return (
      <NotLoggedLayout {...options}>
        <Component />
      </NotLoggedLayout>
    );
  };

export default withNotLoggedLayout;
