import { useMutation, useQuery } from 'react-query';

import { url, post, put, getData } from './internal/rest-api';

export const useChangePasswordByToken = (queryClient) =>
  useMutation(({ tokenId, data }) => put(url`token/${tokenId}`, { ...data }), {
    onSuccess: () => queryClient.invalidateQueries('password')
  });

export const useCreateToken = (queryClient, type) =>
  useMutation((data) => post(url`token/${data.idStr}/${data.role}/${type}`, data), {
    onSuccess: () => queryClient.invalidateQueries()
  });

export const useForgotPassword = (queryClient) =>
  useMutation(({ idStr, role }) => post(url`token/${idStr}/${role}/password-reset`), {
    onSuccess: () => queryClient.invalidateQueries()
  });

export const useConfirmEmailToken = (queryClient) =>
  useMutation(({ tokenId, password, secretQuestions }) => put(url`token/${tokenId}`, { password, secretQuestions }), {
    onSuccess: () => queryClient.invalidateQueries('password')
  });

export const useCheckToken = ({ tokenId }) =>
  useQuery(['check-token'], () => getData(url`token/${tokenId}`));

export const useGetQuestionsByUser = ({ tokenId }) =>
  useQuery(['questions', tokenId], () => getData(url`token/${tokenId}/questions`));
