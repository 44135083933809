import React from 'react';
import { Typography } from 'antd';
import { _t } from 'rosis-translation';
import { useConfigs } from 'rosis-api';

const { Text } = Typography;

export default function Description() {
  const { data: config } = useConfigs('pub');
  const descriptionKey = config?.['admin-description'] || config?.description;
  const description = descriptionKey ? _t(descriptionKey) : null;

  return <Text>{description}</Text>;
}
