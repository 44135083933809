import React, { useEffect, useState } from 'react';
import { Form, Input, Typography, Alert } from 'antd';
import PropTypes from 'prop-types';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { _t } from 'rosis-translation';
import { useRegex, useWhoami } from 'rosis-api';
import GeneratePasswordButton from 'rosis-components/PasswordScreens/GeneratePasswordButton';

const { Text } = Typography;

function ChangePasswordForm({
  setPasswd,
  externalForm,
  lastUpdateResult,
  setLastUpdateResult,
  onFinish,
  onFormValueChange
}) {
  const [capsLock, setCapsLock] = useState(false);
  const { data: regex } = useRegex();

  const { data: user } = useWhoami();

  const onKeyDown = (keyEvent) => {
    try {
      setCapsLock(keyEvent.getModifierState('CapsLock'));
    } catch (err) {
      setCapsLock(false);
    }
  };

  useEffect(() => {
    if (lastUpdateResult?.status === 'success') {
      externalForm.setFieldsValue({
        oldPassword: '',
        password: '',
        confirm: ''
      });
      setPasswd('');
    }
    if (lastUpdateResult?.status === 'error') {
      externalForm.setFieldsValue({
        oldPassword: ''
      });
    }
  }, [lastUpdateResult]);

  const fillPassword = (generatedPsw) => {
    externalForm.setFieldsValue({
      password: generatedPsw
    });
    setPasswd(generatedPsw);
    setLastUpdateResult(null);
    onFormValueChange();
  };

  const formValueChange = () => {
    setLastUpdateResult(null);
    onFormValueChange();
  };

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        scrollToFirstError={{
          block: 'center'
        }}
        onChange={formValueChange}
        onFinish={onFinish}
        form={externalForm}>
        <Form.Item
          label={_t('profile-password_change::Old password')}
          name="oldPassword"
          rules={[
            {
              required: true,
              message: _t('profile-password_change::Please input your old password')
            }
          ]}>
          <Input.Password
            onKeyDown={onKeyDown}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <GeneratePasswordButton onGeneration={fillPassword} />
        <Form.Item
          label={_t('profile-password_change::New password')}
          name="password"
          rules={[
            {
              required: true,
              message: _t('profile-password_change::Please input your new password')
            },
            {
              pattern: new RegExp(regex),
              message: _t("profile-password_change::Password doesn't meet complexity requirements")
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value) {
                  return Promise.resolve();
                }
                if (value === getFieldValue('oldPassword')) {
                  return Promise.reject(
                    new Error(_t('error::Cannot change password to the same value'))
                  );
                }
                if (
                  user?.user?.idStr &&
                  value.toUpperCase().includes(user?.user?.idStr.toUpperCase())
                ) {
                  return Promise.reject(new Error(_t('error::Password cannot contain login name')));
                }
                return Promise.resolve();
              }
            })
          ]}>
          <Input.Password
            onKeyDown={onKeyDown}
            onChange={(e) => setPasswd(e.target.value)}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <Form.Item
          label={_t('profile-password_change::Confirm new password')}
          validateTrigger="onSubmit"
          name="confirm"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: _t('profile-password_change::Please input password again')
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error(
                    _t('profile-password_change::The two passwords that you entered do not match')
                  )
                );
              }
            })
          ]}>
          <Input.Password
            onKeyDown={onKeyDown}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        {lastUpdateResult && (
          <Alert
            message={lastUpdateResult.message}
            type={lastUpdateResult.status}
            showIcon
            style={{ marginBottom: '15px' }}
          />
        )}
      </Form>
      {capsLock && (
        <Text
          style={{
            position: 'absolute',
            insetBlockStart: '15px',
            insetInlineEnd: '15px',
            textTransform: 'uppercase'
          }}>
          {_t('::Caps lock is on')}
        </Text>
      )}
    </>
  );
}

ChangePasswordForm.propTypes = {
  setPasswd: PropTypes.func,
  onFinish: PropTypes.func,
  externalForm: PropTypes.object,
  lastUpdateResult: PropTypes.object,
  setLastUpdateResult: PropTypes.func,
  onFormValueChange: PropTypes.func
};

ChangePasswordForm.defaultProps = {
  onFormValueChange: () => {}
};

export default ChangePasswordForm;
