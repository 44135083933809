import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'antd';
import { useFormContext } from 'react-hook-form';
import { _t } from 'rosis-translation';
import { SFInput } from 'rosis-setting-form';

const regexs = {
  email: '^[\\w.!#$%&’*+/=?^`{|}~-]+@[\\w-]+(?:\\.[\\w-]+)+$',
  phone: '^\\+?[0-9\\s-]+$',
  url: '^(https?):\\/\\/(\\w+:?\\w*@)?(\\S+)(:[0-9]+)?(\\/|\\/([\\w#!:.?+=&%@\\-\\/]))?$'
};

function Regex(props) {
  const { label } = props;
  const { setValue } = useFormContext();

  const addRegex = (regex) => () => {
    setValue('regex', regex, { shouldDirty: true });
  };

  return (
    <>
      <Form.Item label={`${label}:`}>
        <SFInput name="regex" noStyle addonBefore="/" addonAfter="/" />
        {Object.entries(regexs).map(([name, regex]) => (
          <Button style={{ margin: '10px 10px 0 0' }} key={name} onClick={addRegex(regex)}>
            {name}
          </Button>
        ))}
        <Button style={{ margin: '10px 10px 0 0' }} key="clear" onClick={addRegex('')}>
          {_t('button::Erase')}
        </Button>
      </Form.Item>
    </>
  );
}

Regex.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
};

const regex = {
  get label() {
    return _t('::Regex');
  },
  Field: Regex,
  name: 'regex'
};

export default regex;
