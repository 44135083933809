import { useQuery, useMutation, useQueryClient } from 'react-query';

import { url, getData, del, put, post } from './internal/rest-api';
import { useProjection } from './internal/utils';

export const useSiteMembers = () => useQuery(['sites', 'members'], () => getData('site/members'));

export const useSiteUsage = () => useQuery(['sites', 'usage'], () => getData('site/usage'));

export const useSiteUsers = ({ name, role }) =>
  useQuery(['sites', name, role], () => getData(`site/users/${name}/${role}`));

export const useSitesData = () => useQuery(['sites', 'data'], () => getData('site'));

export const useSiteNames = () => useQuery(['sites', 'names'], () => getData('site/names'));

export const useCreateSite = (queryClient) =>
  useMutation(({ name, lang }) => post(lang ? url`site/${name}/${lang}` : url`site/${name}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('sites');
    }
  });

export const useUpdateSite = () =>
  useMutation(({ name, lang }) => put(url`site/lang/${name}/${lang}`));

export const useRemoveSite = (queryClient) =>
  useMutation((site) => del(url`site/${site}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('sites');
    }
  });

export const useAddUserToSite = (queryClient) =>
  useMutation(({ site, id, onlyOne }) => put(url`site/user/${site}/${id}${onlyOne}`), {
    onSuccess: () => {
      queryClient.invalidateQueries();
    }
  });

export const useSetSitesForUser = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ userId, toAdd, toRemove }) =>
      Promise.all([
        ...toAdd.map((site) => put(url`site/user/${site}/${userId}`)),
        ...toRemove.map((site) => del(url`site/user/${site}/${userId}`))
      ]),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      }
    }
  );
};

export const useRemoveUserFromSite = (queryClient) =>
  useMutation(({ site, id }) => del(url`site/user/${site}/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries();
    }
  });

export const useMySites = () => useQuery(['site', 'my'], () => getData(`site/my`));

export const useNumberOfUserPerSite = (name) => useQuery(['countRolePerSites', name], () => getData(`site/roles/${name}`));

//COMPLEX

export const useSiteById = (id) => {
  const queryResult = useSiteMembers();
  return useProjection(queryResult, (data) => data.filter((item) => item.user === id));
};

export const useSites = () => {
  const queryResults = useSitesData();
  return useProjection(queryResults, (data) =>
    data.map(({ site, lang }) => ({ id: site, title: site, lang }))
  );
};
