import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { makeToText } from 'make-to-text';
import parseValueToQuery from '../../../utils/parseValue';
import MultiselectCompare from '../../../utils/compare/Multiselect';
import Item from '../../../Item';

const toText = makeToText('Multi', 'text', { _t });

function Text({ value, ...rest }) {
  return <Item {...rest}>{toText(value)}</Item>;
}

Text.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string)
};
const text = {
  value: 'text',
  get label() {
    return _t('::Text');
  },
  toText,
  default: true,
  component: Text,
  compareComponent: MultiselectCompare,
  parseValueToQuery: parseValueToQuery.multi,
  example: <Text value={['option 1', 'option 2']} />
};

export default text;
