import { useQuery, useMutation, useQueryClient } from 'react-query';

import { url, getData, put } from './internal/rest-api';
import { useProjection } from './internal/utils';

export const useMtx = (config = {}) => useQuery('mtx', () => getData('mtx'), config);

export const useMtxForRole = (role, options = {}) =>
  useQuery(['mtx', role], () => getData(url`mtx/${role}`), options);

export const useMtxForRoleAndModel = (role, model, options = {}) =>
  useQuery(['mtx', role, model], () => getData(url`mtx/${role}/${model}`), options);

export const useUpdateMtx = (queryClient) =>
  useMutation((newMtx) => put('mtx', newMtx), {
    onSuccess: () => {
      queryClient.invalidateQueries('mtx');
    }
  });

export const useUpdateMtxItem = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ role, frame, field, right, value }) =>
      put(`mtx/${role}/${frame}/${field}/${right}/${value}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('mtx');
      }
    }
  );
};

export const useUpdateMtxManyItems = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ role, frame, fields, right, value }) =>
      Promise.all(fields.map((field) => put(`mtx/${role}/${frame}/${field}/${right}/${value}`))),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('mtx');
      }
    }
  );
};

export const usePermissionPerFrame = (role) => {
  const queryResults = useMtx();
  return useProjection(queryResults, (data) => {
    const roleData = Object.entries(data).find(([roleName]) => roleName === role);
    return roleData ? roleData[1] : null;
  });
};
