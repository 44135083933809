import React from 'react';
import PropTypes from 'prop-types';
import BaseDate from './BaseDate';

const Datetime = React.forwardRef(({ val, onChange }, ref) => {
  return <BaseDate val={val} onChange={onChange} pickerKind="datetime" ref={ref} />;
});

Datetime.displayName = 'Datetime';

Datetime.propTypes = {
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func
};

export default Datetime;
