import React from 'react';
import PropTypes from 'prop-types';
import { Input, Modal, Form, Select } from 'antd';
import { _t } from 'rosis-translation';
import { useModelsWhichCanBeParent } from 'rosis-api';

function CloneModal({ visible, onClone, onCancel }) {
  const [form] = Form.useForm();
  const { data: parentOptions, isLoading: isParentOptionsLoading } = useModelsWhichCanBeParent();
  return (
    <Modal
      open={visible}
      title={_t('::Duplicate section')}
      okText={_t('button::Duplicate')}
      cancelText={_t('button::Cancel')}
      onCancel={onCancel}
      onOk={() => {
        form.validateFields().then(({ label, linkTo }) => {
          form.resetFields();
          onClone({ label, linkTo });
        });
      }}>
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="label"
          label={_t('::Label')}
          rules={[
            {
              required: true,
              message: _t('::Please input the label')
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={_t('::Link this section to')}
          name="linkTo"
          rules={[
            {
              required: true,
              message: _t('::Please input section parent')
            }
          ]}>
          <Select options={parentOptions} loading={isParentOptionsLoading} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

CloneModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClone: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default CloneModal;
