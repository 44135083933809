import React from 'react';
import { Layout } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import './Footer.less';
import { useConfigs } from 'rosis-api';

const { Footer } = Layout;

function AppFooter() {
  const { data: config } = useConfigs('pub');
  const footerText = config?.['admin-footerText'] || config?.footerText;
  return <Footer className="admin-footer">{ReactHtmlParser(footerText)}</Footer>;
}

export default AppFooter;
