import React, { useEffect } from 'react';
import { _t } from 'rosis-translation';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { List, Button } from 'antd';
import { useConfig, useUpdateConfig } from 'rosis-api';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import { usePageHeader } from 'contexts/pageHeader';
import TitleActions from 'components/TitleActions';
import { calcSettingPageTitle } from '../utils';
import PageTitle from 'components/PageTitle';
import AutoDivider from 'components/AutoDivider';

const Theme = ({ colors, title }) => {
  return colors.map((color) => (
    <div key={color} className="theme-list">
      <div
        className="theme-block"
        style={{
          background: color
        }}
      />
      <span>{title}</span>
    </div>
  ));
};

function ThemeConfig({ title }) {
  const queryClient = useQueryClient();
  const { showInfoBar } = useInfoBar();
  const { mutate: update, isSuccess: isUpdated } = useUpdateConfig(queryClient, 'bodyClass');

  useEffect(() => {
    if (isUpdated) {
      showInfoBar({
        message: _t('success::Theme updated'),
        type: 'success'
      });
    }
  }, [isUpdated]);

  const updateTheme = (value) => {
    let config = {
      group: 'setup',
      name: 'bodyClass',
      value: value
    };
    update(config);
    document.body.className = value;
  };

  const ChooseTheme = ({ name }) => {
    const { data } = useConfig('setup', 'bodyClass');
    return (
      <Button
        type="primary"
        size="small"
        disabled={data === name}
        onClick={() => updateTheme(name)}>
        {data === name ? _t('::Selected') : _t('::Choose')}
      </Button>
    );
  };

  ChooseTheme.propTypes = {
    name: PropTypes.string
  };

  const pageTitle = calcSettingPageTitle(title);

  usePageHeader(<TitleActions actions={[]}>{pageTitle}</TitleActions>);

  return (
    <>
      <PageTitle title={pageTitle} />
      <AutoDivider>{_t('::Select theme')} </AutoDivider>

      <>
        <List bordered>
          <List.Item actions={[<ChooseTheme key={1} name="theme-default" />]}>
            <List.Item.Meta title={<Theme colors={['#0066CC']} title="default" />} />
          </List.Item>
          <List.Item actions={[<ChooseTheme key={5} name="theme-dark" />]}>
            <List.Item.Meta title={<Theme colors={['#000']} title="theme dark" />} />
          </List.Item>
          <List.Item actions={[<ChooseTheme key={2} name="theme1" />]}>
            <List.Item.Meta title={<Theme colors={['#f0265c']} title="theme 1" />} />
          </List.Item>
          <List.Item actions={[<ChooseTheme key={3} name="theme2" />]}>
            <List.Item.Meta title={<Theme colors={['#f09826']} title="theme 2" />} />
          </List.Item>
          <List.Item actions={[<ChooseTheme key={4} name="theme3" />]}>
            <List.Item.Meta title={<Theme colors={['#00965E']} title="theme 3" />} />
          </List.Item>
        </List>
      </>
    </>
  );
}

ThemeConfig.propTypes = {
  title: PropTypes.string
};

export default ThemeConfig;
