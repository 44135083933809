import { fieldTypes } from '../index';
import SimpleText from './SimpleText';

const getFieldOptions = (type) => Object.values(fieldTypes).find(({ name }) => name === type);

export default function getFieldType(group, type, currentComponent) {
  const options = getFieldOptions(type);
  if (!options) {
    return { name: 'simple', component: SimpleText };
  }
  const components = options[group];
  const defaultComponent = components.find((option) => option.default);
  if (currentComponent) {
    return components.find(({ value }) => value === currentComponent) || defaultComponent;
  } else {
    return defaultComponent || {};
  }
}
