import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';

const NotificationExecutionMessage = ({ message }) => {
  const [recipient, template] = message.split('/');
  return (
    <>
      <div>
        <span className="message-key">{_t('logs-notification-message::Template')}</span>
        <span>{template}</span>
      </div>
      <div>
        <span className="message-key">{_t('logs-notification-message::Recipient')}</span>
        <span>{recipient}</span>
      </div>
    </>
  );
};

NotificationExecutionMessage.propTypes = {
  message: PropTypes.string
};

NotificationExecutionMessage.defaultProps = {
  message: ''
};

export default NotificationExecutionMessage;
