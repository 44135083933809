import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import CollapsePanel from './CollapsePanel';
import CollapseHeader from './CollapseHeader';
import CollapseExtra from './CollapseExtra';
import { useQueryClient } from 'react-query';
import { useParams } from 'rosis-components/navigation-wrapper';
import { useLocal, useUpdateLocal, useView } from 'rosis-api';

const { Panel } = Collapse;

function CollapseRole({ model, mtx, disabled, level, setMtx, onChangeMtxItem }) {
  const { name, label } = model;
  const currentMtx = mtx[name];
  const sectionMtxArray = currentMtx?.['*'] || [];
  const { role } = useParams();

  const queryClient = useQueryClient();
  const { data: roleCollapse } = useLocal(`${role}roleCollapseStatus`);
  const { data: view } = useView(name);
  const { mutate: saveRoleCollapse } = useUpdateLocal(queryClient, `${role}roleCollapseStatus`);

  const [defaultCollapse, setDefaultCollapse] = useState([]);

  useEffect(() => {
    if (roleCollapse) {
      setDefaultCollapse(roleCollapse.split(',').filter((role) => !(name === role && disabled)));
    }
  }, [roleCollapse, disabled]);

  const changeCollapse = (key) => {
    setDefaultCollapse(key);
    if (Array.isArray(key) && key.length > 0) {
      saveRoleCollapse(key.join(','));
    } else {
      saveRoleCollapse('_empty');
    }
  };

  return (
    <li className="roles-list-element">
      <Collapse
        className="roles-collapse"
        key={name}
        activeKey={defaultCollapse}
        onChange={changeCollapse}
        collapsible={disabled ? 'disabled' : ''}>
        <Panel
          className="roles-panel"
          extra={
            disabled || !sectionMtxArray.includes('read') ? null : (
              <CollapseExtra name={name} mtx={mtx} view={view} />
            )
          }
          header={<CollapseHeader label={label} mtx={sectionMtxArray} />}
          key={name}>
          <CollapsePanel name={name} mtx={mtx} setMtx={setMtx} onChangeMtxItem={onChangeMtxItem} />
        </Panel>
      </Collapse>
      <ul className="roles-list roles-list-sub">
        {model?.children?.map((child) => (
          <CollapseRole
            key={child.name}
            name={name}
            model={child}
            mtx={mtx}
            level={level + 1}
            disabled={disabled || !sectionMtxArray.includes('read')}
            setMtx={setMtx}
            onChangeMtxItem={onChangeMtxItem}
          />
        ))}
      </ul>
    </li>
  );
}

CollapseRole.defaultProps = {
  level: 1
};

CollapseRole.propTypes = {
  model: PropTypes.object.isRequired,
  mtx: PropTypes.object,
  disabled: PropTypes.bool,
  level: PropTypes.number,
  setMtx: PropTypes.func,
  onChangeMtxItem: PropTypes.func.isRequired
};

export default CollapseRole;
