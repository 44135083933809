import React from 'react';
import { _t } from 'rosis-translation';
import { useFormContext } from 'react-hook-form';
import { datetime } from 'rosis-date-picker';
import { calcConstraintValue } from '../../../utils';
import RelativeOrFixedDate from '../../../utils/RelativeOrFixedDate';

function Min(props) {
  const { watch } = useFormContext();
  const max = watch('max');
  return <RelativeOrFixedDate {...props} max={max} />;
}

const validate = (val, min) => {
  if (val === null || val === undefined) {
    return null;
  }
  if (min === null || min === undefined) {
    return null;
  }

  if (datetime(val).isBefore(calcConstraintValue(min))) {
    return _t('field_definition::Value must be greater than Min value', { min });
  }
};

const minDate = {
  name: 'min',
  get label() {
    return _t('::Min');
  },
  Field: Min,
  valueType: 'Date',
  order: 0,
  validate
};

export default minDate;
