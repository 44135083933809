import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import MenuContent from './MenuContent';
import TopMenu from './TopMenu';

function MobileMenu({ direction, visible, setVisible }) {
  function handleClose() {
    setVisible(false);
  }
  return (
    <Drawer
      className="mobile-menu"
      width="80%"
      placement={direction === 'ltr' ? 'right' : 'left'}
      closable={false}
      open={visible}
      onClose={handleClose}>
      <MenuContent />
      <TopMenu />
    </Drawer>
  );
}

MobileMenu.propTypes = {
  direction: PropTypes.oneOf(['ltr', 'rtl']),
  handleClose: PropTypes.func,
  visible: PropTypes.bool,
  setVisible: PropTypes.func
};

export default MobileMenu;
