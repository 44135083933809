import { useQuery, useMutation, useQueryClient } from 'react-query';

import { url, get, getData, put, post } from './internal/rest-api';

export const useVersions = () => useQuery('versions', () => getData('ver'));

export const useLoggedUsers = () => useQuery('last', () => getData('last'));

export const useApps = () => useQuery('apps', () => getData('apps'));

export const useSaveLogo = () => useMutation((form) => post(`file`, form));

export const useView = (name) => useQuery(['view', name], () => getData(url`view/${name}`));

export const useViewUpdate = (queryClient, frame) =>
  useMutation((data) => put(url`view/${frame}`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['view', frame]);
    }
  });

export const useRoleData = () => useQuery('roles', () => getData('role/labels/doctor'));

export const useHasAdmin = () => useQuery('hasAdmin', () => getData('has-admin'));

export const useCalcFunc = (config = {}) =>
  useQuery(['calc', 'funcList'], () => getData('calc/func-list'), config);

export const useIsCalcFormulaValid = () =>
  useMutation((formula) => getData('calc/is-valid', { params: { formula } }));

export const useSignUp = () =>
  useMutation(({ role, formData }) => post(url`signup/${role}`, formData));

export const useFileInfo = (id, config = {}) =>
  useQuery(['file-info', id], () => getData(url`file/info/${id}`), config);

export const getNewCounter = async (frameName, fieldName, step, paddingWith, prefix, length) => {
  const counter = await get(url`counter/${frameName}${fieldName}/${step || ''}`).then(
    ({ data }) => data?.counter
  );
  const currentChars = counter.toString().length;
  const missingCharsCount = length - currentChars;
  const missingChars = missingCharsCount > 0 ? (paddingWith || '0').repeat(missingCharsCount) : '';
  return `${prefix || ''}${missingChars}${counter}`;
};

export const useFreeze = (queryClient, model) =>
  useMutation(({ id }) => put(`freezelock/freeze/${model}/${id}`), {
    onSuccess: () => queryClient.invalidateQueries(['db', 'data'])
  });

export const useLock = (queryClient, model) =>
  useMutation(({ id }) => put(`freezelock/lock/${model}/${id}`), {
    onSuccess: () => queryClient.invalidateQueries(['db', 'data'])
  });

export const useUnfreeze = (queryClient, model) =>
  useMutation(({ id }) => put(`freezelock/unfreeze/${model}/${id}`), {
    onSuccess: () => queryClient.invalidateQueries(['db', 'data'])
  });

export const useUnlock = (queryClient, model) =>
  useMutation(({ id }) => put(`freezelock/unlock/${model}/${id}`), {
    onSuccess: () => queryClient.invalidateQueries(['db', 'data'])
  });

export const useRecordStatus = (model, id) =>
  useQuery('freezelockStatus', () => getData(`freezelock/status/${model}/${id}`));

export const usePing = () => useMutation(() => put('idle'));
