import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { Form, InputNumber } from 'antd';

function PswdReq({ label, value, onChange, resetButton }) {
  const reqData = [
    { label: _t('::Digits'), minValue: 0 },
    { label: _t('::Uppercase letters'), minValue: 0 },
    { label: _t('::Lowercase letters'), minValue: 0 },
    { label: _t('::Special characters'), minValue: 0 },
    { label: _t('::Min length'), minValue: 1 },
    { label: _t('::Max length'), minValue: 1 }
  ];

  const requirements = useMemo(() => value? value.split(' ') : [], [value]);

  const handleChange = (current, index) => {
    const newValue = current || reqData[index].minValue;
    requirements[index] = newValue;
    onChange(requirements.join(' '));
  };

  return (
    <>
      <h3>{label} {resetButton}</h3>
      {requirements.map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Form.Item key={index}>
          <InputNumber
            type="number"
            value={value}
            onChange={(value) => handleChange(value, index)}
            min={reqData[index].minValue}
          />
          {reqData[index].label}
        </Form.Item>
      ))}
    </>
  );
}

PswdReq.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  resetButton: PropTypes.element.isRequired
};

export default PswdReq;
