import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import Item from '../../../Item';

function Text(props) {
  const { value, ...rest } = props;
  return <Item {...rest}>{value ? value : ''}</Item>;
}

Text.propTypes = {
  value: PropTypes.string
};

const options = {
  value: 'text',
  get label() {
    return _t('::Text');
  },
  default: true,
  component: Text,
  example: <Text value={'000000001'} />
};

export default options;
