import * as displayAs from './displayAs';
import * as editAs from './editAs';
import * as additionalOptions from './additionalOptions';
import makeType from '../../makeType';

const date = makeType({
  name: 'Date',
  label: '::Date',
  displayAs,
  editAs,
  additionalOptions
});

export default date;
