import { useState, useEffect } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import { getFieldsList } from './utils';

const compare = (a, b) => {
  return a.order - b.order;
};

const useViewFields = ({ fields, views, onFieldsChange }) => {
  const [fieldsList, setFieldsList] = useState([]);

  useEffect(() => {
    if (!fields || !views) {
      return;
    }
    const newFieldsList = getFieldsList(fields)
      .map((field) => {
        const viewDef = views[field.name];
        return {
          ...field,
          checked: Object.keys(views).includes(field.name),
          order: viewDef?.order || null,
          sortable: viewDef?.sortable || null,
          defSort: viewDef?.defSort || null
        };
      })
      .sort(compare);
    setFieldsList(newFieldsList);
  }, [fields, views]);

  const changeFieldOrder = (name, newIndex, oldIndex) => {
    const selectedFields = arrayMove(
      fieldsList.filter((item) => item.checked),
      oldIndex,
      newIndex
    ).map((field, index) => ({
      ...field,
      order: index
    }));
    const unselectedFields = fieldsList.filter((item) => !item.checked);
    const list = [...selectedFields, ...unselectedFields];
    setFieldsList(list);
    notifyChange(list);
  };

  const changeFieldConfig = (name, property, value) => {
    const list = fieldsList
      .map((item) =>
        property === 'defSort'
          ? {
              ...item,
              defSort: 'none'
            }
          : item
      )
      .map((item) =>
        item.name === name
          ? {
              ...item,
              [property]: value
            }
          : item
      )
      .sort(compare);
    setFieldsList(list);
    notifyChange(list);
  };

  const notifyChange = (list) => {
    const entries = list
      .filter((item) => item.checked)
      .map((item, index) => [
        item.name,
        {
          order: index,
          sortable: item.sortable,
          defSort: item.defSort
        }
      ]);
    const payload = Object.fromEntries(entries);
    onFieldsChange(payload);
  };

  return {
    fieldsList,
    changeFieldOrder,
    changeFieldConfig
  };
};

export default useViewFields;
