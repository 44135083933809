import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import ResetToDefault from 'components/buttons/ResetToDefault';
import { usePreConfigs, usePreOgFileConfig } from 'rosis-api';

function DefaultButton({ name, group, value, kind, setEditedValue, readValue }) {
  const { data: preValues, refetch } = usePreConfigs(group, {
    enabled: !!group
  });

  const preValue = useMemo(
    () => (preValues ? readValue(kind, preValues[name]) : null),
    [preValues]
  );

  const isHidden = useMemo(() => preValue?.toString() === value?.toString(), [value, preValues]);

  const { data: image } = usePreOgFileConfig(name, {
    enabled: kind === 'image' && !isHidden
  });

  useEffect(() => {
    refetch();
  }, [refetch, value]);

  return isHidden ? null : (
    <ResetToDefault onClick={() => setEditedValue(image || preValue, true)} />
  );
}

DefaultButton.propTypes = {
  name: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  value: PropTypes.any,
  setEditedValue: PropTypes.func.isRequired,
  readValue: PropTypes.func.isRequired
};

export default DefaultButton;
