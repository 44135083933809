import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popconfirm, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { _t } from 'rosis-translation';

function RemoveButton({
  mainText,
  cantRemove,
  isRemoving,
  onConfirm,
  bigButton,
  buttonText,
  confirmText
}) {
  const content = (
    <div>
      {mainText}
      {cantRemove?.map((err) => (
        <div key={err}>{_t(err)}</div>
      ))}
    </div>
  );
  const button = (
    <Button
      type={bigButton ? 'default' : 'link'}
      className="action-remove"
      disabled={cantRemove?.length}>
      {isRemoving ? (
        <FontAwesomeIcon icon={faSpinner} pulse />
      ) : (
        <>
          <FontAwesomeIcon icon={faTrash} />
          {bigButton ? buttonText || _t('button::Remove') : ''}
        </>
      )}
    </Button>
  );
  return (
    <Popconfirm
      title={confirmText}
      okText={_t('::Yes')}
      cancelText={_t('::No')}
      disabled={cantRemove?.length}
      onConfirm={isRemoving ? null : onConfirm}>
      {cantRemove?.length ? (
        <Tooltip content={content} title={content} placement="bottomRight">
          {button}
        </Tooltip>
      ) : (
        <Tooltip title={_t('button::Remove')} placement="bottom">
          {button}
        </Tooltip>
      )}
    </Popconfirm>
  );
}

RemoveButton.propTypes = {
  mainText: PropTypes.string.isRequired,
  bigButton: PropTypes.bool,
  buttonText: PropTypes.string,
  isRemoving: PropTypes.bool,
  cantRemove: PropTypes.arrayOf(PropTypes.string),
  onConfirm: PropTypes.func,
  confirmText: PropTypes.string
};

export default RemoveButton;
