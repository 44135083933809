import React from 'react';
import PropTypes from 'prop-types';
import ConfigSettings from 'components/ConfigSettings';
import { ConfigFormControlProvider } from 'components/ConfigSettings/ConfigFormControl';
import ConfigPageHeader from 'components/ConfigSettings/ConfigPageHeader';
import PageTitle from 'components/PageTitle';
import GridSectionEditor from 'components/ConfigSettings/GridSectionEditor';
import { calcSettingPageTitle } from '../utils';
import { useChange } from 'contexts/change';
import symbol from 'system/symbol';
import IdlenessMaxEditor from './IdlenessMaxEditor';
import LockTimeEditor from './LockTimeEditor';
import { _t } from 'rosis-translation';

const { panels } = symbol;

const getLoginLockSettings = () => {
  let result = [];
  for (let panel in panels) {
    const label = _t(panels[panel]);
    result = [
      ...result,
      {
        name: panel + '-loginLockTimeInMinutes',
        group: 'setup',
        label: _t('::Login lock duration - {label}', { label }),
        kind: 'custom',
        editorProps: { panel },
        CustomComponent: LockTimeEditor
      }
    ];
  }
  return result;
};

const getIdlenessMaxSettings = () => {
  let result = [];
  for (let panel in panels) {
    result = [
      ...result,
      {
        name: `${panel}-idlenessMax`,
        group: 'setup',
        label: _t('::Timeout time'),
        kind: 'custom',
        CustomComponent: IdlenessMaxEditor,
        editorProps: { min: 5, max: 60 },
        validationRules: [{ required: true, message: _t("::It's required") }]
      }
    ];
  }
  return result;
};

function GeneralSecuritySettings({ title }) {
  const { isChanged, setIsChanged } = useChange();
  const pageTitle = calcSettingPageTitle(title);

  const settings = [
    {
      name: 'blockingUsers',
      label: _t('::Blocking users'),
      subsettings: [
        {
          name: 'numberOfFailLoginTries',
          group: 'setup',
          label: _t('::Number of failed login attempts before login lock'),
          kind: 'number',
          editorProps: { min: 1 }
        },
        ...getLoginLockSettings()
      ]
    },
    {
      name: 'pinCode',
      label: _t('::PIN code'),
      subsettings: [
        {
          name: 'pinLasting',
          group: 'setup',
          label: _t('::PIN code time expiry'),
          kind: 'select',
          options: [
            { label: _t('::{seconds} seconds', { seconds: 30 }), value: '30s' },
            { label: _t('::{seconds} seconds', { seconds: 60 }), value: '60s' },
            { label: _t('::{seconds} seconds', { seconds: 90 }), value: '90s' },
            { label: _t('::{minutes} minutes', { minutes: 2 }), value: '2m' },
            { label: _t('::{minutes} minutes', { minutes: 3 }), value: '3m' },
            { label: _t('::{minutes} minutes', { minutes: 5 }), value: '5m' }
          ]
        }
      ]
    },
    {
      kind: 'custom',
      name: 'idleness-max',
      subsettings: getIdlenessMaxSettings(),
      label: _t('::Idle session timeout'),
      CustomComponent: GridSectionEditor,
      editorProps: {
        onGetCellIds: (item) => item.name.split('-'),
        titleColumns: [
          {
            title: _t('::Panel name'),
            dataIndex: 'key',
            render: (val) => _t(panels[val])
          }
        ]
      }
    }
  ];

  return (
    <ConfigFormControlProvider>
      <PageTitle title={pageTitle} />
      <ConfigPageHeader pageTitle={pageTitle} isDirty={isChanged} />
      <ConfigSettings settings={settings} onIsDirtyChange={setIsChanged} />
    </ConfigFormControlProvider>
  );
}

GeneralSecuritySettings.propTypes = {
  title: PropTypes.string
};

export default GeneralSecuritySettings;
