import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BreadcrumbsContext } from 'rosis-contexts/breadcrumbs';
import { AuthContextProvider } from 'rosis-contexts/auth';
import { InfoBarProvider } from 'rosis-components/InfoBar/infoBar';
import { TranslationProvider } from 'contexts/translation';
import { ChangeProvider } from 'contexts/change';
import Router from 'router';
import { HelmetProvider } from 'react-helmet-async';
import { bool } from 'system/helper/value';

const { REACT_APP_REACT_QUERY_DEVTOOLS } = process.env;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

function AppContent() {
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  return (
    <ChangeProvider>
      <AuthContextProvider appLoginPoint="admin">
        <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
          <TranslationProvider>
            <HelmetProvider>
              <InfoBarProvider>
                <Router />
              </InfoBarProvider>
            </HelmetProvider>
          </TranslationProvider>
        </BreadcrumbsContext.Provider>
      </AuthContextProvider>
      {bool(REACT_APP_REACT_QUERY_DEVTOOLS) ? <ReactQueryDevtools initialIsOpen={false} /> : null}
    </ChangeProvider>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppContent />
    </QueryClientProvider>
  );
}

export default App;
