import React, { useEffect, useState } from 'react';
import { Skeleton, Table, Empty, Button, Typography, Popconfirm } from 'antd';
import { Link } from 'rosis-components/navigation-wrapper';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useQueryClient } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEmailTemplates, useEmailOrphans, useRemoveOrphans } from 'rosis-api';
import AddNewEmailTemplate from 'components/buttons/AddNewEmailTemplate';
import { _t } from 'rosis-translation';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';
import { usePageHeader } from 'contexts/pageHeader';
import { useAuth } from 'rosis-contexts/auth';
import PageTitle from 'components/PageTitle';
import { links } from 'router/links';
import './EmailTemplates.less';

const { Title } = Typography;

const getTemplateKey = (template) => template['name'];
const mapTemplatesToDataSource = (templates) => {
  const templateMap = new Map(templates.map((template) => [getTemplateKey(template), template]));
  const result = Array.from(templateMap.values());
  return result;
};

const groupByName = (template) => {
  let cars = template,
    result = cars.reduce(function (r, a) {
      r[a.name] = r[a.name] || [];
      r[a.name].push(a.lang);
      return r;
    }, Object.create(null));

  return result;
};

function EmailTemplates() {
  const queryClient = useQueryClient();
  const { devMode } = useAuth();
  const { data: templates, isLoading } = useEmailTemplates();
  const { data: orphans } = useEmailOrphans();
  const {
    isLoading: isLoadingRemoveOrphans,
    mutate: removeOrphans,
    ...removeOrphansStatus
  } = useRemoveOrphans(queryClient);
  const [templatesDataSource, setTemplatesDataSource] = useState([]);
  const [templatesDataSourceWithLangs, setTemplatesDataSourceWithLangs] = useState([]);

  useApiFeedback({
    status: {
      isLoading: isLoadingRemoveOrphans,
      ...removeOrphansStatus
    },
    successMessage: _t('success::Email orphans has been removed')
  });

  useEffect(() => {
    if (templates) {
      setTemplatesDataSourceWithLangs(groupByName(templates));
      setTemplatesDataSource(mapTemplatesToDataSource(templates));
    }
  }, [templates]);

  const columns = [
    {
      title: _t('tag::Name'),
      dataIndex: 'label',
      key: 'name',
      sorter: (a, b) => a.label > b.label,
      render: (name, record) => (
        <Link key="edit" to={links.emailTemplateDetails(record.name, record.lang)}>
          {name}
        </Link>
      )
    },
    {
      title: _t('::Languages'),
      key: 'lang',
      render: ({ name }) => templatesDataSourceWithLangs[name].join(', ')
    }
  ];

  const title = _t('::Email templates');

  usePageHeader(
    <div className="template-title">
      <Title level={3}>{title}</Title>
      <div>
        {devMode < 0 ? (
          <AddNewEmailTemplate
            title={_t('::Email template')}
            addIcon={true}
            subject=""
            body=""
            defaultName=""
            devMode={devMode < 0}
          />
        ) : null}
        {(orphans || []).length ? (
          <Popconfirm
            key="delete"
            title={_t('::Remove the orphans?')}
            onConfirm={removeOrphans}
            disabled={isLoadingRemoveOrphans}
            okText={_t('::Yes')}
            cancelText={_t('::No')}>
            <Button disabled={isLoadingRemoveOrphans} className="email-btn_orphans">
              <FontAwesomeIcon icon={faTrash} type="error" style={{ marginInlineEnd: '10px' }} />{' '}
              {_t('button::Remove email orphans')}
            </Button>
          </Popconfirm>
        ) : null}
      </div>
    </div>,
    [orphans]
  );

  return (
    <>
      <PageTitle title={title} />
      <>
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <Table
              columns={columns}
              size="middle"
              loading={isLoading}
              pagination={false}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={_t('::No email templates')}
                  />
                )
              }}
              dataSource={templatesDataSource}
              rowKey="name"
            />
          </>
        )}
      </>
    </>
  );
}

export default EmailTemplates;
