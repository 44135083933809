import { useMemo, useRef } from 'react';
import { setItem, storageKeys } from 'rosis-storage';

export const useProjection = (queryResult, projection) => {
  return useMemo(() => {
    if (!queryResult.data) {
      return queryResult;
    }
    return {
      ...queryResult,
      data: projection(queryResult.data)
    };
  }, [queryResult.dataUpdatedAt, queryResult.errorUpdatedAt, queryResult.isFetching]);
};

const areQueryResultsEqual = (lastResultRef, newResult) => {
  if (!lastResultRef || !newResult) {
    return lastResultRef === newResult;
  }
  const lastResultUpdatedAt = lastResultRef.dataUpdatedAt;
  if (lastResultUpdatedAt.length !== newResult.length) {
    return false;
  }
  for (let index = 0; index < lastResultUpdatedAt.length; index++) {
    const item1 = lastResultUpdatedAt[index];
    const item2 = newResult[index];
    if (
      item1.dataUpdatedAt !== item2.dataUpdatedAt ||
      item1.errorUpdatedAt !== item2.errorUpdatedAt ||
      item1.isFetching !== item2.isFetching
    ) {
      return false;
    }
  }
  return true;
};

export const useMultipleProjections = (queryResults, projection) => {
  const resultRef = useRef();

  if (!areQueryResultsEqual(resultRef.current, queryResults)) {
    resultRef.current = {
      dataUpdatedAt: queryResults
        ? queryResults.map((qr) => ({
            dataUpdatedAt: qr.dataUpdatedAt,
            errorUpdatedAt: qr.errorUpdatedAt,
            isFetching: qr.isFetching
          }))
        : [],
      result: projection(queryResults)
    };
  }
  return resultRef.current?.result;
};

export const noRetryFor403 = (failureCount, error) => {
  const is403Error = error.response.status === 403;
  const trialsAllowed = failureCount > 3;
  return !(is403Error || trialsAllowed);
};

export const saveExpireTime = (value) => value && setItem(storageKeys.expiryTime, value);
