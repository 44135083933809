import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import Icon from '@ant-design/icons';

const ResetSvg = () => (
  <svg
    width="15px"
    height="16px"
    viewBox="0 0 15 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Settings--main-settings"
        transform="translate(-584.000000, -274.000000)"
        fill="#0066CC">
        <g id="iconmonstr-gear-12" transform="translate(584.000000, 274.725789)">
          <path
            d="M1.53625,5.90202515 C1.350625,6.49419624 1.25,7.1245114 1.25,7.77796312 C1.25,11.229689 4.050625,14.0310897 7.5,14.0310897 C10.95,14.0310897 13.75,11.229689 13.75,7.77796312 C13.75,4.32686257 10.95,1.52483656 7.5,1.52483656 C6.174375,1.52483656 4.948125,1.94254541 3.935625,2.64789809 L5.205,4.28813698 L0.681744305,4.28813698 L1.85910959,0 L3.170625,1.65677753 C4.394375,0.788218249 5.886875,0.274211245 7.5,0.274211245 C11.639375,0.274211245 15,3.6365174 15,7.77796312 C15,11.9194088 11.639375,15.2742112 7.5,15.2742112 C3.360625,15.2742112 0,11.9194088 0,7.77796312 C0,7.13013921 0.0825,6.50169999 0.236875,5.90202515 L1.53625,5.90202515 Z M8.005,4.01858343 C8.1875,4.53196512 8.239375,4.86025427 8.63625,5.0247115 C9.034375,5.18979404 9.306875,4.99219524 9.795,4.76020424 L10.50875,5.4743113 C10.275625,5.96643236 10.079375,6.23594211 10.244375,6.63364096 C10.408125,7.03008918 10.734375,7.08136482 11.25,7.26458143 L11.25,8.275712 C10.74,8.45705267 10.408125,8.51020424 10.24375,8.90727778 C10.07875,9.30497663 10.27625,9.57698763 10.50875,10.0659821 L9.795,10.7800892 C9.30125,10.5455969 9.033125,10.3504994 8.63875,10.5149566 C8.239375,10.6806645 8.186875,11.0108296 8.005,11.5223353 L6.995,11.5223353 C6.8125,11.0089536 6.760625,10.6800392 6.36375,10.5155819 C5.965625,10.3504994 5.69375,10.5480982 5.205,10.7800892 L4.49125,10.0659821 C4.72,9.58386607 4.921875,9.30810319 4.755625,8.90665247 C4.59125,8.50957893 4.26,8.45705267 3.75,8.275712 L3.75,7.26458143 C4.260625,7.08324076 4.59125,7.03133981 4.755625,6.63364096 C4.920625,6.2353168 4.72375,5.96455642 4.49125,5.4743113 L5.205,4.76020424 C5.6975,4.99407118 5.96625,5.18979404 6.36375,5.0247115 C6.760625,4.86025427 6.813125,4.52946387 6.995,4.01858343 L8.005,4.01858343 Z M7.5,9.02108468 C6.809375,9.02108468 6.25,8.46080454 6.25,7.77045937 C6.25,7.07948888 6.809375,6.51920874 7.5,6.51920874 C8.19,6.51920874 8.75,7.07948888 8.75,7.77045937 C8.75,8.46080454 8.19,9.02108468 7.5,9.02108468 Z"
            id="Shape"></path>
        </g>
      </g>
    </g>
  </svg>
);

const ResetIcon = (props) => <Icon component={ResetSvg} {...props} />;

function ResetToDefault({ onClick }) {
  return (
    <Tooltip title="Reset to default">
      <Button type="link" onClick={onClick} icon={<ResetIcon />} />
    </Tooltip>
  );
}

ResetToDefault.propTypes = { onClick: PropTypes.func };

export default ResetToDefault;
