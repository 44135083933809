import React from 'react';
import PropTypes from 'prop-types';
import { datetime } from 'rosis-date-picker';
import { _t } from 'rosis-translation';
import { makeToText } from 'make-to-text';
import YearCompare from '../../../utils/compare/Year';
import parseValueToQuery from '../../../utils/parseValue';
import Item from '../../../Item';

const toText = makeToText('Date', 'year', { _t });

function Year({ value, ...rest }) {
  return <Item {...rest}>{toText(value)}</Item>;
}

Year.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const options = {
  value: 'year',
  get label() {
    return _t('::Year');
  },
  toText,
  component: Year,
  compareComponent: YearCompare,
  parseValueToQuery: parseValueToQuery.date,
  example: <Year value={datetime('1995-11-25 11:22:00')} />
};

export default options;
