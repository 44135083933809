import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Input, Form } from 'antd';
import { useQueryClient } from 'react-query';
import { _t } from 'rosis-translation';
import { useCreateRole } from 'rosis-api';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';
import { useNavigation } from 'router/links';

function NewRoleModal({ isVisible, setIsVisible }) {
  const navigation = useNavigation();
  const queryClient = useQueryClient();
  const {
    mutate: create,
    data: createData,
    isLoading: isCreateLoading,
    ...createStatus
  } = useCreateRole(queryClient);
  useApiFeedback({
    status: {
      ...createStatus,
      isLoading: isCreateLoading
    },
    errorMessage: _t('role::Error while adding role'),
    onError: () => handleCancel(),
    onSuccess: () => {
      navigation.privilegesForRole(createData.name);
      setIsVisible(false);
      form.resetFields();
    }
  });

  const [form] = Form.useForm();

  const roleNameInputRef = useRef();

  const validateForm = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (err) {
      return false;
    }
  };

  const handleOk = async () => {
    const isValid = await validateForm();
    if (!isValid) {
      return;
    }
    const { name } = form.getFieldsValue();
    if (!isCreateLoading) {
      create(name);
    }
  };

  const handleCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };

  useEffect(() => {
    if (isVisible && roleNameInputRef.current) {
      setTimeout(() => {
        roleNameInputRef.current.focus();
      }, 0);
    }
  }, [isVisible, roleNameInputRef]);

  return (
    <Modal title={_t('::Add new role')} open={isVisible} onOk={handleOk} onCancel={handleCancel}>
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          rules={[
            { required: true, message: _t("::It's required") },
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.resolve(null);
                }
                const escaped = value.trim().replace(/[^a-z0-9_]/g, (chr) => {
                  return chr.match(/[A-Z]/) ? chr.toLowerCase() : '';
                });
                if (escaped.match(/^[a-z]+$/)) {
                  return Promise.resolve(null);
                }
                return Promise.reject(_t('new.role::Invalid name'));
              }
            }
          ]}
          label={_t('role::Name')}>
          <Input ref={roleNameInputRef} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

NewRoleModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired
};

export default NewRoleModal;
