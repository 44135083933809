import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { _t } from 'rosis-translation';
import UserSettingsTab from './UserSettingsTab';
import symbol from 'system/symbol';

const { panels } = symbol;

function UserSettings({ title }) {
  const tabs = useMemo(
    () =>
      Object.entries(panels)
        .filter(([key]) => key !== 'admin')
        .map(([key, label]) => ({
          label: _t(label),
          key,
          children: <UserSettingsTab title={title} appName={key} />
        })),
    [panels]
  );
  return <Tabs destroyInactiveTabPane items={tabs} />;
}

UserSettings.propTypes = {
  title: PropTypes.string
};

export default UserSettings;
