import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Typography, Row, Col, Result, Alert } from 'antd';

import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { _t } from 'rosis-translation';
import {
  useConfigs,
  useRegex,
  useChangePasswordByToken,
  apiUrl,
  useCheckToken,
  useGetQuestionsByUser
} from 'rosis-api';

import { useInfoBar } from '../../InfoBar/infoBar';
import PasswordForm from '../PasswordForm';
import SecretQuestions from '../../SecretQuestions';
import { useApiFeedback } from '../../hooks/use-api-feedback';
import { useImageTitleCfg } from '../../hooks/useImageTitleCfg';

import './ResetPasswordPanel.less';

const { Title } = Typography;

function ResetPasswordPanel({ hash, configPrefix, loginPath }) {
  const queryClient = useQueryClient();
  const { data: regex } = useRegex();
  const { data: pubConfig } = useConfigs('pub');
  const { data: checkToken, isLoading: isLoadingCheckToken } = useCheckToken({ tokenId: hash });
  const { data: questionsByUser } = useGetQuestionsByUser({ tokenId: hash });
  const { image, title } = useImageTitleCfg({ configPrefix });
  const { pswdWidget } = { ...pubConfig };
  const userBlockedAlertRef = useRef();

  const [secretQuestions, setSecretQuestions] = useState([]);

  const {
    mutate: changePassword,
    isLoading: isChangePaswordLoading,
    isSuccess: isChangePasswordSuccess,
    ...changePasswordStatus
  } = useChangePasswordByToken(queryClient);

  useEffect(() => {
    if (questionsByUser) {
      setSecretQuestions(
        questionsByUser.map((question) => ({
          id: question._id,
          value: ''
        }))
      );
    }
  }, [questionsByUser]);

  const [isUserBlocked, setUserBlocked] = useState(false);

  useApiFeedback({
    status: {
      isLoading: isChangePaswordLoading,
      isSuccess: isChangePasswordSuccess,
      ...changePasswordStatus
    },
    silentSuccess: true,
    errorMessage: _t('error::Error while setting new password'),
    onError: (message, error) => {
      const isBlocked = !!error?.response?.data?.blocked;
      setUserBlocked(isBlocked);
      if (isBlocked) {
        setTimeout(() => {
          userBlockedAlertRef.current?.scrollIntoView({ block: 'center' });
        }, 10);
      }
    }
  });

  const submit = (values) => {
    changePassword({
      tokenId: hash,
      hash,
      data: {
        password: values.password,
        secretQuestions
      }
    });
  };

  return (
    <div
      className="reset-password"
      style={
        image
          ? {
              backgroundImage: `url("${apiUrl}${image}")`
            }
          : {}
      }>
      <Row>
        <Col xl={{ span: 8, offset: 8 }} span={24}>
          <Card title={title}>
            <Title level={3} style={{ textAlign: 'center' }}>
              {_t('::Set password')}
            </Title>
            {isChangePasswordSuccess && (
              <Result
                status="success"
                title={_t('::Password has been changed')}
                extra={[
                  <Link key="login" to={loginPath}>
                    <Button type="primary">{_t('::Go to login')}</Button>
                  </Link>
                ]}
              />
            )}
            {!checkToken && !isLoadingCheckToken ? (
              <Result
                status={'error'}
                title={_t('::This link expired')}
                subTitle={_t('::Try reset password again')}></Result>
            ) : null}
            {checkToken && !isChangePasswordSuccess && (
              <>
                {isUserBlocked && (
                  <div ref={userBlockedAlertRef}>
                    <Alert
                      className="reset-password__alert"
                      description={_t(
                        '::Too many failed attempts. Your user account has been locked. Please contact the System Administrator'
                      )}
                      type="error"
                      showIcon
                    />
                  </div>
                )}
                <p>
                  {_t(
                    '::To be able to set a new password, answer your secret question. Contact the System Administrator in case you forgot your answer.'
                  )}
                </p>
                <SecretQuestions
                  secretQuestions={secretQuestions}
                  questionsByUser={questionsByUser}
                  onChangeAnswer={setSecretQuestions}
                />
                <PasswordForm
                  onFormSubmit={submit}
                  showPasswordChecklist={pswdWidget === 'yes'}
                  passwordValidationPattern={regex}
                  isSubmitInProgress={isChangePaswordLoading}
                />
              </>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}

ResetPasswordPanel.propTypes = {
  hash: PropTypes.string.isRequired,
  configPrefix: PropTypes.string.isRequired,
  loginPath: PropTypes.string.isRequired
};

export default ResetPasswordPanel;
