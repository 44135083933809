import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';

function useScrollToError(errors) {
  useEffect(() => {
    for (const error of Object.values(errors)) {
      if (error?.ref) {
        error.ref.focus();
        break;
      }
    }
  }, [errors]);
}

function SFForm({ id, defaultValues, form: externalForm, children, onSubmit, onChange }) {
  const internalForm = useForm({ defaultValues });
  const form = externalForm || internalForm;
  useScrollToError(form.formState.errors);
  const {
    handleSubmit,
    formState: { isDirty }
  } = form;

  useEffect(() => {
    isDirty && onChange && onChange();
  }, [isDirty]);

  return (
    <FormProvider {...form}>
      <form id={id} className="ant-form ant-form-vertical" onSubmit={handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
}

SFForm.propTypes = {
  id: PropTypes.string,
  form: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func
};

export default SFForm;
