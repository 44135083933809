import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { _t } from 'rosis-translation';
import { fieldProps } from './utils';

const { Option } = Select;

function FieldValue({ value, selectedField, onChange }) {
  const [values, setValues] = useState([]);
  useEffect(() => {
    if (selectedField) {
      const options = selectedField.opts ? [...selectedField.opts] : ['True', 'False'];
      setValues(options);
    }
  }, [selectedField]);

  return (
    <Select
      className="conditions__element__value"
      placeholder={_t('::Value')}
      allowClear
      value={value}
      onChange={onChange}>
      {values.map((value) => (
        <Option key={value} value={value}>
          {_t(value)}
        </Option>
      ))}
    </Select>
  );
}

FieldValue.propTypes = {
  value: PropTypes.any,
  selectedField: fieldProps,
  onChange: PropTypes.func
};

export default FieldValue;
