import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, InputNumber, Switch, Select, Row, Col } from 'antd';
import { bool, number, string } from 'system/helper/value';
import ConfigImage from './ConfigImage';
import EncryptedValueEditor from './EncryptedValueEditor';
import ConfigStringValue from './ConfigStringValue';
import DefaultButton from './DefaultButton';
import kinds from './kinds';

const readValue = (kind, value) => {
  switch (kind) {
    case 'bool':
      return bool(value);
    case 'number':
      return number(value);
    case 'string':
    case 'encrypted':
      return string(value);
    case 'image':
      return value || '';
    default:
      return value;
  }
};

const writeValue = (kind, value) => {
  switch (kind) {
    case 'bool':
      return value ? 'yes' : 'no';
    case 'number':
      return value !== null && value !== undefined ? String(value) : value;
    case 'string':
    case 'encrypted':
      return value ? String(value).trim() : '';
    case 'image':
    default:
      return value;
  }
};

const ConfigValuePanel = ({
  name,
  label,
  description,
  value,
  kind,
  showAs,
  error,
  group,
  options,
  editorProps,
  CustomComponent,
  dataUpdatedAt,
  onChange
}) => {
  const [currentValue, setCurrentValue] = useState();

  useEffect(() => {
    setEditedValue(value, false);
  }, [kind, value, dataUpdatedAt]);

  const setEditedValue = (value, isUserChange) => {
    const newValue = readValue(kind, value);
    setCurrentValue(newValue);
    onChange(name, writeValue(kind, newValue), group, isUserChange);
  };

  const defaultBtn = useMemo(
    () => (
      <DefaultButton
        name={name}
        group={group}
        kind={kind}
        value={currentValue}
        setEditedValue={setEditedValue}
        readValue={readValue}
      />
    ),
    [name, group, kind, currentValue, setEditedValue, readValue]
  );

  return (
    <>
      {kind === 'bool' && (
        <Form.Item
          className="settings-bool-config"
          help={error}
          validateStatus={error ? 'error' : null}>
          <Switch
            checked={!!currentValue}
            onChange={(checked) => setEditedValue(checked, true)}
            {...editorProps}
          />
          <div>
            {label}
            {defaultBtn}
            {<div className="conf-description">{description}</div>}
          </div>
        </Form.Item>
      )}
      {kind === 'number' && (
        <Form.Item
          className="settings-number-config"
          help={error}
          validateStatus={error ? 'error' : null}>
          <InputNumber
            value={currentValue}
            type="number"
            onChange={(e) => setEditedValue(e, true)}
            {...editorProps}
          />
          <div>
            {label}
            {defaultBtn}
            {<div className="conf-description">{description}</div>}
          </div>
        </Form.Item>
      )}
      {kind === 'string' && (
        <Form.Item
          label={
            <>
              {label}
              {defaultBtn}
            </>
          }
          help={error}
          validateStatus={error ? 'error' : null}>
          <ConfigStringValue
            showAs={showAs}
            value={currentValue}
            editorProps={editorProps}
            setEditedValue={setEditedValue}
            description={description}
          />
        </Form.Item>
      )}
      {kind === 'select' && (
        <Form.Item
          label={
            <>
              {label}
              {defaultBtn}
            </>
          }
          help={error}
          validateStatus={error ? 'error' : null}>
          <Row>
            <Col xl={{ span: 8 }} span={24}>
              <Select value={currentValue} onChange={(val) => setEditedValue(val, true)}>
                {options.map(({ label, value }) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Form.Item>
      )}
      {kind === 'image' && (
        <Form.Item
          label={
            <>
              {label}
              {defaultBtn}
            </>
          }
          help={error}
          validateStatus={error ? 'error' : null}>
          <ConfigImage
            name={name}
            value={currentValue}
            onChange={(file) => setEditedValue(file, true)}
            {...editorProps}
          />

          {<div className="conf-description">{description}</div>}
        </Form.Item>
      )}
      {kind === 'encrypted' && (
        <Form.Item
          label={
            <>
              {label}
              {defaultBtn}
            </>
          }
          help={error}
          validateStatus={error ? 'error' : null}>
          <EncryptedValueEditor
            name={name}
            value={currentValue}
            onChange={(value) => setEditedValue(value, true)}
            {...editorProps}
          />

          {<div className="conf-description">{description}</div>}
        </Form.Item>
      )}
      {kind === 'custom' && (
        <CustomComponent
          label={label}
          name={name}
          value={currentValue}
          help={error}
          validateStatus={error ? 'error' : null}
          onChange={(value) => setEditedValue(value, true)}
          resetButton={defaultBtn}
          {...editorProps}
        />
      )}
    </>
  );
};

ConfigValuePanel.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.any,
  showAs: PropTypes.string,
  kind: PropTypes.oneOf(kinds).isRequired,
  group: PropTypes.string.isRequired,
  editorProps: PropTypes.any,
  dataUpdatedAt: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  onChange: PropTypes.func.isRequired,
  CustomComponent: PropTypes.elementType
};

export default ConfigValuePanel;
