import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Form, Input } from 'antd';
import { SFSubmit } from 'rosis-setting-form';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { _t } from 'rosis-translation';
import PswChecklist from '../PswChecklist';
import GeneratePasswordButton from '../GeneratePasswordButton';
import QuestionsList from '../../QuestionsList';
import './PasswordForm.less';

const PasswordForm = ({
  showPasswordChecklist,
  passwordValidationPattern,
  isSubmitInProgress,
  isSecretQuestionVisible,
  onFormSubmit
}) => {
  const [passwd, setPasswd] = useState('');
  const [capsLock, setCapsLock] = useState(false);

  const [form] = Form.useForm();

  const checkForCapslock = (keyEvent) => {
    try {
      setCapsLock(keyEvent.getModifierState('CapsLock'));
    } catch (err) {
      setCapsLock(false);
    }
  };

  const fillPassword = (generatedPsw) => {
    form.setFieldsValue({
      password: generatedPsw
    });
    setPasswd(generatedPsw);
  };

  return (
    <div className="password-form">
      <Form layout="vertical" name="basic" onFinish={onFormSubmit} form={form}>
        {isSecretQuestionVisible ? <QuestionsList /> : null}
        <GeneratePasswordButton onGeneration={fillPassword} />
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: _t('::Please input your new password')
            },
            {
              pattern: new RegExp(passwordValidationPattern),
              message: _t("::Password doesn't meet complexity requirements")
            }
          ]}>
          <Input.Password
            onKeyDown={checkForCapslock}
            onChange={(e) => setPasswd(e.target.value)}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <Form.Item
          label={_t('::Confirm Password')}
          name="confirm"
          validateTrigger="onSubmit"
          dependencies={['password']}
          rules={[
            { required: true, message: _t('::Please input password again') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error(_t('::The two passwords that you entered do not match'))
                );
              }
            })
          ]}>
          <Input.Password
            onKeyDown={checkForCapslock}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        {capsLock && <Alert message={_t('::Caps lock is on')} type="warning" showIcon />}
        {showPasswordChecklist && <PswChecklist password={passwd} />}

        <Form.Item>
          <SFSubmit label={_t('button,store::Save')} loading={isSubmitInProgress} />
        </Form.Item>
      </Form>
    </div>
  );
};

PasswordForm.propTypes = {
  showPasswordChecklist: PropTypes.bool,
  passwordValidationPattern: PropTypes.string,
  isSubmitInProgress: PropTypes.bool,
  isSecretQuestionVisible: PropTypes.bool,
  onFormSubmit: PropTypes.func
};

PasswordForm.defaultProps = {
  isSecretQuestionVisible: false
};

export default PasswordForm;
