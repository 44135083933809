import { useMemo } from 'react';

const useIsSitesEditionAvailable = ({ extra }) => {
  const result = useMemo(() => {
    const loginPoint = extra?.loginPoint;
    return {
      data: loginPoint !== 'admin'
    };
  }, [extra]);
  return result;
};

export default useIsSitesEditionAvailable;
