import React, { useContext, createContext, useMemo } from 'react';
import AppLoading from 'rosis-components/AppLoading';
import { useModelsLabel, useFieldsLabel } from 'rosis-api';

const ModelLabelsContext = createContext();

export function useModelsLabels() {
  const { modelLabels, fieldLabels } = useContext(ModelLabelsContext);
  return {
    modelLabels,
    fieldLabels
  };
}

const ModelLabelsProvider = ({ children }) => {
  const { data: modelLabels, isLoading: isModelLabelsLoading } = useModelsLabel();
  const { data: fieldLabels, isLoading: isFieldLabelsLoading } = useFieldsLabel();

  const contextValue = useMemo(
    () => ({
      modelLabels,
      fieldLabels
    }),
    [modelLabels, fieldLabels]
  );

  if (isModelLabelsLoading || isFieldLabelsLoading) {
    return <AppLoading />;
  }
  return <ModelLabelsContext.Provider value={contextValue}>{children}</ModelLabelsContext.Provider>;
};


export default ModelLabelsProvider;