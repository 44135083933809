import React from 'react';
import PropTypes from 'prop-types';
import { datetime } from 'rosis-date-picker';
import { _t } from 'rosis-translation';
import { makeToText } from 'make-to-text';
import DateTimeCompare from '../../../utils/compare/Datetime';
import parseValueToQuery from '../../../utils/parseValue';
import Item from '../../../Item';

const toText = makeToText('DateTime', 'datetime', { _t });

function DateTime({ value, ...rest }) {
  return <Item {...rest}>{toText(value)}</Item>;
}

DateTime.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
const options = {
  value: 'datetime',
  get label() {
    return _t('::Date & time');
  },
  toText,
  component: DateTime,
  compareComponent: DateTimeCompare,
  parseValueToQuery: parseValueToQuery.date,
  example: <DateTime value={datetime('1995-11-25 11:22:00')} />,
  default: true
};

export default options;
