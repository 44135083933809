import React from 'react';
import { _t } from 'rosis-translation';
import PropTypes from 'prop-types';
import { InputNumber } from 'antd';
import Item from '../../../Item';

const FTNumber = React.forwardRef((props, ref) => {
  const { min, max, step, decimal, value, onChange } = props;
  return (
    <Item {...props}>
      <InputNumber
        ref={ref}
        type="number"
        max={max}
        min={min}
        step={step}
        precision={decimal}
        value={value}
        onChange={onChange}
      />
    </Item>
  );
});

FTNumber.displayName = 'FTNumber';

FTNumber.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  decimal: PropTypes.number
};

const options = {
  value: 'number',
  get label() {
    return _t('::Number');
  },
  component: FTNumber,
  example: <FTNumber name="number" value={23} />,
  default: true
};

export default options;
