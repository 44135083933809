import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'antd';
import classNames from 'classnames';
import { _t } from 'rosis-translation';
import './DisplayName.less';
import DisclaimerMessage from 'components/DisclaimerMessage';

function DisplayName({ fields, displayNameText, onAddField, onChangeDisplayName }) {
  const displayNameInput = useRef(null);
  const [cursorPositions, setCursorPositions] = useState(null);

  useEffect(
    () =>
      (displayNameInput.current.input.onblur = (e) => {
        if (e) {
          const { selectionStart, selectionEnd } = e.target;
          setCursorPositions([selectionStart, selectionEnd]);
        }
      }),
    [displayNameInput]
  );

  const addField = (name) => {
    onAddField(name, cursorPositions);
    const currentValue = displayNameInput.current.input.value || '';
    setTimeout(() => {
      const newPosition =
        (cursorPositions ? cursorPositions[0] : currentValue.length + 1) + name.length + 2;
      displayNameInput.current.focus();
      displayNameInput.current.setSelectionRange(newPosition, newPosition);
    }, 0);
  };

  return (
    <div>
      <DisclaimerMessage
        lines={[
          _t(
            '::Use section fields to create display name that will be shown in section details title'
          ),
          _t('::Please select fields that will be unique for record')
        ]}
      />
      <div className="display-name">
        <Input
          ref={displayNameInput}
          value={displayNameText}
          onChange={(e) => onChangeDisplayName(e.target.value)}
        />
        <div className="buttons">
          {fields?.map(({ name, label, required }) => (
            <Button
              key={name}
              value={name}
              size="small"
              className={classNames('field-button', required ? 'field-button__required' : null)}
              onClick={() => addField(name)}>
              {_t(label)}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
}

DisplayName.propTypes = {
  fields: PropTypes.array,
  displayNameText: PropTypes.string,
  onAddField: PropTypes.func,
  onChangeDisplayName: PropTypes.func
};

export default DisplayName;
