import React, { useMemo } from 'react';
import { _t } from 'rosis-translation';
import ThemeConfig from 'views/Settings/ThemeConfig';
import MainSettings from './MainSettings';
import PasswordSettings from './PswdSettings';
import UserSettings from './UserSettings';
import SecuritySettings from './SecuritySettings';
import HtmlHeadersSettings from './HtmlHeadersSettings';
import DevMode from './DevMode';
import { panels } from 'system/symbol';
import PerAppSettingsTab from './AppSettings/PerAppSettingsTab';
import GlobalSettingsTab from './AppSettings/GlobalSettingsTab';
import EmailConfigTab from './EmailSettings/EmailConfigTab';
import EmailTemplatesTab from './EmailSettings/EmailTemplatesTab';
import SubSettings from './SubSettings';

export const tabDefs = [
  {
    id: 'main',
    label: '::Main settings',
    factory: (l) => <MainSettings title={l} />,
    enabled: () => true
  },
  {
    id: 'app',
    label: '::App view',
    subSettings: [
      {
        id: 'global',
        label: '::Global',
        factory: (l) => <GlobalSettingsTab title={l} />,
        enabled: () => true
      },
      {
        id: 'default',
        label: '::Default',
        factory: (l) => <PerAppSettingsTab title={l} />,
        enabled: () => true
      },
      ...Object.entries(panels).map(([name, label]) => ({
        id: name,
        label,
        factory: (l) => <PerAppSettingsTab title={l} appName={name} />,
        enabled: () => true
      }))
    ],
    enabled: () => true
  },
  {
    id: 'password',
    label: '::Password',
    factory: (l) => <PasswordSettings title={l} />,
    enabled: () => true
  },
  {
    id: 'users',
    label: '::Users',
    factory: (l) => <UserSettings title={l} />,
    enabled: () => true
  },
  {
    id: 'email',
    label: '::Email',
    subSettings: [
      {
        id: 'templates',
        label: _t('::Email templates'),
        factory: (l) => <EmailTemplatesTab title={l} />,
        enabled: () => true
      },
      {
        id: 'configuration',
        label: _t('::Email configuration'),
        factory: (l) => <EmailConfigTab title={l} />,
        enabled: () => true
      }
    ],
    enabled: () => true
  },
  {
    id: 'security',
    label: '::Security',
    factory: (l) => <SecuritySettings title={l} />,
    enabled: () => true
  },
  {
    id: 'html-headers',
    label: '::Html Head',
    factory: (l) => <HtmlHeadersSettings title={l} />,
    enabled: (devMode) => devMode < 0
  },
  {
    id: 'theme',
    label: 'motive::Themes',
    factory: (l) => <ThemeConfig title={l} />,
    enabled: () => true
  },
  {
    id: 'devMode',
    label: 'motive::Dev Mode',
    factory: (l) => <DevMode title={l} />,
    enabled: (devMode) => devMode < 0
  }
];

export const useTabs = (tabs, devMode) =>
  useMemo(() => {
    const result = tabs
      .filter((t) => t.enabled(devMode))
      .map((s) => {
        let children = s.factory ? s.factory(_t(s.label)) : <SubSettings tabs={s.subSettings} />;
        return {
          key: s.id,
          label: _t(s.label),
          subSettings: s.subSettings,
          children
        };
      });
    return result || [];
  }, [devMode]);
