import React from 'react';
import PropTypes from 'prop-types';
import { SFCheckbox } from 'rosis-setting-form';
import { _t } from 'rosis-translation';

function Enumerable(props) {
  return <SFCheckbox {...props} />;
}

Enumerable.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
};

const enumerable = {
  get label() {
    return _t('::Enumerable');
  },
  Field: Enumerable,
  name: 'enumerable',
  order: 1
};

export default enumerable;
