import React, { useState, useEffect } from 'react';
import { Card, Timeline, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { quadDot } from 'system/symbol';
import { Link } from 'rosis-components/navigation-wrapper';
import { useLogs, useSchema } from 'rosis-api';
import { datetime } from 'rosis-date-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';
import TitleSection from './TitleSection';
import { _t } from 'rosis-translation';
import { ClockCircleOutlined } from '@ant-design/icons';
import { links } from 'router/links';

const LinkToLogs = () => <Link to={links.logs()}>{_t('::Show more')}</Link>;

const getColorLog = (value) => {
  if (value === 'err') {
    return 'red';
  } else if (value === 'warn') {
    return 'gray';
  } else {
    return 'blue';
  }
};

function Logs({ label, changed }) {
  const { data: schema } = useSchema('log');
  const {
    data: logs,
    isLoading,
    refetch
  } = useLogs({
    page: 1,
    elementsPerPage: 15,
    sortBy: { _createdAt: -1 }
  });
  const { elements } = logs || {};
  const [eventList, setEventList] = useState({});

  useEffect(() => {
    if (schema?.fields?.event?.opts) {
      const { opts } = schema?.fields?.event;
      let list = opts.map((opt) => [opt.split(quadDot)[0], opt]);

      setEventList(Object.fromEntries(list));
    }
  }, [schema]);

  useEffect(() => {
    refetch();
  }, [changed]);

  return (
    <Card
      title={
        <TitleSection
          title={_t(label)}
          icon={<FontAwesomeIcon icon={faUserShield} color="#86888d" />}
        />
      }
      extra={<LinkToLogs />}
      className="log-section">
      {isLoading ? (
        <Skeleton />
      ) : (
        <div className="log-section_timeline">
          <Timeline>
            {(elements || []).map((log) => (
              <Timeline.Item
                key={log._id}
                color={getColorLog(log.level)}
                dot={<ClockCircleOutlined />}>
                <div>{datetime(log._createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                <div>{_t(eventList[log.event])}</div>
                <div>{log.creator}</div>
              </Timeline.Item>
            ))}
          </Timeline>
        </div>
      )}
    </Card>
  );
}

Logs.propTypes = {
  label: PropTypes.string,
  changed: PropTypes.number
};

export default Logs;
