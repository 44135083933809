import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Result, Button } from 'antd';
import { _t } from 'rosis-translation';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true
    });

    console.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <Result
          className="site-layout error-boundary"
          status="error"
          title={_t('::Application error')}
          subTitle={_t('::An error occurred while the application was running.')}
          extra={[
            <Button type="primary" key="console" onClick={() => window.refresh()}>
              {_t('::Refresh page')}
            </Button>
          ]}
        />
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired
};

export default ErrorBoundary;
