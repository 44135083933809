import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { useRemoveField } from 'rosis-api';
import { Button } from 'antd';
import { useChange } from 'contexts/change';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { _t } from 'rosis-translation';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';

const checkSelected = (fieldList) => {
  return Array.isArray(fieldList) && fieldList.find((field) => field.selected);
};

const getFieldsNames = (fieldList) => {
  return fieldList.filter((field) => field.selected);
};

function RemoveFields({ fields, frameName }) {
  const queryClient = useQueryClient();
  const { setIsChanged } = useChange();
  const {
    mutate: remove,
    isLoading: isRemoving,
    ...removeStatus
  } = useRemoveField(queryClient, frameName);
  useApiFeedback({
    status: {
      ...removeStatus,
      isLoading: isRemoving
    },
    successMessage: _t('::The selected fields have been removed'),
    errorMessage: _t('fields::Error while removing fields'),
    onSuccess: () => setIsChanged(false)
  });

  const removeFields = () => {
    let fieldsToRemove = getFieldsNames(fields).map((field) => field.name);
    remove(fieldsToRemove.join(','));
  };

  return checkSelected(fields) ? (
    <Button danger type="default" onClick={removeFields} loading={isRemoving}>
      <FontAwesomeIcon icon={faTrash} />
      {_t('button::Remove fields')}
    </Button>
  ) : null;
}

RemoveFields.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      selected: PropTypes.bool
    })
  ),
  frameName: PropTypes.string
};

export default RemoveFields;
