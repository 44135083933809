import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

function RoleCheckbox({ type, label, privileges, setPrivileges, disabled }) {
  const handleChange = ({ target }) => {
    const { checked } = target;
    setPrivileges(type, checked);
  };
  return (
    <Checkbox checked={privileges.includes(type)} disabled={disabled} onChange={handleChange}>
      {label}
    </Checkbox>
  );
}

RoleCheckbox.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  privileges: PropTypes.array.isRequired,
  setPrivileges: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default RoleCheckbox;
