import React from 'react';
import PropTypes from 'prop-types';
import { Table, Empty } from 'antd';
import { _t } from 'rosis-translation';
import './Logs.less';

const LogsDesktop = ({ elements, isLoading, columns }) => {
  return (
    <Table
      scroll={{ x: true }}
      columns={columns}
      size="middle"
      pagination={false}
      rowClassName={(record) => record.level}
      loading={isLoading}
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={_t('logs::No logs')} />
      }}
      dataSource={elements}
      rowKey="_id"
    />
  );
};

LogsDesktop.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      _createdAt: PropTypes.string,
      event: PropTypes.string,
      creator: PropTypes.string,
      client: PropTypes.string,
      value: PropTypes.string
    })
  ),
  isLoading: PropTypes.bool,
  eventList: PropTypes.shape({}),
  columns: PropTypes.array
};

export default LogsDesktop;
