import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import DatePicker from '../../../utils/DatePicker';
import { datetime } from 'rosis-date-picker';
import Item from '../../../Item';

const FTDate = React.forwardRef((props, ref) => {
  const { value, min, max, editAs, onChange } = props;
  const displayValue = value ? datetime(value) : null;

  return (
    <Item {...props} value={displayValue}>
      <DateEditor
        ref={ref}
        value={displayValue}
        min={min}
        max={max}
        editAs={editAs}
        onChange={onChange}
      />
    </Item>
  );
});

FTDate.displayName = 'FTDate';

FTDate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  min: PropTypes.object,
  max: PropTypes.object,
  editAs: PropTypes.string,
  onChange: PropTypes.func
};

const DateEditor = React.forwardRef(({ value, min, max, editAs, onChange }, ref) => {
  const [editorValue, setEditorValue] = useState(value);

  useEffect(() => {
    if (value !== editorValue) {
      setEditorValue(value);
    }
  }, [value]);

  return (
    <DatePicker
      ref={ref}
      value={editorValue}
      min={min}
      max={max}
      editAs={editAs}
      onChange={onChange}
    />
  );
});

DateEditor.displayName = 'DateEditor';

DateEditor.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  min: PropTypes.object,
  max: PropTypes.object,
  editAs: PropTypes.string,
  onChange: PropTypes.func
};

const options = {
  value: 'date',
  get label() {
    return _t('::Date');
  },
  component: FTDate,
  example: <FTDate name="date" value={'08/12/1992'} />,
  default: true
};

export default options;
