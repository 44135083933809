import React from 'react';
import PropTypes from 'prop-types';
import { Form, Radio } from 'antd';
import { _t } from 'rosis-translation';

const { Item } = Form;

function Direction({ direction, changeDirection }) {
  const options = [
    { value: 'ltr', label: 'LTR' },
    { value: 'rtl', label: 'RTL' }
  ];

  return (
    <Item type="Radio" label={_t('language::Direction')}>
      <Radio.Group value={direction}>
        {options?.map(({ value, label }) => (
          <Radio key={value} value={value} onChange={changeDirection}>
            {label}
          </Radio>
        ))}
      </Radio.Group>
    </Item>
  );
}

Direction.propTypes = {
  direction: PropTypes.string,
  changeDirection: PropTypes.func
};

export default Direction;
