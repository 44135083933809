import { useMutation } from 'react-query';

import { url, put, del } from './internal/rest-api';

export const useChangePassword = () => useMutation((pswds) => put('user/passwd', pswds));

export const useUsersUpdate = (queryClient) =>
  useMutation(({ id, value }) => put(url`user/enable/${id}/${value}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(['db', 'data']);
    }
  });

export const useUserRemove = (queryClient) =>
  useMutation(({ id, reason }) => del(url`user/${id}`, { params: { reason } }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['db', 'data']);
    }
  });

export const useUserUnlock = (queryClient, id) =>
  useMutation(() => del(url`penalty/${id}`), {
    onSuccess: () => queryClient.invalidateQueries(['db', 'data'])
  });