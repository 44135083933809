import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import classNames from 'classnames';
import { _t } from 'rosis-translation';
import './style.less';

const prepareRules = (type, required, min, max, regex, customRules) => {
  if (customRules) {
    return customRules;
  }
  const rules = [];
  required && rules.push({ required: true, message: _t("::It's required") });
  if (type === 'String') {
    min && rules.push({ min, message: _t('::Must be at least {min}', { min }) });
    max && rules.push({ max, message: _t('::Cannot be longer than {max} characters', { max }) });
    regex &&
      rules.push({
        pattern: regex,
        message: _t('::Format of the value is invalid')
      });
  }
  if (type === 'Boolean') {
    // required for boolean is stronger than for other fields - so second validation rule here
    required &&
      rules.push({
        validator(_, value) {
          if (value !== false) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(_t("::It's required")));
        }
      });
  }
  if (type === 'File') {
    rules.push({
      validator(_, value) {
        if (!value) {
          return Promise.resolve();
        }
        if (value?.uploadError === 'invalid-extension') {
          return Promise.reject(new Error(_t('file_upload::File has invalid extension')));
        }
        return Promise.resolve();
      }
    });
  }

  return rules;
};

const Item = ({
  name,
  label,
  required,
  hint,
  value,
  min,
  max,
  valuePropName,
  type,
  regex,
  className,
  noStyle,
  children,
  customRules,
  help,
  validateStatus,
  extra
}) => {
  const rules = prepareRules(type, required, min, max, regex, customRules);
  return (
    <Form.Item
      className={classNames('rosis-field-type', className)}
      label={_t(label)}
      tooltip={_t(hint)}
      required={required}
      help={help}
      validateStatus={validateStatus}
      noStyle={noStyle}>
      {extra}
      <Form.Item
        initialValue={value}
        valuePropName={valuePropName}
        name={name}
        rules={rules}
        noStyle>
        {children}
      </Form.Item>
    </Form.Item>
  );
};

Item.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  hint: PropTypes.string,
  value: PropTypes.any,
  min: PropTypes.any,
  max: PropTypes.any,
  valuePropName: PropTypes.string,
  type: PropTypes.string,
  regex: PropTypes.string,
  className: PropTypes.string,
  noStyle: PropTypes.bool,
  children: PropTypes.node,
  customRules: PropTypes.any,
  help: PropTypes.string,
  validateStatus: PropTypes.string,
  extra: PropTypes.object
};

export default Item;
