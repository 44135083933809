import React from 'react';
import PropTypes from 'prop-types';

const TitleSection = ({ title, icon }) => (
  <div>
    {icon}
    <span className="dashboard-title__section">{title}</span>
  </div>
);

TitleSection.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node
};

export default TitleSection;
