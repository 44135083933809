import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'rosis-contexts/auth';
import SessionExpired from './SessionExpired';
import ModelLabelsProvider from './ModelLabelsProvider';


function PrivateRoute({ element, onlyFor }) {
  const { user } = useAuth();

  onlyFor = Array.isArray(onlyFor) ? onlyFor : [onlyFor];
  return onlyFor.includes(user?.loginPoint) ? (
    <ModelLabelsProvider>{element}</ModelLabelsProvider>
  ) : (
    <SessionExpired />
  );
}
PrivateRoute.propTypes = {
  element: PropTypes.element,
  onlyFor: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])
};

export default PrivateRoute;
