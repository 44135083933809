import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Typography, Row, Col, Button } from 'antd';
import RemoveModel from 'components/RemoveModel';
import { _t } from 'rosis-translation';
import { usePageHeader } from 'contexts/pageHeader';
import TitleActions from 'components/TitleActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import NewRoleModal from './NewRoleModal';
import PageTitle from 'components/PageTitle';

const { Title } = Typography;

function PrivilegesLayout({ model, role, label, children, onRemove }) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const actions = useMemo(() => {
    const result = [
      <Button key="add" type="primary" onClick={() => setIsModalVisible(true)}>
        <FontAwesomeIcon icon={faPlusCircle} />
        {_t('::Add new role')}
      </Button>
    ];
    if (model) {
      result.push(
        <RemoveModel
          key="remove"
          name={role}
          label={label}
          onSuccess={onRemove}
          isProtected={model.protected}
          confirmText={_t('::Confirm removing a role')}
          errorMessages={{
            mainText: _t("::You can't remove this role because:"),
            isProtected: _t('::This role is protected by system'),
            hasElements: _t('::There are user with this role in the system')
          }}
        />
      );
    }
    return result;
  }, [model, label]);

  const title = _t('::Privileges');

  usePageHeader(
    <TitleActions actions={actions}>
      <Row justify="space-between">
        <Col>{title}</Col>
        <Col className="privileges__main-buttons"></Col>
      </Row>
    </TitleActions>,
    [actions]
  );
  return (
    <>
      <PageTitle title={title} />
      <Card className="privileges">
        <Title level={3}></Title>
        <NewRoleModal isVisible={isModalVisible} setIsVisible={setIsModalVisible} />
        {children}
      </Card>
    </>
  );
}

PrivilegesLayout.propTypes = {
  model: PropTypes.object,
  role: PropTypes.string,
  label: PropTypes.string,
  onRemove: PropTypes.func,
  children: PropTypes.node
};

export default PrivilegesLayout;
