import { useQuery, useMutation } from 'react-query';

import { getData, put, url } from './internal/rest-api';

export const useSecretQuestions = () => useQuery('secret-questions', () => getData(`question/all`));

export const useSecretQuestionsForUsers = () => useQuery('secret-questions-for-users', () => getData(`questions`));

export const useUpdateQuestion = (queryClient) =>
  useMutation((data) => put(url`question`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries('secret-questions');
    }
  });