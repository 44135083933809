import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Space } from 'antd';
import PinCodeInputElement from './PinCodeInputElement';

const PIN_LENGTH = 6;

function PinCodeInput({ onChange, onValidityChange }) {
  const inputsRef = useRef([]);
  const [values, setValues] = useState(new Array(PIN_LENGTH).fill(null));
  const focus = (index) => inputsRef.current[index].focus();
  const blur = (index) => inputsRef.current[index].blur();
  const set = (index, value) => {
    values.splice(index, 1, value);
    setValues([...values]);
  };
  const handleChange = (index) => (newValue) => {
    if (newValue) {
      if (newValue.length > 1) {
        for (const i in newValue) {
          if (i >= values.length - index) break;
          set(index + Number.parseInt(i), newValue[i]);
        }
        blur(index);
      } else {
        set(index, newValue);
        if (index < values.length - 1) {
          focus(index + 1);
        } else {
          blur(index);
        }
      }
    }
  };

  useEffect(() => {
    if (values) {
      onChange({ pin: values.join('') });
      onValidityChange(values.every((v) => v?.trim()));
    }
  }, [values]);

  return (
    <Space className="pin-code__input-code-wrapper">
      {values.map((value, index) => (
        <PinCodeInputElement
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          refProp={inputsRef}
          index={index}
          value={value}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={index === 0}
          onChange={handleChange(index)}
          changeFocus={focus}
          changeValue={set}
          blur={blur}
        />
      ))}
    </Space>
  );
}

PinCodeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onValidityChange: PropTypes.func.isRequired
};

export default PinCodeInput;
