import React, { useEffect, useMemo } from 'react';
import { Form, Button, Row, Col, Skeleton } from 'antd';
import { _t } from 'rosis-translation';
import { useQueryClient } from 'react-query';
import { EditField } from 'rosis-field-types';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import DefaultLang from './DefaultLang';
import { useGetMyData, useSchema, useEditMyProfile, useMyUserId } from 'rosis-api';
import { usePageHeader } from 'contexts/pageHeader';
import TitleActions from 'components/TitleActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useChange } from 'contexts/change';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';

function EditMyData() {
  const { isChanged, setIsChanged } = useChange();
  const queryClient = useQueryClient();
  const { data: user } = useMyUserId();
  const { showInfoBar } = useInfoBar();
  const { data: schema, isLoading: isSchemaLoading } = useSchema(user?.role || 'admin');
  const fieldsList = schema?.fields;
  const {
    data: userInfo,
    isLoading: isMyDataLoading,
    dataUpdatedAt,
    refetch: refetchMyData
  } = useGetMyData();

  const {
    mutate: edit,
    isLoading: isLoadingEditUser,
    ...editUserStatus
  } = useEditMyProfile(queryClient);

  useApiFeedback({
    status: {
      isLoading: isLoadingEditUser,
      ...editUserStatus
    },
    successMessage: _t('success::User edited successfully'),
    errorMessage: _t('error::User edit failed'),
    onSuccess: () => {
      setIsChanged(false);
    }
  });

  const cancelEdition = () => {
    refetchMyData();
    setIsChanged(false);
  };

  const saveUser = (values) => {
    const formData = new FormData();
    formData.append('_id', userInfo?._id);
    for (const field in values) {
      let elementValue = values[field] === undefined ? null : values[field];
      formData.append(field, elementValue);
    }
    edit({ formData, role: user?.role });
  };

  useEffect(() => {
    form.resetFields();
  }, [dataUpdatedAt]);

  const onSuccess = (message) => {
    showInfoBar({
      message,
      type: 'success'
    });
  };

  const onFail = (message) => {
    showInfoBar({
      message,
      type: 'error'
    });
  };

  const [form] = Form.useForm();

  const actions = useMemo(
    () => [
      isChanged
        ? [
            <Button
              key="cancel"
              type="button"
              icon={<FontAwesomeIcon icon={faTimes} />}
              isLoading={isLoadingEditUser}
              disabled={isLoadingEditUser}
              onClick={cancelEdition}>
              {_t('button::Cancel')}
            </Button>,
            <Button
              key="submit"
              icon={<FontAwesomeIcon icon={faSave} />}
              type="primary"
              isLoading={isLoadingEditUser}
              disabled={isLoadingEditUser}
              onClick={() => form.submit()}>
              {_t('button,store::Save')}
            </Button>
          ]
        : []
    ],
    [form, isChanged, isLoadingEditUser]
  );

  usePageHeader(<TitleActions actions={actions}>{_t('::My profile')}</TitleActions>, [actions]);

  if (isMyDataLoading || isSchemaLoading) {
    return <Skeleton />;
  }

  return (
    <Row gutter="16" className="my-profile">
      <Col md={8}>
        <Form
          form={form}
          scrollToFirstError={{
            block: 'center'
          }}
          onChange={() => setIsChanged(true)}
          onFinish={saveUser}>
          {fieldsList && userInfo
            ? Object.values(fieldsList)
                .filter((current) => !['Link', 'Virt'].includes(current.type))
                .map((current) => (
                  <EditField key={current.name} value={userInfo[current.name]} {...current} />
                ))
            : null}
        </Form>
      </Col>
      <Col md={16} className="default-lang">
        <DefaultLang onFail={onFail} onSuccess={onSuccess} />
      </Col>
    </Row>
  );
}

export default EditMyData;
