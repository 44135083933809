import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Input, Form, Button } from 'antd';
import { _t } from 'rosis-translation';
import TitleActions from 'components/TitleActions';
import { usePageHeader } from 'contexts/pageHeader';
import { calcSettingPageTitle } from '../utils';
import PageTitle from 'components/PageTitle';

function HeaderEditor({
  label,
  content,
  isNew,
  isEditMode,
  baseActions,
  onStartEdit,
  onSubmitEdition,
  onCancelEdition,
  otherHeaders
}) {
  const [form] = Form.useForm();

  const cancelEdition = () => {
    form.resetFields();
    onCancelEdition();
  };

  const actions = useMemo(() => {
    const result = [...baseActions];
    if (!isEditMode) {
      result.push(
        <Button key="edit" onClick={onStartEdit}>
          {_t('button::Edit')}
        </Button>
      );
    } else {
      result.push(
        <Button key="cancel" htmlType="button" onClick={cancelEdition}>
          {_t('button::Cancel')}
        </Button>
      );
      result.push(
        <Button key="save" type="primary" htmlType="submit" onClick={() => form.submit()}>
          {_t('button,store::Save')}
        </Button>
      );
    }
    return result;
  }, [isEditMode, baseActions]);

  const pageTitle = calcSettingPageTitle(_t('::Custom HTML head items'));

  usePageHeader(<TitleActions actions={actions}>{pageTitle}</TitleActions>, [baseActions]);

  return (
    <>
      <PageTitle title={pageTitle} />
      <Form
        form={form}
        initialValues={{
          label,
          content
        }}
        onFinish={(v) => onSubmitEdition(v)}>
        <Form.Item
          name="label"
          rules={[
            {
              required: true,
              message: _t("::It's required")
            },
            {
              validator: (_, value) => {
                const sameLabelExists = otherHeaders.find(
                  (otherHeader) => otherHeader.label !== label && otherHeader.label === value
                );
                if (!sameLabelExists) {
                  return Promise.resolve(null);
                }
                return Promise.reject(new Error(_t('::It should be unique')));
              }
            }
          ]}>
          <Input readOnly={!isEditMode} />
        </Form.Item>
        <Form.Item
          name="content"
          rules={[
            {
              required: isNew,
              message: _t("::It's required")
            }
          ]}>
          <Input.TextArea rows={10} readOnly={!isEditMode} />
        </Form.Item>
      </Form>
    </>
  );
}

HeaderEditor.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.string,
  isNew: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  baseActions: PropTypes.arrayOf(PropTypes.node),
  onStartEdit: PropTypes.func.isRequired,
  onSubmitEdition: PropTypes.func.isRequired,
  onCancelEdition: PropTypes.func.isRequired,
  otherHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string
    })
  )
};

HeaderEditor.defaultProps = {
  content: ''
};

export default HeaderEditor;
