import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import symbol from 'system/symbol';
import { useModelsLabels } from 'rosis-components/ModelLabelsProvider';

const RoleDefinitionChangedMessage = ({ message }) => {
  const [role, section, field, privilege, action] = message.split('/');
  const isSectionLevel = section !== '*' && field === '*';
  const isGlobalLevel = section === '*' && field === '*';

  const { modelLabels, fieldLabels } = useModelsLabels();

  const getPrivName = () => {
    if (isGlobalLevel) {
      return _t(symbol.privileges.role[privilege]);
    }
    if (isSectionLevel) {
      return _t(symbol.privileges.model[privilege]);
    }
    return _t(symbol.privileges.field[privilege]);
  };

  return (
    <>
      <div>
        <span className="message-key">{_t('::Message')}</span>
        <span>{message}</span>
      </div>
      <div>
        <span className="message-key">{_t('section_privileges::Role')}</span>
        <span>{_t(modelLabels?.[role])}</span>
      </div>
      {!isGlobalLevel && (
        <div>
          <span className="message-key">{_t('::Section')}</span>
          <span>{_t(modelLabels?.[section])}</span>
        </div>
      )}
      {!isGlobalLevel && !isSectionLevel && (
        <div>
          <span className="message-key">{_t('::Field')}</span>
          <span>{_t(fieldLabels?.[section]?.[field]?.label)}</span>
        </div>
      )}
      <div>
        <span className="message-key">{_t('::Privilege')}</span>
        <span>{getPrivName()}</span>
      </div>
      <div>
        <span className="message-key">{_t('::Action')}</span>
        <span>{_t(symbol.privileges.actions[action])}</span>
      </div>
    </>
  );
};

RoleDefinitionChangedMessage.propTypes = {
  message: PropTypes.string
};

RoleDefinitionChangedMessage.defaultProps = {
  message: ''
};

export default RoleDefinitionChangedMessage;
