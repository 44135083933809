import React, { useState, useEffect } from 'react';
import { Button, Modal, AutoComplete, message, Row, Col, Select, Tooltip } from 'antd';
import { _t } from 'rosis-translation';
import { quadDot } from 'system/symbol';
import { useEmailUpdate, useEmailTemplates, useLangs } from 'rosis-api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import { useQueryClient } from 'react-query';
import filterBy from 'system/helper/filter-by';
import PropTypes from 'prop-types';

const { Option } = AutoComplete;

const unique = (arr, props = []) => [
  ...new Map(arr.map((entry) => [props.map((k) => entry[k]).join('|'), entry])).values()
];

function AddNewEmailTemplate({
  title,
  addIcon,
  subject,
  label,
  text,
  body,
  bodyTemplateData,
  defaultName,
  isLink,
  devMode
}) {
  const queryClient = useQueryClient();
  const { data: templates } = useEmailTemplates();
  const { showInfoBar } = useInfoBar();
  const [visible, setVisible] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [name, setName] = useState(null);
  const [lang, setLang] = useState('');
  const { data: languages } = useLangs();
  const {
    isSuccess: isSuccessCreateEmail,
    isError: isErrorCreateEmail,
    isLoading,
    mutate: create
  } = useEmailUpdate(queryClient);

  useEffect(() => {
    if (defaultName) {
      setName(defaultName);
    }
  }, [defaultName]);

  useEffect(() => {
    if (isErrorCreateEmail) {
      message.error(_t('error::Error while adding a new email template'));
    }
    if (isSuccessCreateEmail) {
      setVisible(false);
      showInfoBar({
        message: _t('success::Email template added successfully'),
        type: 'success'
      });
    }
  }, [isSuccessCreateEmail, isErrorCreateEmail]);

  useEffect(() => {
    if (templates) {
      setTemplateList(unique(templates, ['name']));
    }
  }, [templates]);

  useEffect(() => {
    setLang(null);
  }, [visible]);

  const hideModal = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const addTemplate = () => {
    if (name && lang) {
      create({ name, lang, subject, text, body, bodyTemplateData, label });
    } else {
      message.error(_t('error::Language input is empty'));
    }
  };

  const onSearch = (value) => {
    if (value.length && devMode) {
      setTemplateList([...templateList, { name: value, label: value }]);
    }
  };

  return (
    <>
      <Tooltip title={isLink ? _t('button::Duplicated') : null}>
        <Button
          type={addIcon ? 'primary' : isLink ? 'link' : ''}
          onClick={showModal}
          className="email-btn">
          {addIcon ? (
            <FontAwesomeIcon icon={faPlusCircle} style={{ marginInlineEnd: '10px' }} />
          ) : (
            <FontAwesomeIcon icon={faClone} style={{ marginInlineEnd: '10px' }} />
          )}{' '}
          {isLink ? '' : title}
        </Button>
      </Tooltip>
      <Modal
        title={title}
        open={visible}
        onOk={addTemplate}
        onCancel={hideModal}
        footer={[
          <Button key="back" onClick={hideModal}>
            {_t('::Cancel')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            disabled={!lang || !name}
            onClick={addTemplate}>
            {addIcon ? _t('::Add') : _t('duplicate::Duplicate')}
          </Button>
        ]}
        okText={addIcon ? _t('::Add') : _t('duplicate::Duplicate')}
        cancelText={_t('::Cancel')}>
        <Row gutter={16}>
          <Col span={24}>
            <Select
              showSearch
              defaultValue={name}
              style={{ width: '100%' }}
              placeholder={_t('::Template name')}
              optionFilterProp="children"
              onChange={(value) => setName(value)}
              onSearch={onSearch}
              filterOption={filterBy.label}>
              {templateList.map((template) => (
                <Option key={template.name} value={template.name}>
                  {template.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={24} style={{ marginTop: '15px' }}>
            <Select
              showSearch
              style={{ width: '100%' }}
              value={lang}
              onChange={(value) => setLang(value)}
              placeholder={_t('::Select language')}
              optionFilterProp="children"
              filterOption={filterBy.label}>
              {languages &&
                languages.map((lang) => (
                  <Option key={lang.id} value={lang.id}>
                    {_t(`lang${quadDot}${lang.id}`)}
                  </Option>
                ))}
            </Select>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

AddNewEmailTemplate.propTypes = {
  title: PropTypes.string,
  subject: PropTypes.string,
  body: PropTypes.string,
  text: PropTypes.string,
  label: PropTypes.string,
  bodyTemplateData: PropTypes.string,
  defaultName: PropTypes.string,
  addIcon: PropTypes.bool,
  isLink: PropTypes.bool,
  devMode: PropTypes.bool
};

AddNewEmailTemplate.defaultProps = {
  devMode: false
};

export default AddNewEmailTemplate;
