import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import MKEditor from 'components/MKEditor';

const { TextArea } = Input;

function ConfigStringValue({ showAs, value, editorProps, setEditedValue, description }) {
  const control = useMemo(() => {
    switch (showAs) {
      case 'textarea':
        return (
          <TextArea
            value={value}
            onChange={(e) => setEditedValue(e.target.value, true)}
            rows={4}
            {...editorProps}
          />
        );
      case 'editor':
        return <MKEditor value={value} onChange={(v) => setEditedValue(v, true)} />;
      case 'input':
      default:
        return (
          <Input
            value={value}
            onChange={(e) => setEditedValue(e.target.value, true)}
            {...editorProps}
          />
        );
    }
  }, [showAs, value, setEditedValue]);

  return (
    <>
      {control}
      <div className="conf-description">{description}</div>
    </>
  );
}

ConfigStringValue.propTypes = {
  showAs: PropTypes.oneOf(['textarea', 'editor', 'input']),
  value: PropTypes.string,
  editorProps: PropTypes.object,
  setEditedValue: PropTypes.func.isRequired,
  description: PropTypes.string
};

export default ConfigStringValue;
