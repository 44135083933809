import { useQuery } from 'react-query';
import { getData, url } from './internal/rest-api';

export const usePasswordGenerator = () =>
  useQuery('password-generator', () => getData(`passwd/gen`), { enabled: false });

export const useCounterGenerator = ({
  frameName,
  fieldName,
  step,
  paddingWith,
  prefix,
  length
}) => {
  return useQuery(
    'counter-generator',
    async () => {
      const data = await getData(url`counter/${frameName}${fieldName}/${step || ''}`);
      const counter = data.counter;
      const countString = String(counter).padStart(length, paddingWith || '0');
      return `${prefix || ''}${countString}`;
    },
    {
      enabled: false
    }
  );
};
