import React from 'react';
import { useState, useEffect } from 'react';
import { _t } from 'rosis-translation';
import PropTypes from 'prop-types';
import { Progress, Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import Item from '../../../Item';

const FTProgressBar = React.forwardRef((props, ref) => {
  const { label, min, max, step, decimal, value, onChange } = props;
  return (
    <Item {...props}>
      <ProgressBarEditor
        ref={ref}
        value={value}
        min={min}
        max={max}
        step={step}
        decimal={decimal}
        placeholder={label}
        onChange={onChange}
      />
    </Item>
  );
});

FTProgressBar.displayName = 'FTProgressBar';

FTProgressBar.propTypes = {
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  decimal: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func
};

const calcAdjustedValue = (val, min, max) => {
  let result = val;
  if (result === null || result === undefined) {
    result = min;
  }
  if (result < min) {
    result = min;
  }
  if (result > max) {
    result = max;
  }
  if (result === null || result === undefined) {
    result = 0;
  }
  return result;
};

const ProgressBarEditor = React.forwardRef(
  ({ min, max, step, value, decimal, placeholder, onChange }, ref) => {
    const [editorValue, setEditorValue] = useState(calcAdjustedValue(value, min, max));
    const editorMin = min || 0;
    const editorMax = max || 100;
    const editorPrecision = decimal || 0;
    const editorStep = step || 1;
    const numerator = editorValue - editorMin;
    const denominator = editorMax - editorMin;
    const percentage =
      denominator !== 0 ? ((100 * numerator) / denominator).toFixed(editorPrecision) : 0;

    useEffect(() => {
      const val = value || 0;
      if (val !== editorValue) {
        setEditorValue(val);
      }
    }, [value]);

    useEffect(() => {
      setEditorValue(calcAdjustedValue(editorValue, min, max));
    }, [min, max, editorValue]);

    const increase = () => {
      const newValue = editorValue + editorStep;
      setEditorValue(newValue);
      emitOnChangeEvent(newValue);
    };

    const decrease = () => {
      const newValue = editorValue - editorStep;
      setEditorValue(newValue);
      emitOnChangeEvent(newValue);
    };

    const emitOnChangeEvent = (val) => {
      onChange(val);
    };

    return (
      <>
        <Progress percent={percentage} step={step} placeholder={placeholder} ref={ref} />
        <Button.Group>
          <Button
            onClick={decrease}
            icon={<MinusOutlined />}
            disabled={editorValue <= editorMin || denominator === 0}
          />
          <Button
            ref={ref}
            onClick={increase}
            icon={<PlusOutlined />}
            disabled={editorValue >= editorMax || denominator === 0}
          />
        </Button.Group>
      </>
    );
  }
);

ProgressBarEditor.displayName = 'ProgressBarEditor';

ProgressBarEditor.propTypes = {
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  decimal: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};

const options = {
  value: 'progressbar',
  get label() {
    return _t('::Progress bar');
  },
  component: FTProgressBar,
  off: true
};

export default options;
