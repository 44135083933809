import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Skeleton, Tabs } from 'antd';
import {
  useModels,
  useMtxForRole,
  useUpdateMtxItem,
  useUpdateMtxManyItems,
  useConfig
} from 'rosis-api';
import { _t } from 'rosis-translation';
import CollapseRole from './CollapseRole';
import MainPrivileges from './MainPrivileges';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';
import { useNavigation } from 'router/links';
import LoginPoint from './LoginPoint';

function RoleEdit({ role, roles }) {
  const navigation = useNavigation();
  const { data: queryManagementEnabled } = useConfig('conf', 'queryManagementEnabled', 'bool');
  const [roleName, setRoleName] = useState();
  const [currentRole, setCurrentRole] = useState(null);
  const { data: models, isLoading: isLoadingModels } = useModels();
  const patientModel = useMemo(() => models?.find(({ name }) => name === 'patient'), [models]);
  const { data: mtxFromServer, isLoading: isLoadingMtx } = useMtxForRole(roleName, {
    enabled: !!roleName
  });
  const [mtx, setMtx] = useState();

  const { mutate: updateMtxItem, ...updateMtxItemStatus } = useUpdateMtxItem();

  useApiFeedback({
    status: updateMtxItemStatus,
    silentProgress: true,
    silentSuccess: true
  });

  const { mutate: updateMtxManyItems, ...updateMtxManyItemsStatus } = useUpdateMtxManyItems();

  useApiFeedback({
    status: updateMtxManyItemsStatus,
    silentProgress: true,
    silentSuccess: true
  });

  const formItemLayout = {
    labelCol: {
      span: 24
    },
    wrapperCol: {
      span: 24
    }
  };

  useEffect(() => {
    setRoleName(role);
  }, [role]);

  useEffect(() => {
    role && roles && setCurrentRole(roles.find((r) => r.name === role));
  }, [role, roles]);

  useEffect(() => {
    mtxFromServer && setMtx(mtxFromServer[role] || {});
  }, [mtxFromServer, role]);

  const changeMtxItem = (frame, fields, right, value) => {
    if (Array.isArray(fields)) {
      updateMtxManyItems({
        role,
        frame,
        fields,
        right,
        value
      });
    } else {
      updateMtxItem({
        role,
        frame,
        field: fields,
        right,
        value
      });
    }
  };

  const tabs = useMemo(
    () => (roles ? roles.map(({ label, name }) => ({ label: _t(label), key: name })) : []),
    [roles]
  );

  return (
    <>
      <Tabs
        activeKey={roleName}
        destroyInactiveTabPane
        onChange={(key) => {
          navigation.privilegesForRole(key);
        }}
        items={tabs}
      />
      <Form {...formItemLayout}>
        {isLoadingModels || isLoadingMtx || !mtx ? (
          <Skeleton />
        ) : (
          <>
            {currentRole ? (
              <>
                <LoginPoint name={currentRole.loginPoint} />
                {queryManagementEnabled && currentRole.loginPoint === 'doctor' ? (
                  <MainPrivileges mtx={mtx} setMtx={setMtx} onChangeMtxItem={changeMtxItem} />
                ) : null}
              </>
            ) : null}
            <ul className="roles-list">
              <CollapseRole
                model={patientModel}
                mtx={mtx}
                setMtx={setMtx}
                onChangeMtxItem={changeMtxItem}
              />
            </ul>
          </>
        )}
      </Form>
    </>
  );
}

RoleEdit.propTypes = {
  role: PropTypes.string,
  roles: PropTypes.array
};

export default RoleEdit;
