import React from 'react';
import { useFormContext } from 'react-hook-form';
import { _t } from 'rosis-translation';
import { SFNumber } from 'rosis-setting-form';

function Step(props) {
  const { watch } = useFormContext();
  const decimal = watch('decimal');
  return <SFNumber {...props} min={0} precision={decimal} allowClear />;
}

const step = {
  get label() {
    return _t('::Step');
  },
  name: 'step',
  Field: Step,
  order: 30
};

export default step;
