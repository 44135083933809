import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { _t } from 'rosis-translation';

function MarkToRemoveButton({ mainText, cantRemove, selected, onMarkToRemove, name }) {
  const tooltipContent = (
    <div>
      {mainText}
      {cantRemove?.map((err) => (
        <div key={err}>{_t(err)}</div>
      ))}
    </div>
  );
  const button = (
    <Button
      type="link"
      className="action-remove"
      disabled={cantRemove?.length}
      onClick={() => onMarkToRemove(name)}>
      <FontAwesomeIcon icon={faTrash} style={selected ? { color: 'var(--roche-red)' } : null} />
    </Button>
  );
  return cantRemove?.length ? (
    <Tooltip content={tooltipContent} title={tooltipContent} placement="bottomRight">
      {button}
    </Tooltip>
  ) : (
    <Tooltip title={_t('button::Remove')}>{button}</Tooltip>
  );
}

MarkToRemoveButton.propTypes = {
  mainText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onMarkToRemove: PropTypes.func,
  cantRemove: PropTypes.arrayOf(PropTypes.string)
};

export default MarkToRemoveButton;
