import React from 'react';
import PropTypes from 'prop-types';
import { datetime } from 'rosis-date-picker';

export default function OffsetTimezone({ time }) {
  const offset = datetime(time).format('Z');
  return <span>(UTC {offset})</span>;
}

OffsetTimezone.propTypes = {
  time: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string
  ])
};
