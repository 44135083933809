import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Table, Input, message, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { quadDot } from 'system/symbol';
import DisplayTranslationList from './DisplayTranslationList';
import { useEditLang, useRemoveLang } from 'rosis-api';
import { _t } from 'rosis-translation';
import { useQueryClient } from 'react-query';

const Key = ({ value, trans }) => {
  const [context, key] = value ? value.split(quadDot) : [];
  return (
    <>
      <div style={{ color: 'gray' }}>{context}</div>
      <DisplayTranslationList name={value}>
        {trans ? key : <span className="missing">{key}</span>}
      </DisplayTranslationList>
    </>
  );
};

Key.propTypes = {
  value: PropTypes.string,
  trans: PropTypes.string
};

const Trans = ({ value, name, lang }) => {
  const queryClient = useQueryClient();
  const { mutate: edit, isError: isEditError } = useEditLang(queryClient);
  const { mutate: remove, isError: isRemoveError } = useRemoveLang(queryClient);
  const [currentTrans, setCurrentTrans] = useState();
  const [editMode, setEditMode] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setCurrentTrans(value);
  }, [value]);

  const onBlur = () => {
    const variables = { lang, key: name, value: currentTrans };
    currentTrans ? edit(variables) : remove(variables);
    setEditMode(false);
  };

  useEffect(() => {
    editMode && inputRef.current.focus();
  }, [editMode]);

  useEffect(() => {
    isEditError && message.error(_t('error::Error while editing'));
    isRemoveError && message.error(_t('error::Error while removing'));
  }, [isEditError, isRemoveError]);

  const change = ({ target }) => {
    setCurrentTrans(target.value);
  };

  const handlePressEnter = (e) => {
    change(e);
    e.target.blur();
  };

  return (
    <div>
      {editMode ? (
        <Input
          name={name}
          multiple={true}
          value={currentTrans}
          onChange={change}
          onBlur={onBlur}
          onPressEnter={handlePressEnter}
          ref={inputRef}
        />
      ) : (
        <Tooltip title={_t('::Click to edit')}>
          <button type="button" className="edit-translation__btn" onClick={() => setEditMode(true)}>
            {
              <div style={{ minWidth: '200px', textAlign: 'left' }}>
                {currentTrans || (
                  <Tooltip className="missing" title={_t('::Missing translation')}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Tooltip>
                )}
              </div>
            }
          </button>
        </Tooltip>
      )}
    </div>
  );
};

Trans.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  lang: PropTypes.string
};

function getColumns(lang) {
  return [
    {
      title: _t('::Key'),
      dataIndex: 'key',
      key: 'key',
      width: '50%',
      render: (value, { trans }) => <Key value={value} trans={trans} />
    },
    {
      title: _t('::Translation'),
      dataIndex: 'trans',
      key: 'trans',
      width: '50%',
      render: (value, { key }) => <Trans value={value} lang={lang} name={key} />
    }
  ];
}

function TranslationTable({ lang, translations }) {
  const [columns, setColumns] = useState();

  useEffect(() => {
    setColumns(getColumns(lang));
  }, [lang]);

  return (
    <Table
      columns={columns}
      scroll={{ x: true }}
      dataSource={translations}
      pagination={{
        defaultPageSize: 50,
        showSizeChanger: false
      }}
    />
  );
}

TranslationTable.propTypes = {
  lang: PropTypes.string,
  translations: PropTypes.array
};

export default TranslationTable;
