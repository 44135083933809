import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { fieldTypes } from 'rosis-field-types';
import AdditionalOptionItem from './AdditionalOptionItem';

function AdditionalOptions({ schema }) {
  const { watch } = useFormContext();
  const [type, displayAs, editAs] = watch(['type', 'displayAs', 'editAs']);
  const additionalOptions = useMemo(() => {
    const selectedField = fieldTypes[type];
    let defaultOrder = 0;
    return Object.values(selectedField?.additionalOptions || []).map((optionRecord) => {
      const order = (optionRecord.order ?? defaultOrder++) + 1000;
      return (
        <AdditionalOptionItem
          name={optionRecord.name}
          key={optionRecord.name}
          displayAs={displayAs}
          editAs={editAs}
          definition={optionRecord}
          schema={schema}
          order={order}
        />
      );
    });
  }, [type, displayAs, editAs]);

  return type ? additionalOptions : null;
}

AdditionalOptions.propTypes = {
  schema: PropTypes.object
};

export default AdditionalOptions;
