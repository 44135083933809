import React, { useState } from 'react';
import { Card, Typography, Tabs } from 'antd';
import { withLayout } from 'layout';
import { _t } from 'rosis-translation';
import { usePageHeader } from 'contexts/pageHeader';
import PageTitle from 'components/PageTitle';
import { useNavigationBlocker } from 'rosis-components/navigation-blocker';
import ChangePassword from './ChangePassword';
import EditMyData from './EditMyData';
import './MyProfile.less';

const { Title } = Typography;

function MyProfile() {
  const title = _t('::My profile');
  usePageHeader(<Title level={3}>{title}</Title>);
  const { isBlockadeNeeded, setPendingBlockade } = useNavigationBlocker();
  const [activeTab, setActiveTab] = useState('editProfile');
  const changeActiveTab = (tab) => {
    if (isBlockadeNeeded) {
      setPendingBlockade({
        forceNavigate: () => setActiveTab(tab)
      });
      return;
    }
    setActiveTab(tab);
  };
  const tabs = [
    { label: _t('::Edit profile'), key: 'editProfile', children: <EditMyData /> },
    { label: _t('::Change password'), key: 'changePassword', children: <ChangePassword /> }
  ];
  return (
    <>
      <PageTitle title={title} />
      <Card>
        <Tabs
          activeKey={activeTab}
          destroyInactiveTabPane
          items={tabs}
          onChange={changeActiveTab}
        />
      </Card>
    </>
  );
}

export default withLayout(MyProfile);
