import { useMemo } from 'react';
import { useSchema } from './schema';
import { useDbCanRead } from './db';

export const useFieldsWithRole = (frame, all) => {
  const schemaQueryResult = useSchema(frame);
  const canReadQueryResult = useDbCanRead(frame);

  const result = useMemo(() => {
    if (!schemaQueryResult.data || schemaQueryResult.isError) {
      return {
        ...schemaQueryResult,
        data: null,
        isError: schemaQueryResult.isError
      };
    }
    if (!canReadQueryResult.data || canReadQueryResult.isError) {
      return {
        ...canReadQueryResult,
        data: null,
        isError: canReadQueryResult.isError
      };
    }
    const schemaQueryData = schemaQueryResult.data;
    const canReadData = canReadQueryResult.data;
    const allowedFields = Object.entries(schemaQueryData.fields).filter(
      ([key, value]) => all || value.type === 'Link' || !!canReadData[key]
    );
    const fields = allowedFields.reduce((res, [key, value]) => ({ ...res, [key]: value }), {});
    const data = {
      fields,
      extra: schemaQueryData.extra
    };
    return {
      ...schemaQueryResult,
      data,
      isError: false
    };
  }, [
    schemaQueryResult.dataUpdatedAt,
    schemaQueryResult.errorUpdatedAt,
    canReadQueryResult.dataUpdatedAt,
    canReadQueryResult.errorUpdatedAt
  ]);
  return result;
};
