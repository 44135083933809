import React, { useState, useEffect } from 'react';
import { Button, Modal, AutoComplete, message, Row, Col, Input, Select } from 'antd';
import { _t } from 'rosis-translation';
import { quadDot } from 'system/symbol';
import filterBy from 'system/helper/filter-by';
import { useCreateSite, useLangs } from 'rosis-api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';

const { Option } = AutoComplete;

function AddNewSite({ onSuccess, onFail }) {
  const queryClient = useQueryClient();
  const { data: languages } = useLangs();
  const [visible, setVisible] = useState(false);
  const [siteName, setSiteName] = useState('');
  const [lang, setLang] = useState(null);
  const {
    isSuccess: isSuccessCreateSite,
    error: errorCreateSite,
    mutate: create
  } = useCreateSite(queryClient);

  useEffect(() => {
    if (isSuccessCreateSite) {
      onSuccess(_t('success::Added new site'));
    }
    if (errorCreateSite) {
      onFail(_t(errorCreateSite?.response?.data || 'error::Add new site failed'));
    }
    hideModal();
  }, [isSuccessCreateSite, errorCreateSite]);

  useEffect(() => {
    setSiteName(null);
    setLang(null);
  }, [visible]);

  const hideModal = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const addNewSite = () => {
    if (siteName) {
      create({ name: siteName, lang });
    } else {
      message.error(_t('error::Site input is empty'));
    }
  };

  return (
    <>
      <Button type={'primary'} onClick={showModal} className="site-btn-add">
        <FontAwesomeIcon icon={faPlusCircle} style={{ marginInlineEnd: '10px' }} />
        {_t('site::Site')}
      </Button>
      <Modal
        title={_t('site::Add site')}
        open={visible}
        onOk={addNewSite}
        onCancel={hideModal}
        okText={_t('site::Add')}
        cancelText={_t('site::Cancel')}>
        <Row gutter={16}>
          <Col span={24}>
            <Input
              value={siteName}
              placeholder={_t('site::Input new site name')}
              onChange={(e) => setSiteName(e.target.value)}
            />
          </Col>
          <Col span={24} style={{ marginTop: '15px' }}>
            <Select
              showSearch
              id="defaultLang"
              placeholder={_t('::Select language')}
              value={lang}
              style={{ width: '100%' }}
              optionFilterProp="children"
              filterOption={filterBy.label}
              onChange={setLang}>
              {languages &&
                languages.map((lang) => (
                  <Option key={lang.id} value={lang.id}>
                    {_t(`lang${quadDot}${lang.id}`)}
                  </Option>
                ))}
            </Select>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

AddNewSite.propTypes = {
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
};

export default AddNewSite;
