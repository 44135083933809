import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Form } from 'antd';
import { _t } from 'rosis-translation';

function ExpirationTimeEditor({ value, label, help, validateStatus, onChange, resetButton, ...rest }) {
  const currentValue = value ? parseInt(value) : value;

  const changeValue = (val) => {
    onChange(val ? `${val}d` : null);
  };

  return (
    <Form.Item
      className="settings-number-config"
      label={label}
      help={help}
      validateStatus={validateStatus}>
      <InputNumber value={currentValue} type="number" onChange={changeValue} {...rest} />
      <div className="settings-number-config__label">{_t('::Days')} {resetButton}</div>
    </Form.Item>
  );
}

ExpirationTimeEditor.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  help: PropTypes.string,
  validateStatus: PropTypes.string,
  onChange: PropTypes.func,
  resetButton: PropTypes.element
};

ExpirationTimeEditor.defaultProps = {
  onChange: () => {}
};

export default ExpirationTimeEditor;
