import React, { useState, useEffect } from 'react';
import { Row, Col, Space } from 'antd';
import { withNotLoggedLayout } from 'layout';
import { _t } from 'rosis-translation';
import { useAuth } from 'rosis-contexts/auth';
import { useLogin, useConfig } from 'rosis-api';
import Logo from './Logo';
import BackgroundImage from './BackgroundImage';
import AppTitle from './Title';
import Description from './Description';
import Footer from 'layout/Footer';
import './Login.less';
import PinCodeAuthentication from 'rosis-components/PinCodeAuthentication';
import LoginPanel from './LoginPanel';
import ChangePassword from './ChangePassword';
import { useHasAdmin } from 'rosis-api';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';
import PageTitle from 'components/PageTitle';
import { useNavigation } from 'router/links';

function Login() {
  const { data: hasAdmin, isLoading } = useHasAdmin();
  const navigation = useNavigation();
  const {
    showChangePassword,
    setShowChangePassword,
    pswdExpired,
    expiredDays,
    isLoggedUserToApp,
    setIsLoggedUserToApp
  } = useAuth();
  const { mutate: login, data: loginData, isLoading: loginLoading, ...loginStatus } = useLogin();
  const { data: pswdWidget } = useConfig('pub', 'pswdWidget');

  const [passwd, setPasswd] = useState('');
  const [capsLock, setCapsLock] = useState(false);
  const [timer, setTimer] = useState();
  const [user, setUser] = useState({});
  const [restartTime, setRestartTime] = useState(false);

  const onFinish = (values) => {
    if (loginLoading) {
      return;
    }
    const { idStr, password } = values;
    if (!values.pin && loginData) {
      setRestartTime(true);
    }

    const formValuesArray = Object.entries(values).filter(([key]) => key !== 'confirm');
    const formValues = Object.fromEntries(formValuesArray);

    login({ ...user, ...formValues, role: 'admin', loginPoint: 'admin' });
    idStr && password && setUser({ idStr, password });
  };

  const onKeyDown = (keyEvent) => {
    try {
      setCapsLock(keyEvent.getModifierState('CapsLock'));
    } catch (err) {
      setCapsLock(false);
    }
  };

  const goToDashboard = () => {
    navigation.home();
    setIsLoggedUserToApp(true);
  };

  useApiFeedback({
    status: { ...loginStatus, isLoading: loginLoading },
    silentSuccess: true,
    onError: () => setPasswd(''),
    errorMessage: (err) => {
      if ([409, 404, 401].includes(err.response?.status)) {
        return !timer ? _t('error::Login details are not valid') : _t('error::Wrong pin');
      }
      if (err.message === 'Network Error') {
        return _t('error::No connection with the server');
      }
      return _t(err.message);
    }
  });

  useEffect(() => {
    if (loginData?.timeLeft) {
      setTimer(loginData.timeLeft / 1000);
    }
    if (loginData?.rfToken) {
      localStorage.setItem('rf-token', loginData?.rfToken);
    }
  }, [loginData]);

  useEffect(() => {
    if (isLoggedUserToApp && showChangePassword === false) {
      goToDashboard();
    }
  }, [isLoggedUserToApp, showChangePassword]);

  const onSuccessChangePassword = () => {
    setTimer(null);
    setShowChangePassword(false);
  };

  return isLoading ? null : (
    <>
      <Row>
        <PageTitle title={_t('::Login')} />
        <Col md={8} xs={24} className="login-form">
          <Row gutter={12} align="middle">
            <Col span={24}>
              <Logo />
            </Col>
            <Col span={24}>
              <AppTitle />
            </Col>
          </Row>
          <Space direction="vertical" align="left" className="login-info">
            <Description />
          </Space>
          {showChangePassword ? (
            <ChangePassword
              pswdExpired={pswdExpired}
              expiredDays={expiredDays}
              goToDashboard={goToDashboard}
              onSuccessChangePassword={onSuccessChangePassword}
            />
          ) : timer ? (
            <PinCodeAuthentication
              startTime={timer}
              onFinish={onFinish}
              restartTime={restartTime}
              setTimer={setTimer}
            />
          ) : (
            <LoginPanel
              hasAdmin={hasAdmin}
              pswdWidget={pswdWidget}
              capsLock={capsLock}
              passwd={passwd}
              setPasswd={setPasswd}
              onKeyDown={onKeyDown}
              onFinish={onFinish}
            />
          )}
        </Col>
        <BackgroundImage />
      </Row>

      <Footer />
    </>
  );
}

export default withNotLoggedLayout(Login);
