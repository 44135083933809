import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Button, Modal, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import { _t } from 'rosis-translation';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { useGeneratePinCode } from 'rosis-api';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { time } from 'system/helper/value';

const GeneratePinSvg = () => (
  <svg
    width="18px"
    height="15px"
    viewBox="0 0 18 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <title>Group 3</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Users_actions"
        transform="translate(-33.000000, -106.000000)"
        fill="#0066CC"
        fillRule="nonzero">
        <g id="Group-3" transform="translate(33.000000, 106.000000)">
          <g id="lock-solid-(1)" transform="translate(5.000000, 0.000000)">
            <path
              d="M2.57142857,2.8125 L2.57142857,3.75 L5.42857143,3.75 L5.42857143,2.8125 C5.42857143,1.94921875 4.78928571,1.25 4,1.25 C3.21071429,1.25 2.57142857,1.94921875 2.57142857,2.8125 Z M1.42857143,3.75 L1.42857143,2.8125 C1.42857143,1.25976562 2.58035714,0 4,0 C5.41964286,0 6.57142857,1.25976562 6.57142857,2.8125 L6.57142857,3.75 L6.85714286,3.75 C7.4875,3.75 8,4.31054688 8,5 L8,8.75 C8,9.43945312 7.4875,10 6.85714286,10 L1.14285714,10 C0.5125,10 0,9.43945312 0,8.75 L0,5 C0,4.31054688 0.5125,3.75 1.14285714,3.75 L1.42857143,3.75 Z"
              id="Shape"></path>
          </g>
          <g id="****" transform="translate(0.000000, 11.000000)">
            <polygon
              id="Path"
              points="3.8667098 1.42433234 3.37754772 0.569732938 2.32934325 1.27002967 2.39922355 0 1.4325461 0 1.51407312 1.27002967 0.465868651 0.59347181 0 1.45994065 1.11808476 1.99406528 0 2.57566766 0.477515367 3.41839763 1.51407312 2.71810089 1.4325461 4 2.39922355 4 2.32934325 2.71810089 3.37754772 3.41839763 3.85506309 2.56379822 2.72533161 1.99406528"></polygon>
            <polygon
              id="Path"
              points="8.47880945 1.42433234 7.98964736 0.569732938 6.9414429 1.27002967 7.0113232 0 6.04464575 0 6.12617276 1.27002967 5.0779683 0.59347181 4.61209964 1.45994065 5.73018441 1.99406528 4.61209964 2.57566766 5.08961501 3.41839763 6.12617276 2.71810089 6.04464575 4 7.0113232 4 6.9414429 2.71810089 7.98964736 3.41839763 8.46716273 2.56379822 7.33743125 1.99406528"></polygon>
            <polygon
              id="Path"
              points="13.0909091 1.42433234 12.601747 0.569732938 11.5535425 1.27002967 11.6234228 0 10.6567454 0 10.7382724 1.27002967 9.69006794 0.59347181 9.22419929 1.45994065 10.3422841 1.99406528 9.22419929 2.57566766 9.70171466 3.41839763 10.7382724 2.71810089 10.6567454 4 11.6234228 4 11.5535425 2.71810089 12.601747 3.41839763 13.0792624 2.56379822 11.9495309 1.99406528"></polygon>
            <polygon
              id="Path"
              points="18 1.42433234 17.5108379 0.569732938 16.4626335 1.27002967 16.5325137 0 15.5658363 0 15.6473633 1.27002967 14.5991588 0.59347181 14.1332902 1.45994065 15.251375 1.99406528 14.1332902 2.57566766 14.6108056 3.41839763 15.6473633 2.71810089 15.5658363 4 16.5325137 4 16.4626335 2.71810089 17.5108379 3.41839763 17.9883533 2.56379822 16.8586218 1.99406528"></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
function GeneratePinBtn({ id, disabled, pinTime }) {
  const queryClient = useQueryClient();
  const { mutate: getPinCode, data: pinCode } = useGeneratePinCode(queryClient, id);

  const [visibleModal, setVisibleModal] = useState(false);
  const [copied, setCopied] = useState(false);

  const validityPinCode = pinTime ? Math.round(time(pinTime) / 3600000) : '';

  useEffect(() => {
    if (visibleModal) {
      getPinCode();
    }
  }, [visibleModal]);

  const showModal = () => {
    setVisibleModal(true);
  };

  const hideModal = () => {
    setVisibleModal(false);
  };

  const changeCopyState = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <>
      <Tooltip
        placement="bottomLeft"
        title={
          disabled
            ? _t('::The account of the currently logged in user')
            : _t('button::Generate PIN code')
        }>
        <Button type="link" disabled={disabled}>
          <Icon component={GeneratePinSvg} onClick={showModal} />
        </Button>
      </Tooltip>

      <Modal
        title={<span className="text-bold">{_t('::Generate PIN code')}</span>}
        open={visibleModal}
        onCancel={hideModal}
        footer={[
          <Button key="back" onClick={hideModal}>
            {_t('::Close')}
          </Button>
        ]}>
        <p>
          {_t(
            '::Please provide this PIN code to the locked user in order to reset the password. This PIN code is valid for the next '
          )}
          <span className="pin-code__time">
            {_t('::{validityPinCode} hours', {
              validityPinCode
            })}
          </span>
        </p>
        <p>{_t('::PIN code')}:</p>
        <p className="pin-code__text">
          {pinCode ? `${pinCode?.secret}` : ''}
          <CopyToClipboard text={pinCode?.secret} onCopy={changeCopyState}>
            <Tooltip placement="bottomLeft" title={copied ? _t('::Copied') : _t('::Copy PIN')}>
              <FontAwesomeIcon className="pin-code__clone" icon={faCopy} />
            </Tooltip>
          </CopyToClipboard>
        </p>
      </Modal>
    </>
  );
}

GeneratePinBtn.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  disabled: PropTypes.bool,
  pinTime: PropTypes.string
};

export default GeneratePinBtn;
