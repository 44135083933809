import { Result, Button } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { _t } from 'rosis-translation';

const ConfirmationResultSuccess = ({ isPin, isUserEnabled, loginPath }) => {
  const getTitle = () => {
    if (isPin) {
      return _t('::Success');
    }
    if (!isUserEnabled) {
      return _t('::Email adress confirmed');
    }
    return _t('::Account confirmed');
  };
  const getSubTitle = () => {
    if (isPin) {
      return _t('::Secret question and password have been set');
    }
    if (!isUserEnabled) {
      return (
        <>
          <div>{_t('::Your account is pending approval by the system administrator.')}</div>
          <div>{_t('::You will be notified by the email about the account activation.')}</div>
        </>
      );
    }
    return _t('::Your account has been successfully confirmed.');
  };

  return (
    <Result
      status="success"
      title={getTitle()}
      subTitle={getSubTitle()}
      extra={[
        <Link key="login" to={loginPath}>
          <Button type="primary">{_t('::Go to Sign in')}</Button>
        </Link>
      ]}
    />
  );
};

ConfirmationResultSuccess.propTypes = {
  isPin: PropTypes.bool.isRequired,
  loginPath: PropTypes.string.isRequired,
  isUserEnabled: PropTypes.bool
};

export default ConfirmationResultSuccess;
