import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import { _t } from 'rosis-translation';
import Item from '../../../Item';

const FTCheckbox = React.forwardRef((props, ref) => {
  const checked = !!props.value;
  return (
    <Item {...props} valuePropName={options.valuePropName}>
      <Checkbox ref={ref} checked={checked} onChange={props.onChange} />
    </Item>
  );
});

FTCheckbox.displayName = 'FTCheckbox';

FTCheckbox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func
};

const options = {
  value: 'checkbox',
  get label() {
    return _t('::Checkbox');
  },
  component: FTCheckbox,
  default: true,
  example: <FTCheckbox />,
  valuePropName: 'checked'
};

export default options;
