import React from 'react';
import { Divider, Skeleton, Typography } from 'antd';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { useQueryClient } from 'react-query';
import { useSchema, useUpdateExtra, useView, useViewUpdate } from 'rosis-api';
import './Views.less';
import DisplayName from 'components/DisplayName';
import FieldList from './FieldList';
import useViewFields from './useViewFields';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';
import useDisplayName from './useDisplayName';

const { Title } = Typography;

function ViewEdit({ frameName, extra }) {
  const queryClient = useQueryClient();
  const { isLoading, data: model } = useSchema(frameName);
  const { data: views } = useView(frameName);
  const { mutate: updateView, ...updateViewStatus } = useViewUpdate(queryClient, frameName);
  const { mutate: updateDisplay, ...updateDisplayStatus } = useUpdateExtra(queryClient, frameName);

  const { fields } = model || {};

  const {
    fieldsList: viewFields,
    changeFieldOrder,
    changeFieldConfig
  } = useViewFields({
    fields,
    views,
    onFieldsChange: updateView
  });

  const {
    fieldsList: displayNameFields,
    displayNameText,
    addField,
    changeDisplayName
  } = useDisplayName({
    fields,
    extra: extra,
    onDisplayChange: updateDisplay
  });

  useApiFeedback({
    status: updateViewStatus,
    silentSuccess: true,
    silentProgress: true
  });

  useApiFeedback({
    status: updateDisplayStatus,
    silentSuccess: true,
    silentProgress: true
  });

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <Title level={3}>{_t('::Display name')}</Title>
          <DisplayName
            frameName={frameName}
            fields={displayNameFields}
            displayNameText={displayNameText}
            onAddField={addField}
            onChangeDisplayName={changeDisplayName}
          />
          <Divider />
          <FieldList
            frameName={frameName}
            fields={viewFields}
            extra={extra}
            onChangeFieldOrder={changeFieldOrder}
            onChangeFieldConfig={changeFieldConfig}
          />
        </>
      )}
    </>
  );
}

ViewEdit.propTypes = {
  frameName: PropTypes.string,
  extra: PropTypes.object
};

export default ViewEdit;
