import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import ConfigSettings from 'components/ConfigSettings';
import PageTitle from 'components/PageTitle';
import { calcSettingPageTitle } from '../utils';
import ConfigPageHeader from 'components/ConfigSettings/ConfigPageHeader';
import { ConfigFormControlProvider } from 'components/ConfigSettings/ConfigFormControl';
import { useChange } from 'contexts/change';

const GlobalSettingsTab = ({ title }) => {
  const { isChanged, setIsChanged } = useChange();
  const settingsConf = useMemo(() => {
    const result = [
      {
        name: 'maxDisplayFields',
        group: 'setup',
        label: _t('::Highest number of columns displayed in the tables'),
        kind: 'number',
        editorProps: { min: 1 }
      },
      {
        name: 'messageAutoCloseTime',
        group: 'pub',
        label: _t('::Message autoclose time in seconds'),
        description: _t('::After this time operation success messages will be closed'),
        kind: 'number',
        editorProps: { min: 1 }
      },
      {
        name: 'messageToAnotherUser',
        group: 'setup',
        label: _t('::Messaging to another user function'),
        kind: 'bool'
      }
    ];

    return result;
  }, []);

  const pageTitle = calcSettingPageTitle(title);

  return (
    <ConfigFormControlProvider>
      <PageTitle title={pageTitle} />
      <ConfigPageHeader pageTitle={pageTitle} isDirty={isChanged} />
      <ConfigSettings settings={settingsConf} onIsDirtyChange={setIsChanged} />
    </ConfigFormControlProvider>
  );
};

GlobalSettingsTab.propTypes = {
  title: PropTypes.string
};

export default GlobalSettingsTab;
