import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { useTranslation } from 'contexts/translation';

function AutoDivider(props) {
  const { direction } = useTranslation();
  const config = { ...props };
  if (!config.orientation) {
    config.orientation = direction === 'rtl' ? 'right' : 'left';
  }
  return <Divider {...config}>{props.children}</Divider>;
}

AutoDivider.propTypes = {
  children: PropTypes.any.isRequired
};

export default AutoDivider;
