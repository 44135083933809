import React from 'react';
import PropTypes from 'prop-types';
import { List, Empty, Row, Col } from 'antd';
import { _t } from 'rosis-translation';
import './Logs.less';

const LogsMobile = ({ elements, columns }) => {
  const noData = !elements || !elements.length;
  return (
    <List
      itemLayout="horizontal"
      className="user-list"
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={noData ? noData : _t('logs::No logs')}
          />
        )
      }}
      dataSource={elements}
      renderItem={(item) => (
        <List.Item style={{ display: 'block' }}>
          <Row gutter={[10, 0]}>
            {Array.isArray(columns) &&
              columns.map((cell) => (
                <Col span={24} key={cell?.key} className="display-field">
                  <span className="left-section">{_t(cell?.title)}:</span>
                  <span className="field-ellipsis">{cell.render(item[cell?.key], item)}</span>
                </Col>
              ))}
          </Row>
        </List.Item>
      )}
    />
  );
};

LogsMobile.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      _createdAt: PropTypes.string,
      event: PropTypes.string,
      creator: PropTypes.string,
      client: PropTypes.string,
      value: PropTypes.string
    })
  ),
  columns: PropTypes.array
};

export default LogsMobile;
