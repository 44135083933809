import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Space } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const EnableButton = ({ checked, disabled, id, onChange }) => {
  const [isChecked, setChecked] = useState(checked);
  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const updateUser = ({ value }) => {
    setChecked(value);
    onChange(value);
  };

  return (
    <Space>
      <Switch
        disabled={disabled}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        onChange={(value) => updateUser({ value: value, id })}
        checked={isChecked}
      />
    </Space>
  );
};

EnableButton.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func
};

export default EnableButton;
