import * as displayAs from './displayAs';
import * as editAs from './editAs';
import * as additionalOptions from './additionalOptions';
import makeType from '../../makeType';

const multi = makeType({
  name: 'Multi',
  label: '::Multiple choice',
  displayAs,
  editAs,
  additionalOptions
});

export default multi;
