import * as displayAs from './displayAs';
import * as editAs from './editAs';
import makeType from '../../makeType';

const boolean = makeType({
  name: 'Boolean',
  label: '::Boolean',
  displayAs,
  editAs
});

export default boolean;
