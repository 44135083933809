import * as displayAs from './displayAs';
import * as editAs from './editAs';
import * as additionalOptions from './additionalOptions';
import makeType from '../../makeType';

const single = makeType({
  name: 'Select',
  label: '::Single choice',
  displayAs,
  editAs,
  additionalOptions
});

export default single;
