import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { Link } from 'rosis-components/navigation-wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { _t } from 'rosis-translation';
import { useConfig } from 'rosis-api';
import RejectUser from 'components/buttons/RejectUser';
import RemoveUser from './RemoveUser';
import GeneratePinBtn from './GeneratePinBtn';
import BlockedUser from './BlockedUser';
import { links } from 'router/links';

const RowAction = ({
  enabled,
  penalty,
  name,
  _id,
  user,
  pinTime,
  onSuccess,
  onFail,
  actionComplete
}) => {
  const { data: removalAllowed } = useConfig('conf', 'removalAllowed', 'bool');
  const isPenalty = penalty === null || penalty;
  return (
    <>
      <Tooltip title={_t('button::Edit')}>
        <Link key="edit" to={links.userDetails(name, _id)}>
          <Button type="link" aria-label={_t('button::Edit')}>
            <FontAwesomeIcon icon={faPen} />
          </Button>
        </Link>
      </Tooltip>
      {!removalAllowed || (
        <RemoveUser
          role={name}
          id={_id}
          disabled={_id === user._id}
          table={true}
          onSuccess={onSuccess}
          onFail={onFail}
          actionComplete={actionComplete}
        />
      )}

      {isPenalty ? <BlockedUser id={_id} onSuccess={onSuccess} onFail={onFail} /> : null}
      {enabled === null || enabled === undefined ? (
        <RejectUser _id={_id} type="link" onSuccess={onSuccess} onFail={onFail} />
      ) : null}
      <GeneratePinBtn id={_id} disabled={_id === user._id} pinTime={pinTime} />
    </>
  );
};

RowAction.propTypes = {
  enabled: PropTypes.bool,
  penalty: PropTypes.string,
  name: PropTypes.string,
  _id: PropTypes.string,
  pinTime: PropTypes.string,
  user: PropTypes.shape({
    _id: PropTypes.string
  }),
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  actionComplete: PropTypes.func
};

RowAction.defaultProps = {
  actionComplete: () => {}
};

export default RowAction;
