import React, { useEffect } from 'react';
import { Button, Tooltip } from 'antd';
import { _t } from 'rosis-translation';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLock } from '@fortawesome/free-solid-svg-icons';
import { useUserUnlock } from 'rosis-api';

function BlockedUser({ id, onSuccess, onFail }) {
  const queryClient = useQueryClient();

  const {
    isSuccess: successUnlockUser,
    error: errorUnlockUser,
    mutate: unlock
  } = useUserUnlock(queryClient, id);

  useEffect(() => {
    successUnlockUser && onSuccess(_t('success::User unblocked'));
    errorUnlockUser && onFail(_t(errorUnlockUser?.response?.data || 'error::User unblock failed'));
  }, [successUnlockUser, errorUnlockUser]);

  const unlockUser = () => {
    unlock({ id });
  };

  return (
    <Tooltip placement="bottomLeft" title={_t('::Unblock user')}>
      <Button type="link">
        <FontAwesomeIcon icon={faUserLock} onClick={unlockUser} />
      </Button>
    </Tooltip>
  );
}

BlockedUser.propTypes = {
  id: PropTypes.string,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func
};

export default BlockedUser;
