import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import { _t } from 'rosis-translation';
import Item from './Item';

function SFCheckbox(props) {
  const { label } = props;
  return (
    <Item type="Checkbox" customValueField="checked" noLabel {...props}>
      <Checkbox>{label}</Checkbox>
    </Item>
  );
}

SFCheckbox.defaultProps = {
  errorMessage: _t("::It's required")
};

SFCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.bool,
  required: PropTypes.bool,
  initialValue: PropTypes.bool
};

export default SFCheckbox;
