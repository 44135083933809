import React from 'react';
import { _t } from 'rosis-translation';

function Header() {
  return (
    <div className="table-dnd_header">
      <div>{_t('::Section name')}</div>
      <div className="table-dnd_header_actions">{_t('::Actions')}</div>
    </div>
  );
}

export default Header;
