import React, { useEffect, useMemo, useState } from 'react';
import { Skeleton, Tabs, Button } from 'antd';
import { _t } from 'rosis-translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useQueryClient } from 'react-query';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';

import { useConfigs, useChangeConfigValue } from 'rosis-api';

import HeaderEditor from './HeaderEditor';

function HtmlHeadersSettings() {
  const [editableHeaders, setEditableHeaders] = useState([]);
  const [isEditMode, setEditMode] = useState(false);
  const [newHeader, setNewHeader] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);

  const queryClient = useQueryClient();
  const { showInfoBar } = useInfoBar();
  const { data: headers, refetch: refetchHeaders } = useConfigs('head');
  const {
    mutate: changeConfigValue,
    error: changeConfigValueError,
    isSuccess: changeConfigValueSuccess
  } = useChangeConfigValue(queryClient);

  useEffect(() => {
    if (changeConfigValueSuccess) {
      showInfoBar({
        message: _t('success::Header saved'),
        type: 'success'
      });
    }
    if (changeConfigValueError) {
      showInfoBar({
        message: _t(changeConfigValueError?.response?.data || 'error::Error while saving header'),
        type: 'error'
      });
    }
  }, [changeConfigValueSuccess, changeConfigValueError]);

  useEffect(() => {
    const headersMap = headers || {};
    const editable = Object.entries(headersMap).map(([label, content]) => ({
      label,
      content,
      isNew: false
    }));
    if (newHeader) {
      editable.push(newHeader);
    }
    setEditableHeaders(editable);
    if (editable.length && !editable.find((header) => header.label === selectedTab)) {
      setSelectedTab(editable[0].label);
    }
  }, [headers, newHeader]);

  const startEdition = () => {
    setEditMode(true);
  };

  const submitEdition = ({ label, content }) => {
    setEditMode(false);
    setNewHeader(null);
    changeConfigValue({ group: 'head', name: label, value: content });
  };

  const cancelEdition = () => {
    setEditMode(false);
    if (newHeader) {
      setSelectedTab(editableHeaders[0]?.label);
    }
    setNewHeader(null);
    refetchHeaders();
  };

  const addHeader = () => {
    const baseLabel = 'header';
    let newItemLabel = baseLabel;
    let index = 1;
    while (editableHeaders.find((otherHeader) => otherHeader.label === newItemLabel)) {
      newItemLabel = `${baseLabel}-${index}`;
      ++index;
    }
    const newHeader = {
      label: newItemLabel,
      content: '',
      isNew: true
    };
    setNewHeader(newHeader);
    setEditMode(true);
    setSelectedTab(newItemLabel);
  };

  const availableActions = useMemo(
    () =>
      [
        !isEditMode && (
          <Button
            key="add"
            type="primary"
            icon={<FontAwesomeIcon icon={faPlusCircle} />}
            onClick={addHeader}>
            {_t('button::Add item')}
          </Button>
        )
      ].filter((action) => !!action),
    [isEditMode]
  );

  const tabs = useMemo(
    () =>
      editableHeaders.map(({ label, content, isNew }) => ({
        label,
        key: label,
        disabled: isEditMode,
        children: (
          <HeaderEditor
            label={label}
            content={content}
            isNew={isNew}
            isEditMode={isEditMode}
            baseActions={availableActions}
            otherHeaders={editableHeaders}
            onStartEdit={startEdition}
            onSubmitEdition={submitEdition}
            onCancelEdition={cancelEdition}
          />
        )
      })),
    [editableHeaders]
  );

  return (
    <>
      {editableHeaders ? (
        <Tabs
          items={tabs}
          activeKey={selectedTab}
          tabPosition="left"
          destroyInactiveTabPane
          onChange={(tab) => setSelectedTab(tab)}
        />
      ) : (
        <Skeleton />
      )}
    </>
  );
}

export default HtmlHeadersSettings;
