import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { _t } from 'rosis-translation';
import Item from '../../../Item';
import './buttons.less';

const FTButtons = React.forwardRef((props, ref) => {
  const { value, opts, onChange } = props;
  return (
    <Item {...props} className="radio-group-field">
      <RadioEditor ref={ref} value={value} opts={opts} onChange={onChange}></RadioEditor>
    </Item>
  );
});

FTButtons.displayName = 'FTButtons';

FTButtons.propTypes = {
  value: PropTypes.string,
  opts: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func
};

const RadioEditor = React.forwardRef(({ value, opts, onChange }, ref) => {
  const change = (checked, option) => {
    if (onChange) {
      const newValue = checked ? option : null;
      onChange(newValue);
    }
  };
  return (
    <div role="radiogroup" ref={ref}>
      {(opts || [])?.map((option) => {
        const isChecked = option === value;
        return (
          <Button
            key={option}
            role="checkbox"
            type={isChecked ? 'primary' : null}
            aria-checked={isChecked}
            onClick={() => change(!isChecked, option)}>
            {_t(option)}
          </Button>
        );
      })}
    </div>
  );
});

RadioEditor.displayName = 'RadioEditor';

RadioEditor.propTypes = {
  value: PropTypes.string,
  opts: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func
};

const buttons = {
  value: 'buttons',
  get label() {
    return _t('edit-as::Buttons');
  },
  example: <FTButtons opts={['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5']} />,
  component: FTButtons
};

export default buttons;
