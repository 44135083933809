import React from 'react';
import PropTypes from 'prop-types';
import getFieldType from '../src/getFieldType';
const DisplayField = (props) => {
  const { type, displayAs, resultType, value, label, extra, hint, filePath, useFileInfo } = props;
  const { component: Component } = getFieldType('displayAs', resultType || type, displayAs);
  return Component ? (
    <React.Fragment>
      <Component
        value={value}
        label={label}
        extra={extra}
        hint={hint}
        filePath={filePath}
        useFileInfo={useFileInfo}
      />
    </React.Fragment>
  ) : null;
};

DisplayField.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string,
  displayAs: PropTypes.string,
  resultType: PropTypes.string,
  value: PropTypes.any,
  hint: PropTypes.string,
  extra: PropTypes.object,
  label: PropTypes.string,
  filePath: PropTypes.string,
  useFileInfo: PropTypes.func
};

export default DisplayField;
