import * as displayAs from './displayAs';
import * as editAs from './editAs';
import * as additionalOptions from './additionalOptions';
import makeType from '../../makeType';

const text = makeType({
  name: 'String',
  label: '::Text',
  displayAs,
  editAs,
  additionalOptions
});

export default text;
