import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { Checkbox } from 'antd';
import Item from '../../../Item';

const FTCheckboxes = React.forwardRef((props, ref) => {
  const [selectOpts, setSelectOpts] = useState([]);
  const { opts, value, onChange } = props;

  useEffect(() => {
    setSelectOpts(
      (opts || []).map((option) => {
        const value = option;
        const label = _t(option);
        return { value, label };
      }) || []
    );
  }, [opts]);
  return (
    <Item {...props}>
      <Checkbox.Group ref={ref} options={selectOpts} value={value} onChange={onChange} />
    </Item>
  );
});

FTCheckboxes.displayName = 'FTCheckboxes';

FTCheckboxes.propTypes = {
  opts: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func
};

const checkboxes = {
  value: 'checkboxes',
  get label() {
    return _t('::Checkboxes');
  },
  example: <FTCheckboxes opts={['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5']} />,
  component: FTCheckboxes
};

export default checkboxes;
