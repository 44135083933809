import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { Input } from 'antd';
import Item from '../../../Item';

const FTInput = React.forwardRef((props, ref) => {
  const { value, onChange } = props;
  return (
    <Item {...props}>
      <Input ref={ref} value={value} onChange={onChange} allowClear />
    </Item>
  );
});

FTInput.displayName = 'FTInput';

FTInput.propTypes = { value: PropTypes.string, onChange: PropTypes.func };

const options = {
  value: 'input',
  get label() {
    return _t('::Input');
  },
  component: FTInput,
  example: <FTInput name="input" value={_t('::Example text')} />,
  default: true
};

export default options;
