import React, { useMemo, useCallback, useState, createContext, useContext } from 'react';
import { PropTypes } from 'prop-types';

export const ConfigFormControlContext = createContext();

export function useFormControl() {
  const { submitEdition, isSubmiting, cancelEdition, isCanceling, reset } =
    useContext(ConfigFormControlContext);
  return {
    submitEdition,
    isSubmiting,
    cancelEdition,
    isCanceling,
    reset
  };
}

export function ConfigFormControlProvider({ children }) {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const submitEdition = useCallback(() => {
    setIsSubmiting(true);
  });
  const [isCanceling, setIsCanceling] = useState(false);
  const cancelEdition = useCallback(() => {
    setIsCanceling(true);
  });
  const reset = useCallback(() => {
    setIsSubmiting(false);
    setIsCanceling(false);
  });
  const ctx = useMemo(
    () => ({
      submitEdition,
      isSubmiting,
      cancelEdition,
      isCanceling,
      reset
    }),
    [submitEdition, isSubmiting, cancelEdition, isCanceling]
  );
  return (
    <ConfigFormControlContext.Provider value={ctx}>{children}</ConfigFormControlContext.Provider>
  );
}

ConfigFormControlProvider.propTypes = {
  children: PropTypes.node.isRequired
};
