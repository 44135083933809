import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'rosis-components/navigation-wrapper';
import { useQueryClient } from 'react-query';
import { withLayout } from 'layout';
import { Card, Skeleton, Button } from 'antd';
import { SFInput, SFSelect, SFCheckbox, SFForm } from 'rosis-setting-form';
import { useCreateModel, useModelsWhichCanBeParent } from 'rosis-api';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import { _t } from 'rosis-translation';
import { useBreadcrumbs } from 'rosis-contexts/breadcrumbs';
import { usePageHeader } from 'contexts/pageHeader';
import TitleActions from 'components/TitleActions';
import PageTitle from 'components/PageTitle';
import { useNavigation } from 'router/links';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

function FrameForm() {
  const { name: linkToSectionName } = useParams();
  const navigation = useNavigation();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { showInfoBar } = useInfoBar();
  const { data: options, isLoading } = useModelsWhichCanBeParent();
  const queryClient = useQueryClient();
  const {
    isLoading: isCreating,
    isSuccess: isCreated,
    isError: isErrorCreated,
    error: createError,
    mutate: create
  } = useCreateModel(queryClient);
  const [message, setMessage] = useState();

  useEffect(() => {
    setBreadcrumbs([
      {
        label: '',
        link: '/'
      },
      {
        label: _t('::Sections'),
        link: '/sections'
      },
      {
        label: _t('::Create'),
        link: '/sections/create/'
      }
    ]);
  }, []);

  useEffect(() => {
    if (isErrorCreated) {
      showInfoBar({
        message:
          createError?.response?.data ||
          _t('error::An error occurred while trying to add a new section'),
        type: 'error'
      });
    }
  }, [isErrorCreated, createError]);

  useEffect(() => {
    if (isCreated) {
      navigation.sections({ infoBarMessage: { message, type: 'success' } });
    }
  }, [isCreated]);

  const handleSubmit = (data) => {
    if (!isCreating) {
      create(data);
      setMessage(_t('success::Section "{label}" added successfully', { label: data.label }));
    }
  };

  const cancelEdition = () => {
    navigation.sections();
  };

  const title = _t('::Create new section');

  const actions = useMemo(() => [
    <Button
      key="cancel"
      type="button"
      icon={<FontAwesomeIcon icon={faTimes} />}
      onClick={cancelEdition}>
      {_t('button::Cancel')}
    </Button>,
    <Button
      key="submit"
      type="primary"
      htmlType="submit"
      form="create-field-form"
      icon={<FontAwesomeIcon icon={faSave} />}>
      {_t('button,store::Save')}
    </Button>
  ]);

  usePageHeader(<TitleActions actions={actions}>{title}</TitleActions>, [title, actions]);

  return (
    <>
      <PageTitle title={title} />
      <Card>
        {isLoading ? (
          <Skeleton />
        ) : (
          <SFForm
            id="create-field-form"
            onSubmit={handleSubmit}
            defaultValues={{ linkTo: linkToSectionName }}>
            <SFInput label={_t('::Section name')} name="label" required />
            <SFSelect
              label={_t('::Link this section to')}
              name="linkTo"
              options={options}
              required
            />
            <SFInput label={_t('::Hint')} name="hint" />
            <SFCheckbox label={_t('::At most one per parent')} name="onlyOne" />
          </SFForm>
        )}
      </Card>
    </>
  );
}
export default withLayout(FrameForm);
