import React from 'react';
import { useFormContext } from 'react-hook-form';
import { _t } from 'rosis-translation';
import { SFNumber } from 'rosis-setting-form';

function Min(props) {
  const { watch } = useFormContext();
  const max = watch('max');
  return <SFNumber {...props} min={0} max={max} step={1} />;
}

const min = {
  get label() {
    return _t('::Min length');
  },
  Field: Min,
  name: 'min',
  order: 10
};

export default min;
