import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';

const EventCell = ({ event, eventList }) => {
  return _t(eventList[event]);
};

EventCell.propTypes = {
  event: PropTypes.string,
  eventList: PropTypes.object
};

export default EventCell;
