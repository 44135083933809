import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useFormContext, Controller } from 'react-hook-form';
import { _t } from 'rosis-translation';

function SFItem({
  children,
  name,
  label,
  noLabel,
  initialValue,
  required,
  customValueField,
  customDefinitionValidations,
  noStyle,
  className
}) {
  const { control } = useFormContext();
  const rules = {
    required: required && _t("::It's required"),
    ...customDefinitionValidations
  };

  return (
    <Controller
      name={name}
      defaultValue={initialValue}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        const { error, invalid } = fieldState;
        const { value, onChange, ref } = field;
        const childProps = {
          onChange,
          value,
          ref
        };
        customValueField && (childProps[customValueField] = value);
        return (
          <Form.Item
            className={className}
            noStyle={noStyle}
            label={noLabel ? null : label}
            required={required}
            validateStatus={invalid ? 'error' : 'success'}
            help={error?.message}>
            {React.cloneElement(children, childProps)}
          </Form.Item>
        );
      }}
    />
  );
}

SFItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  noLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  initialValue: PropTypes.any,
  required: PropTypes.bool,
  customValueField: PropTypes.string,
  noStyle: PropTypes.bool
};

export default SFItem;
