import React from 'react';
import PropTypes from 'prop-types';
import { Space, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import './DisclaimerMessage.less';

const DisclaimerMessage = ({ lines }) => {
  return (
    <Space>
      <div className="disclaimer-message">
        <FontAwesomeIcon icon={faInfoCircle} />
        <div>
          {lines.map((line) => (
            <Typography key={line} level={5}>
              {line}
            </Typography>
          ))}
        </div>
      </div>
    </Space>
  );
};

DisclaimerMessage.propTypes = {
  lines: PropTypes.arrayOf(PropTypes.string)
};

export default DisclaimerMessage;
