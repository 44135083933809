import React from 'react';
import PropTypes from 'prop-types';
import BaseDate from './BaseDate';

const Month = React.forwardRef(({ val, onChange }, ref) => {
  return <BaseDate val={val} onChange={onChange} pickerKind="month" ref={ref} />;
});

Month.displayName = 'Month';

Month.propTypes = {
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func
};

export default Month;
