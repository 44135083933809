import PropTypes from 'prop-types';

export const fieldProps = PropTypes.shape({
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  opts: PropTypes.arrayOf(PropTypes.string),
  isRequired: PropTypes.bool
});

export const operatorTypes = ['or', 'and'];

export const allowedTypes = ['Multi', 'Select', 'Boolean'];
