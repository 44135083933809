import { useMutation, useQuery } from 'react-query';

import { post, url, getData } from './internal/rest-api';

export const useCheckPinCode = (pin) =>
    useQuery(['pin-code', pin], () => getData(`qpin/rescue-pin/${pin}/use`), { enabled: false });

export const useGeneratePinCode = (queryClient, id) =>
  useMutation(() => post(url`qpin/rescue-pin/${id}`).then((result) => result.data), {
    onSuccess: () => {
      queryClient.invalidateQueries([id, 'pinCode']);
    }
  });


