import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import { _t } from 'rosis-translation';
import Item from './Item';

function SFRadio(props) {
  const { options, onChange } = props;
  return (
    <Item type="Radio" {...props}>
      <Radio.Group>
        {options?.map(({ value, label }) => (
          <Radio key={value} value={value} onChange={onChange}>
            {label}
          </Radio>
        ))}
      </Radio.Group>
    </Item>
  );
}

SFRadio.defaultProps = {
  errorMessage: _t("::It's required")
};

SFRadio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
      })
    ),
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  onChange: PropTypes.func
};

export default SFRadio;
