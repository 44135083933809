import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Form, Select, Typography, Row, Col } from 'antd';
import { _t } from 'rosis-translation';

const DEFAULT_TEMPORARY_LOCK_VALUE = 30;

function LockTimeEditor({ label, value, description, onChange, panel, resetButton, ...rest }) {
  const calcMainSelection = () => {
    if (String(value) === '0') {
      return 'permanent';
    }
    return 'temporary';
  };
  const mainSelection = calcMainSelection();

  const changeMainSelection = (mainSelection) => {
    if (mainSelection === 'permanent') {
      onChange(0);
      return;
    }
    onChange(DEFAULT_TEMPORARY_LOCK_VALUE);
  };

  const changeMinutesValue = (minutes) => {
    onChange(minutes);
  };

  return (
    <>
      <div className="lock-time-editor-wrapper">
        <Form.Item
          label={
            <>
              {label} {resetButton}
            </>
          }>
          <Row>
            <Col xl={{ span: 8 }} span={24}>
              <Select value={mainSelection} onChange={changeMainSelection}>
                {panel === 'admin' ? null : (
                  <Select.Option value="permanent">{_t('lock-time::Permanent')}</Select.Option>
                )}
                <Select.Option value="temporary">{_t('lock-time::Temporary')}</Select.Option>
              </Select>
            </Col>
            <Col xl={{ span: 16 }} span={24}>
              {mainSelection === 'temporary' && (
                <div className="lock-time-editor-minutes-wrapper">
                  <Typography.Text className="lock-time-editor-minutes-label">
                    {_t('lock-time::Number of minutes')}
                  </Typography.Text>
                  <InputNumber
                    value={value}
                    min={1}
                    type="number"
                    onChange={changeMinutesValue}
                    {...rest}
                  />
                </div>
              )}
            </Col>
          </Row>
          {<div className="conf-description">{description}</div>}
        </Form.Item>
      </div>
    </>
  );
}

LockTimeEditor.propTypes = {
  panel: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.any,
  help: PropTypes.string,
  validateStatus: PropTypes.string,
  onChange: PropTypes.func,
  resetButton: PropTypes.element
};

LockTimeEditor.defaultProps = {
  onChange: () => {}
};

export default LockTimeEditor;
