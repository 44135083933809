import axios from 'axios';
import { addAccessErrorsInterceptor, addCsfrTokenInterceptor } from './interceptors';

const axiosApiInstance = axios.create();

let apiUrl;

const refreshToken = async () => {
  return axiosApiInstance.post(`${apiUrl}/jwt-refresh`).then(() => {
    pendingRefresh = null;
  });
};

let pendingRefresh = null;

const handleRefreshToken = (err) => {
  if (err?.response?.status === 401) {
    if (!pendingRefresh) {
      pendingRefresh = refreshToken();
    }
    const originalRequest = err.config;
    return pendingRefresh.then(() => axiosApiInstance(originalRequest));
  }
  return Promise.reject(err);
};

export const executeWithRefresh = (handler, checkRefresh) => {
  return handler().then((res) => {
    if (checkRefresh(res)) {
      if (!pendingRefresh) {
        pendingRefresh = refreshToken();
      }
      return pendingRefresh.then(handler);
    }
    return res;
  });
};

const logError = (err) => {
  if (err.isAxiosError) {
    // eslint-disable-next-line no-console
    console.error(`Communication error: ${err?.response?.data}`);
  }
  return Promise.reject(err);
};

export const get = (url, config) => {
  return axiosApiInstance
    .get(`${apiUrl}/${url}`, { ...config, withCredentials: true })
    .catch(handleRefreshToken)
    .catch(logError);
};

export const getData = (url, config) => {
  return get(url, config).then(({ data }) => data);
};

export const post = (url, data, config) => {
  return axiosApiInstance
    .post(`${apiUrl}/${url}`, data, { ...config, withCredentials: true })
    .catch(handleRefreshToken)
    .catch(logError);
};

export const put = (url, data, config) => {
  return axiosApiInstance
    .put(`${apiUrl}/${url}`, data, {
      ...config,
      withCredentials: true
    })
    .catch(handleRefreshToken)
    .catch(logError);
};

export const del = (url, config) => {
  return axiosApiInstance
    .delete(`${apiUrl}/${url}`, { ...config, withCredentials: true })
    .catch(handleRefreshToken)
    .catch(logError);
};

export const url = (strings, ...params) => {
  return strings.reduce((res, str, index) => {
    let newString = res + str;
    if (params[index] !== undefined) {
      newString += encodeURIComponent(params[index]);
    }
    return newString;
  }, '');
};

export const initApi = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    apiUrl = '/api';
  } else {
    apiUrl = '';
  }

  addAccessErrorsInterceptor(axiosApiInstance);
  addCsfrTokenInterceptor(axiosApiInstance);
};

const api = {
  get,
  post,
  put,
  del,
  apiUrl
};

export default api;

export { apiUrl };
