import React from 'react';
import { SFNumber } from 'rosis-setting-form';
import { _t } from 'rosis-translation';

function DecimalPlaces(props) {
  return <SFNumber {...props} min={0} step={1} precision={0} allowClear />;
}

const decimalPlaces = {
  get label() {
    return _t('::Decimal places');
  },
  Field: DecimalPlaces,
  name: 'decimal'
};

export default decimalPlaces;
