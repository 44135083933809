import * as additionalOptions from './additionalOptions';
import * as editAs from './editAs';
import makeType from '../../makeType';

const text = makeType({
  name: 'Calculated',
  label: '::Calculated',
  editAs,
  additionalOptions
});

export default text;
