import { useMemo } from 'react';
import { useNavigate } from 'rosis-components/navigation-wrapper';

export const links = {
  home: () => '/',
  login: () => '/login',
  pinCode: () => '/pinCode',
  forgotPassword: () => '/forgotPassword',
  dashboard: () => '/dashboard',
  sections: () => `/sections`,
  sectionCreate: () => '/sections/create',
  sectionDetails: (code) => `/sections/${code}`,
  sectionDetailsGroup: (code, group) => `/sections/${code}/${group}`,
  sectionFieldCreate: (sectionCode) => `/sections/${sectionCode}/field/create`,
  sectionField: (sectionCode, code) => `/sections/${sectionCode}/field/${code}`,
  privileges: () => `/privileges`,
  privilegesForRole: (role) => `/privileges/${role}`,
  users: () => '/users',
  usersForRole: (role) => `/users/${role}`,
  userCreate: (role) => `/users/create/${role}`,
  userDetails: (role, id) => `/users/${role}/${id}`,
  langs: () => '/lang',
  langDetails: (langCode) => `/lang/${langCode}`,
  sites: () => '/sites',
  sitesDetails: (code) => `/sites/${code}`,
  emailTemplateDetails: (name, langCode) => `/emailTemplates/${name}/${langCode}`,
  settings: () => '/settings',
  settingsGroup: (group, subgroup) => `/settings/${group}${subgroup ? `/${subgroup}` : ''}`,
  logs: () => '/logs',
  myProfile: () => '/profile'
};

export const useNavigation = () => {
  const normalNav = useNavigate();
  const forcedNav = useNavigate(true);
  return useMemo(() => {
    const createLinks = (nav) => ({
      home: () => nav(links.home()),
      usersForRole: (role, options) => nav(links.usersForRole(role), options),
      emailTemplates: () => nav(links.emailTemplates()),
      emailTemplateDetails: (name, langCode) => nav(links.emailTemplateDetails(name, langCode)),
      langDetails: (langCode) => nav(links.langDetails(langCode)),
      sectionDetails: (code, options) => nav(links.sectionDetails(code), options),
      sectionDetailsGroup: (code, group, options) =>
        nav(links.sectionDetailsGroup(code, group), options),
      privilegesForRole: (role, options) => nav(links.privilegesForRole(role), options),
      sections: (options) => nav(links.sections(), options),
      settingsGroup: (group, subgroup) => nav(links.settingsGroup(group, subgroup)),
      back: () => nav(-1)
    });
    return {
      ...createLinks(normalNav),
      forced: createLinks(forcedNav)
    };
  }, [links, normalNav, forcedNav]);
};
