import React from 'react';
import PropTypes from 'prop-types';
import { Layout, ConfigProvider } from 'antd';
import Content from './Content';
import useInfoBarFromRouting from 'rosis-components/hooks/use-info-bar-from-routing';
import useDocumentDirection from './use-document-direction';
import ErrorBoundary from 'rosis-components/ErrorBoundary';

function NotLoggedLayout({ children }) {
  useInfoBarFromRouting();
  const { direction } = useDocumentDirection();

  return (
    <ConfigProvider direction={direction}>
      <ErrorBoundary>
        <Layout>
          <Content notLogged>{children}</Content>
        </Layout>
      </ErrorBoundary>
    </ConfigProvider>
  );
}

NotLoggedLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default NotLoggedLayout;
