import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { _t } from 'rosis-translation';

import { useConfig, usePasswordGenerator } from 'rosis-api';

const GeneratePasswordButton = ({ onGeneration }) => {
  const { refetch: generatePsw } = usePasswordGenerator();
  const { data: showGeneratePsw } = useConfig('pub', 'generatePsw');

  const generatePswClick = async () => {
    const response = await generatePsw();
    onGeneration(response.data);
  };

  return showGeneratePsw === 'yes' ? (
    <Button type="link" onClick={generatePswClick} className="gen-pswd">
      {_t('::Generate password')}
    </Button>
  ) : null;
};

GeneratePasswordButton.propTypes = {
  onGeneration: PropTypes.func
};

GeneratePasswordButton.defaultProps = {
  onGeneration: () => {}
};

export default GeneratePasswordButton;
