import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, InputNumber, Select } from 'antd';
import { _t } from 'rosis-translation';

const { Option } = Select;

function TokenExpire({ label, value, onChange, resetButton }) {
  const [numberValue, setNumberValue] = useState();
  const [unit, setUnit] = useState();

  useEffect(() => {
    const numberValue = value?.replace(/[^0-9]/g, '');
    const unit = value?.replace(/[0-9]/g, '');
    setNumberValue(numberValue);
    setUnit(unit);
  }, [value]);

  const handleChangeNumber = (newValue) => {
    setNumberValue(newValue);
    onChange(`${newValue}${unit}`);
  };
  const handleChangeUnit = (newValue) => {
    setUnit(newValue);
    onChange(`${numberValue}${newValue}`);
  };

  const selectAfter = (
    <Select value={unit} onChange={handleChangeUnit}>
      <Option value="s">{_t('::seconds')}</Option>
      <Option value="m">{_t('::minutes')}</Option>
      <Option value="h">{_t('::hours')}</Option>
      <Option value="d">{_t('::days')}</Option>
    </Select>
  );

  return (
    <Form.Item label={<>{label} {resetButton}</>}>
      <InputNumber
        type="number"
        value={numberValue}
        onChange={handleChangeNumber}
        addonAfter={selectAfter}
        style={{ maxWidth: 180 }}
      />
    </Form.Item>
  );
}

TokenExpire.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  resetButton: PropTypes.element
};

export default TokenExpire;
