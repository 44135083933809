import React, { useEffect, useState } from 'react';
import { Tooltip, List, Row, Col, Empty, Skeleton } from 'antd';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { useChange } from 'contexts/change';
import { _t } from 'rosis-translation';
import { useUsersUpdate, useElements, useLocal } from 'rosis-api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import ConfirmUser from '../ConfirmUser';
import FilterUser from '../FilterUser';
import Pagination from 'components/Pagination';
import './../Users.less';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';
import EnableButton from '../EnableButton';
import { useAuth } from 'rosis-contexts/auth';
import RowAction from '../RowActions';
import useActiveUsers from '../useActiveUsers';
import useUsersCriteria from '../useUsersCriteria';

function UsersByRoleMobile({ name, noData, sort, onUpdateFilter }) {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const { filters: recentFilters, setFilters: storeRecentFilters } = useChange();

  const { activeUsers } = useActiveUsers();

  const { showInfoBar } = useInfoBar();

  const [isLoadingLocal, setIsLoadingLocal] = useState(true);
  const { data: localPerPage, isLoading: isLoadingLocalPerPage } = useLocal('perPage');

  const {
    page,
    elementsPerPage,
    changePagination,
    changeSortBy,
    filters,
    changeFilters,
    requestCriteria
  } = useUsersCriteria({ role: name, recentFilters, onChangeFiltering: storeRecentFilters });

  useEffect(() => {
    changeSortBy(sort);
  }, [sort]);

  useEffect(() => {
    onUpdateFilter(requestCriteria);
  }, [requestCriteria]);

  const isElementLoadingEnable = !isLoadingLocal;

  const { data, refetch, isLoading } = useElements(
    'user',
    isElementLoadingEnable ? requestCriteria : { find: new Date().getTime() },
    {
      enabled: isElementLoadingEnable
    }
  );

  const { elements, next } = data || {};

  const { mutate: update, ...userUpdateStatus } = useUsersUpdate(queryClient, name);

  useApiFeedback({
    status: userUpdateStatus,
    errorMessage: _t('error::User update was failed'),
    silentSuccess: true
  });

  useEffect(() => {
    if (!isLoadingLocalPerPage) {
      changePagination({ current: 1, perPage: localPerPage || 10 });
      setIsLoadingLocal(false);
    }
  }, [isLoadingLocalPerPage, localPerPage]);

  const onSuccess = (message) => {
    refetch();
    showInfoBar({
      message,
      type: 'success'
    });
  };

  const onFail = (message) => {
    showInfoBar({
      message,
      type: 'error'
    });
  };

  return (
    <React.Fragment>
      <FilterUser values={filters} onChange={changeFilters} />
      {!isElementLoadingEnable || isLoading ? (
        <Skeleton />
      ) : (
        <List
          itemLayout="horizontal"
          className="user-list"
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={noData ? noData : _t('user::No users')}
              />
            )
          }}
          dataSource={elements}
          renderItem={(item) => (
            <List.Item style={{ display: 'block' }}>
              <Row gutter={[10, 0]}>
                <Col span={24} key="idStr" className="display-field">
                  <span className="left-section">{_t('user::Login')}:</span>
                  <span className="field-ellipsis">
                    {item.idStr}
                    {activeUsers.includes(item._id) && (
                      <Tooltip title={_t('::On-line')}>
                        <FontAwesomeIcon
                          icon={faDotCircle}
                          size="xs"
                          style={{ marginLeft: 10, color: '#d9f7be' }}
                          pulse={true}
                        />
                      </Tooltip>
                    )}
                  </span>
                </Col>
                <Col span={24} key="sites" className="display-field">
                  <span className="left-section">{_t('user::Site')}:</span>
                  <span className="field-ellipsis">
                    <div className="user-site_ellipsis">{item.sites && item.sites.join(', ')}</div>
                  </span>
                </Col>
                <Col span={24} className="confirm-mobile">
                  <span className="left-section">{_t('user::Confirmation status:')}</span>
                  <ConfirmUser
                    confirmation={item.confirmation}
                    idStr={item.idStr}
                    onSuccess={onSuccess}
                    onFail={onFail}
                  />
                </Col>
                <Col span={24}>
                  <Row justify="space-between">
                    <Col>
                      <span className="left-section">{_t('user::Enable:')}</span>
                      <EnableButton
                        id={item._id}
                        disabled={item._id === user._id}
                        checked={item.enabled}
                        onChange={(value) => update({ value, id: item._id, name })}
                      />
                    </Col>
                    <Col>
                      <RowAction
                        _id={item._id}
                        enabled={item.enabled}
                        onSuccess={onSuccess}
                        onFail={onFail}
                        name={name}
                        penalty={item.penalty}
                        user={user}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      )}

      <Pagination
        currentPage={page}
        next={next}
        countOfElements={elements?.length}
        elementsPerPage={elementsPerPage}
        onChangePagination={changePagination}
      />
    </React.Fragment>
  );
}

UsersByRoleMobile.propTypes = {
  name: PropTypes.string,
  noData: PropTypes.string,
  sort: PropTypes.object,
  onUpdateFilter: PropTypes.func
};

export default UsersByRoleMobile;
