import { useEffect } from 'react';
import { useTranslation } from 'contexts/translation';

const useDocumentDirection = () => {
  const { direction } = useTranslation();
  useEffect(() => {
    const root = document.documentElement;
    root.classList.remove('ltr', 'rtl');
    root.classList.add(direction);
    root.setAttribute('data-direction', direction);
  }, [direction]);
  return { direction };
};

export default useDocumentDirection;
