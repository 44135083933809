import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { _t } from 'rosis-translation';
import { operatorTypes } from './utils';

function LogicalOperator({ operator, onChange }) {
  return operator ? (
    <Button
      className="conditions__element__logical_operator"
      onClick={() => onChange(operator === 'or' ? 'and' : 'or')}>
      {operator === 'or' ? _t('button::OR') : null}
      {operator === 'and' ? _t('button::AND') : null}
    </Button>
  ) : (
    <div></div>
  );
}

LogicalOperator.propTypes = {
  operator: PropTypes.oneOf(operatorTypes),
  onChange: PropTypes.func
};

export default LogicalOperator;
