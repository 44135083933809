import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';

import UserMessage from './UserMessage';
import NotificationExecutionMessage from './NotificationExecutionMessage';
import TranslationChangedMessage from './TranslationChangedMessage';
import RoleDefinitionChangedMessage from './RoleDefinitionChangedMessage';

const getComponent = (event, message) => {
  const translatedMessage = _t(message);
  switch (event) {
    case 'log_failed':
    case 'log_failed_multiple':
    case 'password_changed':
    case 'password_reset':
    case 'user_registered':
    case 'user_added':
    case 'user_removed':
    case 'user_blocked':
    case 'user_unblocked':
    case 'user_autoblocked':
    case 'user_remove_autoblock':
    case 'user_confirmed':
      return <UserMessage message={translatedMessage} />;
    case 'notification_execution':
    case 'notification_failed':
      return <NotificationExecutionMessage message={translatedMessage} />;
    case 'translation_changed':
      return <TranslationChangedMessage message={translatedMessage} />;
    case 'role_changed':
      return <RoleDefinitionChangedMessage message={message} />;
    default:
      return <div>{translatedMessage}</div>;
  }
};

const MessageRenderer = ({ event, message }) => {
  return <div>{getComponent(event, message)}</div>;
};

MessageRenderer.propTypes = {
  event: PropTypes.string,
  message: PropTypes.string
};

export default MessageRenderer;
