import React from 'react';
import PropTypes from 'prop-types';
import getFieldType from '../src/getFieldType';

const EditField = React.forwardRef((props, ref) => {
  const { value, name, type, editAs } = props;
  const { component: Component, valuePropName } = getFieldType('editAs', type, editAs) || {};
  return Component ? (
    <React.Fragment>
      <Component
        ref={ref}
        initialValue={value}
        type={type}
        name={name}
        valuePropName={valuePropName}
        {...props}
      />
    </React.Fragment>
  ) : null;
});

EditField.displayName = 'EditField';

EditField.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  type: PropTypes.string,
  editAs: PropTypes.string
};

export default EditField;
