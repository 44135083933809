import { useState } from 'react';
import { useEffect, useMemo } from 'react';
import { useAllConfigs, useLangKeys } from 'rosis-api';
import { quadDot } from 'system/symbol';

const translationConfigKey = '_lang';

const useConfigValuesLoader = ({ settings }) => {
  const {
    data: configValues,
    isLoading: isValuesLoading,
    isFetching: isValuesFetching,
    refetch: refetchConfigs,
    dataUpdatedAt: configValuesDataUpdatedAt
  } = useAllConfigs();
  const {
    data: translationValues,
    isLoading: isLangKeysLoading,
    isFetching: isLangKeysFetching,
    refetch: refetchLangKeys,
    dataUpdatedAt: translationValuesDataUpdatedAt
  } = useLangKeys();

  const [dataUpdatedAt, setDataUpdatedAt] = useState(0);

  useEffect(() => {
    if (!isValuesFetching && !isLangKeysFetching) {
      setDataUpdatedAt(Math.min(configValuesDataUpdatedAt, translationValuesDataUpdatedAt));
    }
  }, [
    isValuesFetching,
    configValuesDataUpdatedAt,
    isLangKeysFetching,
    translationValuesDataUpdatedAt
  ]);

  const translationValuesResult = useMemo(() => {
    const collectTranslationKeys = (res, settings) => {
      for (const setting of settings) {
        if (setting.group === translationConfigKey) {
          const contextKey = setting.name.split(quadDot).shift();
          res.add(contextKey);
        }
        if (setting.subsettings) {
          collectTranslationKeys(res, setting.subsettings);
        }
      }
      return Array.from(res);
    };
    const keys = collectTranslationKeys(new Set(), settings);
    const result = {};
    if (!translationValues) {
      return result;
    }
    translationValues.forEach((tv) => {
      const contextKey = tv.split(quadDot).shift();
      if (keys.includes(contextKey)) {
        let val = result[contextKey];
        if (!val) {
          val = [];
          result[contextKey] = val;
        }
        val.push(tv);
        val.sort();
      }
    });
    return result;
  }, [settings, translationValues, translationValuesDataUpdatedAt]);

  const values = useMemo(() => {
    return {
      ...configValues,
      [translationConfigKey]: {
        ...translationValuesResult
      }
    };
  }, [settings, configValues, configValuesDataUpdatedAt, translationValuesResult]);

  const refetch = () => {
    refetchConfigs();
    refetchLangKeys();
  };

  return {
    values,
    isLoading: isValuesLoading || isLangKeysLoading,
    dataUpdatedAt,
    refetch
  };
};

export default useConfigValuesLoader;
