import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';

const TranslationChangedMessage = ({ message }) => {
  const [language, ...additionalInfo] = message.split('/');
  return (
    <>
      <div>
        <span className="message-key">{_t('::Language')}</span>
        <span>{language}</span>
      </div>
      {additionalInfo.length === 1 && (
        <div>
          <span className="message-key">{_t('::Action')}</span>
          <span>{additionalInfo[0]}</span>
        </div>
      )}
      {additionalInfo.length > 1 && (
        <div>
          <span className="message-key">{_t('signature::Key')}</span>
          <span>{additionalInfo[0]}</span>
        </div>
      )}
      {additionalInfo.length > 1 && (
        <div>
          <span className="message-key">{_t('::Translation')}</span>
          <span>{additionalInfo[1]}</span>
        </div>
      )}
    </>
  );
};

TranslationChangedMessage.propTypes = {
  message: PropTypes.string
};

TranslationChangedMessage.defaultProps = {
  message: ''
};

export default TranslationChangedMessage;
