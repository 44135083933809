import React from 'react';
import PropTypes from 'prop-types';
import { SFNumber } from 'rosis-setting-form';
import { _t } from 'rosis-translation';
import { useFormContext } from 'react-hook-form';

function DecimalPlaces({ name, label }) {
  const { watch } = useFormContext();
  const resultType = watch('resultType');
  return resultType === 'Number' ? (
    <SFNumber name={name} label={label} min={0} max={15} step={1} precision={0} clearBtn />
  ) : null;
}

DecimalPlaces.propTypes = { name: PropTypes.string, label: PropTypes.string };

const decimalPlaces = {
  get label() {
    return _t('::Decimal places');
  },
  Field: DecimalPlaces,
  name: 'decimal',
  order: 40
};

export default decimalPlaces;
