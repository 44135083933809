import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import ConfigSettings from 'components/ConfigSettings';
import { calcSettingPageTitle } from '../utils';
import PageTitle from 'components/PageTitle';
import { ConfigFormControlProvider } from 'components/ConfigSettings/ConfigFormControl';
import ConfigPageHeader from 'components/ConfigSettings/ConfigPageHeader';
import { useChange } from 'contexts/change';

const PerAppSettingsTab = ({ title, appName }) => {
  const { isChanged, setIsChanged } = useChange();
  const settingsConf = useMemo(() => {
    const perAppSettings = [
      {
        name: 'title',
        group: 'pub',
        label: _t('::Title'),
        description: _t(
          '::Title will be displayed on the Log in screen and in the top left corner of the app (next to logo).'
        ),
        kind: 'string'
      },
      {
        name: 'description',
        group: 'pub',
        label: _t('::Subtitle'),
        description: _t('::Description will be displayed on the Log in screen.'),
        kind: 'string'
      },
      {
        name: 'footerText',
        group: 'pub',
        label: _t('::Footer'),
        description: _t('::Footer will be displayed below main app content.'),
        kind: 'string',
        showAs: 'editor'
      },
      {
        name: 'logo',
        group: 'opengraph',
        label: _t('::Logo'),
        description: _t(
          '::Logo will be displayed in the top left corner. Upload SVG (recommended), PNG, or JPG file type.'
        ),
        kind: 'image'
      },
      { name: 'image', group: 'opengraph', label: _t('::Image for login screen '), kind: 'image' },
      { name: 'icon', group: 'opengraph', label: _t('::URL icon (favicon)'), kind: 'image' },
      {
        name: 'baseUrl',
        group: 'setup',
        label: _t('::Base link for application'),
        description: _t('::Set this param if application URL is changed (e.g. by proxy setting).'),
        hideOnDefaultPage: true,
        kind: 'string'
      },
      {
        name: 'opengraph',
        label: _t('::Opengraph'),
        subsettings: []
      },
      {
        name: 'title',
        group: 'opengraph',
        label: _t('::Application name'),
        kind: 'string'
      },
      {
        name: 'description',
        group: 'opengraph',
        label: _t('::Application description'),
        kind: 'string'
      }
    ];

    return appName
      ? perAppSettings.map((setting) => {
          const current = { ...setting };
          current.name = `${appName}-${current.name}`;
          return current;
        })
      : perAppSettings.filter((setting) => !setting.hideOnDefaultPage);
  }, [appName]);

  const pageTitle = calcSettingPageTitle(title);

  return (
    <ConfigFormControlProvider>
      <PageTitle title={pageTitle} />
      <ConfigPageHeader pageTitle={pageTitle} isDirty={isChanged} />
      <ConfigSettings settings={settingsConf} onIsDirtyChange={setIsChanged} />
    </ConfigFormControlProvider>
  );
};

PerAppSettingsTab.propTypes = {
  title: PropTypes.string,
  appName: PropTypes.string
};

export default PerAppSettingsTab;
