import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Select } from 'antd';
import { _t } from 'rosis-translation';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSites, useSetSitesForUser } from 'rosis-api';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';

const { Option } = Select;

function AssignSitesToUserModal({ sites, sitesList, onChangeSites }) {
  return (
    <Row gutter={16}>
      <Col span={24} style={{ marginTop: '15px' }}>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={sites}
          placholder={_t('::Selected sites')}
          onChange={onChangeSites}>
          {sitesList && Array.isArray(sitesList)
            ? sitesList.map((site) => (
                <Option key={site.id} value={site.id} label={site.title}>
                  <div>{site.title}</div>
                </Option>
              ))
            : null}
        </Select>
      </Col>
    </Row>
  );
}

AssignSitesToUserModal.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.string),
  sitesList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, title: PropTypes.string })),
  onChangeSites: PropTypes.func
};

function AssignSitesToUser({ userId, sites: sitesProp }) {
  const { data: sitesList } = useSites();

  const [visibleModal, setVisibleModal] = useState(false);
  const [sites, setSites] = useState([]);

  const {
    mutate: storeSites,
    isLoading: isLoadingStoreSites,
    ...storeSitesStatus
  } = useSetSitesForUser();

  useEffect(() => {
    setSites(sitesProp || []);
  }, [sitesProp]);

  const assignToSites = () => {
    const toAdd = sites.filter((s) => !sitesProp.includes(s));
    const toRemove = sitesProp.filter((s) => !sites.includes(s));
    if (toAdd.length || toRemove.length) {
      storeSites({ userId, toAdd, toRemove });
    } else {
      hideModal();
    }
  };

  useApiFeedback({
    status: {
      ...storeSitesStatus,
      isLoading: isLoadingStoreSites
    },
    onSuccess: () => {
      hideModal();
    },
    onError: () => {
      hideModal();
    }
  });

  const changeSites = (value) => {
    setSites(value || []);
  };

  const showModal = () => {
    setVisibleModal(true);
  };

  const hideModal = () => {
    setVisibleModal(false);
  };

  return (
    <>
      <Button
        icon={<FontAwesomeIcon icon={faPen} />}
        type="link"
        className="font-bold"
        disabled={isLoadingStoreSites}
        loading={isLoadingStoreSites}
        aria-label={_t('button::Assign sites')}
        onClick={showModal}></Button>

      <Modal
        title={_t('::Assign user to sites')}
        open={visibleModal}
        destroyOnClose
        onOk={assignToSites}
        onCancel={hideModal}
        maskClosable={false}
        okText={_t('button,store::Save')}
        cancelText={_t('::Cancel')}>
        <AssignSitesToUserModal sites={sites} sitesList={sitesList} onChangeSites={changeSites} />
      </Modal>
    </>
  );
}

AssignSitesToUser.propTypes = {
  userId: PropTypes.string,
  sites: PropTypes.arrayOf(PropTypes.string)
};

export default AssignSitesToUser;
