import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input } from 'antd';
import { _t } from 'rosis-translation';
import '../../Settings.less';

const AddNewQuestion = ({ questions, onAddNewQuestion, questionExist, setQuestionExist }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (!questions.some((question) => question.value === values.question && !question.deleted)) {
      onAddNewQuestion({
        value: values.question
      });
      form.resetFields();
      setQuestionExist(false);
    } else {
      setQuestionExist(true);
    }
  };

  const onChange = () => {
    if (questionExist) setQuestionExist(false);
  };

  return (
    <Form
      form={form}
      name="new-question-form"
      className="add-new-question-form"
      layout="inline"
      autoComplete="off"
      onFinish={onFinish}>
      <Form.Item
        name="question"
        style={{ width: '50%' }}
        validateStatus={questionExist ? 'error' : undefined}
        help={questionExist ? _t('::This question already exists') : undefined}
        rules={[
          {
            required: true,
            message: _t('::Please input your question!')
          }
        ]}>
        <Input onChange={onChange} autoComplete="off" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {_t('::Add question')}
        </Button>
      </Form.Item>
    </Form>
  );
};

AddNewQuestion.propTypes = {
  questions: PropTypes.array,
  onAddNewQuestion: PropTypes.func,
  questionExist: PropTypes.bool,
  setQuestionExist: PropTypes.func
};

export default AddNewQuestion;
