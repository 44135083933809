import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { useAuth } from 'rosis-contexts/auth';
import { useParams } from 'rosis-components/navigation-wrapper';
import { useNavigation } from 'router/links';
import { useTabs } from './tabs';

function SubSettings({ tabs }) {
  const { section, subsection } = useParams();
  const { devMode } = useAuth();
  const navigation = useNavigation();
  const activeSection = subsection || tabs[0].id;
  const items = useTabs(tabs, devMode);
  const handleChange = (activeKey) => navigation.settingsGroup(section, activeKey);
  return (
    <Tabs items={items} activeKey={activeSection} onChange={handleChange} destroyInactiveTabPane />
  );
}

SubSettings.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      children: PropTypes.array,
      enabled: PropTypes.func
    })
  )
};

export default SubSettings;
