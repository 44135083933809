import React from 'react';
import { _t } from 'rosis-translation';
import PropTypes from 'prop-types';
import DatePicker from '../../../utils/DatePicker';
import { datetime } from 'rosis-date-picker';
import Item from '../../../Item';

const FTDateTime = React.forwardRef((props, ref) => {
  const { value, min, max, editAs, onChange } = props;
  const displayValue = value ? datetime(value) : null;

  return (
    <Item {...props} value={displayValue}>
      <DatePicker
        ref={ref}
        value={displayValue}
        min={min}
        max={max}
        editAs={editAs}
        onChange={onChange}
      />
    </Item>
  );
});

FTDateTime.displayName = 'FTDateTime';

FTDateTime.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  min: PropTypes.object,
  max: PropTypes.object,
  editAs: PropTypes.string,
  onChange: PropTypes.string
};

const options = {
  value: 'datetime',
  get label() {
    return _t('::Date & time');
  },
  component: FTDateTime,
  example: <FTDateTime name="datetime" editAs="datetime" value={'08/12/1992 5:00 PM'} />,
  default: true
};

export default options;
