import React from 'react';
import { Typography } from 'antd';
import { useConfigs } from 'rosis-api';

const { Title } = Typography;

function AppTitle() {
  const { data: config } = useConfigs('pub');
  const title = config?.['admin-title'] || config?.title;

  return <Title className="login-title">{title}</Title>;
}

export default AppTitle;
