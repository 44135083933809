import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { SFInput } from 'rosis-setting-form';

function Prefix(props) {
  return <SFInput {...props} />;
}

Prefix.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
};

const prefix = {
  get label() {
    return _t('::Prefix');
  },
  Field: Prefix,
  name: 'prefix'
};

export default prefix;
