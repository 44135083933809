import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { Tabs } from 'antd';
import { useInfoBar } from 'rosis-components/InfoBar/infoBar';
import ResetModel from './ResetModel';
import Config from './Config';
import { usePageHeader } from 'contexts/pageHeader';
import TitleActions from 'components/TitleActions';
import GenerateDemoData from './GenerateDemoData';
import PageTitle from 'components/PageTitle';
import { calcSettingPageTitle } from '../utils';

function DevMode({ title }) {
  const { showInfoBar } = useInfoBar();
  const onSuccess = (message) => {
    showInfoBar({
      message,
      type: 'success'
    });
  };

  const onFail = (message) => {
    showInfoBar({
      message,
      type: 'error'
    });
  };

  const pageTitle = calcSettingPageTitle(title);

  usePageHeader(<TitleActions actions={[]}>{pageTitle}</TitleActions>);

  const tabs = [
    {
      label: _t('::Reset model'),
      key: 'resetModel',
      children: <ResetModel onSuccess={onSuccess} onFail={onFail} />
    },
    {
      label: _t('::Configuration'),
      key: 'config',
      children: <Config onSuccess={onSuccess} onFail={onFail} />
    },
    {
      label: _t('::Generate demo data'),
      key: 'demoData',
      children: <GenerateDemoData />
    }
  ];

  return (
    <>
      <PageTitle title={pageTitle} />
      <Tabs defaultActiveKey="resetModel" items={tabs} destroyInactiveTabPane />
    </>
  );
}

DevMode.propTypes = {
  title: PropTypes.string
};

export default DevMode;
