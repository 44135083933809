import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { _t } from 'rosis-translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Item from '../../../Item';

function Enum(props) {
  const { value, frameName, name, step, paddingWith, prefix, length, getNewCounter, form } = props;
  const [displayValue, setDisplayValue] = useState(value);

  const handleClick = async () => {
    const counter = await getNewCounter(frameName, name, step, paddingWith, prefix, length);
    form.setFieldsValue({ [name]: counter });
    setDisplayValue(counter);
  };
  return (
    <Item {...props}>
      {props.enumerable ? (
        <>
          {displayValue ? displayValue : ''}
          <Button type="link" onClick={handleClick}>
            <Tooltip title={_t('enum_field_next_value::Assign next value')}>
              <FontAwesomeIcon icon={faPlusCircle} />
            </Tooltip>
          </Button>
        </>
      ) : (
        displayValue
      )}
    </Item>
  );
}

Enum.propTypes = {
  value: PropTypes.string,
  frameName: PropTypes.string,
  name: PropTypes.string,
  step: PropTypes.number,
  paddingWith: PropTypes.string,
  prefix: PropTypes.string,
  length: PropTypes.number,
  getNewCounter: PropTypes.func,
  enumerable: PropTypes.bool,
  form: PropTypes.func
};

const options = {
  value: 'enum',
  get label() {
    return _t('::Enumeration');
  },
  default: true,
  component: Enum,
  example: <Enum value={'000000001'} />
};

export default options;
