import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import Row from './Row';

function DndRow({ model, index, extendedModels, handleExtend, lastOne }) {
  const { name, person } = model;
  const currentRow = (
    <Row
      index={index}
      model={model}
      extendedModels={extendedModels}
      handleExtend={handleExtend}
      lastOne={lastOne}
    />
  );
  return person ? (
    currentRow
  ) : (
    <Draggable draggableId={name} index={index}>
      {(provided, snapshot) => {
        return React.cloneElement(currentRow, {
          provided,
          className: snapshot.isDragging ? 'dragging' : null
        });
      }}
    </Draggable>
  );
}

DndRow.propTypes = {
  model: PropTypes.shape({ name: PropTypes.string.isRequired, person: PropTypes.bool }),
  index: PropTypes.number,
  extendedModels: PropTypes.array,
  handleExtend: PropTypes.func,
  lastOne: PropTypes.bool
};

export default DndRow;
