import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber } from 'antd';
import { _t } from 'rosis-translation';

import { isRelativeValuePattern } from './index';

const unitToLabel = {
  d: 'form_settings_date::days',
  D: 'form_settings_date::days',
  M: 'form_settings_date::months',
  Y: 'form_settings_date::years'
};

const editAsToUnit = {
  datetime: 'd',
  date: 'D',
  month: 'M',
  year: 'Y'
};

const fromNumberToString = (val, unit) => {
  if (val === null || val === undefined) {
    return val;
  }
  return `${val}${unit}`;
};

const RelativeInput = ({ name, value, editAs, min, max, onChange }) => {
  const numberValue = fromStringToNumber(value);
  const unit = editAsToUnit[editAs];

  const change = (numberVal) => {
    onChange(fromNumberToString(numberVal, unit));
  };
  const minNumber = fromStringToNumber(min);
  const maxNumber = fromStringToNumber(max);

  const unitLabel = _t(unitToLabel[unit]);

  return (
    <div className="rof-date-input-value-wrapper">
      <InputNumber
        type="number"
        name={name}
        value={numberValue}
        min={minNumber}
        max={maxNumber}
        onChange={change}
      />
      <label htmlFor={name}>
        {_t('form_settings_date::{unitLabel} from edition moment', { unitLabel })}
      </label>
    </div>
  );
};

const fromStringToNumber = (val) => {
  if (!val) {
    return null;
  }
  const match = isRelativeValuePattern.exec(val);
  if (!match) {
    //it is possible when formatting other date (e.g min constraint for max editor)
    return null;
  }
  return Number(match[1]);
};

RelativeInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  editAs: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func
};

export default RelativeInput;
