import React from 'react';
import PropTypes from 'prop-types';
import { useCalcFunc, useIsCalcFormulaValid } from 'rosis-api';
import { _t } from 'rosis-translation';

function AdditionalOptionItem({ name, definition, displayAs, editAs, schema, order }) {
  const { label, Field, supportsCalculation } = definition;
  const { data: calcFunc } = useCalcFunc({ enabled: supportsCalculation });
  const { mutateAsync: calcIsFormulaValid } = useIsCalcFormulaValid();
  const checkFormulaValidity = async (val) => {
    const res = await calcIsFormulaValid(val);
    return !res ? _t('::Invalid expression') : null;
  };
  return (
    <div key={name} style={{ order }}>
      <Field
        name={name}
        label={label}
        displayAs={displayAs}
        editAs={editAs}
        schema={schema}
        calcFunc={calcFunc}
        checkFormulaValidity={checkFormulaValidity}
      />
    </div>
  );
}

AdditionalOptionItem.propTypes = {
  schema: PropTypes.object,
  name: PropTypes.string,
  definition: PropTypes.shape({
    label: PropTypes.string,
    Field: PropTypes.object,
    supportsCalculation: PropTypes.bool
  }),
  displayAs: PropTypes.string,
  editAs: PropTypes.string,
  order: PropTypes.number
};

export default AdditionalOptionItem;
