import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'rosis-date-picker';
import { datetime } from 'rosis-date-picker';
import { _t } from 'rosis-translation';
import { dateFormats } from 'system/symbol';

const calcPickerProps = (pickerKind) => {
  switch (pickerKind) {
    case 'datetime':
      return {
        showTime: { use12Hours: true, defaultValue: null },
        format: dateFormats.datetime
      };
    case 'month':
      return {
        picker: 'month',
        format: dateFormats.month
      };
    case 'year':
      return {
        picker: 'year',
        format: dateFormats.year
      };
    default:
      return {
        format: dateFormats.date
      };
  }
};

const { RangePicker } = DatePicker;

const BaseDate = React.forwardRef(({ val, onChange, pickerKind }, ref) => {
  const datePickerProps = calcPickerProps(pickerKind);
  const dates = useMemo(() => {
    if (!val) {
      return [];
    }
    return [val[0] ? datetime(val[0]) : val[0], val[1] ? datetime(val[1]) : val[1]];
  }, [val]);

  const handleChange = (values) => {
    onChange([
      values?.[0] ? datetime(values[0]).format(datePickerProps.format) : null,
      values?.[1] ? datetime(values[1]).format(datePickerProps.format) : null
    ]);
  };

  return (
    <RangePicker
      style={{ width: '100%' }}
      value={dates}
      allowEmpty={[true, true]}
      placeholder={[_t('::From'), _t('::To')]}
      {...datePickerProps}
      onChange={handleChange}
      ref={ref}
    />
  );
});

BaseDate.displayName = 'BaseDate';

BaseDate.propTypes = {
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  pickerKind: PropTypes.string
};

export default BaseDate;
