const extensionList = {
  '3g2': ['other'],
  '3gp': ['other'],
  '3mf': ['other'],
  '7z': ['other'],
  aac: ['other'],
  ac3: ['other'],
  ai: ['other'],
  aif: ['other'],
  alias: ['other'],
  amr: ['other'],
  ape: ['other'],
  apng: ['other'],
  appcache: ['other'],
  ar: ['other'],
  arrow: ['other'],
  arw: ['other'],
  asar: ['other'],
  asf: ['other'],
  avi: ['other'],
  avif: ['other'],
  blend: ['other'],
  bmp: ['other'],
  bpg: ['other'],
  bz2: ['other'],
  c: ['other'],
  cab: ['other'],
  cer: ['other'],
  cfb: ['other'],
  cr2: ['other'],
  cr3: ['other'],
  crx: ['other'],
  css: ['other'],
  csv: ['other'],
  cur: ['image'],
  dcm: ['other'],
  deb: ['other'],
  dmg: ['other'],
  dng: ['other'],
  doc: ['doc', 'default'],
  docx: ['doc', 'default'],
  dot: ['other'],
  dsf: ['other'],
  eot: ['other'],
  eps: ['other'],
  epub: ['other'],
  f4a: ['other'],
  f4b: ['other'],
  f4p: ['other'],
  f4v: ['other'],
  flac: ['other'],
  flif: ['other'],
  flv: ['other'],
  gif: ['image', 'default'],
  glb: ['other'],
  gz: ['other'],
  h: ['other'],
  heic: ['other'],
  htm: ['other'],
  html: ['other'],
  icns: ['other'],
  ico: ['image', 'default'],
  ics: ['other'],
  indd: ['other'],
  it: ['other'],
  jp2: ['other'],
  jpeg: ['image', 'default'],
  jpg: ['image', 'default'],
  jpm: ['other'],
  jpx: ['other'],
  js: ['other'],
  json: ['other'],
  jxr: ['other'],
  ktx: ['other'],
  latex: ['other'],
  lha: ['other'],
  lz: ['other'],
  lzh: ['other'],
  m4a: ['other'],
  m4b: ['other'],
  m4p: ['other'],
  m4v: ['other'],
  mid: ['other'],
  mie: ['other'],
  mj2: ['other'],
  mkv: ['other'],
  mobi: ['other'],
  mov: ['other'],
  mp1: ['other'],
  mp2: ['other'],
  mp3: ['audio'],
  mp4: ['video'],
  mpc: ['other'],
  mpeg: ['other'],
  mpg: ['other'],
  mts: ['other'],
  mxf: ['other'],
  nef: ['other'],
  nes: ['other'],
  odp: ['other'],
  ods: ['other'],
  odt: ['other'],
  oga: ['other'],
  ogg: ['audio'],
  ogm: ['other'],
  ogv: ['other'],
  ogx: ['other'],
  opus: ['other'],
  orf: ['other'],
  otf: ['other'],
  pcap: ['other'],
  pdf: ['doc', 'default'],
  pgp: ['other'],
  png: ['image', 'default'],
  pptx: ['other'],
  ps: ['other'],
  psd: ['other'],
  qcp: ['other'],
  raf: ['other'],
  rar: ['other'],
  rpm: ['other'],
  rtf: ['other'],
  rw2: ['other'],
  s3m: ['other'],
  shp: ['other'],
  skp: ['other'],
  spx: ['other'],
  sqlite: ['other'],
  stl: ['other'],
  svg: ['image'],
  swf: ['other'],
  tar: ['other'],
  tex: ['other'],
  tgz: ['other'],
  tif: ['other'],
  tiff: ['other'],
  ttf: ['other'],
  txt: ['doc', 'default'],
  vcf: ['other'],
  voc: ['other'],
  wasm: ['other'],
  wav: ['audio'],
  webm: ['video'],
  webp: ['other'],
  woff: ['other'],
  woff2: ['other'],
  wv: ['other'],
  xcf: ['other'],
  xls: ['other'],
  xlsx: ['other'],
  xm: ['other'],
  xpi: ['other'],
  xz: ['other'],
  z: ['other'],
  Z: ['other'],
  zip: ['other'],
  zst: ['other']
};

export default extensionList;
