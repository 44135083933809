import React, { useEffect } from 'react';
import { Typography, Spin } from 'antd';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';

const { Title } = Typography;

const SessionExpired = ({ to }) => {
  useEffect(() => {
    window.location = to;
  }, []);
  return (
    <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center' }}>
      <Title level={2}>
        <Spin style={{ marginRight: '10px' }} />
        {_t('::Session expired')}
      </Title>
    </div>
  );
};

SessionExpired.propTypes = {
  to: PropTypes.string
};

SessionExpired.defaultProps = {
  to: `${process.env["PUBLIC_URL"]}/login`
};

export default SessionExpired;
