import React from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { SFInput } from 'rosis-setting-form';

function PaddingWith(props) {
  return <SFInput {...props} />;
}

PaddingWith.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
};

const paddingWith = {
  get label() {
    return _t('::Character to padding with');
  },
  Field: PaddingWith,
  name: 'paddingWith',
  order: 20
};

export default paddingWith;
