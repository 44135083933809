import React from 'react';
import { Select, Form } from 'antd';
import { _t } from 'rosis-translation';
import { stdKeys } from 'system/helper/trans-key';
import { useUpdateSite, useLangs } from 'rosis-api';
import PropTypes from 'prop-types';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';

const { Option } = Select;

function DefaultLang({ siteName, defaultLang }) {
  const { data: languages } = useLangs();
  const { mutate: update, ...updateStatus } = useUpdateSite();

  useApiFeedback({
    status: updateStatus,
    successMessage: _t('success::Default language has been changed'),
    errorMessage: _t('error::Change default language failed')
  });

  const changeDefaultLang = (value) => {
    update({ name: siteName, lang: value });
  };

  return (
    <Form name="defaultLang" autoComplete="off">
      <Form.Item label={_t('site::Default language')}>
        <Select
          id="defaultLang"
          defaultValue={defaultLang}
          style={{ width: 100 }}
          onSelect={changeDefaultLang}>
          {languages &&
            languages.map((lang) => (
              <Option key={lang.id} value={lang.id}>
                {_t(stdKeys.lang(lang.id))}
              </Option>
            ))}
        </Select>
      </Form.Item>
    </Form>
  );
}

DefaultLang.propTypes = {
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  siteName: PropTypes.string,
  defaultLang: PropTypes.string
};

export default DefaultLang;
