import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { _t } from 'rosis-translation';
import { Select } from 'antd';
import Item from '../../../Item';

const { Option } = Select;

const FTMultiSelect = React.forwardRef((props, ref) => {
  const [selectOpts, setSelectOpts] = useState([]);
  const { opts, value, onChange } = props;

  useEffect(() => {
    setSelectOpts(
      (opts || []).map((option) => {
        const value = option;
        const label = _t(option);
        return { value, label };
      }) || []
    );
  }, [opts]);
  const workValue = value || [];
  return (
    <Item {...props} value={workValue}>
      <Select ref={ref} mode="multiple" allowClear value={workValue} onChange={onChange}>
        {selectOpts.map(({ value, label }) => (
          <Option key={value} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    </Item>
  );
});

FTMultiSelect.displayName = 'FTMultiSelect';

FTMultiSelect.propTypes = {
  opts: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func
};

const multiselect = {
  value: 'multiselect',
  get label() {
    return _t('::Multiselect');
  },
  example: <FTMultiSelect opts={['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5']} />,
  component: FTMultiSelect,
  default: true
};

export default multiselect;
