import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Space } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const EnableButton = ({ id, enabled, onChange }) => {
  const [isChecked, setChecked] = useState(enabled);
  useEffect(() => {
    setChecked(enabled);
  }, [enabled]);

  const updateQuestion = ({ value }) => {
    setChecked(value);
    onChange({ value, id, oldValue: value, name: 'enabled' });
  };
  return (
    <Space>
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        checked={isChecked}
        onChange={(value) => updateQuestion({ value, id })}
      />
    </Space>
  );
};

EnableButton.propTypes = {
  enabled: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func
};

export default EnableButton;
