import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

function Download({ link, onClick, type, children }) {
  return (
    <a href={link} download>
      <Button onClick={onClick} type={type}>
        {children}
      </Button>
    </a>
  );
}

Download.propTypes = {
  link: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['primary', 'ghost', 'dashed', 'link', 'text', 'default']),
  children: PropTypes.node
};

export default Download;
