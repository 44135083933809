import { useQuery, useMutation } from 'react-query';

import { url, getData, put } from './internal/rest-api';

export const useRegex = () => useQuery(['passwd', 'regex'], () => getData(`passwd/regexp`));

export const usePasswordChecklist = () =>
  useQuery(['passwd', 'checklist'], () => getData(`passwd/checklist`));

export const usePswdReq = () => useQuery('passwd', () => getData('passwd/must-have'));

export const useUpdatePswdReq = (queryClient) =>
  useMutation((value) => put(url`passwd/must-have/${value}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('passwd');
    }
  });

export const useGeneratePsd = () => useQuery(['pass', 'gen'], () => getData(`passwd/gen`));
