import React, { useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { _t } from 'rosis-translation';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useUserRemove } from 'rosis-api';
import { useApiFeedback } from 'rosis-components/hooks/use-api-feedback';

function RemoveUser({ id, table, disabled, onSuccess, onFail, actionComplete }) {
  const queryClient = useQueryClient();
  const {
    mutate: remove,
    isLoading: isLoadingRemoveUser,
    ...removeStatus
  } = useUserRemove(queryClient);

  useApiFeedback({
    status: {
      ...removeStatus,
      isLoading: isLoadingRemoveUser
    },
    successMessage: _t('success::User removed'),
    errorMessage: _t('error::User removing failed'),
    silentSuccess: true,
    silentError: true,
    onSuccess: (msg) => {
      onSuccess(msg);
      actionComplete();
      hideModal();
    },
    onError: (msg) => {
      onFail(msg);
      hideModal();
    }
  });

  const [visibleModal, setVisibleModal] = useState(false);

  const removeUser = () => {
    remove({ id });
  };

  const showModal = () => {
    setVisibleModal(true);
  };

  const hideModal = () => {
    setVisibleModal(false);
  };

  return (
    <>
      <Tooltip
        placement="bottomLeft"
        title={
          disabled
            ? _t('::The account of the currently logged in user cannot be removed')
            : _t('::Remove user')
        }>
        {table ? (
          <Button type="link" disabled={disabled}>
            <FontAwesomeIcon icon={faTrash} onClick={showModal} />
          </Button>
        ) : (
          <Button
            icon={<FontAwesomeIcon icon={faTrash} />}
            disabled={isLoadingRemoveUser || disabled}
            className="font-bold"
            onClick={showModal}>
            {_t('button::Remove user')}
          </Button>
        )}
      </Tooltip>

      <Modal
        title={_t('::Remove user?')}
        open={visibleModal}
        onOk={removeUser}
        onCancel={hideModal}
        okText={_t('::Remove')}
        cancelText={_t('::Cancel')}>
        <p>{_t('::User will be removed.')}</p>
      </Modal>
    </>
  );
}

RemoveUser.defaultProps = {
  actionComplete: () => {}
};

RemoveUser.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  table: PropTypes.bool,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  actionComplete: PropTypes.func
};

export default RemoveUser;
